import { useSelector } from "react-redux";

const useUserRole = () => {
  // Access the Redux auth state.
  // This depends on how your Redux store is structured.

  const auth = useSelector((state) => state.auth);
  const userRole = auth.me.role;
  const isAuthenticated = auth.isAuthenticated;
  const meInfo = auth.me;

  const isAdmin = userRole === "admin";
  const isManager = userRole === "manager";
  const isEditor = userRole === "editor";
  const isEditable = userRole === "manager" || userRole === "editor";
  const isStaff = isAdmin || isManager || isEditor;

  const subscription = isStaff ? 3 : auth.me.subscription || 1;

  return {
    isAdmin,
    isManager,
    isEditor,
    isEditable,
    isStaff,
    isAuthenticated,
    meInfo,
    auth,
    subscription,
  };
};

export default useUserRole;

