import React from "react";
import { Col } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import VideoItem from "./VideoItem";
import { mergeArrays } from "../../../../../utils/common";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import TopnewsCardHeader from "./Header";
import { Img } from "react-image";
import { useParams } from "react-router-dom";

const TopCommunityHeader = ({ title, website, route, trend = true }) => {
  return (
    <TopnewsCardHeader
      leftComponent={
        <div className="d-flex flex-row align-items-center">
          {trend ? (
            <Img width={20} height={20} src="/images/trending.png" />
          ) : (
            <Img width={20} height={20} src="/images/addIcon.png" />
          )}
          <span className="topnews-card-title">{title}</span>
        </div>
      }
      website={website}
      route={route}
    />
  );
};

const RecentAndTopVideos = ({ highlightData, vertical }) => {
  const { domain } = useParams();

  const topVideos = highlightData?.["top_videos"] ?? [];
  // TODO uncomment this code.
  // const recentVideos = highlightData?.['recent_videos'] ?? [];
  const mergedVideos = mergeArrays(topVideos, []);
  const isMobile = useMediaQuery();
  if (mergedVideos.length === 0) return <></>;

  return (
    <Col xs={12} md={vertical ? 12 : 4}>
      <TopCommunityHeader
        title="Trending Videos Last 30 Days"
        website={domain}
        route="videos"
      />
      <div
        className={`${isMobile ? "h-content" : ""} topnews-card`}
        style={{ height: "unset" }}
      >
        {mergedVideos.map((item, index) => {
          return <VideoItem key={index} data={item} />;
        })}
      </div>
    </Col>
  );
};

export default RecentAndTopVideos;
