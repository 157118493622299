import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import { useDispatch } from "react-redux";

import {
  Form,
  Row,
  Col
} from 'react-bootstrap';

import { addKeywordSchema } from './validation';
import ProjectFilter from '../../ProjectFilter';
import KeywordCategorySelection from '../../KeywordCategorySelection';
import { createMainModel } from '../../../../../store/actions/mainModelActions';

const AddKeywordModal = ( props ) => {
  const dispatch = useDispatch();
  const { show, setShowModal } = props;
  const [keyword, setKeyword] = useState('All');
  const [keywordNick, setKeywordNick] = useState('all')
  const [category, setCategory] = useState('SEARCH');
  const formik = useFormik({
    initialValues: {
      keyword: '',
      project_name: '',
      category: '',
    },
    validationSchema: addKeywordSchema,
    onSubmit: async (values) => {
      values.project_name = keyword
      values.category = category
      await dispatch(createMainModel('project/keyword/', values));
    },
  });

  const udpateKeyword = (keywordObject) => {
    setKeyword(keywordObject.label);
    setKeywordNick(keywordObject.value);
  }
  const udpateCategory = (categoryObject) => {
    setCategory(categoryObject);
  }
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={'sm'}
    // aria-labelledby="filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 18, textAlign: 'center', width: '100%' }}>
          Add Keyword
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="pt-3" onSubmit={formik.handleSubmit}>
          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className='mr-3'>Project Name</div>
            <ProjectFilter
              keyword={{ label: keyword, value: keywordNick }}
              setKeyword={udpateKeyword}
            />
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Keyword</div>
            <Form.Control
              type='text'
              size='lg'
              placeholder='Enter Keyword ...'
              name='keyword'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.keyword}
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="text-danger">{formik.errors.name}</p>
            ) : null}
          </Form.Group>

          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className='mr-3'>Category</div>
            <KeywordCategorySelection
              category={category}
              changeCategory={udpateCategory}
            />
          </Form.Group>

          <Row className="mt-4">
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  disabled={!formik.isValid}
                  type="submit"
                >
                  Add
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer>
        
      </Modal.Footer> */}
    </Modal>
  );
};

export default AddKeywordModal;