import React from "react";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import RecentAndTopPatners from "./Components/RecentAndTopPatners";
import RecentAndTopVideos from "./Components/RecentAndTopVideos";
import Statistics from "./Components/Statistics";
import "./styles.css";
import useMediaQuery from "../../../../hooks/useMediaQuery";

const TopnewsCards = ({ detailsData, vertical }) => {
  const { data: highlightData } = useSelector((state) => state.highlight);
  const isMobile = useMediaQuery();
  return (
    <Container
      fluid
      className="p-0"
      style={
        vertical
          ? {
              position: "sticky",
              top: 0,
              overflow: "hidden auto",
              maxHeight: isMobile ? "unset" : "100vh",
            }
          : {}
      }
    >
      <Row className="d-flex justify-content-center align-items-center">
        <Statistics
          detailsData={detailsData}
          highlightData={highlightData}
          vertical={vertical}
        />
        <RecentAndTopPatners
          highlightData={highlightData}
          vertical={vertical}
        />
        <RecentAndTopVideos highlightData={highlightData} vertical={vertical} />
      </Row>
    </Container>
  );
};

export default TopnewsCards;
