import React from "react";
import { useSelector } from "react-redux";
import MainSelection from "../../../Selections/MainSelection";
import { capitalizeFirstLetter } from "../../../../utils/common";

const DomainFilter = (props) => {
  const { domain, setDomain } = props;
  const socialDomains =
    useSelector((state) => state.socialDomain.socialDomains) || [];

  let items = socialDomains?.map((domain) => {
    return { label: capitalizeFirstLetter(domain), value: domain };
  });

  const defaultItem = {
    label: capitalizeFirstLetter(domain),
    value: domain ? domain : "website",
  };

  if (
    !socialDomains?.includes(domain) &&
    domain !== "all" &&
    domain !== undefined
  )
    items = [defaultItem, ...items];
  items = [
    { label: "Website", value: "website" },
    { label: "All", value: "all" },
    ...items,
  ];

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <MainSelection
        title=""
        selectionItems={items}
        defaultItem={defaultItem}
        setItem={setDomain}
        sorted={false}
        width={150}
      />
    </div>
  );
};

export default DomainFilter;
