import React from "react";

import MainTable from "../../../../components/Tables/MainTable";
import { useParams } from "react-router-dom";

import {
  DISCORD_CHANNELS_TABLE_HEADER,
  DISCORD_CHANNELS_MAIN_TABLE_API,
} from "../../../../config/constants/tableHeaders";
import { RecommendItems } from "../..";

const DiscordChannels = (props) => {
  const UPDATE_DISCORD_CHANNELS_TABLE_HEADER =
    DISCORD_CHANNELS_TABLE_HEADER.filter(
      (header) => header.title !== "Guild Id"
    );
  const { nickName } = useParams();

  return (
    <>
      <MainTable
        tableHeader={UPDATE_DISCORD_CHANNELS_TABLE_HEADER}
        URL={DISCORD_CHANNELS_MAIN_TABLE_API}
        withTermsFilter
        projectNickName={nickName}
        withoutProjectFilter
        withoutFilterModal
        defaultTerm={"7d"}
        model={"discord-channels"}
      />
      <RecommendItems />
    </>
  );
};

export default DiscordChannels;

