import React, {} from 'react'
import Modal from 'react-bootstrap/Modal';

import {
  Form,
  Row,
  Col
} from 'react-bootstrap';

// import { deleteMainModel } from '../../../../../store/actions/mainModelActions';

const DeleteServerInviteModal = ( props ) => {
  const { show, setShowModal, item } = props;
  // const oldId = item?.id;
  const onDeleteServerInvite = async () => {
    console.log("prevent this feature.")
    // await dispatch(deleteMainModel((`discord/serverinvite/${oldId}/`));
  }

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      size={'sm'}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 18, textAlign: 'center', width: '100%' }}>
          Delete Server
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="pt-3" onSubmit={()=> {onDeleteServerInvite()}}>
          <h4> Really, Are you sure you want to permanently remove this Server : "{item?.name}"?</h4>

          <Row className="mt-4">
            <Col>
              <div>
                <button
                  className="btn btn-block btn-danger btn-lg submit login-button disabled"
                  type="submit"
                >
                  Delete
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};


export default DeleteServerInviteModal;