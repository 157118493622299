import { useHistory } from "react-router-dom";
import "./styles.css";

const HomeButtonComponent = ({
  style,
  title = "Click me",
  link = null,
  className,
  variant = "primary",
  innerClassname = "large",
  innerStyle = {},
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (link) {
      history.push(link);
    }
  };
  return (
    <div style={style} className={className}>
      <button
        onClick={handleClick}
        className={`home-btn ${innerClassname} ${
          variant !== "primary" ? variant : ""
        }`}
        style={innerStyle}
      >
        {title}
      </button>
    </div>
  );
};

export const HomeButton = HomeButtonComponent;

