import React from "react";
import { useLocation } from "react-router-dom";
import { routing2TableHeaderURL } from "../../../../../../utils/routing2TableHelper";
import { getDefaultOrdering } from "../../../../../../utils/tableDataHelper";
import MainTable from "../../../../../../components/Tables/MainTable";
import {
  CATEGORIES,
  WEBSITE_TOPIC_FILTER_MODAL_FIELDS,
  YOUTUBE_CHANNELS_FILTER_MODAL_FIELDS,
  YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS,
} from "../../../../../../config/constants/data";

const DashboardTableView = ({
  category = "category",
  model = "trending",
  topic = undefined,
  domain = undefined,
  modelName,
  leftComponent = null,
}) => {
  const location = useLocation();
  let currentModelName = modelName;
  if (topic !== undefined) currentModelName = "website-link--category-topic";
  if (domain !== undefined) currentModelName = "social-link--category-domain";
  const [tableHeader, URL] = routing2TableHeaderURL(model, topic, domain);
  const initOrdering = getDefaultOrdering(model);
  let filters = window.advancedFilters || {};
  filters = { ...filters, ...location.state };
  if (domain !== undefined) {
    filters["domain"] = domain;
  }
  const categoryLabel =
    category === "category"
      ? category
      : CATEGORIES.filter((row) => row.value === category)?.[0]?.label ||
        "category";

  return (
    <div>
      {model === "company" ? (
        <>This company page come soon!</>
      ) : (
        <MainTable
          leftComponent={leftComponent}
          currentTopic={topic}
          tableHeader={tableHeader}
          URL={URL}
          category={categoryLabel}
          filters={filters}
          model={model}
          withTermsFilter={model !== "market"}
          withWebsiteTopicTypeSelection={model === "websites"}
          withoutFilterModal={
            model !== "youtube-channels" &&
            model !== "youtube-videos" &&
            currentModelName !== "website-link--category-topic"
          }
          withoutProjectFilter={
            model !== "youtube-channels" &&
            model !== "youtube-videos"
          }
          filterModalFields={
            model === "youtube-videos"
              ? YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS
              : model === "websites"
              ? WEBSITE_TOPIC_FILTER_MODAL_FIELDS
              : YOUTUBE_CHANNELS_FILTER_MODAL_FIELDS
          }
          withVideoLengthFilter={model === "youtube-videos"}
          initOrdering={initOrdering}
          defaultTerm={"1d"}
          withoutHeaderCheck={model === "websites" && topic === undefined}
          withoutTopicFilter={
            model === "youtube-videos" ||
            model === "youtube-channels" ||
            // (model === "websites" && topic === undefined) ||
            model === "news" ||
            model === "market" ||
            model === "trending"
          }
          modelName={currentModelName}
        />
      )}
    </div>
  );
};

export default DashboardTableView;

