import {
  GET_PROJECT_DETAILS_LOADING,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAIL
} from '../types';

const initialState = {
  data: [],
  count: 0,
  isLoading: false,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROJECT_DETAILS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        data: payload.data,
        count: payload.count,
        isLoading: false,
        error: null,
      };
    case GET_PROJECT_DETAILS_FAIL:
      return {
        ...state,
        data: [],
        count: 0,
        isLoading: false,
        error: payload?.error
      };
    default:
      return state;
  }
}
