import axios from 'axios';

import {
  GET_MOST_VIDEOS_LOADING,
  GET_MOST_VIDEOS_SUCCESS,
  GET_MOST_VIDEOS_FAIL,
} from '../types';
import { attachTokenToHeaders } from './authActions';
import { API_HOST } from '../../config/constants/data';

export const getYoutubeMostVideosApi = (params) => async (dispatch, getState) => {
  dispatch({ type: GET_MOST_VIDEOS_LOADING });
  try {
    const response = await axios.get(`${API_HOST}/api/v1/youtube/videos/?keyword=${params.name}&terms=7d&page_size=10&ordering=-viewCount`, attachTokenToHeaders(getState));
    dispatch({
      type: GET_MOST_VIDEOS_SUCCESS,
      payload: { mostVideosData: response.data.results },
    });

  } catch (err) {
    console.log("getYoutubeMostVideosApi error: ", err)
    dispatch({
      type: GET_MOST_VIDEOS_FAIL,
      payload: { topChannelsError: err.response.data.message },
    });
  }
};
