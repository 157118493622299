import axios from 'axios';

import {
  SET_SUBSCRIBE_EMAIL_LOADING,
  SET_SUBSCRIBE_EMAIL_SUCCESS,
  SET_SUBSCRIBE_EMAIL_FAIL,
} from '../types';

import { API_HOST } from '../../config/constants/data';
import { attachTokenToHeaders } from './authActions';

export const setSubscribeEmail = (params) => async (dispatch, getState) => {
  dispatch({ type: SET_SUBSCRIBE_EMAIL_LOADING });
  try {
    const paramURI = `${API_HOST}/api/v1/user/newsletter/sign-up`;
    const response = await axios.post(
      paramURI,
      params,
      attachTokenToHeaders(getState)
    );

    dispatch({
      type: SET_SUBSCRIBE_EMAIL_SUCCESS,
      payload: { data: response.data.status },
    });
  } catch (err) {
    dispatch({
      type: SET_SUBSCRIBE_EMAIL_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};