import React, { useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import { ToButton, MobileToButton} from './Styles';
import useMediaQuery from '../../hooks/useMediaQuery';

const ToTopButton = () => {

  const [visible, setVisible] = useState(false)
  const isMobile = useMediaQuery();

  const toggleVisible = () => {
    const scrolled = document.body.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
        in place of 'smooth' */
    });
  };

  document.body.addEventListener('scroll', toggleVisible);
  if (isMobile)
    return (
      <MobileToButton>
        <FaArrowCircleUp onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }} />
      </MobileToButton>
    );
  else
    return (
      <ToButton>
        <FaArrowCircleUp onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }} />
      </ToButton>
    );
}

export default ToTopButton;
