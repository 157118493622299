import React from 'react'
import './styles.css'

const GroupButton = (props) => {
  return (
    <div className={props.groupClass}>
      {props?.items && props.items.map((button, i) => (
        <div key={i} onClick={() => { props.onClick(button.value) }} className={props.buttonClass.concat(props.selected === button.value ? " selected" : "")}>
          {button.label}  
        </div>
      ))}
    </div>
  )
}

export default GroupButton