import React from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const TopnewsCardHeader = ({ leftComponent, website, route, order=null, more = true }) => {
  const newPath = `/website/${website}/${route}`;
  return (
    <div className="d-flex flex-row align-items-center justify-content-between mb-3">
      <div>
        <h5 className="mb-0">{leftComponent}</h5>
      </div>
      {more ? (
        <Link
          className="d-flex flex-row align-items-center more-text-wrapper"
          to={{
            pathname: newPath,
            state: {order: order},
          }}
          style={{ cursor: "pointer" }}
        >
          {/* Removed the <a> tag and replaced with onClick event */}
          <span className="more-text">More</span>
          <Icon
            icon="uiw:right"
            height="12px"
            className="ml-1"
            color="#3761fb"
          />
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TopnewsCardHeader;
