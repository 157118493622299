import {
  LOGOUT_SUCCESS,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ME_LOADING,
  ME_SUCCESS,
  ME_FAIL,
  REGISTER_WITH_EMAIL_LOADING,
  REGISTER_WITH_EMAIL_SUCCESS,
  REGISTER_WITH_EMAIL_FAIL,
  REGISTER_WITH_EMAIL_GET_CODE,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  GOOGLE_LOGIN_SUCCESS,
  SET_USER_PROFILE_LOADING,
  SET_USER_PROFILE_SUCCESS,
  SET_USER_PROFILE_FAIL,
  SET_SUBSCRIBE_EMAIL_LOADING,
  SET_SUBSCRIBE_EMAIL_SUCCESS,
  SET_SUBSCRIBE_EMAIL_FAIL,
} from '../types';

const initialState = {
  me: {
    role: "public",
    subscription: 0,
    language: "en"
  },
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  isLoading: false,
  isSubscribed: false,
  // appLoaded: true,
  status: "",
  authCodeId: null,
  error: null,
  googleLoggedIn: localStorage.getItem('googleLoggedIn') === "true",
  googleImageUrl: localStorage.getItem('googleImageUrl'),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case ME_LOADING:
      return {
        ...state,
        isLoading: true,
        // appLoaded: false,
        authCodeId: null,
        error: null,
      };
    case ME_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        me: payload.me,
        status: payload.status,
        error: null,
        // appLoaded: true,
      };
    case ME_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        me: null,
        error: null,
        // appLoaded: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
    case SET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        status: payload.status,
        isLoading: false,
        error: null,
      };
    case CHANGE_PASSWORD_FAIL:
    case SET_USER_PROFILE_FAIL:
      return {
        ...state,
        status: "FAIL",
        isLoading: false,
        error: payload.error,
      };
    case LOGIN_LOADING:
    case CHANGE_PASSWORD_LOADING:
    case REGISTER_WITH_EMAIL_LOADING:
    case SET_USER_PROFILE_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
        authCodeId: null,
        status: "",
      };
    case LOGIN_SUCCESS:
    case REGISTER_WITH_EMAIL_SUCCESS:
      localStorage.setItem('token', payload.token);
      localStorage.setItem('googleLoggedIn', false);
      localStorage.setItem('googleImageUrl', null);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        token: payload.token,
        me: payload.me,
        authCodeId:null,
        error: null,
        isSubscribed:false,
        googleLoggedIn: false,
        googleImageUrl: null,
      };
    case GOOGLE_LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      localStorage.setItem('googleLoggedIn', true);
      localStorage.setItem('googleImageUrl', payload.imageUrl);
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        token: payload.token,
        me: payload.me,
        authCodeId:null,
        error: null,
        isSubscribed:false,
        googleLoggedIn: true,
        googleImageUrl: payload.imageUrl,
      };
    case REGISTER_WITH_EMAIL_GET_CODE: 
      return {
        ...state,
        isLoading: false,
        authCodeId: payload.authCodeId,
        error: null,
      };
    case LOGOUT_SUCCESS:
    case LOGIN_FAIL:
    case REGISTER_WITH_EMAIL_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        authCodeId:null,
        error: payload?.error, //payload message ovde i razdvoj logout i fail
      };
    case SET_SUBSCRIBE_EMAIL_SUCCESS:
      return {
        ...state,
        isSubscribed: true,
      };
    case SET_SUBSCRIBE_EMAIL_LOADING:
    case SET_SUBSCRIBE_EMAIL_FAIL:
      return {
        ...state,
        isSubscribed: false,
      };
    default:
      return state;
  }
}