import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { MODEL_IDS, SITE_IDS } from "../config/constants/data";

dayjs.extend(utc);

const processCompareValueException = (value, type = "string") => {
  try {
    if (value === null || value === undefined) {
      if (type === "numeric") {
        return 0;
      } else return "";
    }
    if (!isNaN(value) && type === "numeric") {
      // numeric
      try {
        return parseFloat(value);
      } catch (error) {
        return 0;
      }
    }
    return value;
  } catch (error) {
    return "";
  }
};
// Sorting funciton for table data
export const sortTableData = (tableData, direction, sortField, sortType) => {
  let temp_data = [];
  if (sortType === "datetime" || sortType === "date") {
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              dayjs(processCompareValueException(a[sortField])) -
              dayjs(processCompareValueException(b[sortField]))
          ))
      : (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              dayjs(processCompareValueException(b[sortField])) -
              dayjs(processCompareValueException(a[sortField]))
          ));
  } else if (sortType === "promoting") {
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              dayjs(processCompareValueException(a["final_datetime"])) -
              dayjs(processCompareValueException(a["first_datetime"])) -
              dayjs(processCompareValueException(b["final_datetime"])) +
              dayjs(processCompareValueException(b["first_datetime"]))
          ))
      : (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              dayjs(processCompareValueException(b["final_datetime"])) -
              dayjs(processCompareValueException(b["first_datetime"])) -
              dayjs(processCompareValueException(a["final_datetime"])) +
              dayjs(processCompareValueException(a["first_datetime"]))
          ));
  } else if (sortType === "lang") {
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort((a, b) =>
            processCompareValueException(a[sortField])
              .split(",")[0]
              .localeCompare(
                processCompareValueException(b[sortField]).split(",")[0]
              )
          ))
      : (temp_data = []
          .concat(tableData)
          .sort((a, b) =>
            processCompareValueException(b[sortField])
              .split(",")[0]
              .localeCompare(
                processCompareValueException(a[sortField]).split(",")[0]
              )
          ));
  } else if (sortType === "numeric") {
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              processCompareValueException(a[sortField], "numeric") -
              processCompareValueException(b[sortField], "numeric")
          ))
      : (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              processCompareValueException(b[sortField], "numeric") -
              processCompareValueException(a[sortField], "numeric")
          ));
  } else if (sortType === "prePercentage") {
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              (a["pre_".concat(sortField)] === "0"
                ? 0
                : (parseFloat(a[sortField]) -
                    parseFloat(a["pre_".concat(sortField)])) /
                  parseFloat(a["pre_".concat(sortField)])) -
              (b["pre_".concat(sortField)] === "0"
                ? 0
                : (parseFloat(b[sortField]) -
                    parseFloat(b["pre_".concat(sortField)])) /
                  parseFloat(b["pre_".concat(sortField)]))
          ))
      : (temp_data = []
          .concat(tableData)
          .sort(
            (b, a) =>
              (a["pre_".concat(sortField)] === "0"
                ? 0
                : (parseFloat(a[sortField]) -
                    parseFloat(a["pre_".concat(sortField)])) /
                  parseFloat(a["pre_".concat(sortField)])) -
              (b["pre_".concat(sortField)] === "0"
                ? 0
                : (parseFloat(b[sortField]) -
                    parseFloat(b["pre_".concat(sortField)])) /
                  parseFloat(b["pre_".concat(sortField)]))
          ));
  } else if (sortType === "suggestRate") {
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              (a["total_" + sortField] === "0"
                ? 0
                : parseFloat(a[sortField]) /
                  parseFloat(a["total_" + sortField])) -
              (b["total_" + sortField] === "0"
                ? 0
                : parseFloat(b[sortField]) /
                  parseFloat(b["total_" + sortField]))
          ))
      : (temp_data = []
          .concat(tableData)
          .sort(
            (b, a) =>
              (a["total_" + sortField] === "0"
                ? 0
                : parseFloat(a[sortField]) /
                  parseFloat(a["total_" + sortField])) -
              (b["total_" + sortField] === "0"
                ? 0
                : parseFloat(b[sortField]) /
                  parseFloat(b["total_" + sortField]))
          ));
  } else if (sortType === "avgViews") {
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              (a["videos"] === "0"
                ? 0
                : parseFloat(a[sortField]) / parseFloat(a["videos"])) -
              (b["videos"] === "0"
                ? 0
                : parseFloat(b[sortField]) / parseFloat(b["videos"]))
          ))
      : (temp_data = []
          .concat(tableData)
          .sort(
            (b, a) =>
              (a["videos"] === "0"
                ? 0
                : parseFloat(a[sortField]) / parseFloat(a["videos"])) -
              (b["videos"] === "0"
                ? 0
                : parseFloat(b[sortField]) / parseFloat(b["videos"]))
          ));
  } else if (sortType === "viewRate") {
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              (a["viewCount"] === "0"
                ? 0
                : a["subscriberCount"] === "0"
                ? 0
                : parseFloat(a[sortField]) /
                  parseFloat(a["subscriberCount"])) -
              (b["viewCount"] === "0"
                ? 0
                : b["subscriberCount"] === "0"
                ? 0
                : parseFloat(b[sortField]) /
                  parseFloat(b["subscriberCount"]))
          ))
      : (temp_data = []
          .concat(tableData)
          .sort(
            (b, a) =>
              (a["videos"] === "0"
                ? 0
                : a["subscriberCount"] === "0"
                ? 0
                : parseFloat(a[sortField]) /
                  parseFloat(a["subscriberCount"])) -
              (b["videos"] === "0"
                ? 0
                : b["subscriberCount"] === "0"
                ? 0
                : parseFloat(b[sortField]) /
                  parseFloat(b["subscriberCount"]))
          ));
  } else if (sortType === "preCount") {
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort(
            (a, b) =>
              (a["pre_".concat(sortField)] === "0"
                ? 0
                : parseFloat(a[sortField]) -
                  parseFloat(a["pre_".concat(sortField)])) -
              (b["pre_".concat(sortField)] === "0"
                ? 0
                : parseFloat(b[sortField]) -
                  parseFloat(b["pre_".concat(sortField)]))
          ))
      : (temp_data = []
          .concat(tableData)
          .sort(
            (b, a) =>
              (a["pre_".concat(sortField)] === "0"
                ? 0
                : parseFloat(a[sortField]) -
                  parseFloat(a["pre_".concat(sortField)])) -
              (b["pre_".concat(sortField)] === "0"
                ? 0
                : parseFloat(b[sortField]) -
                  parseFloat(b["pre_".concat(sortField)]))
          ));
  } else {
    // default sortField === false, 'string'.
    direction === "+"
      ? (temp_data = []
          .concat(tableData)
          .sort((a, b) =>
            processCompareValueException(a[sortField]).localeCompare(
              processCompareValueException(b[sortField])
            )
          ))
      : (temp_data = []
          .concat(tableData)
          .sort((a, b) =>
            processCompareValueException(b[sortField]).localeCompare(
              processCompareValueException(a[sortField])
            )
          ));
  }
  return temp_data;
};

export const getDefaultOrderingField = (tableModelId) => {
  switch (tableModelId) {
    case 17:
      return "influencers";
    default:
      return "participants_count";
  }
};

export const getPrimaryKey = (model) => {
  switch (model) {
    case "youtube-channels":
    case "youtube":
      return "channelId";
    case "twitter-users":
    case "twitter":
      return "username";
    case "discord-servers":
    case "discord":
      return "id";
    case "websites":
    case "website":
      return "domain";
    case "analytics":
      return "domain";
    case "telegram-groups":
    case "telegram":
      return "dialog_id";
    default:
      return "id";
  }
};
export const getVotingParams = (mainPath, finalPath = "") => {
  const checkPath = finalPath === "websites" ? finalPath : mainPath;
  let defaultParams = {
    siteId: SITE_IDS.PROJECT,
    modelId: MODEL_IDS.PROJECT,
    primaryKey: "keyword",
  };
  switch (checkPath) {
    case "youtube-channels":
    case "channels":
      defaultParams.siteId = SITE_IDS.CHANNEL;
      defaultParams.modelId = MODEL_IDS.CHANNEL;
      defaultParams.primaryKey = "channelId";
      break;
    case "twitter-users":
    case "users":
      defaultParams.siteId = SITE_IDS.USER;
      defaultParams.modelId = MODEL_IDS.USER;
      defaultParams.modelId = "user";
      break;
    case "discord-servers":
    case "servers":
    case "discord":
      defaultParams.siteId = SITE_IDS.SERVER;
      defaultParams.modelId = MODEL_IDS.SERVER;
      defaultParams.primaryKey = "id";
      break;
    case "websites":
      defaultParams.siteId = SITE_IDS.WEBSITE;
      defaultParams.modelId = MODEL_IDS.WEBSITE;
      defaultParams.primaryKey = "domain";
      break;
    case "telegram-groups":
    case "groups":
    case "telegram":
      defaultParams.siteId = SITE_IDS.GROUP;
      defaultParams.modelId = MODEL_IDS.GROUP;
      defaultParams.primaryKey = "dialog_id";
      break;
    case "blog":
      defaultParams.siteId = SITE_IDS.BLOG;
      defaultParams.modelId = MODEL_IDS.BLOG;
      defaultParams.primaryKey = "id";
      break;
    case "":
    case "market":
    case "keyword":
      defaultParams.siteId = SITE_IDS.PROJECT;
      defaultParams.modelId = MODEL_IDS.PROJECT;
      defaultParams.primaryKey = "keyword";
      break;
    default:
      break;
  }
  return defaultParams;
};

export const modelName2VotingParams = (modelName) => {
  let defaultParams = {
    siteId: SITE_IDS.PROJECT,
    modelId: MODEL_IDS.PROJECT,
    primaryKey: "keyword",
  };
  switch (modelName) {
    case "youtube-channel":
      defaultParams.siteId = SITE_IDS.CHANNEL;
      defaultParams.modelId = MODEL_IDS.CHANNEL;
      defaultParams.primaryKey = "channelId";
      break;
    case "twitter-user":
      defaultParams.siteId = SITE_IDS.USER;
      defaultParams.modelId = MODEL_IDS.USER;
      defaultParams.modelId = "user";
      break;
    case "discord-server":
      defaultParams.siteId = SITE_IDS.SERVER;
      defaultParams.modelId = MODEL_IDS.SERVER;
      defaultParams.primaryKey = "id";
      break;
    case "website--topic":
      defaultParams.siteId = SITE_IDS.WEBSITE;
      defaultParams.modelId = MODEL_IDS.WEBSITE;
      defaultParams.primaryKey = "domain";
      break;
    case "telegram-group":
      defaultParams.siteId = SITE_IDS.GROUP;
      defaultParams.modelId = MODEL_IDS.GROUP;
      defaultParams.primaryKey = "dialog_id";
      break;
    default:
      break;
  }
  return defaultParams;
};

export const convertTerm2Hours = (term) => {
  let hours = 1;
  switch (term) {
    case "1d":
      hours = 24;
      break;
    case "7d":
      hours = 24 * 7;
      break;
    case "30d":
      hours = 24 * 30;
      break;
    case "3m":
      hours = 24 * 30 * 3;
      break;
    case "6m":
      hours = 24 * 30 * 6;
      break;
    case "1y":
      hours = 24 * 365;
      break;
    default:
      break;
  }
  return hours;
};

export const getDiffDays = (date) => {
  try {
    const date_formatted = dayjs(date, "YYYY-MM-DD");
    return dayjs.duration(dayjs().startOf("day").diff(date_formatted)).asDays();
  } catch (e) {
    return 1;
  }
};

export const transformNumber = (num, transform = "integer") => {
  if (num === "") return 0;
  const floatNum = parseFloat(num);
  let plus = "";
  if (floatNum < 0) plus = "-";
  const intNum = parseInt(num * (plus === "-" ? -1 : 1));
  if (isNaN(intNum) || isNaN(floatNum)) {
    return 0;
  } else if (transform === "integer") {
    return intNum;
  } else if (transform === "commaNumber") {
    try {
      return plus.concat(
        intNum.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
      );
    } catch (e) {
      return num;
    }
  } else if (transform === "commaNumberDollar") {
    try {
      return "$".concat(
        intNum.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
      );
    } catch (e) {
      console.log("error", e);
      return num;
    }
  } else if (transform === "coinPrice") {
    try {
      if (floatNum >= 1) {
        // Format for numbers greater than or equal to 1
        return (
          "$" + floatNum.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        );
      } else {
        // return "$" + floatNum.toPrecision(4);
        // Check if the number is in scientific notation
        const numStr = num.toString();
        if (numStr.includes("e")) {
          // Determine the exponent
          // const exponent = parseInt(numStr.split("e-")[1]);
          // Adjust precision based on exponent
          // const precision = exponent + 3;
          // return "$" + floatNum.toFixed(precision);
          return "$" + floatNum.toPrecision(4);
        } else {
          // Format small numbers not in scientific notation
          const match = floatNum.toString().match(/0\.0*[1-9]\d{0,3}/);
          return match ? "$" + match[0] : "$" + floatNum;
        }
      }
      // return "$" + floatNum.toString().match(/0.0*[1-9]\d{3}/g)[0];
    } catch (e) {
      return num;
    }
  } else if (transform === "percentage") {
    return parseInt(num * 100) / 100 + "%";
  } else if (transform === "shorterNumber") {
    return plus.concat(
      intNum < 100
        ? intNum
        : intNum < 1000000
        ? Math.round(intNum / 100) / 10 + "K"
        : intNum < 1000000000
        ? Math.round(intNum / 100000) / 10 + "M"
        : Math.round(intNum / 100000000) / 10 + "B"
    );
  } else if (transform === "float2") {
    return floatNum.toFixed(2);
  } else if (transform === "float4") {
    return floatNum.toFixed(4);
  } else if (transform === "float3") {
    return floatNum.toFixed(3);
  } else {
    return num;
  }
};

export const diffTimeStamp = (timestamp, until = "now", min_unit = "all") => {
  if (timestamp === undefined) {
    return "";
  }

  // Current date or custom 'until' date in UTC
  const curDate = until === "now" ? dayjs().utc() : dayjs.utc(until);
  const targetDateUtc = dayjs.utc(timestamp);

  const units = ["year", "month", "day", "hour", "minute", "second"];
  let diff, unit;

  for (let i = 0; i < units.length; i++) {
    const unitDiff = curDate.diff(targetDateUtc, units[i]);
    if (unitDiff > 0 || units[i] === min_unit) {
      diff = unitDiff;
      unit = units[i].charAt(0);
      if (unit === "m") {
        unit = units[i] === "month" ? "m" : "min";
      }
      break;
    }
  }

  if (diff === undefined) {
    diff = 0;
    unit = "s"; // fallback unit
  }

  if (unit === "d" && min_unit === "day") {
    diff = diff || 1;
  }

  // Format the output
  unit = unit === "second" ? "sec" : unit; // change 'second' to 'sec'

  return diff.toString().concat(unit, until !== "now" ? "" : " ago");
};

export const seconds2HMS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  if (hours > 0) {
    const formattedHours = hours.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};

export const getDefaultOrdering = (model) => {
  switch (model) {
    case "trending":
      return "-influencer";
    default:
      return "";
  }
};

export const dedicatedColor = (value, preValue) => {
  const percentage = (parseFloat(value) / parseFloat(preValue)) * 100;
  if (percentage < 30) {
    return "red";
  } else if (percentage < 50) {
    return "orange";
  } else {
    return "green";
  }
};

export const dedicatedTitle = (value, preValue) => {
  const percentage = (parseFloat(value) / parseFloat(preValue)) * 100;
  if (percentage < 30) {
    return "Mentioned";
  } else if (percentage < 50) {
    return "Recommends";
  } else {
    return "Dedicated";
  }
};

export const filterTableHeader = (tableHeader, modelName, filterType) => {
  // console.log("modelName: ", modelName);
  switch (modelName) {
    case "website-link--category-topic":
    case "project-website-link--nickName-topic":
    case "website--topic":
      switch (filterType) {
        case "shared":
          return tableHeader.filter(
            (row) =>
              ![
                "youtube_videos",
                "youtube_views",
                "followers",
                "community_users",
                "youtube_first_datetime",
                "community_first_datetime",
                "youtube_last_datetime",
                "community_last_datetime",
              ].includes(row.value)
          );
        case "youtube":
          return tableHeader.filter(
            (row) =>
              ![
                "community_users",
                "links",
                "first_datetime",
                "last_datetime",
                "community_count",
                "community_first_datetime",
                "community_last_datetime",
              ].includes(row.value)
          );
        case "community":
          return tableHeader.filter(
            (row) =>
              ![
                "youtube_channels",
                "youtube_videos",
                "youtube_views",
                "links",
                "followers",
                "first_datetime",
                "last_datetime",
                "youtube_first_datetime",
                "youtube_last_datetime",
              ].includes(row.value)
          );
        default:
          return tableHeader;
      }
    default:
      return tableHeader;
  }
};

export const responsiveCarousel = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
    partialVisibilityGutter: 30,
  },
};
