import axios from 'axios';
import { API_HOST } from '../../config/constants/data';

import {
  GET_SEARCH_ITEMS_LOADING,
  GET_SEARCH_ITEMS_SUCCESS,
  GET_SEARCH_ITEMS_FAIL
} from '../types'


export const getSearchItems = () => async (dispatch) => {
  dispatch({ type: GET_SEARCH_ITEMS_LOADING });
  try {
      const response = await axios.get(`${API_HOST}/api/v1/manager/search-items/`);

      dispatch({
          type: GET_SEARCH_ITEMS_SUCCESS,
          payload: { data: response.data.results, count: response.data.count },
      });

  } catch (err) {
    console.log("getSearchItems error: ", err)
      dispatch({
          type: GET_SEARCH_ITEMS_FAIL,
          payload: { error: err.response.data.message },
      });
  }
};

export const setSearchItems = (payload) => (dispatch) => {
  dispatch({
    type: GET_SEARCH_ITEMS_SUCCESS,
    payload: { data: payload.SearchItems, count: payload.SearchItemsCount },
  });
};
