import * as Yup from 'yup';

export const editProjectSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Must be 2 characters at minimum')
    .max(100, 'Must be 100 characters or less')
    .matches(/^[a-zA-Z 0-9 -_]+$/, 'Invalid characters in Project Name')
    .required('Required'),
  nickname: Yup.string()
    .min(2, 'Must be 2 characters at minimum')
    .max(50, 'Must be 50 characters or less')
    .matches(/^[a-zA-Z 0-9 -_]+$/, 'Invalid characters in Project Nick Name')
    .required('Required'),
  // icon: Yup.mixed()
  //   // .test('fileSize', 'The file is too large', (value) => {
  //   //   return value && value.size <= 20000
  //   // })
  //   .required('You need to provide icon file'),
});
