import React from "react";

const MetaTags = () => {
  return (
    <></>
    // <Helmet>
    //   <meta name="keywords" content="metalyzer,Youtube,Discord,Telegram,Websites,Social,Analytics,Metrics,Research,management,reduce cost, optimize time, competition" />
    //   <meta name="robots" content="index, follow" />
    //   <link rel="canonical" href="https://metalyzer.ai/" />
    //   {/* <meta http-equiv="refresh" content="5" url="https://metalyzer.ai/" /> */}
    // </Helmet>
  );
};

export default MetaTags;

