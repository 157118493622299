import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import "./styles.scss";
import LogInOut from "./Components/LogInOut";
// import LanguageSelection from './Components/LanguageSelection';
import { DISCORD_INVITE_LINK, TWITTER_LINK } from "../../config/constants/data";
import { Icon } from "@iconify/react";
import { HomeButton } from "../../components/Shared/HomeButton";

const StatisticsBar = () => {
  // const dominanceList = globalStatisticsData?.projects_score?.length > 0 ? globalStatisticsData?.projects_score.map((project) => {
  //   return `${project.name}: ${parseFloat(project.score / globalStatisticsData?.sum_score).toFixed(2)}% `
  // }) : [];
  const { pathname } = useLocation();

  return (
    <Container fluid>
      <Row className="d-flex align-items-center px-3">
        {/* <div className="d-flex" style={{ minWidth: 910 }}>
          <LabelLinkItem
            label="Projects:"
            value={globalStatisticsData?.project_count}
            link="/"
          />
          <LabelLinkItem
            label="Categories:"
            value={globalStatisticsData?.category_count}
            link="/"
          />
          <LabelLinkItem
            label="24h Total Score:"
            value={parseFloat(globalStatisticsData?.sum_score).toFixed(4)}
            pastValue={globalStatisticsData?.y_sum_score}
          />
          <LabelLinkItem
            label="Dominance:"
            value={dominanceList.map((domi) => domi)}
          />
        </div> */}
        <div
          className="d-flex align-items-center justify-content-end my-2"
          style={{ flex: 1 }}
        >
          {/* <LanguageSelection /> */}
          <Link
            className=""
            to={{ pathname: DISCORD_INVITE_LINK }}
            target="_blank"
            rel="noopener noreferer"
          >
            <div className="mr-2 social-link-icon discord-hover-color">
              <Icon icon="ic:baseline-discord" height="2rem" color="#5562EA" />
            </div>
          </Link>
          <Link
            className=""
            to={{ pathname: TWITTER_LINK }}
            target="_blank"
            rel="noopener noreferer"
          >
            <div className="mr-4 social-link-icon twitter-hover-color">
              <Icon icon="prime:twitter" height="1.5rem" color="#fff" />
            </div>
          </Link>
          {pathname == "/home" && (
            <HomeButton
              link={"/view/all"}
              style={{ whiteSpace: "nowrap" }}
              className="mr-4"
              title="Launch App"
              variant="outline"
              innerClassname="medium"
              innerStyle={{ padding: "0.5rem 1.5rem", minHeight: 0 }}
            />
          )}
          <LogInOut />
        </div>
      </Row>
    </Container>
  );
};

export default StatisticsBar;

