import React from "react";
import { ToastContainer, Flip } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Layout from "../../layout/Layout";

import LinkButtons from "../../components/LinkButtons";
import MainTable from "../../components/Tables/MainTable";
import { useLocation, useParams } from "react-router-dom";

import {
  YOUTUBE_CATEGORY,
  YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS,
} from "../../config/constants/data";
import {
  YOUTUBE_VIDEOS_TABLE_HEADER,
  YOUTUBE_VIDEOS_MAIN_TABLE_API,
} from "../../config/constants/tableHeaders";

const YoutubeVideos = () => {
  const { keyword } = useParams();
  const location = useLocation();
  let filters = window.advancedFilters || {};
  YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });
  filters = { ...filters, ...location.state };

  return (
    <Layout>
      <div className="tabs align-items-center mb-3">
        <LinkButtons linkItems={YOUTUBE_CATEGORY} activeItem={"video"} />
      </div>
      <MainTable
        tableHeader={YOUTUBE_VIDEOS_TABLE_HEADER}
        URL={YOUTUBE_VIDEOS_MAIN_TABLE_API}
        withTermsFilter
        withVideoLengthFilter
        projectNickName={keyword}
        filters={filters}
        filterModalFields={YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS}
        model={"youtube-videos"}
        withoutTopicFilter
        withCategoryFilter
        modelName="youtube-video"
      />
      <ToastContainer theme="dark" transition={Flip} />
    </Layout>
  );
};

export default YoutubeVideos;

