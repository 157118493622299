import React from "react";

import Layout from "../../layout/Layout";
import Breadcrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import MainTable from "../../components/Tables/MainTable";

import {
  USERS_MAIN_TABLE_API,
  USERS_TABLE_HEADER,
} from "../../config/constants/tableHeaders";

const Users = () => {
  return (
    <Layout>
      <Breadcrumb currentLabel="Users" />
      <PageTitle title="Users Page." description="" />
      <MainTable
        tableHeader={USERS_TABLE_HEADER}
        URL={USERS_MAIN_TABLE_API}
        withoutFilterModal
        withoutProjectFilter
        withoutTopicFilter
        model={"users"}
        modelName="users"
      />
    </Layout>
  );
};

export default Users;
