import React, { useState } from 'react';
import "./Avatar.css"; // Create a separate CSS file for styling the avatar

const getHashCode = (str) => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

const NameAvatar = ({ name, imageUrl, validImage=true }) => {
    const [isValidImageUrl, setIsValidImageUrl] = useState(validImage);
    const getInitials = (name) =>
    name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('');
  
    const hashCode = getHashCode(name);
    const backgroundColor = `hsl(${Math.abs(hashCode) % 360}, 70%, 50%)`;
    const textColor = hashCode % 2 === 0 ? '#fff' : '#000';
  
    const avatarStyle = {
        backgroundColor,
        color: textColor,
        width: '30px', // or any desired size
        height: '30px', // or any desired size
        borderRadius: '50%',
        overflow: 'hidden',
        fontSize: '13px',
        textTransform: 'uppercase',
      };
    
      const handleImageLoad = () => {
        setIsValidImageUrl(true);
      };
    
      const handleImageError = () => {
        setIsValidImageUrl(false);
      };
    
      return (
        <div className="avatar-container" style={{ ...avatarStyle }}>
          {isValidImageUrl && imageUrl ? (
            <img
              className="avatar-image"
              src={imageUrl}
              alt={name}
              onLoad={handleImageLoad}
              onError={handleImageError}
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          ) : (
            <div className="avatar-text" style={{ ...avatarStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {getInitials(name)}
            </div>
          )}
        </div>
      );
    };

export default NameAvatar;
