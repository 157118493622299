import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from "react-redux";

import {
  Form,
  Row,
  Col
} from 'react-bootstrap';

import ProjectFilterWithoutAll from './../../ProjectFilterWithoutAll/index';
import ProjectCategorySelection from '../../ProjectCategorySelection';
import { createMainModel } from '../../../../../store/actions/mainModelActions';

const AddCategoryModal = ( props ) => {
  const dispatch = useDispatch();
  const { show, setShowModal } = props;
  const [keyword, setKeyword] = useState(null);
  const [keywordNick, setKeywordNick] = useState(null)
  const [category, setCategory] = useState('Agency');
  const onPostSubmit = async () => {
    let values = {
      "project_name" : keyword,
      "category" : category,
    }
    await dispatch(createMainModel('project/project_category/', values));
  }

  const udpateKeyword = (keywordObject) => {
    setKeyword(keywordObject.label);
    setKeywordNick(keywordObject.value);
  }
  const udpateCategory = (categoryObject) => {
    setCategory(categoryObject);
  }
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={'sm'}
    // aria-labelledby="filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 18, textAlign: 'center', width: '100%' }}>
          Add Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="pt-3">
          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className='mr-3'>Project Name</div>
            <ProjectFilterWithoutAll
              keyword={{ label: keyword, value: keywordNick }}
              setKeyword={udpateKeyword}
            />
          </Form.Group>
          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className='mr-3'>Category</div>
            <ProjectCategorySelection
              category={category}
              changeCategory={udpateCategory}
            />
          </Form.Group>

          <Row className="mt-4">
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => onPostSubmit()}
                >
                  Add
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCategoryModal;