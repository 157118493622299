import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { ContextAwareToggle } from "../../Navbar";
import "./styles.scss";
import LoginSignupModal from "../../../components/LoginSignupModal";
import { etcMenu, socialMenu } from "../../../config/constants/menu";
import useUserRole from "../../../hooks/useUserRole";

export const MainMobileMenu = ({ key, title, link, onClick }) => {
  return (
    <Card key={key}>
      <div>
        <Link
          to="#"
          onClick={(e) => {
            onClick(e, link);
          }}
          className="nav-link"
        >
          <p
            className="mb-0"
            style={{
              color: "#ffffff",
              fontSize: 16,
              whiteSpace: `nowrap`,
              lineHeight: 1,
              borderBottom: `1px solid #222531`,
              paddingBottom: 18,
              textAlign: "left",
            }}
          >
            {title}
          </p>
        </Link>
      </div>
    </Card>
  );
};

const MenuModalFeed = ({ closeModal }) => {
  const { isAuthenticated } = useUserRole();
  let history = useHistory();
  const navigateLink = (e, link) => {
    e.preventDefault();
    e.stopPropagation();
    closeModal();
    history.push(link);
  };
  const [showModal, setShowModal] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(true);
  const handleShow = (isLogin) => {
    setIsLoginModal(isLogin);
    setShowModal(true);
  };

  return (
    <div>
      <div>
        <MainMobileMenu title="Main" link="/" onClick={navigateLink} />
        <Accordion>
          {socialMenu.map((menuItem, index) => (
            <Card>
              <Card.Header
                style={{ padding: `8px 12px 10px`, fontSize: "18px" }}
              >
                <ContextAwareToggle eventKey={index + 1}>
                  {menuItem.title}
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={index + 1}>
                <Card.Body
                  style={{ padding: `8px 12px 10px`, fontSize: "16px" }}
                >
                  {menuItem.children.map((subMenuItem) => (
                    <Link
                      to="#"
                      onClick={(e) => {
                        navigateLink(e, subMenuItem.link);
                      }}
                      className="nav-link d-flex align-items-center mb-3"
                    >
                      <div className="mr-3 menu-icon">
                        <Icon
                          icon={subMenuItem.icon}
                          height="2rem"
                          color={subMenuItem.color}
                        />
                      </div>
                      {subMenuItem.title}
                    </Link>
                  ))}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
        {etcMenu
          .filter((item) => isAuthenticated || item.title !== "Watchlist")
          .map((menuItem, index) => (
            <MainMobileMenu
              key={`etcMenu-${index}`}
              title={menuItem.title}
              link={menuItem.link}
              onClick={navigateLink}
            />
          ))}
        {!isAuthenticated && (
          <div>
            <div>
              <div
                className="btn btn-lg btn-block mb-2 mt-4 btn-primary"
                onClick={() => handleShow(false)}
                style={{ borderRadius: 8 }}
              >
                Sign Up
              </div>
            </div>
            <div>
              <div
                className="btn btn-lg btn-block mb-2 btn-default"
                onClick={() => handleShow(true)}
                style={{ borderRadius: 8 }}
              >
                Log In
              </div>
            </div>
          </div>
        )}
      </div>
      <LoginSignupModal
        isLoginModal={isLoginModal}
        setIsLoginModal={setIsLoginModal}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
};

export default MenuModalFeed;
