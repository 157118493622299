import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { fetchMainModel } from "../../../store/actions/mainModelActions";
import Loader from "../../Loader/Loader";
import { ToastContainer, toast, Flip } from "react-toastify";
import SearchComponent from "../Components/SearchComponent";
import ProjectFilter from "../Components/ProjectFilter";
import HeaderCheckDropdown from "../Components/HeaderCheckDropdown";
import TableView from "../Components/TableView";
import TablePaginate from "../Components/TablePaginate";
import PageSizeSelection from "../Components/PageSizeSelection";
import {
  convertNick2Name,
  getTopicOption,
  newSocialPathFromDomain,
  newWebsitePathFromTopic,
} from "../../../utils/routingHelper";
import "../styles.css";
import TermsFilter from "../Components/TermsFilter";
import ReactTooltip from "react-tooltip";
import TopicSelection from "../../Selections/TopicSelection";
import PrefilterSelection from "../../Selections/PrefilterSelection";
import { routing2PrefilterTopicModelId } from "../../../utils/routing2TableHelper";
import NewsView from "../Components/NewsView";
import useMediaQuery from "../../../hooks/useMediaQuery";
import FullModal from "../../FullModal";
import {
  DEFAULT_VIDEO_LENGTH,
  TERMS,
  VIDEO_LENGTH_SELECTION,
} from "../../../config/constants/data";
import HeaderCheckDropdownModal from "../Components/HeaderCheckDropdownModal";
import VideoLengthSelection from "../Components/VideoLengthSelection";
import {
  setPageSize,
  setSearchText,
  setTerms,
  setWebsiteTopicType,
} from "../../../store/actions/uiSettings";
import SubTableView from "../../Subscription/SubTableView";
import MobileFilterModalButton from "../Components/MobileFilterModalButton";
import { setSocailDomain } from "../../../store/actions/socialDomainActions";
import DomainFilter from "../Components/DomainFilter";
import { capitalizeFirstLetter } from "../../../utils/common";
// import useUserRole from "../../../hooks/useUserRole";
import TableAdvancedFilterModal from "../Components/TableFilterModal";
import WebsiteTopicTypeSelection from "../Components/WebsiteTopicTypeSelection";
import {
  filterTableHeader,
  getDefaultOrderingField,
} from "../../../utils/tableDataHelper";
import MainSelection from "../../Selections/MainSelection";
import { setWebsiteTopic } from "../../../store/actions/websiteTopicActions";
import CategoryFilter from "../Components/CategoryFilter";

const MainTable = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { domain } = useParams();
  // const { subscription } = useUserRole();
  const { data, count, isLoading, error } = useSelector(
    (state) => state.mainModel
  );
  const { isSubscribed } = useSelector((state) => state.subscribe);
  const [clickableSubscribe, setClickableSubscribe] = useState(!isSubscribed);
  let oneTimeClick = 0;
  const pageSize =
    useSelector((state) => props.pageSize || state.uiSettings.pageSize) || 100;
  // const [search, setSearch] = useState("");
  const [search, setSearch] = useState(
    useSelector((state) => state.uiSettings.searchText) || ""
  );
  const queryParams = useMemo(
    () => new URLSearchParams(location?.search),
    [location]
  );
  const paramPage = useMemo(
    () => parseInt(queryParams.get("page")) || 1,
    [queryParams]
  );
  const page =
    count === 0 || pageSize * (paramPage - 1) < count ? paramPage : 1;

  const { projectNickName, currentTopic, category, modelName } = props;

  // Is Mobile
  const isMobile = useMediaQuery();

  const isNews = props.model === "news" || props.model === "website-news";

  // console.log("modelName: ", modelName);
  const withDomainFilter = modelName.includes("social");

  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  const topics = useSelector((state) => state.topics.topics) || [];
  const terms = useSelector((state) => state.uiSettings.terms) || "1d";
  const topicType =
    useSelector((state) => state.uiSettings.topicType) || "shared";

  // Props states.
  const [keyword, setKeyword] = useState(() =>
    convertNick2Name(projectNames, projectNickName)
  );
  const [keywordNick, setKeywordNick] = useState(
    projectNickName ? projectNickName : "all"
  );

  const [localCategory, setLocalcategory] = useState("category");

  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showVideoLengthModal, setShowVideoLengthModal] = useState(false);

  // Pagination states.
  const tableModelId = routing2PrefilterTopicModelId(props?.model);
  const [showPageSizeModal, setShowPageSizeModal] = useState(false);

  const currentTerms = useMemo(
    () => queryParams.get("term") || props?.defaultTerm || terms,
    [queryParams, props, terms]
  );

  const [prefilter, setPrefilter] = useState(0);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showDomainModal, setShowDomainModal] = useState(false);

  // Topic state.
  const [topic, setTopic] = useState(() =>
    getTopicOption(topics, currentTopic, tableModelId)
  );

  const [videoLength, setVideoLength] = useState({
    label: "All",
    value: "0",
  });

  const [showTopicModal, setShowTopicModal] = useState(false);
  const [showWebsiteTopicTypeModal, setShowWebsiteTopicTypeModal] =
    useState(false);

  const [showTableTypeModal, setShowTableTypeModal] = useState(false);

  // Filters states.
  const [filters, setFilters] = useState(props?.filters);
  const [showFilterModal, setShowFilterModal] = useState(false);

  useEffect(() => {
    window.advancedFilters = filters;
  }, [filters]);

  // Table Header state.
  const [tableHeader, setTableHeader] = useState(
    filterTableHeader(props.tableHeader, modelName, topicType)
  );
  const [showCustomHeaderModal, setShowCustomHeaderModal] = useState(false);

  // TODO update this code for advaced filter
  const withAdvancedFilterModal =
    !props.withoutFilterModal ||
    (props.modelName === "website-link--category-topic" &&
      topicType === "youtube");

  const scrollToTop = () => {
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getCurrentOrdering = () => {
    const val = window.currentOrdering || getDefaultOrderingField(tableModelId);
    // delete window.currentOrdering;
    return val;
  };

  // Call Api function.
  const callApi = (params) => {
    // Check if the subscription conditions are met to update clickableSubscribe and oneTimeClick
    if (isSubscribed) {
      if (clickableSubscribe) {
        setClickableSubscribe(false);
      }
      oneTimeClick++;
      // If oneTimeClick exceeds 2, regardless of the clickableSubscribe state, scroll to the top
      if (oneTimeClick > 2) {
        scrollToTop();
      }
    }

    // Simplify newPage determination using the logical OR operator
    const newPage = params?.newPage || page;
    const newPageSize = params?.newPageSize || pageSize;
    const newKeyword = params?.newKeyword || keyword;
    const newCategory = params?.newCategory || category;
    const newTopic = params?.newTopic || topic.value;
    const newTopicType = params?.newTopicType || topicType;
    const newVideoLength = params?.newVideoLength || videoLength.value;
    const newPrefilter = params?.newPrefilter || prefilter;
    const newTerm = params?.newTerm
      ? params.newTerm
      : props?.defaultTerm
      ? currentTerms
      : terms;
    const ordering = getCurrentOrdering();

    dispatch(
      fetchMainModel({
        URL: props.URL,
        // ...(modelName === "social-link--domain" ? props.filters : filters), // TODO remove this code later.
        ...filters,
        keyword: newKeyword,
        topic: newTopic,
        topic_type: newTopicType,
        prefilter: newPrefilter,
        terms: newTerm,
        category: newCategory,
        page: newPage,
        page_size: newPageSize,
        search: search,
        length: newVideoLength,
        ordering,
      })
    );
  };

  const [currentOrdering, setCurrentOrdering] = useState(getCurrentOrdering());

  const handleOrdering = (direction, sortField, sortType) => {
    setCurrentOrdering(
      (window.currentOrdering =
        (direction === "-" ? direction : "") + sortField)
    );
    callApi({ newPage: page });
  };

  // First Loading.
  useEffect(() => {
    setTableHeader(filterTableHeader(props.tableHeader, modelName, topicType));
    const newKeyword = convertNick2Name(projectNames, projectNickName);
    setKeyword(newKeyword);
    setKeywordNick(projectNickName);
    const topicOption = getTopicOption(topics, currentTopic, tableModelId);
    setTopic(topicOption);
    callApi({
      newKeyword: newKeyword,
      newTopic: topicOption.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    projectNickName,
    currentTopic,
    category,
    props.tableHeader,
    props.URL,
    // props?.filters,
    page,
  ]);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    if (pageSize * (paramPage - 1) > count) {
      handlePageClick(1);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (modelName.includes("social-website")) {
      dispatch(setSocailDomain(data?.map((v) => v.domain)));
    } else if (modelName.includes("website-topic")) {
      dispatch(setWebsiteTopic(data?.map((v) => v.topic)));
    }
    // eslint-disable-next-line
  }, [data]);

  // handles
  const handleApply = () => {
    callApi({ newPage: page });
    if (showFilterModal) setShowFilterModal(false);
  };

  const handleSearchText = (newText) => {
    dispatch(setSearchText(newText));
    handleApply();
  };

  const handlePageClick = (newPage) => {
    const searchParams = new URLSearchParams(location.search);
    if (newPage === 1) {
      searchParams.delete("page");
    } else {
      searchParams.set("page", newPage);
    }

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    scrollToTop();
    // callApi({ newPage });
  };

  const setKeywordObject = (keywordObject) => {
    setKeyword(keywordObject.label);
    setKeywordNick(keywordObject.value);
    // TODO when changing the projectName, format the server_id in discord.
    if (filters !== undefined && "server_id" in filters) {
      setFilters({
        ...filters,
        server_id: "",
      });
    }
  };

  const changePageSize = (pageSize) => {
    dispatch(setPageSize(pageSize));
    callApi({ newPage: 1, newPageSize: pageSize });
  };

  const updateTerm = (newTerm) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("term", newTerm);

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    if (!props?.defaultTerm) {
      dispatch(setTerms(newTerm));
    }
    callApi({ newPage: 1, newTerm: newTerm });
  };

  const updateTopic = (newTopic) => {
    const searchParams = new URLSearchParams(location.search).toString();
    if (tableModelId === 8) {
      // if website.
      history.push({
        pathname: newWebsitePathFromTopic(location.pathname, newTopic.label),
        search: searchParams,
      });
    } else {
      setTopic(newTopic);
      callApi({ newPage: 1, newTopic: newTopic.value });
    }
  };

  const updateWebsiteTopicType = (newTopic) => {
    dispatch(setWebsiteTopicType(newTopic));
    setTableHeader(filterTableHeader(props.tableHeader, modelName, newTopic));
    callApi({ newPage: 1, newTopicType: newTopic });
  };

  const updateDomain = (newDomain) => {
    history.push(newSocialPathFromDomain(location.pathname, newDomain));
  };

  const updateVideoLength = (newVideoLength) => {
    const newVideoLeng =
      newVideoLength === undefined
        ? DEFAULT_VIDEO_LENGTH
        : VIDEO_LENGTH_SELECTION.filter(
            (val) => val.value === newVideoLength
          )?.[0];
    setVideoLength(newVideoLeng);
    callApi({ newPage: 1, newVideoLength: newVideoLength });
  };

  const updatePrefilter = (newPrefilter) => {
    setPrefilter(newPrefilter);
    callApi({ newPage: 1, newPrefilter: newPrefilter });
  };

  const udpateKeyword = (keywordObject) => {
    setKeywordObject(keywordObject);
    callApi({ newPage: 1, newKeyword: keywordObject?.label });
  };

  const updateCategory = (categoryObject) => {
    setLocalcategory(categoryObject);
    setKeyword("All");
    setKeywordNick("all");
    callApi({ newPage: 1, newCategory: categoryObject });
  };

  return (
    <div className="row">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center x-scroll-wrapper-mobile">
              <div className="d-flex flex-row align-items-center">
                <>{props.leftComponent || <></>}</>
                {props.withCategoryFilter &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowCategoryModal}
                      title={localCategory}
                    />
                  ) : (
                    <div className="ml-2">
                      <CategoryFilter
                        defaultCategory={localCategory}
                        setCategory={updateCategory}
                      />
                    </div>
                  ))}
                {!props.withoutProjectFilter &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowKeywordModal}
                      title={keyword}
                    />
                  ) : (
                    <ProjectFilter
                      keyword={{ label: keyword, value: keywordNick }}
                      setKeyword={udpateKeyword}
                    />
                  ))}
                {props.withTermsFilter &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowTermsModal}
                      title={
                        TERMS.filter((row) => {
                          return row.value === props?.defaultTerm
                            ? currentTerms
                            : terms;
                        })?.[0]?.label
                      }
                    />
                  ) : (
                    <div className="d-flex flex-row align-items-center">
                      <TermsFilter
                        setTerm={updateTerm}
                        term={props?.defaultTerm ? currentTerms : terms}
                        modelName={modelName}
                      />
                    </div>
                  ))}
                {withDomainFilter &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowDomainModal}
                      title={capitalizeFirstLetter(domain)}
                    />
                  ) : (
                    <DomainFilter setDomain={updateDomain} domain={domain} />
                  ))}
                {!props.withoutTopicFilter &&
                  tableModelId <= routing2PrefilterTopicModelId() &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowTopicModal}
                      title={topic.label}
                    />
                  ) : (
                    <TopicSelection
                      topic={topic}
                      setTopic={updateTopic}
                      tableModelId={tableModelId}
                    />
                  ))}
                {props.withTableTypeSelection &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowTableTypeModal}
                      title={props?.tableType.label}
                    />
                  ) : (
                    <MainSelection
                      defaultItem={props?.tableType}
                      selectionItems={props?.tableTypes}
                      setItem={props?.updateTableType}
                      updateWithItem
                      width={100}
                    />
                  ))}
                {props.withWebsiteTopicTypeSelection &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowWebsiteTopicTypeModal}
                      title={
                        topicType === "shared"
                          ? "All Sources"
                          : capitalizeFirstLetter(topicType)
                      }
                    />
                  ) : (
                    <WebsiteTopicTypeSelection
                      topicType={topicType}
                      setTopicType={updateWebsiteTopicType}
                    />
                  ))}

                {/* TODO remove the true for Prefilter later. */}
                {props.withOutPrefilterFilter ||
                tableModelId > routing2PrefilterTopicModelId() ||
                true ? (
                  <></>
                ) : (
                  <PrefilterSelection
                    setPrefilter={updatePrefilter}
                    tableModelId={tableModelId}
                  />
                )}
                {props.withVideoLengthFilter &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowVideoLengthModal}
                      title={videoLength?.label}
                    />
                  ) : (
                    <VideoLengthSelection
                      videoLength={videoLength}
                      changeVideoLength={updateVideoLength}
                    />
                  ))}
              </div>

              <div className="d-flex flex-row align-items-center">
                {!props.withoutSearch && (
                  <SearchComponent
                    search={search}
                    setSearch={setSearch}
                    handleSearch={handleSearchText}
                  />
                )}
                {!props.withoutPagination &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowPageSizeModal}
                      title={pageSize}
                    />
                  ) : (
                    <PageSizeSelection
                      pageSize={pageSize}
                      changePageSize={changePageSize}
                    />
                  ))}

                {withAdvancedFilterModal /* && subscription > 1*/ && (
                  <MobileFilterModalButton
                    showModal={setShowFilterModal}
                    title={"Filters"}
                  />
                )}
                {!props.withoutHeaderCheck &&
                  !isNews &&
                  (isMobile ? (
                    <MobileFilterModalButton
                      showModal={setShowCustomHeaderModal}
                      title={"Customize"}
                    />
                  ) : (
                    <HeaderCheckDropdown
                      header={tableHeader}
                      setHeader={setTableHeader}
                    />
                  ))}
              </div>
            </div>
            <SubTableView model={props?.model}>
              {isLoading ? (
                <Loader />
              ) : isNews ? (
                <div>
                  <NewsView
                    header={tableHeader}
                    data={data}
                    startNumber={pageSize * (page - 1)}
                    initOrdering={
                      props?.initOrdering ? props?.initOrdering : ""
                    }
                    terms={props?.defaultTerm ? currentTerms : terms}
                    stickyHeader
                    tableModelId={tableModelId}
                  />
                  {!props.withoutPagination && (
                    <TablePaginate
                      pageSize={pageSize}
                      page={page}
                      count={count}
                      handlePageClick={handlePageClick}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <TableView
                    header={tableHeader}
                    data={data}
                    startNumber={pageSize * (page - 1)}
                    initOrdering={currentOrdering}
                    handleOrdering={handleOrdering}
                    terms={props?.defaultTerm ? currentTerms : terms}
                    stickyHeader
                    tableModelId={tableModelId}
                    modelName={modelName}
                    topicType={topicType}
                  />
                  {!props.withoutPagination && (
                    <TablePaginate
                      pageSize={pageSize}
                      page={page}
                      count={count}
                      handlePageClick={handlePageClick}
                    />
                  )}
                </div>
              )}
            </SubTableView>
            <ReactTooltip />
          </div>
        </div>
      </div>

      <TableAdvancedFilterModal
        show={showFilterModal}
        setShowModal={setShowFilterModal}
        filters={filters}
        setFilters={setFilters}
        filterModalFields={props.filterModalFields}
        handleApplyFilter={handleApply}
        isMobile={isMobile}
      />

      <FullModal
        isShowModal={showTermsModal}
        setShowModal={setShowTermsModal}
        title={"Select Terms"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="mr-4">Terms :</div>
          <TermsFilter
            setTerm={updateTerm}
            term={props?.defaultTerm ? currentTerms : terms}
            tableModelId={tableModelId}
          />
        </div>
      </FullModal>

      <FullModal
        isShowModal={showDomainModal}
        setShowModal={setShowDomainModal}
        title={"Select Domain"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="mr-4">Domain :</div>
          <DomainFilter setDomain={updateDomain} domain={domain} />
        </div>
      </FullModal>

      <FullModal
        isShowModal={showTopicModal}
        setShowModal={setShowTopicModal}
        title={"Select Topic"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="mr-4">Topic :</div>
          <TopicSelection
            topic={topic}
            setTopic={updateTopic}
            tableModelId={tableModelId}
          />
        </div>
      </FullModal>

      <FullModal
        isShowModal={showWebsiteTopicTypeModal}
        setShowModal={setShowWebsiteTopicTypeModal}
        title={"Select Topic Type"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="mr-4">Topic Type:</div>
          <WebsiteTopicTypeSelection
            topicType={topicType}
            setTopicType={updateWebsiteTopicType}
          />
        </div>
      </FullModal>

      <FullModal
        isShowModal={showTableTypeModal}
        setShowModal={setShowTableTypeModal}
        title={"Select Table Type"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="mr-4">Table Type:</div>
          <MainSelection
            defaultItem={props?.tableType}
            selectionItems={props?.tableTypes}
            setItem={props?.updateTableType}
            updateWithItem
            width={100}
          />
        </div>
      </FullModal>

      <FullModal
        isShowModal={showPageSizeModal}
        setShowModal={setShowPageSizeModal}
        title={"Select Page Size"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <PageSizeSelection
            pageSize={pageSize}
            changePageSize={changePageSize}
          />
        </div>
      </FullModal>

      <FullModal
        isShowModal={showKeywordModal}
        setShowModal={setShowKeywordModal}
        title={"Select Keyword"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div>Keyword : </div>
          <ProjectFilter
            keyword={{ label: keyword, value: keywordNick }}
            setKeyword={udpateKeyword}
          />
        </div>
      </FullModal>

      <FullModal
        isShowModal={showCategoryModal}
        setShowModal={setShowCategoryModal}
        title={"Select Category"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div>Category : </div>
          <CategoryFilter
            defaultCategory={localCategory}
            setCategory={updateCategory}
          />
        </div>
      </FullModal>

      <FullModal
        isShowModal={showVideoLengthModal}
        setShowModal={setShowVideoLengthModal}
        title={"Select Video Length"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div>Video Length : </div>
          <VideoLengthSelection
            videoLength={videoLength}
            changeVideoLength={updateVideoLength}
          />
        </div>
      </FullModal>

      <FullModal
        isShowModal={showCustomHeaderModal}
        setShowModal={setShowCustomHeaderModal}
        title={"Select Header Customize"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <HeaderCheckDropdownModal
            header={tableHeader}
            setHeader={setTableHeader}
          />
        </div>
      </FullModal>

      <ToastContainer theme="dark" transition={Flip} />
    </div>
  );
};

export default MainTable;

