import React from 'react';
import EditMainTable from '../../../../../components/Tables/EditMainTable';
import { DISCORD_SERVERS_STAFF_MAIN_TABLE_API, DISCORD_SERVERS_TABLE_HEADER } from '../../../../../config/constants/tableHeaders'

const Server = () => {
  let updatedHeader = structuredClone(DISCORD_SERVERS_TABLE_HEADER);
  updatedHeader = updatedHeader.filter(header => (!['', 'Vote', 'Reply', 'Reply +%', 'Avg Word'].includes(header.title)))
  updatedHeader[updatedHeader.length-1].type = "component";
  updatedHeader[updatedHeader.length-1].checked = true
  
  return (
    <EditMainTable
      tableHeader={updatedHeader}
      URL={DISCORD_SERVERS_STAFF_MAIN_TABLE_API}
      withTermsFilter
      withoutFilterModal
      model={"discord-servers"}
      modelName="edit-topic-server"
    />
  )
}

export default Server