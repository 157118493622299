import React from 'react'
import MainSelection from '../../../Selections/MainSelection'
import { WEBSITE_TOPIC_TYPE_SELECTION, DEFAULT_WEBSITE_TOPIC_TYPE } from '../../../../config/constants/data'

const WebsiteTopicTypeSelection = (props) => {
  const {topicType, setTopicType} = props;
  const defaultValue = WEBSITE_TOPIC_TYPE_SELECTION.filter((cat) => {return cat.value===topicType});
  const defaultItem = defaultValue.length > 0 ? defaultValue[0] : DEFAULT_WEBSITE_TOPIC_TYPE;
  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <MainSelection
        title={props?.title}
        selectionItems={WEBSITE_TOPIC_TYPE_SELECTION}
        defaultItem={defaultItem}
        setItem={setTopicType}
        width={85}
        sorted={false}
      />
    </div>
  )
}

export default WebsiteTopicTypeSelection