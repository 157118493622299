import axios from 'axios';
import { API_HOST } from "../../config/constants/data";
import {
  FETCH_MAIN_MODEL_REQUEST,
  MAIN_MODEL_REQUEST,
  MAIN_MODEL_FAILURE,
  UPDATE_MAIN_MODEL_SUCCESS,
  CREATE_MAIN_MODEL_SUCCESS,
  DELETE_MAIN_MODEL_SUCCESS,
  UPDATE_MAIN_MODEL,
  POST_MAIN_MODEL_SUCCESS,
} from "../types";
import { attachTokenToHeaders } from './authActions';

export const fetchMainModel = (params) => async (dispatch) => {
  dispatch({ type: FETCH_MAIN_MODEL_REQUEST, payload: params });
};

export const createMainModel = (URL, params) => async (dispatch, getState) => {
  dispatch({ type: MAIN_MODEL_REQUEST });
  try {
    const paramURI = `${API_HOST}/api/v1/${URL}`;
    const response = await axios.post(
      paramURI,
      params,
      attachTokenToHeaders(getState)
    );
    dispatch({
      type: CREATE_MAIN_MODEL_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    console.log("createMainModel error : ", err);
    dispatch({
      type: MAIN_MODEL_FAILURE,
      payload: { error: err.response.data.message },
    });
  }
};

export const postMainModel = (URL, params) => async (dispatch, getState) => {
  dispatch({ type: MAIN_MODEL_REQUEST });
  try {
    const paramURI = `${API_HOST}/api/v1/${URL}`;
    const response = await axios.post(
      paramURI,
      params,
      attachTokenToHeaders(getState)
    );
    dispatch({
      type: POST_MAIN_MODEL_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    console.log("error : ", err);
    dispatch({
      type: MAIN_MODEL_FAILURE,
      payload: { error: err.response.data.message },
    });
  }
};

export const putMainModel = (URL, params) => async (dispatch, getState) => {
  dispatch({ type: MAIN_MODEL_REQUEST });
  try {
    const paramURI = `${API_HOST}/api/v1/${URL}`;
    const response = await axios.put(
      paramURI,
      params,
      attachTokenToHeaders(getState)
    );
    dispatch({
      type: UPDATE_MAIN_MODEL_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    console.log("error : ", err);
    dispatch({
      type: MAIN_MODEL_FAILURE,
      payload: { error: err.response.data.message },
    });
  }
};

export const deleteMainModel = (URL, id) => async (dispatch, getState) => {
  dispatch({ type: MAIN_MODEL_REQUEST });
  try {
    const paramURI = `${API_HOST}/api/v1/${URL}`;
    await axios.delete(
      paramURI,
      attachTokenToHeaders(getState)
    );
    dispatch({
      type: DELETE_MAIN_MODEL_SUCCESS,
      payload: {id: id},
    });
  } catch (err) {
    console.log("error : ", err);
    dispatch({
      type: MAIN_MODEL_FAILURE,
      payload: { error: err.response.data.message },
    });
  }
};

export const updateMainModel = (data) => ({
  type: UPDATE_MAIN_MODEL,
  payload: { data },
});
