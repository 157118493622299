import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";

import App from "./App";
import "./assets/scss/index.scss";
import rootReducer, { rootSaga } from "./store/reducers";
import axios from "axios";

const initialState = {
  projectNames: [],
  projectNamesLoading: false,
};

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(thunk, sagaMiddleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      compose
  )
);
sagaMiddleware.run(rootSaga);

const customHistory = createBrowserHistory();
const orgPush = customHistory.push;
customHistory.push = (pathOrLocation, state) => {
  orgPush(pathOrLocation, state);
  let ppp;
  if (typeof pathOrLocation === "string") {
    ppp = pathOrLocation;
  } else if (typeof pathOrLocation === "object" && pathOrLocation.pathname) {
    const { pathname } = pathOrLocation;
    ppp = pathname;
  }
  axios
    .post(`/meta_api${ppp}`)
    .then(({ data }) => {
      //// remove old meta tags
      const metaTags = document.head.querySelectorAll("meta");
      metaTags.forEach((metaTag) => {
        document.head.removeChild(metaTag);
      });

      const existingTitle = document.head.querySelector("title");
      if (existingTitle) {
        document.head.removeChild(existingTitle);
      }

      /// add new meta tags
      const container = document.createElement("div");
      container.innerHTML = data;

      while (container.firstChild) {
        document.head.appendChild(container.firstChild);
      }
    })
    .catch((e) => {
      console.log("fetch meta error:", e);
    });
};

ReactDOM.render(
  <Provider store={store}>
    <Router history={customHistory}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

