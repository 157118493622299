import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Navbar,
  Nav,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap";
import { Icon } from "@iconify/react";

import "./styles.css";
import useMediaQuery from "../hooks/useMediaQuery";
import SearchItemsModal from "../components/SearchItemsModal";
import IconButton from "../components/Shared/IconButton";
import { BsStarFill } from "react-icons/bs";
import StatisticsBar from "./StatisticsBar";
import StatisticsBarMobile from "./StatisticsBarMobile";

export function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      className="d-flex flex-row justify-content-between nav-item align-items-center"
      onClick={decoratedOnClick}
      style={{ borderBottom: `1px solid #222531`, paddingBottom: 18 }}
    >
      <p
        className="mb-0"
        style={{
          color: isCurrentEventKey ? "#3861FB" : "#ffffff",
          fontSize: 14,
          whiteSpace: `nowrap`,
          lineHeight: 1,
        }}
      >
        {children}
      </p>
      <div>
        {isCurrentEventKey ? (
          <Icon
            icon="material-symbols:keyboard-arrow-up"
            height="1.5rem"
            color="#3861FB"
            style={{ marginRight: 3, marginTop: -3 }}
          />
        ) : (
          <Icon
            icon="material-symbols:keyboard-arrow-down"
            height="1.5rem"
            color="#ffffff"
            style={{ marginRight: 3, marginTop: -3 }}
          />
        )}
      </div>
    </div>
  );
}

const NavbarComponent = ({ fluid }) => {
  const auth = useSelector((state) => state.auth);
  let history = useHistory();
  const searchItems =
    useSelector((state) => state.searchItems.searchItems) || [];

  // const [socialShow, setSocialShow] = useState(false);
  // const [competitionShow, setCompetitionShow] = useState(false);
  // const [contentShow, setContentShow] = useState(false);

  const isMobile = useMediaQuery();

  const navigateLink = (e, link) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(link);
  };
  const { pathname } = useLocation();

  return (
    <>
      {isMobile ? <StatisticsBarMobile /> : <></>}
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="border-top"
      >
        <Container fluid={fluid}>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-between"
          >
            <div className="navbar-brand-wrapper d-lg-flex d-none align-items-center justify-content-center">
              {(!auth.isAuthenticated || pathname.indexOf("/home") >= 0) && (
                <Navbar.Brand style={{ color: "white", fontSize: 24 }}>
                  <Link to="/">
                    <img
                      src="/Metalyzer.png"
                      className="brand-logo mr-3"
                      style={{
                        width: "180px",
                        height: "70px",
                        marginLeft: "-6px",
                      }}
                      alt="logo"
                    />
                  </Link>
                </Navbar.Brand>
              )}
            </div>
            {/* {auth.isAuthenticated && isMobile ? (
            <></>
          ) : (
            <Nav className="me-auto">
              <NavDropdown
                title="Main"
                id="social-nav-dropdown"
                onMouseEnter={() => setSocialShow(true)}
                onMouseLeave={() => setSocialShow(false)}
                onClick={(e) => navigateLink(e, "/")}
                show={socialShow}
              >
                {socialMenu.map((menuItem, idx) => (
                  <Link
                    to="#"
                    onClick={(e) => {
                      navigateLink(e, menuItem.link);
                    }}
                    className="nav-link d-flex align-items-center"
                    key={`social-menu-${idx}`}
                  >
                    <div className="mr-3 menu-icon">
                      <Icon
                        icon={menuItem.icon}
                        height="2rem"
                        color={menuItem.color}
                      />
                    </div>
                    {menuItem.title}
                  </Link>
                ))}
              </NavDropdown>
              {etcMenu.map(
                (menuItem, idx) =>
                  menuItem.title !== "Watchlist" && (
                    <Nav.Item key={`etc-menu-${idx}`}>
                      <Link to={menuItem.link} className="nav-link">
                        {menuItem.title}
                      </Link>
                    </Nav.Item>
                  )
              )}
            </Nav>
          )} */}
            <Nav>
              {auth.isAuthenticated && (
                <SearchItemsModal searchItems={searchItems} buttonWidth={450} />
              )}
            </Nav>
            <Nav>
              {auth.isAuthenticated && (
                <IconButton
                  className={"bookmark-wrapper"}
                  icon={<BsStarFill size={14} color={"#F5B97F"} />}
                  title={"Watchlist"}
                  onClick={(e) => {
                    navigateLink(e, "/watchlist");
                  }}
                />
              )}
              <StatisticsBar />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarComponent;

