import axios from 'axios';

import {
  GET_RECENT_VIDEOS_LOADING,
  GET_RECENT_VIDEOS_SUCCESS,
  GET_RECENT_VIDEOS_FAIL,
} from '../types';
import { attachTokenToHeaders } from './authActions';
import { API_HOST } from '../../config/constants/data';

export const getYoutubeRecentVideosApi = (params) => async (dispatch, getState) => {
  dispatch({ type: GET_RECENT_VIDEOS_LOADING });
  try {
    const response = await axios.get(`${API_HOST}/api/v1/youtube/videos/?keyword=${params.name}&terms=7d&page_size=10`, attachTokenToHeaders(getState));
    dispatch({
      type: GET_RECENT_VIDEOS_SUCCESS,
      payload: { recentVideosData: response.data.results },
    });

  } catch (err) {
    console.log("getYoutubeRecentVideosApi error: ", err)
    dispatch({
      type: GET_RECENT_VIDEOS_FAIL,
      payload: { recentVideosError: err.response.data.message },
    });
  }
};
