import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Row,
  Col
} from 'react-bootstrap';

import ProjectFilterWithoutAll from '../../ProjectFilterWithoutAll/index';
import { editServerInviteSchema } from './validation';
import { convertID2Name, convertNick2ID } from '../../../../../utils/routingHelper';
import { putMainModel } from '../../../../../store/actions/mainModelActions';

const EditServerInviteModal = ( props ) => {
  const { show, setShowModal, item } = props;
  const dispatch = useDispatch();
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  const oldId = item?.id;
  const [keyword, setKeyword] = useState(convertID2Name(projectNames, item?.project_id));
  const [keywordNick, setKeywordNick] = useState('all')
  const [projectID, setProjectID] = useState(item?.project_id)
  const formik = useFormik({
    initialValues: {
      id: item?.id,
      scraper: item?.scraper,
      project_id: item?.project_id,
      invite: item?.invite,
    },
    validationSchema: editServerInviteSchema,
    onSubmit: async (values) => {
      let params = {}
      params.id = values.id
      params.scraper = values.scraper
      params.project_id = projectID
      params.invite = values.invite
      await dispatch(putMainModel(`discord/serverinvite/${oldId}/`, params))
      setShowModal(false);
    },
  });

  const udpateKeyword = (keywordObject) => {
    setKeyword(keywordObject.label);
    setKeywordNick(keywordObject.value);
    setProjectID(convertNick2ID(projectNames, keywordObject.value))
  }
  
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={'sm'}
    // aria-labelledby="filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 18, textAlign: 'center', width: '100%' }}>
          Edit Server Invite & Project
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="pt-3" onSubmit={formik.handleSubmit}>
          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className='mr-3'>Project</div>
            <ProjectFilterWithoutAll
              keyword={{ label: keyword, value: keywordNick }}
              setKeyword={udpateKeyword}
            />
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Invite Link</div>
            <Form.Control
              type='text'
              size='lg'
              placeholder='Enter Server Invite Link ...'
              name='invite'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.invite}
            />
            {formik.touched.invite && formik.errors.invite ? (
              <p className="text-danger">{formik.errors.invite}</p>
            ) : null}
          </Form.Group>
          <Row className="mt-4">
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  disabled={!formik.isValid}
                  type="submit"
                >
                  Edit
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditServerInviteModal;