import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import Select from "react-select";

import { useFormik } from "formik";

import { Col, Form, Row } from "react-bootstrap";

import { contactMessageWithEmail } from "../../store/actions/authActions";
import { contactUsSchema } from "./validation";

import "../../assets/scss/index.scss";
import "./styles.scss";
import Layout from "../../layout/Layout";
import { DISCORD_INVITE_LINK, TWITTER_LINK } from "../../config/constants/data";
import { Icon } from "@iconify/react";

import { selectionStyle } from "../../components/Selections/styles";
import { ToastContainer, toast, Flip } from "react-toastify";
import LoadingOverlay from "../../components/LoadingOverlay";
import useUserRole from "../../hooks/useUserRole";

const ContactUs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useUserRole();
  const [submitType, setSubmitType] = useState("submit");
  const [spin, setSpin] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      title: "",
      submitType: "",
      siteType: "",
      submitId: "",
      email: "",
      message: "",
    },
    validationSchema: contactUsSchema,
    onSubmit: async (values) => {
      values.siteType = siteType;
      values.submitType = submitType;
      setSpin(true);
      await dispatch(contactMessageWithEmail(values, history));
      setSpin(false);
      notifySuccess("Submit Successfully.");
    },
  });
  let customStyle = selectionStyle();
  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

  // if (isAuthenticated) return <Redirect to="/" />;
  const typeOptions = [
    { value: "submit", label: "Submit" },
    { value: "business", label: "Business Inquiry" },
    { value: "request", label: "Request" },
  ];

  const handleTypeChange = (value) => {
    setSubmitType(value?.value);
  };

  const siteOptions = [
    { value: "youtube", label: "Youtube" },
    { value: "telegram", label: "Telegram" },
    { value: "discord", label: "Discord" },
    { value: "website", label: "Website" },
  ];

  const placeholders = {
    youtube: "Youtube Channel URL",
    telegram: "Telegram group invite link",
    discord: "No expire Discord join link",
    website: "Website URL",
  };

  const [siteType, setSiteType] = useState("youtube");
  const [idPlaceholder, setIdPlaceholder] = useState(placeholders["youtube"]);
  const handleSiteChange = (value) => {
    setSiteType(value?.value);
    setIdPlaceholder(placeholders[value?.value]);
  };

  useEffect(() => {
    // Update the submitId field based on the submitType value
    formik.setValues((values) => ({
      ...values,
      submitId: submitType === "submit" ? "" : "submitId",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitType]);
  return (
    <Layout>
      <div className="h-100">
        <div className="d-flex align-items-start auth px-0 h-100">
          <div className="row w-100 mx-0">
            <div className="col-lg-6 mx-auto">
              {spin && (
                <div style={{ textAlign: "center" }}>
                  <LoadingOverlay />
                </div>
              )}
              <div className="card text-left py-3 px-4 px-sm-5">
                {/* <h2 className="mb-3">Submit a resource, request or business inquiry</h2>
                <h3 style={{ marginBottom: 20 }}>
                  Feel free to contact us for any inquiries related to our service. Whether you want to add a new resource, wish to explore partnership opportunities, or require assistance with your requests.
                </h3> */}
                <div
                  className="d-flex align-items-center justify-content-around"
                  style={{ flex: 1 }}
                >
                  <Link
                    className=""
                    to={{ pathname: DISCORD_INVITE_LINK }}
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    <div className="mr-2 social-link-icon discord-hover-color">
                      <Icon
                        icon="ic:baseline-discord"
                        height="4rem"
                        color="#5562EA"
                      />
                    </div>
                  </Link>
                  <Link
                    className=""
                    to={{ pathname: TWITTER_LINK }}
                    target="_blank"
                    rel="noopener noreferer"
                  >
                    <div className="mr-4 social-link-icon twitter-hover-color">
                      <Icon icon="prime:twitter" height="3rem" color="white" />
                    </div>
                  </Link>
                </div>

                <Form
                  className="pt-3"
                  onSubmit={formik.handleSubmit}
                  noValidate
                >
                  <Form.Group className="d-flex flex-column pt-3">
                    <Form.Control
                      placeholder="Email"
                      name="email"
                      className="h-auto"
                      type="email"
                      size="lg"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />

                    {formik.touched.email && formik.errors.email ? (
                      <p className="text-danger">{formik.errors.email}</p>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="d-flex flex-column pt-3">
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      className="h-auto"
                      size="lg"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />

                    {formik.touched.name && formik.errors.name ? (
                      <p className="text-danger">{formik.errors.name}</p>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="d-flex flex-column pt-3">
                    <Row>
                      <Col xs={6} md={6}>
                        <Select
                          defaultValue={typeOptions[0]}
                          size="lg"
                          id="typeSelect"
                          onChange={handleTypeChange}
                          options={typeOptions}
                          styles={customStyle}
                        ></Select>
                      </Col>
                      {submitType === "submit" && (
                        <Col xs={6} md={6}>
                          <Select
                            as="select"
                            size="lg"
                            id="business"
                            defaultValue={siteOptions[0]}
                            options={siteOptions}
                            onChange={handleSiteChange}
                            styles={customStyle}
                          ></Select>
                        </Col>
                      )}
                    </Row>
                  </Form.Group>
                  {submitType === "submit" && (
                    <Form.Group className="d-flex flex-column pt-3">
                      <Form.Control
                        type="text"
                        placeholder={idPlaceholder}
                        name="submitId"
                        className="h-auto"
                        size="lg"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.submitId}
                      />

                      {formik.touched.submitId && formik.errors.submitId ? (
                        <p className="text-danger">{formik.errors.submitId}</p>
                      ) : null}
                    </Form.Group>
                  )}
                  <Form.Group className="d-flex flex-column pt-3">
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      name="title"
                      className="h-auto"
                      size="lg"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                    />

                    {formik.touched.title && formik.errors.title ? (
                      <p className="text-danger">{formik.errors.title}</p>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="d-flex flex-column pt-3">
                    <Form.Control
                      as="textarea"
                      rows={10}
                      placeholder="Write your message..."
                      name="message"
                      className="h-auto"
                      type="text"
                      size="lg"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                    />

                    {formik.touched.message && formik.errors.message ? (
                      <p className="text-danger">{formik.errors.message}</p>
                    ) : null}
                  </Form.Group>

                  {auth.error && <p className="text-danger">{auth.error}</p>}
                  {/* {status && notifySuccess("Sucessfully "+status)} */}
                  <div className="mt-3 d-flex justify-content-end">
                    <button
                      className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      type="submit"
                      style={{ width: "150px", borderRadius: 8 }}
                      disabled={auth.isLoading || !formik.isValid}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer theme="dark" transition={Flip} />
    </Layout>
  );
};

export default ContactUs;

