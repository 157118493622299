import React from 'react'
import { useParams } from 'react-router-dom'
import { Flip, ToastContainer } from 'react-toastify';
import LinkButtons from '../../../components/LinkButtons';
import PageTitle from '../../../components/PageTitle';
import { EDIT_DISCORD_MODEL_LINKS } from '../../../config/constants/data';
import ServerInvite from './Components/ServerInvite';
import Layout from '../../../layout/Layout';

const EditDiscord = () => {
  const { model } = useParams();
  const modelName = model ? model : "serverinvite";
  return (
    <Layout>
      <PageTitle
        title='Discord Edit Page.'
        description='You can edit the Discords here.'
      />
      <div className="tabs align-items-center">
        {/* <div className="category_divide" /> */}
        <LinkButtons
          linkItems={EDIT_DISCORD_MODEL_LINKS}
          activeItem={model ? model==="serverinvite" ? "server invite" : model : "server invite"}
        />
      </div>
      {modelName === "serverinvite" && <ServerInvite />}
      <ToastContainer theme="dark" transition={Flip} />
    </Layout>
  )
}

export default EditDiscord