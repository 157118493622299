import React from "react";
import { Button } from "react-bootstrap";
import useUserRole from "../../../hooks/useUserRole";
import PropTypes from "prop-types";

const SubTableView = ({ children, model, subLimitation = 1 }) => {
  const { subscription } = useUserRole();
  const redirectToPricing = () => {
    window.location.href = "/plans"; // Modify this to the correct route for your pricing page.
  };
  // TODO update the subLimitation = 2 and model names.
  const enabledSubscription =
    ["new-partners", "partners"].includes(model) &&
    subscription < subLimitation;

  return (
    <div>
      <div className="mx-1">{children}</div>
      {enabledSubscription && (
        <div
          style={{
            position: "absolute",
            minHeight: "400px",
            top: "128px",
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(23, 23, 26, 0.5)",
            backdropFilter: "blur(8px)",
            display: "flex",
            alignItems: "start",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ zIndex: 2, borderRadius: 6, marginTop: 50 }}
            size="lg"
            onClick={redirectToPricing}
          >
            Buy Premium
          </Button>
        </div>
      )}
    </div>
  );
};

SubTableView.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubTableView;

