import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  createRef,
} from "react";

import { useHistory } from "react-router-dom";
import { RiSearchLine } from "react-icons/ri";
import { Icon } from "@iconify/react";
import { getIconFromSearchItem, getLinkFromSearchItem } from "../../../utils/routingHelper";

const SearchModalFeed = (props) => {
  const { closeModal, searchItems } = props;
  const history = useHistory();

  const [searchResults, setSearchResults] = useState({
    activeIndex: 0,
    filteredItems: [],
    showProjects: true,
    userInput: "",
  });
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const onClear = () => {
    setSearchResults({
      activeIndex: 0,
      filteredProjects: [],
      showProjects: true,
      userInput: "",
    });
    inputRef.current.focus();
  };
  const [displayedItems, setDisplayedItems] = useState([]);
  const loadMoreRef = useRef(null);

  const MAX_ITEMS_PER_LOAD = 20; // Number of items to load per batch

  const loadMoreItems = useCallback(() => {
    setDisplayedItems((prevItems) => {
      const nextIndex = prevItems.length;
      const moreItems = searchResults.filteredItems?.slice(
        nextIndex,
        nextIndex + MAX_ITEMS_PER_LOAD
      );
      return [...prevItems, ...moreItems];
    });
  }, [searchResults.filteredItems]);

  useEffect(() => {
    setDisplayedItems(searchResults.filteredItems?.slice(0, MAX_ITEMS_PER_LOAD));
  }, [searchResults.filteredItems]);

  useEffect(() => {
    const currentRef = loadMoreRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      },
      { threshold: 1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [loadMoreItems]);

  const filterItems = (userInput) => {
    const lowerCaseInput = userInput.toLowerCase();
    return searchItems.filter((item) =>
      item.name.toLowerCase().includes(lowerCaseInput)
    );
  };

  const onChange = (e) => {
    const userInput = e.target.value;
    const filteredItems = filterItems(userInput);
    setSearchResults({
      activeIndex: 0,
      filteredItems,
      showProjects: true,
      userInput,
    });
  };

  const onClick = (item) => {
    history.push(getLinkFromSearchItem(item));
    closeModal();
  };

  const itemRefs = useRef([]);

  useEffect(() => {
    // Adjust the itemRefs array to match the number of displayed items
    itemRefs.current = displayedItems.map((_, i) => itemRefs.current[i] || createRef());
  }, [displayedItems]);

  const onKeyDown = (e) => {
    const { activeIndex, filteredItems } = searchResults;
    let newIndex = activeIndex;

    // User pressed the enter key
    if (e.keyCode === 13) {
      history.push(getLinkFromSearchItem(filteredItems[activeIndex]));
      closeModal();
    }
    // User pressed the ESC
    if (e.keyCode === 27) {
      closeModal();
    }

    // User pressed the up arrow
    if (e.keyCode === 38 && activeIndex > 0) {
      newIndex = activeIndex - 1;
    }
    // User pressed the down arrow
    else if (e.keyCode === 40 && activeIndex < filteredItems.length - 1) {
      newIndex = activeIndex + 1;
    }

    if (newIndex !== activeIndex) {
      setSearchResults({ ...searchResults, activeIndex: newIndex });
      // Scroll the new active item into view
      if (itemRefs.current[newIndex] && itemRefs.current[newIndex].current) {
        itemRefs.current[newIndex].current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  };
  let searchItemsComponent;

  if (searchResults.showProjects) {
    if (
      searchResults.filteredItems &&
      searchResults.filteredItems.length
    ) {
      searchItemsComponent = (
        <ul className="projects">
          {displayedItems.map((item, index) => {
              let className = "";

              // Flag the active project with a class
              if (index === searchResults.activeIndex) {
                className = "project-active";
              }

              return (
                <li
                  ref={itemRefs.current[index]}
                  className={"search-result-row " + className}
                  key={item.id}
                  onClick={() => onClick(item)}
                >
                  <div>
                    <img
                      src={getIconFromSearchItem(item)}
                      className="mr-3"
                      style={{ borderRadius: "100%", height: 25, width: 25 }}
                      alt=""
                    />
                    {item.name}
                  </div>
                </li>
              );
            })}
            <div ref={loadMoreRef} />
        </ul>
      );
    } else {
      searchItemsComponent = (
        <div className="no-projects">
          No result for "{searchResults.userInput}"
        </div>
      );
    }
  }

  return (
    <div className="search-modal-border">
      <div className="search-modal-wrapper">
        <div className="search-row">
          <RiSearchLine size={24} color={"#848BA1"} />
          <input
            ref={inputRef}
            type="text"
            className="project-search-input"
            placeholder="Search"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={searchResults.userInput}
          />
          <div className="hide-button" onClick={onClear}>
            <Icon icon="ph:backspace-bold" height="25px" color="#323546" />
          </div>
          <div onClick={closeModal} style={{ marginLeft: 20 }}>
            <Icon icon="mdi:close-thick" height="25px" color="#fff" />
          </div>
        </div>
        <div className="search-result-modal">{searchItemsComponent}</div>
      </div>
    </div>
  );
};

export default SearchModalFeed;
