import React from "react";

import MainTable from "../../../../components/Tables/MainTable";
import { useLocation } from "react-router-dom";

import {
  LINK_DETAILS_TABLE_HEADER,
  LINK_DETAILS_TABLE_API,
} from "../../../../config/constants/tableHeaders";
import { RecommendItems } from "../..";

const LinkDetails = (props) => {
  const location = useLocation();
  const filters = { link: encodeURIComponent(location.state.link) };
  const UPDATE_LINK_DETAILS_TABLE_HEADER = LINK_DETAILS_TABLE_HEADER.filter(
    (header) => header.title !== "Project"
  );

  return (
    <>
      <MainTable
        tableHeader={UPDATE_LINK_DETAILS_TABLE_HEADER}
        URL={LINK_DETAILS_TABLE_API}
        filters={filters}
        withTermsFilter
        withoutProjectFilter
        withoutFilterModal
        model={"details"}
      />
      <RecommendItems />
    </>
  );
};

export default LinkDetails;

