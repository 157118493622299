export const faqData = [
  {
    question: "What is Floki?",
    answer: `<div>
      <p>
        <a href="https://floki.com/alexandria/article/an-intro-to-floki">
          Floki
        </a>
        is a visionary
        <a href="https://floki.com/alexandria/article/what-are-meme-coins">
          meme coin
        </a>
        inspired by the vibrant culture of cryptocurrencies and named
        after the beloved pet of a legendary entrepreneur. Launched in
        mid-2021, Floki aims to blend social media trends with practical
        utility in the crypto space.
      </p>
      <p>
        <a href="https://floki.com/alexandria/article/what-is-floki">
          Floki
        </a>
        is not just another digital currency; it's a movement. With a
        strong focus on community and social impact, Floki is dedicated
        to
        <a href="https://floki.com/alexandria/article/floki-charity-projects">
          charity projects
        </a>
        and educational initiatives to promote the adoption of
        cryptocurrencies worldwide. Its mission is to create an
        inclusive financial ecosystem that empowers the average person.
      </p>
      <p>
        The inception of Floki has sparked a wave of interest in
        <a href="https://floki.com/alexandria/article/how-to-participate-in-meme-coin-economy">
          meme coin economies
        </a>
        , encouraging innovation and investment in the sector. As the
        first meme coin with a clear vision for integrating blockchain
        technology into real-world applications, Floki sets a new
        precedent for the future of digital currencies.
      </p>
  </div>`,
  },
  {
    question: "Who Are the Founders of Floki?",
    answer: `
    <div>
      <p>
        The founders of <a href="https://floki.com/alexandria/article/who-are-the-founders-of-floki">Floki</a> are shrouded in mystery, much like the early days of Bitcoin. However, it is widely believed that Floki was conceived by a diverse group of cryptocurrency enthusiasts, tech entrepreneurs, and software developers who share a common vision for the future of decentralized finance and the role of meme coins within it.
      </p>
      <p>
        Among the known figures, Elon Musk's pet Shiba Inu, "Floki," has been an unofficial mascot and inspiration behind the coin's creation. The project leverages the popularity of Musk's influence in the crypto space to foster a community-driven approach to its development and governance.
      </p>
      <p>
        The core team remains anonymous, choosing instead to focus on the collective identity of the Floki community. They believe that true decentralization means moving away from the cult of personality and towards a more democratic model of leadership. This ethos is reflected in their decision-making processes, development, and promotional strategies, which prioritize community input and consensus.
      </p>
      <p>
        The Floki team is committed to transparency and open communication, regularly updating the community through their official channels on <a href="https://floki.com/alexandria/article/floki-social-media-channels">social media</a> and <a href="https://floki.com/alexandria/article/floki-community-forums">community forums</a>. Their dedication to building a meme coin with real-world utility and a strong, supportive community sets Floki apart in the crowded cryptocurrency market.
      </p>
    </div>
    `,
  },
];
