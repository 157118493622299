import React from "react";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { transformNumber } from "../../../../utils/tableDataHelper";

function PrePercentageComponent({ item, h, isDirect = false }) {
  const val = isDirect
    ? parseFloat(item[h.value])
    : !item["pre_".concat(h.value)] || item["pre_".concat(h.value)] === "0"
    ? 0
    : parseFloat(
        (transformNumber(item[h.value]) -
          transformNumber(item["pre_".concat(h.value)])) /
          transformNumber(item["pre_".concat(h.value)])
      ) * 100;
  return (
    <span
      style={{
        color: val >= 0 ? "#16C784" : "#EA3943",
        height: "40px",
      }}
    >
      {val >= 0 ? (
        <BsFillCaretUpFill size={11} />
      ) : (
        <BsFillCaretDownFill size={11} />
      )}
      &nbsp;
      {val.toFixed(2)}%
    </span>
  );
}

export default PrePercentageComponent;

