import { useEffect, useState } from "react";
import TableView from "../Components/TableView";
import axios from "axios";
import { API_HOST } from "../../../config/constants/data";
import { getDefaultOrderingField } from "../../../utils/tableDataHelper";

const SimpleTable = ({
  URL,
  tableHeader,
  tableModelId,
  size = 3,
  topicType = "shared",
  ...others
}) => {
  const [data, setData] = useState([]);

  const getCurrentOrdering = () => {
    const val = window.currentOrdering || getDefaultOrderingField(tableModelId);
    // delete window.currentOrdering;
    return val;
  };
  const [currentOrdering, setCurrentOrdering] = useState(
    getCurrentOrdering(tableModelId)
  );

  useEffect(() => {
    let cancelled = false;
    const fetchData = async () => {
      let url = `${API_HOST}${URL}`;
      if (url.indexOf("?") >= 0) {
        url += "&";
      } else {
        url += "?";
      }
      url += `page_size=${size}&ordering=${currentOrdering}`;
      const { data } = await axios.get(url);
      if (cancelled) {
        return;
      }
      setData(data.results);
    };
    fetchData();

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URL, currentOrdering]);

  const handleOrdering = (direction, sortField, sortType) => {
    setCurrentOrdering(
      (window.currentOrdering =
        (direction === "-" ? direction : "") + sortField)
    );
  };

  return (
    <TableView
      forceProps
      header={tableHeader}
      data={data}
      startNumber={0}
      topicType={topicType}
      initOrdering={currentOrdering}
      handleOrdering={handleOrdering}
      tableModelId={tableModelId}
      {...others}
    />
  );
};
export default SimpleTable;

