import {
  GET_EVERY_MINUTE_LOADING,
  GET_EVERY_MINUTE_SUCCESS,
  GET_EVERY_MINUTE_FAIL,
} from '../types';

const initialState = {
  everyMinuteData: [],
  everyMinuteApiLoading: false,
  everyMinuteError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_EVERY_MINUTE_LOADING:
      return {
        ...state,
        everyMinuteApiLoading: true,
        everyMinuteError: null,
      };
    case GET_EVERY_MINUTE_SUCCESS:
      return {
        ...state,
        everyMinuteData: payload.everyMinuteData,
        everyMinuteApiLoading: false,
        everyMinuteError: null,
      };
    case GET_EVERY_MINUTE_FAIL:
      return {
        ...state,
        everyMinuteData: [],
        everyMinuteApiLoading: false,
        everyMinuteError: payload?.everyMinuteError
      };
    default:
      return state;
  }
}
