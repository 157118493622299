import {
  GET_GENERAL_API_LOADING,
  GET_GENERAL_API_SUCCESS,
  GET_GENERAL_API_FAIL,
} from '../types';

const initialState = {
  generalData: [],
  generalApiLoading: false,
  generalError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_GENERAL_API_LOADING:
      return {
        ...state,
        generalApiLoading: true,
        generalError: null,
      };
    case GET_GENERAL_API_SUCCESS:
      return {
        ...state,
        generalData: payload.generalData,
        generalApiLoading: false,
        generalError: null,
      };
    case GET_GENERAL_API_FAIL:
      return {
        ...state,
        generalData: [],
        generalApiLoading: false,
        generalError: payload?.generalError
      };
    default:
      return state;
  }
}
