import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

import "./styles.css";
import { DISCORD_INVITE_LINK, TWITTER_LINK } from "../config/constants/data";
import { etcMenu, socialMenu } from "../config/constants/menu";

const Footer = () => {
  return (
    <div className="footer">
      <Container fluid className="footer-menubar mt-5 pt-5 px-5">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="d-flex align-items-center">
              <img
                src="/logo.png"
                width="40"
                height="40"
                className="logo-content align-left"
                alt=""
              />
              <p className="logo-name mb-0">Follow us</p>
            </div>

            <div className="footer-logo-description">
              <div
                className="d-flex align-items-center mt-3"
                style={{ flex: 1, marginLeft: 40 }}
              >
                <Link
                  className=""
                  to={{ pathname: DISCORD_INVITE_LINK }}
                  target="_blank"
                  rel="noopener noreferer"
                >
                  <div className="mr-4 social-link-icon discord-hover-color">
                    <Icon
                      icon="ic:baseline-discord"
                      height="3rem"
                      color="#5562EA"
                    />
                  </div>
                </Link>
                <Link
                  className=""
                  to={{ pathname: TWITTER_LINK }}
                  target="_blank"
                  rel="noopener noreferer"
                >
                  <div className="mr-4 social-link-icon twitter-hover-color">
                    <Icon icon="prime:twitter" height="2.3rem" color="white" />
                  </div>
                </Link>
              </div>
              {/* <p className="">
              Metalyzer serves as your AI-powered social media agent. 
              Fed up with spending endless hours researching content on the internet? 
              Allow us to handle the grunt work while you concentrate on the important aspects.
              </p> */}
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 px-5">
            <div className="row justify-content-between">
              {socialMenu.map((item, index) => (
                <div
                  className="col-lg-2 col-6 footer-menubar-section mt-3"
                  key={`social-${index}`}
                >
                  <span className="title">{item.title}</span>
                  <ul className="nav flex-column">
                    {item.children.map((child, childIndex) => (
                      <li className="nav-item" key={childIndex}>
                        <Link className="nav-link active" to={child.link}>
                          {child.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <div className="col-lg-2 col-6 footer-menubar-section mt-3">
                <span className="title">Service</span>
                <ul className="nav flex-column">
                  {etcMenu.map(
                    (child, childIndex) =>
                      child.title !== "Watchlist" && (
                        <li className="nav-item" key={childIndex}>
                          <Link className="nav-link active" to={child.link}>
                            {child.title}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid className="py-5 footer-copyright px-5">
        <p>© 2024 metalyzer.ai All rights reserved</p>
      </Container>
    </div>
  );
};

export default Footer;
