import {
  SET_MINISIDENAV,
  SET_PAGE_SIZE,
  SET_TERMS,
  SET_TOPIC_TYPE,
  SET_TABLE_TYPE,
  SET_SEARCH_TEXT,
  SET_SEARCH_TEXT_GLOBAL,
} from "../types";

const initialState = {
  miniSideNav: true,
  terms: "1d", // 7d, 30d, 3m, 6m, 1y, all.
  pageSize: 100,
  topicType: "shared",
  tableType: { label: "Subs", value: "partner" },
  searchText: "",
  searchTextGlobal: "",
};

const uiSettingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MINISIDENAV:
      return { ...state, miniSideNav: payload };
    case SET_TERMS:
      return { ...state, terms: payload };
    case SET_PAGE_SIZE:
      return { ...state, pageSize: payload };
    case SET_TOPIC_TYPE:
      return { ...state, topicType: payload };
    case SET_TABLE_TYPE:
      return { ...state, tableType: payload };
    case SET_SEARCH_TEXT:
      return { ...state, searchText: payload };
    case SET_SEARCH_TEXT_GLOBAL:
      return { ...state, searchTextGlobal: payload };
    default:
      return state;
  }
};

export default uiSettingsReducer;

