import React from 'react'
import { useParams } from 'react-router-dom'
import { Flip, ToastContainer } from 'react-toastify';
import LinkButtons from '../../../components/LinkButtons';
import PageTitle from '../../../components/PageTitle';
import { EDIT_PROJECT_MODEL_LINKS } from '../../../config/constants/data';
import Project from './Components/Project';
import Keyword from './Components/Keyword';
import Category from './Components/Category';
import Layout from '../../../layout/Layout';

const EditProject = () => {
  const { model } = useParams();
  const modelName = model ? model : "project";
  return (
    <Layout>
      <PageTitle
        title='Project Edit Page.'
        description='You can edit the Projects here.'
      />
      <div className="tabs align-items-center" style={{marginLeft: -2}}>
        {/* <div className="category_divide" /> */}
        <LinkButtons
          linkItems={EDIT_PROJECT_MODEL_LINKS}
          activeItem={model ? model : "project"}
        />
      </div>
      {modelName === "project" && <Project />}
      {modelName === "keyword" && <Keyword />}
      {modelName === "category" && <Category />}
      <ToastContainer theme="dark" transition={Flip} />
    </Layout>
  )
}

export default EditProject