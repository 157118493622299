import React from 'react';
import EditMainTable from '../../../../../components/Tables/EditMainTable'
import { YOUTUBE_CHANNELS_TABLE_HEADER, YOUTUBE_CHANNEL_TOPIC_MAIN_TABLE_API } from '../../../../../config/constants/tableHeaders'

const Channel = () => {
  let updatedHeader = structuredClone(YOUTUBE_CHANNELS_TABLE_HEADER);
  updatedHeader = updatedHeader.filter(header => (header.title!=='Vote' && header.title!==''))
  updatedHeader[updatedHeader.length-1].type = "component";
  updatedHeader[updatedHeader.length-1].checked = true;
  
  return (
    <EditMainTable
      tableHeader={updatedHeader}
      URL={YOUTUBE_CHANNEL_TOPIC_MAIN_TABLE_API}
      withTermsFilter
      withoutFilterModal
      model={"youtube-channels"}
      modelName="edit-topic-channel"
    />
  )
}

export default Channel