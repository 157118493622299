import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import MainTable from "../../../../components/Tables/MainTable";
import { routing2TableHeaderURL } from "../../../../utils/routing2TableHelper";
import { RecommendItems } from "../..";

const WebsiteSocial = (props) => {
  const { channelId, domain } = useParams();
  useEffect(() => {
    props.setSelectedRoute("social");
    // eslint-disable-next-line
  }, []);
  let modelName = "youtube-channel-social-website--channelId";
  if (domain !== undefined) {
    modelName = "youtube-channel-social-link--channelId-domain";
  }
  const [tableHeader, URL] = routing2TableHeaderURL(
    modelName,
    undefined,
    domain
  );

  return (
    <div>
      <MainTable
        tableHeader={tableHeader}
        URL={URL.replace("{channelId}", channelId)}
        filters={domain !== undefined ? { domain: domain } : {}}
        withTermsFilter
        withoutProjectFilter
        withoutFilterModal
        withoutTopicFilter
        defaultTerm={"7d"}
        model={"social"}
        modelName={modelName}
      />
      <RecommendItems />
    </div>
  );
};

export default WebsiteSocial;

