import React from "react";

import "react-toastify/dist/ReactToastify.css";

import Layout from "../../layout/Layout";
import {
  BLOGS_MAIN_TABLE_API,
  BLOGS_TABLE_HEADER,
} from "../../config/constants/tableHeaders";

import Breadcrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import MainTable from "../../components/Tables/MainTable";

import "./Blog.scss";

const Blog = () => {
  return (
    <Layout>
      {/* <Helmet>
        <title>Blog | metalyzer </title>
        <meta name="description" content={"Blog."} />
        <meta
          name="keywords"
          content={
            "blogs,Project,metalyzer,Crypto,Social,Analytics,Metrics,Research"
          }
        />
      </Helmet> */}
      <Breadcrumb
        // parentLink="/news-project/"
        // parentLabel="Project"
        currentLabel="Blogs"
      />
      <PageTitle
        title="Blog Page."
        description=" metalyzer shows all blogs by our service."
      />
      {/* <div className="tabs align-items-center">
        <LinkButtons
          linkItems={NEWS_CATEGORY}
          activeItem={'news'}
        />
      </div> */}

      {/* <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
      /> */}

      <MainTable
        tableHeader={BLOGS_TABLE_HEADER}
        URL={BLOGS_MAIN_TABLE_API}
        withTermsFilter
        withoutSearch
        // withoutProjectFilter
        withoutFilterModal
        withoutTopicFilter
        model={"blog"}
        modelName="blogs"
      />
    </Layout>
  );
};

export default Blog;

