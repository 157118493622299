import React from "react";
import ReactPaginate from "react-paginate";
import MainSelection from "../../../Selections/MainSelection";

const TablePaginate = (props) => {
  const { count, page, pageSize, handlePageClick } = props;
  
  const totalPages = Math.ceil(count / parseInt(pageSize));
  const pageItems = Array.from({ length: totalPages }, (_, i) => ({
    label: "Page " + (i + 1).toString(),
    value: i,
  }));
  const defaultItem = { label: "Page " + page, value: parseInt(page)-1 };
  const onPageChange = (e) => {
    if (e.selected >= 0) {
      const newPage = parseInt(e.selected) + 1;
      handlePageClick(newPage);
    }
  };

  const onDropdownChange = (e) => {
    const newPage = parseInt(e) + 1;
    handlePageClick(newPage);
  };

  const paginationStyle = {
    minHeight: "50px", // Example fixed height
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.25rem", // mt-5 -> 1.25rem
    display: "flex",
  };

  return (
    <div style={paginationStyle}>
      <label htmlFor="#" className="mb-0 text-white-50 text-nowrap mr-4">
        Results : {count}
      </label>
      <div className="mt-3">
        <ReactPaginate
          previousLabel={<i className="mdi mdi-chevron-left"></i>}
          nextLabel={<i className="mdi mdi-chevron-right"></i>}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          forcePage={page - 1}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={onPageChange}
          disableInitialCallback={true}
          containerClassName={"pagination justify-content-end"}
          subContainerClassName={"pages pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
      <MainSelection
        title=""
        selectionItems={pageItems}
        defaultItem={defaultItem}
        setItem={onDropdownChange}
        sorted={false}
        width={80}
        height={28}
      />
    </div>
  );
};

export default TablePaginate;
