import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import Select from "react-select";
import '../styles.css';

import {
  Row,
  Col
} from 'react-bootstrap';
import { selectionStyle } from '../styles';
import '../styles.css';

const compare = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}

const TopicComponent = (props) => {
  const { withoutTopic, topic, setTopic, tableModelId = 1, primaryKey } = props;
  const topics = useSelector((state) => state.topics.topics) || [];
  const [options, setOptions] = useState([]);
  let customStyle = selectionStyle();
  customStyle.menuList = (styles) => ({ ...styles, backgroundColor: "#323546", minWidth: '150px', left: 0, textAlign: 'left' });

  let updateOptions;
  const getDefaultValue = (topics, tableModelId, topic) => {
    updateOptions = topics?.filter(
      topic => { return topic?.model === tableModelId; }
    ).map(topic => {
      return { label: topic.label, value: topic.id }
    });
    updateOptions = withoutTopic ? [{ label: "No Topic", value: 0 }, ...updateOptions.sort(compare)]
      : [{ label: "Topic", value: -1 }, { label: "No Topic", value: 0 }, ...updateOptions.sort(compare)];
    if (tableModelId !== 5 && tableModelId !== 8 && withoutTopic)
      updateOptions.splice(2, 0, { label: "Blacklist", value: -2})
    const defaultFilter = updateOptions.filter((option) => { return option?.value === parseInt(topic) });
    const defaultValue = defaultFilter.length > 0 ? defaultFilter?.[0] : withoutTopic ? { label: "No Topic", value: 0 } : { label: "Topic", value: -1 }
    return defaultValue
  }
  const [topicValue, setTopicValue] = useState(getDefaultValue(topics, tableModelId, topic));
  const onChange = (topic) => {
    if (topic.value !== parseInt(topicValue)) {
      setTopic(topic.value, primaryKey);
      setTopicValue(topic)
    }
  }

  useEffect(() => {
    setTopicValue(getDefaultValue(topics, tableModelId, topic))
    setOptions(updateOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic])

  return (
    <>
      {
        props.title &&
        <Row>
          <Col>
            <label htmlFor="#" className="mb-0 text-success text-nowrap">
              {props.title}
            </label>
          </Col>
        </Row>
      }
      <Row>
        <Col className='d-flex justify-content-end sss'>
          {
            options && options.length &&
            <Select
              defaultValue={topicValue}
              value={topicValue}
              onChange={onChange}
              options={options}
              styles={customStyle}
              isSearchable={true}
              className='mx-1 fff position-relative'
              classNamePrefix="topic-select"
            />
          }
        </Col>
      </Row>
    </>
  );
};


export default TopicComponent;
