import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Navbar } from "react-bootstrap";
import FullModal from "../../components/FullModal";
import MenuModalFeed from "./MenuModalFeed";
import SearchModalFeed from "./SearchModalFeed";
import DiamondModalFeed from "./DiamondModalFeed";

const StatisticsBarMobile = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const searchItems =
    useSelector((state) => state.searchItems.searchItems) || [];

  const openDialog = (sym) => {
    setModalType(sym);
    setIsShowModal(true);
  };

  const renderModalTitle = () => {
    return (
      <div className=" container d-flex flex-row align-items-center justify-content-between py-2">
        <div className="d-lg-flex align-items-center justify-content-center">
          <Navbar.Brand
            href="/"
            style={{ color: "white", fontSize: "22px", fontWeight: "600" }}
          >
            {/* <img
              src="/logo.png"
              className="brand-logo mr-3"
              style={{ width: 30 }}
              alt="logo"
            /> */}
            <img
              src="/Metalyzer.png"
              className="brand-logo mr-3"
              style={{ width: "180px", height: "70px", marginLeft: "-6px" }}
              alt="logo"
            />
          </Navbar.Brand>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="d-lg-flex align-items-center justify-content-center">
          <Navbar.Brand
            href="/"
            style={{ color: "white", fontSize: "22px", fontWeight: "600" }}
          >
            {/* <img
              src="/logo.png"
              className="brand-logo mr-3"
              style={{ width: 30 }}
              alt="logo"
            /> */}
            <img
              src="/Metalyzer.png"
              className=""
              style={{ width: "180px", height: "70px", marginLeft: "0px" }}
              alt="logo"
            />
          </Navbar.Brand>
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="mr-4" onClick={() => openDialog("search")}>
            <Icon
              icon="mingcute:search-line"
              height="2rem"
              color="#ffffff"
              style={{}}
            />
          </div>
          {/* <div className='mr-4' onClick={() => openDialog('diamond')}>
            <Icon
              icon="material-symbols:diamond"
              height="2rem"
              color="#ffffff"
              style={{}}
            />
          </div> */}
          <div className="mr01" onClick={() => openDialog("menu")}>
            <Icon
              icon="ri:menu-line"
              height="2rem"
              color="#ffffff"
              style={{}}
            />
          </div>
        </div>
      </div>
      <FullModal
        isShowModal={isShowModal}
        setShowModal={setIsShowModal}
        title={renderModalTitle()}
        showHeader={modalType === "menu"}
      >
        {modalType === "menu" ? (
          <MenuModalFeed
            closeModal={() => {
              setIsShowModal(false);
            }}
          />
        ) : modalType === "search" ? (
          <SearchModalFeed
            closeModal={() => {
              setIsShowModal(false);
            }}
            searchItems={searchItems}
          />
        ) : modalType === "diamond" ? (
          <DiamondModalFeed />
        ) : (
          <></>
        )}
      </FullModal>
    </div>
  );
};

export default StatisticsBarMobile;
