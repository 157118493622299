import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS } from "../../../../config/constants/data";
import {
  WEBSITE_VIDEOS_TABLE_HEADER,
  WEBSITE_VIDEOS_MAIN_TABLE_API,
} from "../../../../config/constants/tableHeaders";
import MainTable from "../../../../components/Tables/MainTable";
import { RecommendItems } from "../..";

const WebsiteVideos = (props) => {
  const { domain } = useParams();
  useEffect(() => {
    props.setSelectedRoute("videos");
    // eslint-disable-next-line
  }, []);
  let filters = window.advancedFilters || {};
  YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });
  if (domain !== "" && domain !== undefined) {
    filters["website"] = domain;
  }

  return (
    <div>
      <MainTable
        tableHeader={WEBSITE_VIDEOS_TABLE_HEADER}
        URL={WEBSITE_VIDEOS_MAIN_TABLE_API}
        withTermsFilter
        withoutProjectFilter
        withoutTopicFilter
        filters={filters}
        filterModalFields={YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS}
        model={"youtube-videos"}
        modelName="website-video--domain"
      />
      <RecommendItems />
    </div>
  );
};

export default WebsiteVideos;

