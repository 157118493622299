import React, { useEffect } from "react";

import MainTable from "../../../../components/Tables/MainTable";
import { useParams } from "react-router-dom";

import {
  DISCORD_SERVERS_TABLE_HEADER,
  DISCORD_SERVERS_MAIN_TABLE_API,
} from "../../../../config/constants/tableHeaders";
import { RecommendItems } from "../..";

const DiscordServers = (props) => {
  const UPDATE_DISCORD_SERVERS_TABLE_HEADER =
    DISCORD_SERVERS_TABLE_HEADER.filter(
      (header) =>
        header.title !== "Owner" &&
        header.title !== "Region" &&
        header.title !== "Online Users" &&
        header.title !== "+ Online Users"
    );
  const { nickName } = useParams();
  useEffect(() => {
    props.setSelectedRoute("servers");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainTable
        tableHeader={UPDATE_DISCORD_SERVERS_TABLE_HEADER}
        URL={DISCORD_SERVERS_MAIN_TABLE_API}
        withTermsFilter
        projectNickName={nickName}
        withoutProjectFilter
        withoutFilterModal
        defaultTerm={"7d"}
        model={"discord-servers"}
        modelName="project-discord-server--nickName"
      />
      <RecommendItems />
    </>
  );
};

export default DiscordServers;

