import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import MainChart from "./MainChart";
// import TopInfluencersCard from "./TopInfluencersCard";
import { convertNick2Name } from "../../../../utils/routingHelper";
import { RecommendItems } from "../..";

const ProjectOverview = (props) => {
  const { nickName } = props;
  const [description, setDescription] = useState("");
  const generalData = useSelector((state) => state.generalApiState.generalData);
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  const projectName = convertNick2Name(projectNames, nickName);
  useEffect(() => {
    try {
      setDescription(generalData?.["description"]?.["description"]);
    } catch (e) {}
  }, [generalData]);

  return (
    <div className="row mt-3">
      <div className="col col-12">
        <MainChart nickName={nickName} />
        <div className="project-detail-description pt-4">
          <h1 className="px-2 font-weight-bold">
            {"What is " + projectName + "?"}
          </h1>
          <p>{description}</p>
        </div>
        <RecommendItems />
      </div>
    </div>
  );
};

export default ProjectOverview;
