import * as Yup from 'yup';

export const contactUsSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Must be 2 characters at minimum')
    .max(50, 'Must be 30 characters or less')
    .matches(/^[a-zA-Z .]+$/, 'Invalid characters in Name')
    .required('Required'),
  title: Yup.string()
    .min(2, 'Must be 2 characters at minimum')
    .max(30, 'Must be 30 characters or less')
    // .matches(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/, 'Invalid characters in Title')
    .required('Required'),
  email: Yup.string().email('Invalid Email address').required('Required'),
  submitId: Yup.string().min(3, 'Must be 3 characters at minimum').required('Required'),
  message: Yup.string()
    .min(3, 'Must be 3 characters at minimum')
    .required('Required')
});
