
import styled from 'styled-components';
  
export const ToButton = styled.div`
   position: fixed;
   right: 80px;
   bottom: 100px;
   font-size: 4rem;
   z-index: 1;
   cursor: pointer;
   color: #727272;
`

export const MobileToButton = styled(ToButton)`
  right: 30px;
  bottom: 110px;  
`