import {
  GET_TOP_CHANNELS_LOADING,
  GET_TOP_CHANNELS_SUCCESS,
  GET_TOP_CHANNELS_FAIL,
} from '../types';

const initialState = {
  topChannelsData: [],
  topChannelsApiLoading: false,
  topChannelsError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_TOP_CHANNELS_LOADING:
      return {
        ...state,
        topChannelsApiLoading: true,
        topChannelsError: null,
      };
    case GET_TOP_CHANNELS_SUCCESS:
      return {
        ...state,
        topChannelsData: payload.topChannelsData,
        topChannelsApiLoading: false,
        topChannelsError: null,
      };
    case GET_TOP_CHANNELS_FAIL:
      return {
        ...state,
        topChannelsData: [],
        topChannelsApiLoading: false,
        topChannelsError: payload?.topChannelsError
      };
    default:
      return state;
  }
}
