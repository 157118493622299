import axios from 'axios';
import { sha256 } from 'js-sha256';

import {
  ME_LOADING,
  ME_SUCCESS,
  ME_FAIL,
  LOGOUT_SUCCESS,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_WITH_EMAIL_LOADING,
  REGISTER_WITH_EMAIL_SUCCESS,
  REGISTER_WITH_EMAIL_FAIL,
  CONTACT_MESSAGE_SEND_LOADING,
  CONTACT_MESSAGE_SEND_SUCCESS,
  CONTACT_MESSAGE_SEND_FAIL,
  REGISTER_WITH_EMAIL_GET_CODE,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GOOGLE_LOGIN_SUCCESS,
  SET_USER_PROFILE_LOADING,
  SET_USER_PROFILE_SUCCESS,
  SET_USER_PROFILE_FAIL,
} from '../types';

import { API_HOST } from '../../config/constants/data';

export const loadMe = () => async (dispatch, getState) => {
  // TODO GET uuID.
  await getuuId()
  dispatch({ type: ME_LOADING });

  try {
    const response = await axios.get(API_HOST + '/api/v1/user/loadMe', attachTokenToHeaders(getState));

    dispatch({
      type: ME_SUCCESS,
      payload: { me: response.data },
    });
  } catch (err) {
    dispatch({
      type: ME_FAIL,
      payload: { error: err.response.data.message },
    });
  }
}

export const loginUser = (formData, history) => async (dispatch, getState) => {
  dispatch({ type: LOGIN_LOADING });
  try {
    const response = await axios.post(API_HOST + '/api/v1/auth/login', formData);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { token: response.data.auth_token, me: response.data },
    });

    history.push('/');
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};

export const googleLogin = (formData, imageUrl, history) => async (dispatch) => {
  dispatch({ type: LOGIN_LOADING });
  try {
    const response = await axios.post(API_HOST + '/api/v1/auth/google-login/', formData);
    dispatch({
      type: GOOGLE_LOGIN_SUCCESS,
      payload: { token: response.data.auth_token, me: response.data, imageUrl:imageUrl },
    });

    history.push('/');
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};

export const registerUserWithEmail = (formData) => async (dispatch) => {
  dispatch({ type: REGISTER_WITH_EMAIL_LOADING });
  try {
    const response = await axios.post(API_HOST + '/api/v1/auth/register', formData);
    if ("auth_id" in response.data){
      dispatch({
        type: REGISTER_WITH_EMAIL_GET_CODE,
        payload: { authCodeId: response.data.auth_id },
      });
    }
    else{
      dispatch({
        type: REGISTER_WITH_EMAIL_SUCCESS,
        payload: { token: response.data.auth_token, me: response.data},
      });
    }
    // history.push('/');
  } catch (err) {
    dispatch({
      type: REGISTER_WITH_EMAIL_FAIL,
      payload: { error: err?.response?.data?.message },
    });
  }
};

export const resetPassword = (formData) => async (dispatch) => {
  dispatch({ type: REGISTER_WITH_EMAIL_LOADING });
  try {
    const response = await axios.post(API_HOST + '/api/v1/auth/reset_password', formData);
    if ("auth_id" in response.data){
      dispatch({
        type: REGISTER_WITH_EMAIL_GET_CODE,
        payload: { authCodeId: response.data.auth_id },
      });
    }
    else{
      dispatch({
        type: REGISTER_WITH_EMAIL_SUCCESS,
        payload: { token: response.data.auth_token, me: response.data},
      });
    }
    // history.push('/');
  } catch (err) {
    dispatch({
      type: REGISTER_WITH_EMAIL_FAIL,
      payload: { error: err?.response?.data?.message },
    });
  }
};

export const contactMessageWithEmail = (formData, history) => async (dispatch, getState) => {
  dispatch({ type: CONTACT_MESSAGE_SEND_LOADING });
  try {
    const response = await axios.post(API_HOST + '/api/v1/auth/contact-us/', formData);
    dispatch({
      type: CONTACT_MESSAGE_SEND_SUCCESS,
      payload: { status: response.data.status},
    });
    // history.push('/');
  } catch (err) {
    dispatch({
      type: CONTACT_MESSAGE_SEND_FAIL,
      payload: { error: err?.response?.data?.[0] },
    });
  }
};
export const changePassword = (formData) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_PASSWORD_LOADING });
  try {
    const response = await axios.post(API_HOST + '/api/v1/auth/password_change', formData, attachTokenToHeaders(getState));
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: { status: response.data.status},
    });
  } catch (err) {
    dispatch({
      type: CHANGE_PASSWORD_FAIL,
      payload: { error: err?.response?.data?.[0] },
    });
  }
};
// Log user out
export const logOutUser = (history) => async (dispatch, getState) => {
  try {
    deleteAllCookies();
    //just to log user logut on the server
    await axios.get(API_HOST + '/api/v1/auth/logout', attachTokenToHeaders(getState));

    dispatch({
      type: LOGOUT_SUCCESS,
    });
    if (history) history.push('/');
    window.location.reload();
  } catch (err) { }
};

export const setUserProfile = (params) => async (dispatch, getState) => {
  dispatch({ type: SET_USER_PROFILE_LOADING });
  try {
    const paramURI = `${API_HOST}/api/v1/user/profile/`;
    const response = await axios.post(
      paramURI,
      params,
      attachTokenToHeaders(getState)
    );

    dispatch({
      type: SET_USER_PROFILE_SUCCESS,
      payload: { data: response.data.status },
    });
  } catch (err) {
    dispatch({
      type: SET_USER_PROFILE_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};

function deleteAllCookies() {
  var cookies = document.cookie.split(';');

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf('=');
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export const attachTokenToHeaders = (getState) => {
  const token = getState().auth.token;

  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }

  return config;
};

export const getHeadersWithToken = (token) => {

  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }

  return config;
};

export const generateuuId = (ipAddress) => {
  const id = sha256(ipAddress);
  return id.substring(0, 32); // Take the first 32 characters of the ID
};

export const getRandomIpAddress = () => {
  const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
  const randomIpSegments = Array.from({ length: 4 }, () => getRandomNumber(0, 255));
  return randomIpSegments.join('.');
};

export const fetchIpAddress = async () => {
  try {
    const response = await axios.get('https://api.ipify.org/?format=json');
    const { ip } = response.data;
    return ip;
  } catch (error) {
    console.error('Failed to fetch IP address:', error);
    return getRandomIpAddress();
  }
}

export const getuuId = async () => {
  let uuId = localStorage.getItem('uuid');
  if (uuId === null) {
    const ipAddress = await fetchIpAddress()
    uuId = generateuuId(ipAddress)
    localStorage.setItem('uuid', uuId)
  }
  return uuId
}