import React from "react";
import { Link } from "react-router-dom";
import { DASHBOARD_SUB_ROUTING_BUTTONS } from "../../../../../../../config/constants/data";

const SubRoutingButtons = ({ category, model, mainRoute }) => {
  const linkItems = DASHBOARD_SUB_ROUTING_BUTTONS?.[mainRoute];
  const mainLink = `/view/${category ? category : "all"}/`;

  return (
    <>
      {linkItems && linkItems.length > 0 && (
        <div className="d-flex mb-3 ml-1 x-scroll-wrapper">
          {linkItems.map((c, i) => {
            return (
              <li key={i} className="tab">
                <Link
                  to={mainLink + c.value}
                  className={`tab-link ${model === c.value && "active"}`}
                >
                  {c.label}
                </Link>
              </li>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SubRoutingButtons;

