import React from 'react';
import { ProgressBar } from 'react-bootstrap';

import './styles.css';

const Loader = (props) => {
  return (
    <div className="loader-container loader">
      <h3 className="loader-content">Loading..</h3>
      <p>{props.text && props.text}</p>
      {
        props.progressData &&
        <div className="mt-3 w-100">
          <div className="d-flex justify-content-end mb-2">
            <span className="text-success">{props.progressData.current} / {props.progressData.total}</span>
          </div>
          <ProgressBar variant="success" now={props.progressData.current} min={0} max={props.progressData.total} className="w-100" />
        </div>
      }
    </div>
  );
};

export default Loader;