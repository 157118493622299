import React from 'react'
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_SELECTION } from '../../../../config/constants/data'
import MainSelection from '../../../Selections/MainSelection'

const PageSizeSelection = (props) => {
  const {pageSize, changePageSize} = props;
  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <MainSelection
        title="Rows: "
        selectionItems={PAGE_SIZE_SELECTION}
        defaultItem={pageSize===undefined ? DEFAULT_PAGE_SIZE : {label: pageSize, value: pageSize}}
        setItem={changePageSize}
        sorted={false}
      />
    </div>
  )
}

export default PageSizeSelection