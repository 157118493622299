import {
  GET_GLOBAL_STATISTICS_LOADING,
  GET_GLOBAL_STATISTICS_SUCCESS,
  GET_GLOBAL_STATISTICS_FAIL,
} from '../types';

const initialState = {
  globalStatisticsData: [],
  globalStatisticsApiLoading: false,
  globalStatisticsError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_GLOBAL_STATISTICS_LOADING:
      return {
        ...state,
        globalStatisticsApiLoading: true,
        globalStatisticsError: null,
      };
    case GET_GLOBAL_STATISTICS_SUCCESS:
      return {
        ...state,
        globalStatisticsData: payload.globalStatisticsData,
        globalStatisticsApiLoading: false,
        globalStatisticsError: null,
      };
    case GET_GLOBAL_STATISTICS_FAIL:
      return {
        ...state,
        globalStatisticsData: [],
        globalStatisticsApiLoading: false,
        globalStatisticsError: payload?.globalStatisticsError
      };
    default:
      return state;
  }
}
