import React from "react";
import MainSelection from "../../../Selections/MainSelection";
import {
  CATEGORIES,
  DEFAULT_CATEGORIE,
} from "../../../../config/constants/data";

const CategoryFilter = (props) => {
  const { defaultCategory, setCategory } = props;
  const defaultValue = CATEGORIES.filter((cat) => {
    return cat.value === defaultCategory;
  });
  const defaultItem =
    defaultValue.length > 0 ? defaultValue[0] : DEFAULT_CATEGORIE;
  return (
    <div className="d-flex flex-row justify-content-center align-items-center ml-1">
      <MainSelection
        title={props?.title}
        selectionItems={CATEGORIES}
        defaultItem={defaultItem}
        setItem={setCategory}
        width={115}
        sorted={false}
        paddingLeftZero={true}
      />
    </div>
  );
};

export default CategoryFilter;

