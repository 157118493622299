import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import SocialShareModal from "../../../../components/SocialShareModal";
import IconButton from "../../../../components/Shared/IconButton";
import { BsBoxArrowUpRight, BsUpload } from "react-icons/bs";
import { DISCORD_ASSETS_LINK } from "../../../../config/constants";
import Breadcrumb from "../../../../components/Breadcrumb";
import {
  setSpecialVoteValue,
  getSpecialVoteValue,
} from "../../../../store/actions/userVoteActions";
import { useEffect } from "react";
import { getVotingParams } from "../../../../utils/tableDataHelper";
import DetailVoteComponent from "../../../../components/Tables/Components/DetailVoteComponent";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { getIconFromMedia } from "../../../../utils/routingHelper";
import BookMarkButton from "../../../../components/Shared/BookMarkButton";
import {
  LINKS_DOMAINS_DETAILS_SUB_ROUTING_GROUP_BUTTON,
  MODEL_IDS,
  SOCIAL_LINK_ICONS,
} from "../../../../config/constants/data";
import GroupButton from "../../../../components/GroupButton";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const TopSummary = ({ detailsData, selectedRoute, setSelectedRoute }) => {
  const { domain } = useParams();
  const dispatch = useDispatch();
  const vote = useSelector((state) => state.userVote);
  const [modalShow, setModalShow] = useState(false);
  const votingParams = getVotingParams("websites");
  const modelIds = [domain];
  const params = {
    site_id: votingParams.siteId,
    model_type_id: votingParams.modelId,
    model_ids: modelIds,
  };
  useEffect(() => {
    dispatch(getSpecialVoteValue(params));
    // eslint-disable-next-line
  }, [detailsData]);
  const setVoteValue = async (v, modelId) => {
    if (votingParams.modelId === "") return;
    const params = {
      vote_score: v,
      site_id: votingParams.siteId,
      model_type_id: votingParams.modelId,
      model_id: modelId,
    };
    dispatch(setSpecialVoteValue(params));
    const getParams = {
      site_id: votingParams.siteId,
      model_type_id: votingParams.modelId,
      model_ids: modelIds,
    };
    dispatch(getSpecialVoteValue(getParams));
  };
  const isMobile = useMediaQuery();

  const contactInfo = detailsData?.contact_info;
  let groupButtons = LINKS_DOMAINS_DETAILS_SUB_ROUTING_GROUP_BUTTON;

  // const {subscription} = useUserRole()
  // if (subscription === 1){
  //   groupButtons = groupButtons.slice(0,3)
  // }
  let history = useHistory();
  const navTo = (val, new_domain = domain) => {
    if (val === "overview") {
      history.push(`/website/${new_domain}`);
    } else {
      history.push(`/website/${new_domain}/${val}`);
    }
    setSelectedRoute(val);
  };

  const location = useLocation();
  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const route = pathParts[3] || "overview";
    setSelectedRoute(route);
    // eslint-disable-next-line
  }, [location]);

  return (
    <Container fluid className="px-1">
      <Row style={{ paddingLeft: "9px" }}>
        <Breadcrumb
          currentLabel={domain}
          homeLabel="Home"
          parentLabel="Websites"
          parentLink="/websites/"
        />
      </Row>
      <div
        className="d-flex flex-column flex-md-row justify-content-between align-items-stretch align-items-md-center mb-3"
        style={{ marginLeft: "0 !important" }}
      >
        <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-center">
          <a
            href={`https://${domain}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "white",
              textDecoration: "none",
              width: "unset",
              paddingLeft: 20,
              paddingRight: 20,
            }}
            className="d-flex justify-content-center align-items-center link-item-30 icon-btn"
          >
            {detailsData?.icon && (
              <img
                src={getIconFromMedia(detailsData?.icon)}
                alt=""
                className="mr-3"
                style={{ width: 30, borderRadius: "50%" }}
              />
            )}
            <div style={{ fontSize: 24, fontWeight: "bold", marginRight: 10 }}>
              {domain}
            </div>
            <BsBoxArrowUpRight size={22} color={"white"} />
          </a>
          <div
            className="d-flex"
            style={{
              marginTop: isMobile ? 15 : 0,
              marginLeft: isMobile ? 0 : 10,
            }}
          >
            <GroupButton
              items={groupButtons}
              selected={selectedRoute}
              onClick={navTo}
              groupClass="flex-fill d-flex flex-row align-items-center x-scroll-wrapper"
              buttonClass="project-tab-btn mr-2 flex-fill text-center"
            />
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
          <div
            style={{
              marginTop: isMobile ? 15 : 0,
            }}
            className="d-flex flex-row align-items-center justify-content-center"
          >
            {contactInfo &&
              Object.keys(SOCIAL_LINK_ICONS)
                .filter((key) => Object.keys(contactInfo).includes(key))
                .map((key, index) => (
                  <Link
                    style={{ padding: "0 8px" }}
                    key={`social-${index}`}
                    to={{ pathname: contactInfo[key] }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="website-social-link-icon m-0">
                      <Icon icon={SOCIAL_LINK_ICONS[key]} color="#E7E9EA" />
                    </div>
                  </Link>
                ))}
          </div>
          <div
            style={{
              marginTop: isMobile ? 15 : 0,
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <BookMarkButton
              model_id={MODEL_IDS.WEBSITE}
              main_id={domain}
              className="ml-mobile-auto icon-btn"
              style={{ height: "40px", width: "40px", marginLeft: "10px" }}
              fetchable={true}
            />
            {/* <IconButton
              className="ml-mobile-auto"
              style={{ height: "40px", width: "40px", marginLeft: "10px" }}
              onClick={() => setModalShow(true)}
              icon={<BsUpload size={22} color={"white"} />}
              title={""}
            />
            <div style={{ paddingLeft: 15 }}>
              <DetailVoteComponent
                id={domain}
                vote={vote}
                setVoteValue={setVoteValue}
              />
            </div> */}
          </div>
        </div>

        {/* <Row style={{padding:'5px', marginLeft:'10px', marginRight:'10px'}}>
            <Button variant="secondary" size="lg" block style={{borderRadius:'8px'}}>Share Server</Button>
          </Row> */}
      </div>
      {/* <Row className="m-0 p-0 mt-1" style={{ fontSize: 18 }}>
        <div>{detailsData?.short_summary}</div>
      </Row> */}
      <SocialShareModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        image={`${DISCORD_ASSETS_LINK}icons/${detailsData?.id}/${detailsData?.icon}.png`}
        url={`https://metalyzer.ai/website/${domain}/`}
      />
    </Container>
  );
};

export default TopSummary;

