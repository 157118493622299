import React from 'react'
import Modal from 'react-bootstrap/Modal';
import "./styles.css"
import { Button } from 'react-bootstrap';

const FullModal = ({ children, isShowModal, setShowModal, title, showHeader=true}) => {
  return (
    <Modal show={isShowModal} dialogClassName="modal-100wh" onHide={() => setShowModal(false)} animation>
      { showHeader ? 
      <Modal.Header closeButton>
        <Modal.Title>{title || "Modal title"}</Modal.Title>
      </Modal.Header> : <></>
      }
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button 
          variant="outline-secondary" 
          size="lg"
          block
          onClick={() => setShowModal(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FullModal