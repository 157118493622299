import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import DashboardTableView from "../DashboardTableView";
import SubRoutingButtons from "./SubRoutingButtons";
import SearchItemsModal from "../../../../../../components/SearchItemsModal";
import { useSelector } from "react-redux";
import SearchCategoryFilter from "../../../../../../components/Tables/Components/SearchCategoryFilter";
import FullModal from "../../../../../../components/FullModal";
import SearchModalFeed from "../../../../../../layout/StatisticsBarMobile/SearchModalFeed";
import useMediaQuery from "../../../../../../hooks/useMediaQuery";
import MobileFilterModalButton from "../../../../../../components/Tables/Components/MobileFilterModalButton";
import { SEARCH_CATEGORIES } from "../../../../../../config/constants/data";
import { Icon } from "@iconify/react";

const DashboardMainView = ({
  setSelectedRoute,
  mainRoute,
  modelName,
  categorySelector,
}) => {
  const { category, topic, domain } = useParams();
  const location = useLocation();
  // Is Mobile
  const isMobile = useMediaQuery();
  const [isSearchCategoryShowModal, setIsSearchCategoryShowModal] =
    useState(false);
  const [isSearchShowModal, setIsSearchShowModal] = useState(false);
  const initSearchItems = useSelector((state) => state.searchItems.searchItems);
  const searchItems = useMemo(() => initSearchItems || [], [initSearchItems]);
  const model =
    modelName === "market" ? "market" : location.pathname.split("/")[3];
  const [searchCategory, setSearchCategory] = useState("-1");
  const [filteredSearchItems, setFilteredSearchItems] = useState(searchItems);
  const updateSearchCategory = (newCategory) => {
    setFilteredSearchItems(
      searchItems.filter(
        (item) => newCategory === "-1" || item?.model_id === newCategory
      )
    );
    setSearchCategory(newCategory);
  };

  useEffect(() => {
    const updateRoute =
      mainRoute === "discord"
        ? "discord-servers"
        : mainRoute === "telegram"
        ? "telegram-groups"
        : mainRoute === "youtube"
        ? "youtube-channels"
        : mainRoute;
    setSelectedRoute(updateRoute);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchItems) setFilteredSearchItems(searchItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItems]);

  return (
    <div>
      {modelName === "trending_old" ? (
        isMobile ? (
          <div className="d-flex flex-row justify-content-center align-items-center">
            <MobileFilterModalButton
              showModal={setIsSearchCategoryShowModal}
              title={
                SEARCH_CATEGORIES.filter((row) => {
                  return row.value === searchCategory;
                })?.[0]?.label
              }
            />
            <div className="ml-4" onClick={() => setIsSearchShowModal(true)}>
              <Icon
                icon="mingcute:search-line"
                height="2rem"
                color="#ffffff"
                style={{}}
              />
            </div>
          </div>
        ) : (
          <div className="tabs justify-content-center align-items-center">
            <SearchCategoryFilter
              searchCategory={searchCategory}
              setSearchCategory={updateSearchCategory}
            />
            <SearchItemsModal
              searchItems={filteredSearchItems}
              selectedCategory={searchCategory}
            />
          </div>
        )
      ) : (
        <div className="tabs align-items-center">
          <SubRoutingButtons
            category={category}
            model={model}
            mainRoute={mainRoute}
          />
        </div>
      )}
      <DashboardTableView
        leftComponent={categorySelector}
        category={category}
        model={model}
        topic={topic}
        domain={domain}
        modelName={modelName}
      />
      <FullModal
        isShowModal={isSearchCategoryShowModal}
        setShowModal={setIsSearchCategoryShowModal}
        title={"Select Search Category"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="mr-4">Category :</div>
          <SearchCategoryFilter
            searchCategory={searchCategory}
            setSearchCategory={updateSearchCategory}
          />
        </div>
      </FullModal>
      <FullModal
        isShowModal={isSearchShowModal}
        setShowModal={setIsSearchShowModal}
        title={null}
        showHeader={false}
      >
        <SearchModalFeed
          closeModal={() => {
            setIsSearchShowModal(false);
          }}
          searchItems={filteredSearchItems}
        />
      </FullModal>
    </div>
  );
};

export default DashboardMainView;

