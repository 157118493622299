import axios from 'axios';

import {
  SET_TOPIC_VALUE_LOADING,
  SET_TOPIC_VALUE_SUCCESS,
  SET_TOPIC_VALUE_FAIL,
} from '../types';

import { API_HOST } from '../../config/constants/data';
import { attachTokenToHeaders } from './authActions';

export const setTopicValue = (site, params) => async (dispatch, getState) => {
  dispatch({ type: SET_TOPIC_VALUE_LOADING });
  try {
    const paramURI = `${API_HOST}/api/v1/${site}/s-topic/`;
    const response = await axios.post(paramURI, params, attachTokenToHeaders(getState));

    dispatch({
      type: SET_TOPIC_VALUE_SUCCESS,
      payload: { data: response.data.status },
    });

  } catch (err) {
    console.log("error : ", err)
    dispatch({
      type: SET_TOPIC_VALUE_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};
