import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";

import { Form, Row, Col } from "react-bootstrap";

import ProjectFilterWithoutAll from "../../ProjectFilterWithoutAll/index";
import ProjectCategorySelection from "../../ProjectCategorySelection";
import { putMainModel } from "../../../../../store/actions/mainModelActions";

const EditCategoryModal = (props) => {
  const { show, setShowModal, item } = props;
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState(item?.project_name);
  const [keywordNick, setKeywordNick] = useState(null);
  const [category, setCategory] = useState(item?.category);
  const categoryId = item?.id;
  const onPutSubmit = async () => {
    let values = {
      project_name: keyword,
      category: category,
    };
    await dispatch(putMainModel(`project/project_category/${categoryId}`, values));
  };

  const udpateKeyword = (keywordObject) => {
    setKeyword(keywordObject.label);
    setKeywordNick(keywordObject.value);
  };
  const udpateCategory = (categoryObject) => {
    setCategory(categoryObject);
  };
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={"sm"}
      // aria-labelledby="filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ fontSize: 18, textAlign: "center", width: "100%" }}
        >
          Edit Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="pt-3">
          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className="mr-3">Project Name</div>
            <ProjectFilterWithoutAll
              keyword={{ label: keyword, value: keywordNick }}
              setKeyword={udpateKeyword}
            />
          </Form.Group>
          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className="mr-3">Category</div>
            <ProjectCategorySelection
              category={category}
              changeCategory={udpateCategory}
            />
          </Form.Group>

          <Row className="mt-4">
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => onPutSubmit()}
                >
                  Edit
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCategoryModal;
