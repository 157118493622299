import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import SocialShareModal from "../../../../components/SocialShareModal";
import IconButton from "../../../../components/Shared/IconButton";
import { BsUpload } from "react-icons/bs";
import Breadcrumb from "../../../../components/Breadcrumb";
import {
  setSpecialVoteValue,
  getSpecialVoteValue,
} from "../../../../store/actions/userVoteActions";
import { useEffect } from "react";
import { getVotingParams } from "../../../../utils/tableDataHelper";
import DetailVoteComponent from "../../../../components/Tables/Components/DetailVoteComponent";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import BookMarkButton from "../../../../components/Shared/BookMarkButton";
import { MODEL_IDS } from "../../../../config/constants/data";

const TopSummary = (props) => {
  const { detailsData } = props;
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const vote = useSelector((state) => state.userVote) || [];

  const [modalShow, setModalShow] = useState(false);
  const votingParams = getVotingParams("channels");
  const modelIds = [detailsData?.channelId];
  const params = {
    site_id: votingParams.siteId,
    model_type_id: votingParams.modelId,
    model_ids: modelIds,
  };
  useEffect(() => {
    // if (modelIds && modelIds.length && votingParams.modelId !== "") {
    dispatch(getSpecialVoteValue(params));
    // }
    // eslint-disable-next-line
  }, [detailsData]);
  const setVoteValue = async (v, modelId) => {
    if (votingParams.modelId === "") return;
    const params = {
      vote_score: v,
      site_id: votingParams.siteId,
      model_type_id: votingParams.modelId,
      model_id: modelId,
    };
    await dispatch(setSpecialVoteValue(params));
    const getParams = {
      site_id: votingParams.siteId,
      model_type_id: votingParams.modelId,
      model_ids: modelIds,
    };
    dispatch(getSpecialVoteValue(getParams));
  };
  const isMobile = useMediaQuery();
  return (
    <Container fluid>
      <Row>
        <Breadcrumb
          currentLabel={detailsData?.title}
          homeLabel="Home"
          parentLabel="Youtube Channel"
          parentLink="/youtube-channels/"
        />
      </Row>
      <Row className="d-flex align-items-center">
        <Col xs={12} md={9}>
          <Row className="d-flex justify-content-start align-items-center link-item-30 mb-2">
            <img
              src={`${detailsData?.thumbnail}`}
              alt=""
              className="mr-3"
              style={{ width: 40, borderRadius: "50%" }}
            />
            <div style={{ fontSize: 24, fontWeight: "bold", marginRight: 10 }}>
              {detailsData?.title}
            </div>
            <BookMarkButton
              model_id={MODEL_IDS.CHANNEL}
              main_id={channelId}
              className="ml-mobile-auto icon-btn"
              style={{ height: "40px", width: "40px", marginLeft: "10px" }}
              fetchable={true}
            />
            {/* <IconButton
              className="ml-mobile-auto"
              style={{ height: "40px", width: "40px", marginLeft: "10px" }}
              onClick={() => setModalShow(true)}
              icon={<BsUpload size={22} color={"white"} />}
              title={""}
            /> */}

            {/* <CustomBadge style={{ marginLeft: 10 }}>
              {detailsData?.customUrl}
            </CustomBadge> */}
          </Row>
        </Col>
        <Col xs={12} md={3} className={isMobile ? "mt-3" : "pr-0"}>
          <Row className="d-flex justify-content-between align-items-center">
            {/* <Col xs={10} md={10} className="pl-0"> */}
            <Col xs={12} md={12} className="pl-0">
              <a
                className="project-tab-btn selected"
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "16px",
                  display: "block",
                }}
                href={`https://youtube.com/channel/${channelId}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                View Channel
              </a>
            </Col>
            {/* <Col xs={2} md={2} className="pr-0">
              <DetailVoteComponent
                id={detailsData?.channelId}
                vote={vote}
                setVoteValue={setVoteValue}
              />
            </Col> */}
          </Row>
          {/* <Row style={{padding:'5px', marginLeft:'10px', marginRight:'10px'}}>
            <Button variant="secondary" size="lg" block style={{borderRadius:'8px'}}>Share Server</Button>
          </Row> */}
        </Col>
      </Row>
      <SocialShareModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        image={`${detailsData?.thumbnail}`}
        url={`https://metalyzer.ai/youtube/channel/${channelId}/`}
      />
    </Container>
  );
};

export default TopSummary;

