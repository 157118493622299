import * as Yup from 'yup';

export const editBlogSchema = Yup.object({
  title: Yup.string()
    .min(2, 'Must be 2 characters at minimum')
    .max(250, 'Must be 250 characters or less')
    .required('Required'),
  user_name: Yup.string()
    .min(2, 'Must be 2 characters at minimum')
    .max(250, 'Must be 250 characters or less')
    .required('Required'),
});
