
import React from 'react'
import {
  Modal,
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  RedditShareButton,
  TwitterIcon,
  FacebookIcon,
  TelegramIcon,
  RedditIcon,
} from "react-share";
import { useRef } from 'react';
import "./styles.css";


const SocialShareModal = (props) => {
  const {image, url} = props
  const copyLink = useRef(null);
  const copyCodeToClipboard = () => {
    copyLink.current.select();
    document.execCommand("copy");
  }
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" >
          <div className="d-flex justify-content-center align-itmes-center">
            <img
              src={image}
              style={{ borderRadius: 0, height: 32, marginRight: 10 }}
              alt=""
            />
            <h2>
              {props.keyword}
            </h2>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 className='d-flex justify-content-center'>Share it with your friends</h3>
        <div className='d-flex justify-content-center my-4'>
          <div className='mx-1'>
            <FacebookShareButton
              url={url}
            >
              <FacebookIcon size={40} borderRadius={12} />
            </FacebookShareButton>
          </div>
          <div className='mx-1'>
            <TwitterShareButton
              url={url}
            >
              <TwitterIcon size={40} borderRadius={12} />
            </TwitterShareButton>
          </div>
          <div className='mx-1'>
            <TelegramShareButton
              url={url}
            >
              <TelegramIcon size={40} borderRadius={12} />
            </TelegramShareButton>
          </div>
          <div className='mx-1'>
            <RedditShareButton
              url={url}
            >
              <RedditIcon size={40} borderRadius={12} />
            </RedditShareButton>
          </div>
        </div>
        <div className='ml-3'>Or copy link</div>
        <div className='linkWrapper'>
          <input
            ref={copyLink}
            type="text"
            className="linkInput"
            defaultValue={url}
          />
          <Button variant="primary" onClick={copyCodeToClipboard} className='copyButton'>Copy</Button>{' '}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SocialShareModal