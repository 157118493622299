import React from "react";
import { useSelector } from "react-redux";
import { Img } from "react-image";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import {
  diffTimeStamp,
  seconds2HMS,
  transformNumber,
} from "../../../../../utils/tableDataHelper";
import useMediaQuery from "../../../../../hooks/useMediaQuery";
import { getYoutubeChannelIcon } from "../../../../../utils/routingHelper";

const VideoItem = ({ data }) => {
  const history = useHistory();
  const navigateTo = () => {
    history.push(`/youtube/channel/${data?.channelId}`); // Use history to navigate
  };
  const isMobile = useMediaQuery();
  const searchItems =
    useSelector((state) => state.searchItems.searchItems) || [];
  const channelItems = searchItems.filter((item) => item.model_id === "1");

  return (
    <div className="card-height-padding">
      <div className="d-flex flex-column">
        <div
          className="d-flex flex-row align-items-center project-channel-title"
          onClick={navigateTo}
        >
          <img
            src={getYoutubeChannelIcon(channelItems, data?.channelId)}
            alt="pfp"
            width={20}
            height={20}
            className="rounded-circle"
            style={{
              boxShadow:
                "inset 0 3px 6px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.45)",
            }}
          />
          <p className="ml-2 mb-0 h6 gray-text system-font">
            {data.channelTitle}
          </p>
        </div>
        <a
          className={`${
            isMobile ? "my-0" : "my-3"
          } top-community-article-description system-font `}
          href={`https://youtube.com/watch?v=${data?.videoId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {data.title}
        </a>
        <a
          href={`https://youtube.com/watch?v=${data?.videoId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Img
            style={{ borderRadius: 10, width: "100%" }}
            src={`https://i.ytimg.com/vi/${data?.videoId}/maxresdefault.jpg`}
          />
        </a>
        <div
          className="d-flex flex-row align-items-center justify-content-between mt-3"
          style={{ fontSize: 12 }}
        >
          <span className="gray-text">{diffTimeStamp(data.publishedAt)}</span>
          <span className="gray-dot "></span>
          <div className="d-flex flex-row align-items-center">
            <Icon
              icon={"ph:eye-bold"}
              height="16px"
              className="gray-text"
              color={"#858ca2"}
            />
            <span className="gray-text ml-2">
              {transformNumber(data.viewCount, "shorterNumber")}
            </span>
          </div>
          <span className="gray-dot "></span>
          <div className="d-flex flex-row align-items-center">
            <Icon
              icon={"tdesign:time-filled"}
              height="16px"
              className="gray-text"
              color={"#858ca2"}
            />
            <span className="gray-text ml-2">
              {seconds2HMS(parseInt(data.duration))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
