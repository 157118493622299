import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

import { Form, Row, Col } from "react-bootstrap";

import { setUserProfile } from "../../store/actions/authActions";
import Layout from "../../layout/Layout";
import Loader from "../../components/Loader/Loader";
import { profileSchema } from "./validation";

import "./styles.css";
import { API_HOST } from "../../config/constants/data";
import useUserRole from "../../hooks/useUserRole";
import { loadMe } from "../../store/actions/authActions";

const Profile = () => {
  const dispatch = useDispatch();
  const { auth, meInfo } = useUserRole();
  const [isEdit, setIsEdit] = useState(false);
  const [image, setImage] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const retryCount = useRef(0);
  const profileImageUrl = `${API_HOST}/api/v1/user/profile/image/`;

  useEffect(() => {
    dispatch(loadMe());
    // eslint-disable-next-line
  }, []);
  // if changed his own username reload me, done in userActions

  const onChange = (event) => {
    formik.setFieldValue("image", event.currentTarget.files[0]);
    setImage(URL.createObjectURL(event.target.files[0]));
    setAvatar(event.target.files[0]);
  };

  const handleClickEdit = () => {
    retryCount.current = 0;
    setIsEdit((oldIsEdit) => !oldIsEdit);
    setImage(null);
    setAvatar(null);
    formik.setFieldValue("id", meInfo.id);
    formik.setFieldValue("email", meInfo.email);
    formik.setFieldValue("first_name", meInfo.first_name);
    formik.setFieldValue("last_name", meInfo.last_name);
    formik.setFieldValue("role", meInfo.role);
    formik.setFieldValue(
      "subscription",
      meInfo.subscription ? meInfo.subscription : ""
    );
    // formik.setFieldValue("language", meInfo.language);
    // formik.setFieldValue("country", meInfo.country);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      first_name: "",
      last_name: "",
      username: "",
      email: "",
    },
    validationSchema: profileSchema,
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("user_id", meInfo.id);
      formData.append("avatar", avatar);
      formData.append("email", values.email);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("role", values.role);
      formData.append("subscription", values.subscription);
      // formData.append("language", values.language);
      // formData.append("country", values.country);
      await dispatch(setUserProfile(formData));
      setIsEdit(false);
      // window.location.reload();
    },
  });

  return (
    <Layout>
      <div className="h-100">
        <div className="d-flex align-items-center auth px-0 h-100">
          <div className="row w-100 mx-0">
            <div className="col-lg-6 mx-auto">
              <div className="container profile">
                {auth.isLoading || !meInfo ? (
                  <Loader />
                ) : (
                  <div className="card text-left py-5 mx-auto">
                    <h1 style={{ marginBottom: 20 }}>Profile page</h1>
                    <div className="card-body">
                      <Row>
                        <Col>
                          <p className="card-description">
                            {" "}
                            Subscription : <code> {meInfo.subscription} </code>
                          </p>
                        </Col>
                        <Col>
                          <p className="card-description">
                            {" "}
                            Role : <code> {meInfo.role} </code>
                          </p>
                        </Col>
                      </Row>
                      <Form
                        className="pt-3"
                        onSubmit={formik.handleSubmit}
                        noValidate
                      >
                        <Row className="mb-3">
                          <Col>
                            <div className="profile-avatar">
                              <label htmlFor="avatar">Avatar</label>
                              <input
                                name="image"
                                id="avatar"
                                className="avatar-input"
                                type="file"
                                onChange={onChange}
                                disabled={!isEdit || auth.googleLoggedIn}
                              />
                              <img
                                src={
                                  auth.googleLoggedIn
                                    ? auth.googleImageUrl
                                    : image
                                    ? image
                                    : `${profileImageUrl}${meInfo.id}`
                                }
                                className="avatar-image"
                                alt="profile-avatar"
                              />
                            </div>
                            {image && (
                              <button
                                className="btn btn-link float-right"
                                onClick={() => {
                                  setImage(null);
                                  setAvatar(null);
                                }}
                                type="button"
                              >
                                Remove Image
                              </button>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <Form.Group className="d-flex flex-column pt-3">
                              <Form.Label>First name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={meInfo.first_name}
                                name="first_name"
                                className="h-auto"
                                size="lg"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.first_name}
                                disabled={!isEdit || auth.googleLoggedIn}
                              />

                              {formik.touched.first_name &&
                              formik.errors.first_name ? (
                                <p className="text-danger">
                                  {formik.errors.first_name}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="d-flex flex-column pt-3">
                              <Form.Label>Last name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={meInfo.last_name}
                                name="last_name"
                                className="h-auto"
                                size="lg"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.last_name}
                                disabled={!isEdit || auth.googleLoggedIn}
                              />

                              {formik.touched.last_name &&
                              formik.errors.last_name ? (
                                <p className="text-danger">
                                  {formik.errors.last_name}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col md="6">
                            <Form.Group className="d-flex flex-column pt-3">
                              <Form.Label>
                                {meInfo.role === "manager"
                                  ? "Language"
                                  : "Language"}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={meInfo.language}
                                name="language"
                                className="h-auto"
                                size="lg"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.language}
                                disabled={!isEdit}
                              />

                              {formik.touched.language &&
                              formik.errors.language ? (
                                <p className="text-danger">
                                  {formik.errors.language}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="d-flex flex-column pt-3">
                              <Form.Label>{"Country"}</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={meInfo.country}
                                name="country"
                                className="h-auto"
                                size="lg"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.country}
                                disabled={!isEdit}
                              />

                              {formik.touched.country &&
                              formik.errors.country ? (
                                <p className="text-danger">
                                  {formik.errors.country}
                                </p>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row> */}

                        <Form.Group className="d-flex flex-column pt-3">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            placeholder={meInfo.email}
                            name="email"
                            className="h-auto"
                            type="email"
                            size="lg"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            disabled={!isEdit || auth.googleLoggedIn}
                          />

                          {formik.touched.email && formik.errors.email ? (
                            <p className="text-danger">{formik.errors.email}</p>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="d-flex flex-column pt-3">
                          <Form.Control
                            type="hidden"
                            name="id"
                            value={formik.values.id}
                          />
                        </Form.Group>

                        {auth.error && (
                          <p className="text-danger">{auth.error}</p>
                        )}
                        <div className="mt-3">
                          {isEdit ? (
                            <button
                              className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                              type="submit"
                              disabled={auth.isLoading || !formik.isValid}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                              type="button"
                              onClick={handleClickEdit}
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      </Form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
