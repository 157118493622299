import React from "react";
import { Col, Row } from "react-bootstrap";
import { Chart } from "react-google-charts";
// import CustomBadge from "../../../../components/Shared/CustomBadge";
import { transformNumber } from "../../../../utils/tableDataHelper";
import TrendPercentage from "../../../../components/Shared/TrendPercentage";
import StatisticsInforRow from "../../../../components/Shared/StatitsicsInforRow";
import { RecommendItems } from "../..";

export const options = {
  title: "",
  legend: "none",
  pieSliceText: "label",
  is3D: true,
  pieHole: 0.4,
  backgroundColor: "transparent",
  height: 300,
};

const Overview = (props) => {
  const { detailsData } = props;
  const data = [["category", "videos"]].concat(
    detailsData?.category_videos
      // ?.filter(row => row.category_name!=="Other")
      ?.map((row) => [row.category_name, parseInt(row.videos)])
  );
  return (
    <Row>
      <Col xs={12} md={9}>
        <div className="ml-0 mt-2" style={{ fontSize: 18 }}>
          {detailsData?.description}
        </div>
        <RecommendItems />
      </Col>
      <Col xs={12} md={3}>
        {/* <Row>
          <CustomBadge style={{ marginRight: 10 }}>
            {detailsData?.keyword}
          </CustomBadge>
        </Row> */}
        <Row className="d-flex flex-row align-items-baseline my-2 m-0">
          <div className="mr-2" style={{ fontSize: 24, fontWeight: "bold" }}>
            {transformNumber(detailsData?.subscriberCount, "commaNumber")}
          </div>
          <TrendPercentage
            currentValue={detailsData?.subscriberCount}
            pastValue={detailsData?.pre_subscriberCount}
            mark="% (7d)"
          />
        </Row>
        <StatisticsInforRow
          title="videoCount"
          count={detailsData?.videoCount}
          preCount={detailsData?.pre_videoCount}
        />
        <StatisticsInforRow
          title="viewCount"
          count={detailsData?.viewCount}
          preCount={detailsData?.pre_viewCount}
        />
        <Chart
          chartType="PieChart"
          data={data}
          options={options}
          // width={"100%"}
          // height={"300px"}
        />
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ marginTop: -270, fontSize: 16 }}
        >
          {"category"}
        </div>
      </Col>
    </Row>
  );
};

export default Overview;

