import React from "react";

const MobileFilterModalButton = (props) => {
  const { showModal, title } = props;
  return (
    <div
      className="btn btn-default table-header-filter d-flex align-items-center mx-1"
      onClick={() => showModal(true)}
    >
      <div className="pl-1">{title}</div>
    </div>
  );
};

export default MobileFilterModalButton;
