import React from "react";
import { ToastContainer, Flip } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Layout from "../../layout/Layout";
import { WEBSITES_CATEGORY } from "../../config/constants/data";
import {
  NEWS_MAIN_TABLE_API,
  NEWS_PROJECTS_TABLE_HEADER,
} from "../../config/constants/tableHeaders";

import Breadcrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import LinkButtons from "../../components/LinkButtons";
import MainTable from "../../components/Tables/MainTable";

const WebsiteNews = () => {
  return (
    <Layout>
      <Breadcrumb
        // parentLink='/news-project/'
        // parentLabel='Project'
        currentLabel="News"
      />
      <PageTitle
        title="News Page."
        description=" metalyzer shows all news posted on social sites."
      />
      <div className="tabs align-items-center">
        <LinkButtons linkItems={WEBSITES_CATEGORY} activeItem={"news"} />
      </div>
      <MainTable
        tableHeader={NEWS_PROJECTS_TABLE_HEADER}
        URL={NEWS_MAIN_TABLE_API}
        withTermsFilter
        // withoutSearch
        // withoutProjectFilter
        withoutFilterModal
        withoutTopicFilter
        model={"news"}
        modelName="news"
      />
      <ToastContainer theme="dark" transition={Flip} />
    </Layout>
  );
};

export default WebsiteNews;

