import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import {
  diffTimeStamp,
  getVotingParams,
  transformNumber,
  getPrimaryKey,
  seconds2HMS,
  modelName2VotingParams,
  dedicatedTitle,
  dedicatedColor,
} from "../../../../utils/tableDataHelper";
import ItemLinkComponent from "../ItemLinkComponent";
import { DISCORD_ASSETS_LINK } from "../../../../config/constants";
import { FaUserCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import VoteComponent from "../VoteComponent";
import {
  setUserVoteValue,
  getUserVoteValue,
} from "../../../../store/actions/userVoteActions";
import { setTopicValue } from "../../../../store/actions/setTopicActions";
import DetailsItem from "../DetailsItem";
import { useRef } from "react";
import TopicComponent from "../../../Selections/TopicComponent";
import ActionComponent from "../ActionComponent";
import { sortString } from "../../../../utils/common";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import VideoModal from "../videoModal";
import "./styles.scss";
import useUserRole from "../../../../hooks/useUserRole";
import { API_HOST } from "../../../../config/constants/data";
import { convertID2Name } from "../../../../utils/routingHelper";
import {
  postMainModel,
  updateMainModel,
} from "../../../../store/actions/mainModelActions";
import NameAvatar from "../../../NameAvatar";
import { Form } from "react-bootstrap";
import PrePercentageComponent from "../PrePercentageComponent";
import TableHeader from "../TableHeader";
import SparklineGraph from "../SparklineGraph/SparklinGraph";
import BookMarkButton from "../../../Shared/BookMarkButton";
// import { reloadBookmarkValue } from "../../../../store/actions/userBookmarkActions";

const TableView = (props) => {
  const dispatch = useDispatch();
  const { auth, subscription } = useUserRole();
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  const vote = useSelector((state) => state.userVote);
  const topic = useSelector((state) => state.topics);
  const { data: mainData } = useSelector((state) => state.mainModel) || [];
  // According to userRole, remove the voting field.
  const userRole = auth?.me?.role;
  const [tableData, setTableData] = useState(props.data);
  const realData = useMemo(() => {
    if (!props.forceProps) {
      return tableData;
    }
    return props.data;
  }, [tableData, props.data, props.forceProps]);
  /*
  const [currentOrdering, setCurrentOrdering] = useState(
    getDefaultOrderingField(props?.tableModelId)
  );
  */
  // const [sortType, setSortType] = useState("prePercentage");
  const [currentTableHeader, setCurrentTableHeader] = useState(
    props.header.filter(
      (row) =>
        row?.subscription === undefined || row?.subscription <= subscription
    ) || []
  );
  useEffect(() => {
    setCurrentTableHeader(
      props.header.filter(
        (row) =>
          row?.subscription === undefined || row?.subscription <= subscription
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.header]);
  const topicsList = topic.topics;
  const [modelIds, setModelIds] = useState([]);
  const location = useLocation();
  const mainPath = location.pathname.split("/")[1];
  const tailPath = location.pathname.split("/")[3];
  const finalPath = location.pathname.split("/")[4];
  let votingParams = getVotingParams(
    mainPath === "projects" || mainPath === "view"
      ? tailPath === undefined
        ? ""
        : tailPath
      : mainPath,
    finalPath
  );
  if (mainPath === "watchlist") {
    votingParams = modelName2VotingParams(props.modelName);
  }
  // TODO update the voting params
  // Table Header Sticky variables;
  const stickyRef = useRef(null);
  const tableRef = useRef(null);
  let topOffset = 0;
  let getTopOffset = false;
  const navBarHeight = 0;
  // ended Table Header Sticky variables;
  const getTopicLabel = (topicId) => {
    const topicObject = topicsList?.filter((topic) => {
      return topic.id === parseInt(topicId);
    });
    return topicObject.length > 0 ? topicObject[0]?.label : "No Topic";
  };

  const voteFieldExist =
    currentTableHeader?.some((row) => row?.transform === "vote") ?? false;

  /*
  const handleOrdering = (direction, sortField, sortType) => {
    const newData = sortTableData(tableData, direction, sortField, sortType);
    setTableData(newData);
    dispatch(updateMainModel(newData));
    setCurrentOrdering((direction === "-" ? direction : "") + sortField);
    setSortType(sortType);
    if (voteFieldExist) dispatch(reloadBookmarkValue());
  };
  */

  useEffect(() => {
    // if (props.data && props.data.length && votingParams.modelId !== '' && userRole && userRole !== 'public') {
    if (
      voteFieldExist &&
      props.data &&
      props.data.length &&
      votingParams.modelId !== ""
    ) {
      setModelIds(
        props?.data && props.data.map((d) => d?.[votingParams.primaryKey])
      );
    }
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    if (voteFieldExist) {
      if (!Array.isArray(tableData)) return;
      const tempData = tableData?.map((row) => {
        const primaryValue = row?.[votingParams.primaryKey];
        const isExist = primaryValue in vote?.data;
        row["sum_score"] = isExist
          ? vote?.data?.[row?.[votingParams.primaryKey]]?.sum_score
          : 0;
        return row;
      });
      setTableData(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vote?.data]);

  useEffect(() => {
    if (!voteFieldExist) return;
    if (modelIds && modelIds.length && votingParams.modelId !== "") {
      const params = {
        site_id: votingParams.siteId,
        model_type_id: votingParams.modelId,
        model_ids: modelIds,
      };
      dispatch(getUserVoteValue(params));
    }
    // eslint-disable-next-line
  }, [modelIds]);

  const setVoteValue = async (v, modelId) => {
    if (!voteFieldExist) return;
    if (votingParams.modelId === "") return;
    const params = {
      vote_score: v,
      site_id: votingParams.siteId,
      model_type_id: votingParams.modelId,
      model_id: modelId,
    };
    await dispatch(setUserVoteValue(params));
    const getParams = {
      site_id: votingParams.siteId,
      model_type_id: votingParams.modelId,
      model_ids: modelIds,
    };
    dispatch(getUserVoteValue(getParams));
  };

  const updateTopic = async (v, modelId) => {
    const params = {
      topic: v,
      id: modelId,
    };
    await dispatch(setTopicValue(props?.site, params));
  };
  // The functions for Table Header Sticky;
  const setTopOffset = () => {
    if (!getTopOffset) {
      try {
        topOffset =
          document.body.scrollTop +
          tableRef.current.getBoundingClientRect()?.top -
          navBarHeight;
        getTopOffset = true;
      } catch (e) {}
    }
  };

  const handleScroll = (e) => {
    try {
      if (
        stickyRef.current &&
        tableRef.current?.getBoundingClientRect()?.top < navBarHeight
      ) {
        setTopOffset();
        stickyRef.current.style.transform = `translateY(${
          document.body.scrollTop - topOffset
        }px)`;
      }
      if (
        stickyRef.current &&
        tableRef.current?.getBoundingClientRect()?.top > navBarHeight
      ) {
        stickyRef.current.style.transform = "unset";
      }
    } catch (e) {}
  };

  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll);
    setTimeout(() => setTopOffset(), 100);
    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Ended The functions for Table Header Sticky;

  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleVideoModalClose = () => setShowVideoModal(false);
  const handleVideoModalShow = () => setShowVideoModal(true);
  const [openVideoRowIndex, setOpenVideoRowIndex] = useState(0);
  const handleShowVideoModal = (index) => {
    setOpenVideoRowIndex(index);
    handleVideoModalShow();
  };

  // Onchange for checkbox.
  const onChangeCheckbox = async (e, item) => {
    if (item["id"] === "2") return;
    const isChecked = e.target.checked;
    if (isChecked) {
      // Handle when checkbox is checked
      await dispatch(
        postMainModel("user/users/", { user_id: item["id"], active: 1 })
      );
      const newData = tableData.map((row) => {
        if (row["id"] === item["id"]) row["is_active"] = "1";
        return row;
      });
      setTableData(newData);
      dispatch(updateMainModel(newData));
    } else {
      // Handle when checkbox is unchecked
      await dispatch(
        postMainModel("user/users/", { user_id: item["id"], active: 0 })
      );
      const newData = tableData.map((row) => {
        if (row["id"] === item["id"]) row["is_active"] = "0";
        return row;
      });
      setTableData(newData);
      dispatch(updateMainModel(newData));
    }
  };
  // const isMobile = useMediaQuery();
  return (
    <div>
      <div className="statistics-table table-responsive mt-2">
        <table ref={tableRef} className="table table-hover">
          <thead
            ref={stickyRef}
            className={props.stickyHeader ? " bg-table" : ""}
          >
            <TableHeader
              header={props.header}
              currentTableHeader={currentTableHeader}
              handleOrdering={props.handleOrdering}
              currentOrdering={props.initOrdering}
              // sortType={sortType}
            />
          </thead>
          <tbody>
            {realData && Array.isArray(realData) ? (
              realData.map((item, index) => {
                let colorStyle = "";
                return (
                  <tr key={index}>
                    {props?.header &&
                      currentTableHeader.map((h, i) => {
                        if (h.colored) {
                          colorStyle =
                            item[h.value] <= 19
                              ? "text-danger"
                              : item[h.value] <= 33.32
                              ? "text-warning"
                              : "text-success";
                        } else {
                          colorStyle = "";
                        }
                        return (
                          h.checked &&
                          (h.title === "#" ? (
                            <td className="text-center">
                              {props.startNumber + index + 1}
                            </td>
                          ) : (
                            <td key={i} className={colorStyle}>
                              {h.transform === "link" ? (
                                <ItemLinkComponent
                                  item={item}
                                  field={h.value}
                                  userRole={userRole}
                                  modelName={props.modelName}
                                />
                              ) : h.transform === "timestamp" ? (
                                diffTimeStamp(item[h.value])
                              ) : h.transform === "bookmark" ? (
                                <BookMarkButton
                                  model_id={votingParams.modelId}
                                  main_id={item?.[votingParams.primaryKey]}
                                  className="bookmark-button"
                                  borderButton={false}
                                  style={{}}
                                  fetchable={index === 0}
                                />
                              ) : h.transform === "project_id" ? (
                                <div
                                  className={`d-flex justify-content-${h?.align} align-items-center`}
                                >
                                  <img
                                    className="mr-3"
                                    alt=""
                                    src={`${API_HOST}/api/v1/project/icon/${convertID2Name(
                                      projectNames,
                                      item[h.value]
                                    )}`}
                                  />
                                  <div>
                                    {convertID2Name(
                                      projectNames,
                                      item[h.value]
                                    )}
                                  </div>
                                </div>
                              ) : h.transform === "duration" ? (
                                seconds2HMS(parseInt(item[h.value]))
                              ) : h.transform === "today_member_count" ? (
                                item["member_count_" + props.terms]
                              ) : h.transform === "sparkline" ? (
                                <SparklineGraph
                                  data={
                                    item[h.value]
                                      ? JSON.parse(item[h.value])?.map(Number)
                                      : []
                                  }
                                />
                              ) : h.transform === "discord_avatar" ? (
                                item.author !== undefined ? (
                                  <div>
                                    {" "}
                                    {JSON.parse(item?.author)?.username}{" "}
                                    {JSON.parse(item?.author)?.avatar !==
                                    null ? (
                                      <img
                                        src={`${DISCORD_ASSETS_LINK}avatars/${
                                          JSON.parse(item["author"]).id
                                        }/${
                                          JSON.parse(item["author"]).avatar
                                        }.png`}
                                        alt=""
                                        onError={(error) => {
                                          error.target.src = require("../../../../assets/icons/discord-logo.ico");
                                        }}
                                      />
                                    ) : (
                                      <FaUserCircle size={30} />
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )
                              ) : h.transform === "text" ? (
                                <div
                                  data-tip={
                                    item?.[h.value]?.length > 100
                                      ? item?.[h.value]
                                          ?.toString()
                                          .substring(0, 300)
                                      : ""
                                  }
                                >
                                  {item[h.value]?.toString().substring(0, 100)}
                                </div>
                              ) : h.transform === "textLeft" ? (
                                <div
                                  className="d-flex justify-content-start"
                                  data-tip={
                                    item?.[h.value]?.length > 100
                                      ? item?.[h.value]
                                          ?.toString()
                                          .substring(0, 300)
                                      : ""
                                  }
                                >
                                  {item[h.value]?.toString().substring(0, 100)}
                                </div>
                              ) : h.transform === "user_avatar" ? (
                                <div className="d-flex justify-content-start align-items-center">
                                  <div className="mr-2">
                                    <NameAvatar
                                      name={`${item["first_name"]} ${item["last_name"]}`}
                                      imageUrl={`${API_HOST}/media/${item["avatar"]}`}
                                      validImage={item["avatar"] !== ""}
                                    />
                                  </div>
                                  {item[h.value]?.toString().substring(0, 100)}
                                </div>
                              ) : h.transform === "checkBox" ? (
                                <div
                                  className="d-flex justify-content-end align-items-center"
                                  style={{ height: 30 }}
                                >
                                  <Form.Group>
                                    <Form.Check
                                      key={item["user_id"]}
                                      type="checkbox"
                                      label=""
                                      checked={item[h.value] === "1"}
                                      onChange={
                                        h.type === "action"
                                          ? (e) => onChangeCheckbox(e, item)
                                          : null
                                      }
                                    />
                                  </Form.Group>
                                </div>
                              ) : h.transform === "text400" ? (
                                <div
                                  data-tip={
                                    item?.[h.value]?.length > 50
                                      ? item?.[h.value]
                                          ?.toString()
                                          .substring(0, 300)
                                      : ""
                                  }
                                  style={{
                                    maxWidth: 400,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "left",
                                  }}
                                >
                                  {item[h.value]}
                                </div>
                              ) : h.transform === "timestamp_mil" ? (
                                dayjs(item[h.value]).format(
                                  "h:mm:ss.S a MM/DD/YYYY"
                                )
                              ) : h.transform === "lang" &&
                                item.lang === "0" ? (
                                ""
                              ) : h.transform === "vote" ? (
                                <VoteComponent
                                  item={item}
                                  vote={vote}
                                  setVoteValue={setVoteValue}
                                  primaryKey={votingParams.primaryKey}
                                  showVoteButton={
                                    userRole && userRole !== "publicNone"
                                  }
                                />
                              ) : h.transform === "topic" ? (
                                // TODO update this topics List part.
                                h.type === "numeric" ? (
                                  getTopicLabel(item["topic"])
                                ) : (
                                  <TopicComponent
                                    withoutTopic
                                    topic={item["topic"]}
                                    setTopic={updateTopic}
                                    tableModelId={props?.tableModelId}
                                    primaryKey={
                                      item[getPrimaryKey(props?.site)]
                                    }
                                  />
                                )
                              ) : h.transform === "detailsItem" ? (
                                <DetailsItem item={item} value={h.value} />
                              ) : h.transform === "marketpercentage" ? (
                                <span
                                  style={{
                                    color:
                                      parseFloat(item[h.value]) >= 0
                                        ? "#16C784"
                                        : "#EA3943",
                                  }}
                                >
                                  {parseFloat(item[h.value]) >= 0 ? (
                                    <BsFillCaretUpFill size={11} />
                                  ) : (
                                    <BsFillCaretDownFill size={11} />
                                  )}
                                  &nbsp;{item[h.value]}%
                                </span>
                              ) : h.type === "numeric" ? (
                                transformNumber(item[h.value], h.transform)
                              ) : h.transform === "prePercentage" ? (
                                <PrePercentageComponent
                                  item={item}
                                  h={h}
                                  isDirect={h.type !== "prePercentage"}
                                />
                              ) : h.transform === "preCount" ? (
                                transformNumber(
                                  item["pre_".concat(h.value)]
                                ) === 0 ? (
                                  0
                                ) : (
                                  transformNumber(
                                    transformNumber(item[h.value]) -
                                      transformNumber(
                                        item["pre_".concat(h.value)]
                                      ),
                                    "shorterNumber"
                                  )
                                )
                              ) : h.transform === "percentage" ? (
                                transformNumber(item[h.value], "percentage")
                              ) : h.transform === "suggestRate" ? (
                                <div>
                                  {parseInt(item[h.value]) >
                                  parseInt(item["total_" + h.value])
                                    ? item["total_" + h.value]
                                    : item[h.value]}
                                  /{item["total_" + h.value]} -{" "}
                                  {parseInt(item[h.value]) >
                                  parseInt(item["total_" + h.value])
                                    ? "100%"
                                    : transformNumber(
                                        (item[h.value] /
                                          item["total_" + h.value]) *
                                          100,
                                        "percentage"
                                      )}
                                </div>
                              ) : h.transform === "Dedicated" ? (
                                <div
                                  style={{
                                    color: dedicatedColor(
                                      item[h.value],
                                      item["total_" + h.value]
                                    ),
                                    fontWeight: "Bold",
                                  }}
                                >
                                  {dedicatedTitle(
                                    item[h.value],
                                    item["total_" + h.value]
                                  )}
                                </div>
                              ) : h.transform === "Promoting" ? (
                                <div>
                                  {diffTimeStamp(
                                    item[h.value],
                                    item["final_datetime"],
                                    "day"
                                  )}
                                </div>
                              ) : h.transform === "avgViews" ? (
                                <div>
                                  {transformNumber(
                                    parseInt(item["videos"]) === 0
                                      ? 0
                                      : parseInt(item[h.value]) /
                                          parseInt(item["videos"]),
                                    "commaNumber"
                                  )}
                                </div>
                              ) : h.transform === "viewRate" ? (
                                <div>
                                  {transformNumber(
                                    parseInt(item["subscriberCount"]) === 0
                                      ? 0
                                      : ((parseInt(item["viewCount"]) === 0
                                          ? 0
                                          : parseInt(item["viewCount"])) /
                                          parseInt(item["subscriberCount"])) *
                                          100,
                                    "percentage"
                                  )}
                                </div>
                              ) : h.transform === "project_description" ? (
                                <div
                                  data-tip={
                                    item?.[h.value]?.length > 50
                                      ? item?.[h.value]
                                          ?.toString()
                                          .substring(0, 300)
                                      : ""
                                  }
                                  style={{
                                    maxWidth: 400,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    marginLeft: "auto",
                                  }}
                                >
                                  {item[h.value]}
                                </div>
                              ) : h.transform === "project_official_links" ? (
                                <div
                                  data-tip={
                                    item?.[h.value]?.length > 50
                                      ? item?.[h.value]
                                          ?.toString()
                                          .substring(0, 300)
                                      : ""
                                  }
                                  style={{
                                    maxWidth: 300,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    marginLeft: "auto",
                                  }}
                                >
                                  {JSON.stringify(item[h.value])}
                                </div>
                              ) : h.transform === "action_component" ? (
                                <ActionComponent
                                  type={h.type}
                                  item={item}
                                  deletable
                                />
                              ) : h.transform === "videoModal" ? (
                                <div
                                  className={`d-flex align-items-center justify-content-${h.align} link-item-800`}
                                  onClick={() => handleShowVideoModal(index)}
                                >
                                  <img
                                    src={item["thumbnail"]}
                                    alt=""
                                    className="mr-3"
                                  />
                                  <div
                                    style={{
                                      color: "#007BFF",
                                      maxWidth: 450,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {sortString(item[h.value])}
                                  </div>
                                </div>
                              ) : (
                                sortString(item[h.value]?.toString())
                              )}
                            </td>
                          ))
                        );
                      })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" colSpan={20}>
                  <h3>Not Match Found</h3>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <VideoModal
        openVideoRowIndex={openVideoRowIndex}
        show={showVideoModal}
        onHide={handleVideoModalClose}
        data={mainData}
      />
    </div>
  );
};

export default TableView;

