import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { Img } from 'react-image';
import { useLocation} from 'react-router-dom';
import { DISCORD_ASSETS_LINK } from '../../../../config/constants';

// This component will implement the all routing logic according to table items.
const DetailsItem = ({item, value}) => {
  const location = useLocation()
  const mainPath = location.pathname.split('/')[1];
  const tailPath = location.pathname.split('/')[3];
  let filterPath = mainPath;
  if ((mainPath === 'projects' || mainPath === 'view')&& tailPath !== undefined){
    filterPath = tailPath;
  }
  switch (filterPath) {
    case 'link-details': case 'details':
      switch (item?.found_model) {
        case 'Video':
          switch (value) {
            case 'content':
              return (
                <div className="d-flex justify-content-end align-items-center">
                  <a
                    data-tip={item?.details?.description?.substring(0, 300)}
                    href={`https://youtube.com/watch?v=${item?.model_id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item?.details?.title?.substring(0, 20)}
                  </a>
                  <div className="count-indicator ml-3">
                    <Img src={item?.details?.thumbnail} />
                  </div>
                </div>
              )
            case 'user':
              return (
                <div className="d-flex justify-content-end align-items-center">
                  <a
                    data-tip={item?.details?.description?.substring(0, 300)}
                    href={`https://youtube.com/channel/${item?.user_id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item?.details?.channelTitle}
                  </a>
                  <div className="count-indicator ml-3">
                    <Img src={item?.details?.channel_thumbnail} />
                  </div>
                </div>
              )
            case 'follwers':
              return (
                <div>
                  {item?.details?.final_subscriberCount}
                </div>
              )
            default:
              return <>test</>
          }
        case 'Channel':
          switch (value) {
            case 'content':
              return (
                <div className="d-flex justify-content-end align-items-center">
                  <a
                    data-tip={item?.details?.description?.substring(0, 300)}
                    href={`https://youtube.com/channel/${item?.user_id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item?.details?.description?.substring(0, 20)}
                  </a>
                </div>
              )
            case 'user':
              return (
                <div className="d-flex justify-content-end align-items-center">
                  <a
                    data-tip={item?.details?.description?.substring(0, 300)}
                    href={`https://youtube.com/channel/${item?.user_id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item?.details?.title}
                  </a>
                  <div className="count-indicator ml-3">
                    <Img src={item?.details?.thumbnail} />
                  </div>
                </div>
              )
            case 'follwers':
              return (
                <div>
                  {item?.details?.subscriberCount}
                </div>
              )
            default:
              return <>test</>
          }
        case 'Tweet':
          switch (value) {
            case 'content':
              return (
                <div className="d-flex justify-content-end align-items-center">
                  <a
                    data-tip={item?.details?.text?.substring(0, 300)}
                    href={`https://twitter.com/${item?.details?.handle}/status/${item?.model_id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item?.details?.text?.substring(0, 20)}
                  </a>
                </div>
              )
            case 'user':
              return (
                <div className="d-flex justify-content-end align-items-center">
                  <a
                    data-tip={item?.details?.description?.substring(0, 300)}
                    href={`https://twitter.com/${item?.details?.handle}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item?.details?.name}
                  </a>
                  <div className="count-indicator ml-3">
                    <Img src={item?.details?.profile_image_url} />
                  </div>
                </div>
              )
            case 'follwers':
              return (
                <div>
                  {item?.details?.followers}
                </div>
              )
            default:
              return <>test</>
          }
        case 'User':
          switch (value) {
            case 'content':
              return (
                <div className="d-flex justify-content-end align-items-center">
                  <div
                    data-tip={item?.details?.description?.substring(0, 300)}
                  >
                    {item?.details?.description?.substring(0, 20)}
                  </div>
                </div>
              )
            case 'user':
              return (
                <div className="d-flex justify-content-end align-items-center">
                  <a
                    href={`https://twitter.com/${item?.user_id}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {item?.details?.name}
                  </a>
                  <div className="count-indicator ml-3">
                    <Img src={item?.details?.profile_image_url} />
                  </div>
                </div>
              )
            case 'follwers':
              return (
                <div>
                  {item?.details?.followers}
                </div>
              )
            default:
              return <>test</>
          }
        case 'Message':
          switch (value) {
            case 'content':
              return (
                <div className="d-flex justify-content-end align-items-center">
                  <div
                    data-tip={item?.details?.content?.substring(0, 300)}
                  >
                    {item?.details?.content?.substring(0, 20)}
                  </div>
                </div>
              )
            case 'user':
              return (
                item?.details?.author !== undefined ? (
                  <div>
                    {' '}{JSON.parse(item?.details?.author)?.username}{' '}
                    {JSON.parse(item?.details?.author)?.avatar !== null ? (
                      <img
                        src={`${DISCORD_ASSETS_LINK}avatars/${JSON.parse(item?.details?.['author']).id}/${JSON.parse(item?.details?.['author']).avatar}.png`}
                        alt=""
                        onError={(error) => { error.target.src = require('../../../../assets/icons/discord-logo.ico') }}
                      />) : (
                      <FaUserCircle size={30} />
                    )}
                  </div>
                ) :
                  <></>
              )
            case 'follwers':
              return (
                <div>
                  {0}
                </div>
              )
            default:
              return <>test</>
          }
        default:
          return <>test</>
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return <></>
  }


}


export default DetailsItem;