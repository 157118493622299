import React from "react";
import { useDispatch } from "react-redux";
import { Trans } from "react-i18next";
import { logOutUser } from "../../../../store/actions/authActions";
import { Row, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./styles.css";
import { useState } from "react";
import LoginSignupModal from "../../../../components/LoginSignupModal";
import NameAvatar from "../../../../components/NameAvatar";
import { API_HOST } from "../../../../config/constants/data";
import ForgetPasswordModal from "../../../../components/ForgetPasswordModal";
import { ToastContainer, toast, Flip } from "react-toastify";
import useUserRole from "../../../../hooks/useUserRole";

const LogInOut = () => {
  const dispatch = useDispatch();
  const {isAuthenticated, meInfo, auth} = useUserRole();
  const [showModal, setShowModal] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(true);
  const handleShow = (isLogin) => {
    setIsLoginModal(isLogin);
    setShowModal(true);
  };
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);
  const onShowForgetPassword = () => {
    setShowModal(false);
    setShowForgetPasswordModal(true);
  };
  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  return (
    <>
      {!isAuthenticated ? (
        <div>
          <Row>
            <div className="login-wrapper" onClick={() => handleShow(true)}>
              <div className="login">
                <Trans>Log In</Trans>
              </div>
            </div>
            <div className="login-wrapper" onClick={() => handleShow(false)}>
              <div className="signup">
                <Trans>Sign up</Trans>
              </div>
            </div>
          </Row>
          <LoginSignupModal
            isLoginModal={isLoginModal}
            setIsLoginModal={setIsLoginModal}
            show={showModal}
            onHide={() => setShowModal(false)}
            onShowForgetPassword={onShowForgetPassword}
            notifyError={notifyError}
          />
          <ForgetPasswordModal
            show={showForgetPasswordModal}
            onHide={() => setShowForgetPasswordModal(false)}
            notifySuccess={notifySuccess}
            notifyError={notifyError}
          />
        </div>
      )
      : (
        <Dropdown className="nav-item" alignRight>
          <Dropdown.Toggle as="a" className="cursor-pointer no-caret">
            <div className="profile-pic d-flex flex-row justify-content-center align-items-center mt-0">
              <div className="count-indicator mr-2">
                {/* <img className="img-xs rounded-circle " src={meInfo.avatar} alt="profile" /> */}
                <NameAvatar
                  name={`${meInfo.first_name} ${meInfo.last_name}`}
                  imageUrl={
                    auth.googleLoggedIn
                      ? auth.googleImageUrl
                      : `${API_HOST}/api/v1/user/profile/image/${meInfo.id}`
                  }
                />
                <span className="count bg-success"></span>
              </div>
              <div className="profile-name">
                <h4 className="mb-0 font-weight-normal">
                  <Trans>
                    {meInfo.first_name} {meInfo.last_name}
                  </Trans>
                </h4>
                {/* <span className="text-uppercase font-weight-normal text-success ml-2 text-decoration-none">
                  {meInfo.subscription === 1 && 'Free'}
                  {meInfo.subscription === 2 && 'Essentials'}
                  {meInfo.subscription === 3 && 'Business'}
                  {meInfo.subscription === 4 && 'Enterprise'}
                </span> */}
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="sidebar-dropdown">
            <Link to={`/profile`} className="dropdown-item">
              <Trans>Account settings</Trans>
            </Link>
            {!auth.googleLoggedIn && (
              <Link to="/change-password" className="dropdown-item">
                <Trans>Change Password</Trans>
              </Link>
            )}
            <div className="dropdown-divider"></div>
            <a
              href="#/"
              className="dropdown-item"
              onClick={() => dispatch(logOutUser())}
            >
              <Trans>Log out</Trans>
            </a>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <ToastContainer theme="dark" transition={Flip} />
    </>
  );
};


export default LogInOut;
