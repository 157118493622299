// VerticalProgressBar.jsx
import React from "react";

const GradiantProgressBar = ({
  progress,
  vertical = false,
  active = false,
  width = "3px",
}) => {
  
  const backgroundImage = `linear-gradient(${vertical?"":"90deg,"}#30359e,#0d0f4e)`;
  const activeBackgroundImage = `linear-gradient(${vertical?"":"90deg,"}#bd8bf3,#30459e)`;
  const progressWidth = vertical ? "100%" : `${Math.max(0, Math.min(progress.toFixed(2), 100))}%`;
  const progressHeight = vertical ? `${Math.max(0, Math.min(progress.toFixed(2), 100))}%` : "100%";

  return (
    <div
      style={{
        width: vertical ? width : "100%",
        height: vertical ? "100%" : width,
        backgroundImage: backgroundImage,
        position: "absolute",
        zIndex: 1,
        top: vertical ? "0%" : "100%",
      }}
    >
      {active && (
        <div
          style={{
            width: progressWidth,
            height: progressHeight,
            backgroundImage: activeBackgroundImage,
            position: "absolute",
            top: "0%",
            bottom: "auto",
            left: "0%",
            right: "0%",
          }}
        />
      )}
    </div>
  );
};

export default GradiantProgressBar;
