import axios from 'axios';

import {
  GET_HIGHLIGHT_DATA_LOADING,
  GET_HIGHLIGHT_DATA_SUCCESS,
  GET_HIGHLIGHT_DATA_FAIL,
} from '../types';
import { attachTokenToHeaders } from './authActions';
import { params2URL } from '../../utils/common';

export const getHighlightData = (params) => async (dispatch, getState) => {
  dispatch({ type: GET_HIGHLIGHT_DATA_LOADING });
  try {
    const response = await axios.get(params2URL(params), attachTokenToHeaders(getState));
    dispatch({
      type: GET_HIGHLIGHT_DATA_SUCCESS,
      payload: { data: response.data },
    });

  } catch (err) {
    dispatch({
      type: GET_HIGHLIGHT_DATA_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};
