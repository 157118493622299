import React, { useEffect, useRef } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { getSubscriptionPayment } from "../../store/actions/getSubscriptionPaymentActions";

const Payment = ({
  props,
  auth,
  getSubscriptionPaymentFunc,
  subscriptionState,
  history,
}) => {
  const location = useLocation();
  const hasCalledAPI = useRef(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const transactionId = queryParams.get("id");
    const user = queryParams.get("user");
    const first = queryParams.get("first");
    const last = queryParams.get("last");
    const org = queryParams.get("org");
    console.log("transactionId: " + transactionId, subscriptionState);
    if (!subscriptionState.subscriptionLoading && !hasCalledAPI.current) {
      hasCalledAPI.current = true;
      getSubscriptionPaymentFunc(
        {
          transactionId: transactionId,
          user: user,
          first: first,
          last: last,
          org: org,
        },
        history
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>payment</div>;
};

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  props: ownProps,
  subscriptionState: state.subscriptionPayment,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getSubscriptionPaymentFunc: getSubscriptionPayment,
  })
)(Payment);
