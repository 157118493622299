import React from "react";
import { useParams } from "react-router-dom";

import Layout from "../../layout/Layout";
import ProjectDetailsRouting from "./Components/ProjectDetailsRouting";

import TopSummary from "./Components/TopSummary";
import TopInfluencersCard from "./Components/ProjectOverview/TopInfluencersCard";
import SimpleTable from "../../components/Tables/SimpleTable";
import {
  PROJECTS_RANDOM_TABLE_API,
  PROJECT_TABLE_HEADER,
} from "../../config/constants/tableHeaders";

export const RecommendItems = () => (
  <div className="mt-5 w-100 flex flex-column">
    <div className="text-center topnews-card-title mb-3">
      Recommend Projects
    </div>
    <SimpleTable
      header={PROJECT_TABLE_HEADER}
      URL={PROJECTS_RANDOM_TABLE_API}
      modelName="trending"
    />
  </div>
);

const ProjectDetails = () => {
  const { nickName } = useParams();
  return (
    <Layout fullWidth>
      <div className="row">
        <div className="col-12 col-lg-2 border-none border-lg-right">
          <TopSummary />
        </div>
        <div className="col-12 col-lg-8 border-none border-lg-right">
          <ProjectDetailsRouting nickName={nickName} />
        </div>
        <div className="col-12 col-lg-2 p-0">
          <TopInfluencersCard nickName={nickName} />
        </div>
      </div>
    </Layout>
  );
};

export default ProjectDetails;
