import axios from "axios";
import { API_HOST } from "../../config/constants/data";

import {
  GET_TOPICS_LOADING,
  GET_TOPICS_SUCCESS,
  GET_TOPICS_FAIL,
} from "../types";
import { attachTokenToHeaders } from "./authActions";

export const getTopics = () => async (dispatch, getState) => {
  dispatch({ type: GET_TOPICS_LOADING });
  try {
    const response = await axios.get(
      `${API_HOST}/api/v1/manager/topic/`,
      attachTokenToHeaders(getState)
    );

    dispatch({
      type: GET_TOPICS_SUCCESS,
      payload: { data: response.data.results, count: response.data.count },
    });
  } catch (err) {
    dispatch({
      type: GET_TOPICS_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};

export const setTopics = (payload) => (dispatch) => {
  dispatch({
    type: GET_TOPICS_SUCCESS,
    payload: { data: payload.topics, count: payload.topicsCount },
  });
};

