import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { ToastContainer, toast, Flip } from "react-toastify";
import SearchComponent from "../Components/SearchComponent";
import HeaderCheckDropdown from "../Components/HeaderCheckDropdown";
import { BsSliders } from "react-icons/bs";
import TableFilterModal from "../Components/TableFilterModal";
import TableView from "../Components/TableView";
import TablePaginate from "../Components/TablePaginate";
import PageSizeSelection from "../Components/PageSizeSelection";
import "../styles.css";
import TermsFilter from "../Components/TermsFilter";
import ReactTooltip from "react-tooltip";
import "../../../assets/css/styles.css";
import TopicSelection from "../../Selections/TopicSelection";
import {
  routing2PrefilterTopicModelId,
  model2Site,
} from "../../../utils/routing2TableHelper";
import CategoryFilter from "../Components/CategoryFilter";
import { convertNick2Name } from "../../../utils/routingHelper";
import ProjectFilter from "../Components/ProjectFilter";
import { fetchMainModel } from "../../../store/actions/mainModelActions";
import { setPageSize } from "../../../store/actions/uiSettings";
import { getDefaultOrderingField } from "../../../utils/tableDataHelper";

// TODO in the future will move this component to global.
const EditMainTable = (props) => {
  const { modelName } = props;
  const location = useLocation();
  const history = useHistory();
  const { data, count, isLoading, error } = useSelector(
    (state) => state.mainModel
  );
  const pageSize = useSelector((state) => state.uiSettings.pageSize) || 100;
  const queryParams = new URLSearchParams(location.search);
  const paramPage = parseInt(queryParams.get("page")) || 1;
  const page = pageSize * (paramPage - 1) < count ? paramPage : 1;
  const dispatch = useDispatch();
  const setTopicState = useSelector((state) => state.setTopic);
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];

  // Props states.
  const [keyword, setKeyword] = useState(
    convertNick2Name(projectNames, props.projectNickName)
  );
  const [keywordNick, setKeywordNick] = useState(
    props.projectNickName ? props.projectNickName : "all"
  );
  // Pagination states.
  const tableModelId = routing2PrefilterTopicModelId(props?.model);

  const getCurrentOrdering = () => {
    const val = window.currentOrdering || getDefaultOrderingField(tableModelId);
    // delete window.currentOrdering;
    return val;
  };
  const [currentOrdering, setCurrentOrdering] = useState(getCurrentOrdering());

  const site = model2Site(props?.model);
  // const [showPageSizeModal, setShowPageSizeModal] = useState(false)
  // Terms states.
  const [term, setTerm] = useState(props?.term ? props?.term : "1d");
  const [topic, setTopic] = useState({ label: "Topic", value: -1 });
  const [category, setCategory] = useState("category");
  // Filters states.
  const [filters, setFilters] = useState(props?.filters);
  const [showFilterModal, setShowFilterModal] = useState(false);
  // Search state.
  // const [search, setSearch] = useState("");
  const [search, setSearch] = useState(
    useSelector((state) => state.uiSettings.searchText) || ""
  );
  // Table Header state.
  // const [tableHeader, setTableHeader] = useState(auth?.token? props.tableHeader : props.tableHeader.filter((header)=>header.title!=="Vote"))
  const [tableHeader, setTableHeader] = useState(props.tableHeader);

  const [currentLoading, setCurrentLoading] = useState(false);
  // Call Api function.
  const callApi = (params) => {
    const newPage = params?.newPage ? params.newPage : page;
    const newPageSize = params?.newPageSize ? params.newPageSize : pageSize;
    const newTerm = params?.newTerm ? params.newTerm : term;
    const newKeyword = params?.newKeyword ? params.newKeyword : keyword;
    const newTopic =
      params?.newTopic !== undefined ? params.newTopic : topic.value;
    const newCategory = params?.newCategory ? params.newCategory : category;
    const ordering = getCurrentOrdering();
    dispatch(
      fetchMainModel({
        URL: props.URL,
        ...filters,
        terms: newTerm,
        keyword: newKeyword,
        topic: newTopic,
        category: newCategory,
        page: newPage,
        page_size: newPageSize,
        search: search,
        ordering,
      })
    );
  };

  const handleOrdering = (direction, sortField, sortType) => {
    setCurrentOrdering(
      (window.currentOrdering =
        (direction === "-" ? direction : "") + sortField)
    );
    callApi({ newPage: page });
  };

  // First Loading.
  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Error toast message.
  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    if (setTopicState.isLoading) {
      setCurrentLoading(true);
    }
    if (setTopicState.data && !setTopicState.isLoading && currentLoading) {
      setCurrentLoading(false);
      toast.info("Topic " + setTopicState.data, {
        position: "top-right",
        autoClose: 1000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTopicState]);

  const setKeywordObject = (keywordObject) => {
    setKeyword(keywordObject.label);
    setKeywordNick(keywordObject.value);
    setCategory("category");
  };

  const udpateKeyword = (keywordObject) => {
    setKeywordObject(keywordObject);
    callApi({
      newPage: 1,
      newKeyword: keywordObject?.label,
      newCategory: "category",
    });
  };
  // handles
  const handleApply = () => {
    callApi({ newPage: page });
  };

  const handlePageClick = (newPage) => {
    const searchParams = new URLSearchParams(location.search);

    if (newPage === 1) {
      searchParams.delete("page");
    } else {
      searchParams.set("page", newPage);
    }

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    callApi({ newPage });
  };

  const changePageSize = (pageSize) => {
    dispatch(setPageSize(pageSize));
    callApi({ newPage: 1, newPageSize: pageSize });
  };
  const updateTerm = (newTerm) => {
    setTerm(newTerm);
    if (props?.setTerm) props.setTerm(newTerm);
    callApi({ newPage: 1, newTerm: newTerm });
  };

  const updateTopic = (newTopic) => {
    setTopic(newTopic);
    callApi({ newPage: 1, newTopic: newTopic.value });
  };

  const updateCategory = (newCategory) => {
    setCategory(newCategory);
    setKeyword("All");
    setKeywordNick("all");
    callApi({ newPage: 1, newCategory: newCategory, newKeyword: "All" });
  };

  return (
    <div className="row">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center x-scroll-wrapper-mobile">
              <div className="d-flex flex-row align-items-center ml-2">
                {props.withOutCategorySelection ||
                tableModelId > routing2PrefilterTopicModelId() ? (
                  <></>
                ) : (
                  <CategoryFilter
                    defaultCategory={category}
                    setCategory={updateCategory}
                  />
                )}
                {props.withoutProjectFilter ? (
                  <></>
                ) : (
                  <ProjectFilter
                    keyword={{ label: keyword, value: keywordNick }}
                    setKeyword={udpateKeyword}
                  />
                )}
                {props.withoutTopicFilter ||
                tableModelId > routing2PrefilterTopicModelId() ? (
                  <></>
                ) : (
                  <div className="d-flex flex-row align-items-center">
                    <TopicSelection
                      topic={topic}
                      setTopic={updateTopic}
                      tableModelId={tableModelId}
                      withNoTopic
                    />
                  </div>
                )}
                {props.withTermsFilter ? (
                  <div className="d-flex flex-row align-items-center">
                    <TermsFilter setTerm={updateTerm} term={term} />
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="d-flex flex-row align-items-center">
                {props.withoutSearch ? (
                  <></>
                ) : (
                  <SearchComponent
                    search={search}
                    setSearch={setSearch}
                    handleSearch={handleApply}
                  />
                )}
                <PageSizeSelection
                  pageSize={pageSize}
                  changePageSize={changePageSize}
                />
                {props.withoutFilterModal ? (
                  <></>
                ) : (
                  <a
                    href="#/"
                    className="btn btn-default mr-3"
                    onClick={() => setShowFilterModal(true)}
                  >
                    <BsSliders /> Filters
                  </a>
                )}
                {props.withoutHeaderCheck ? (
                  <></>
                ) : (
                  <HeaderCheckDropdown
                    header={tableHeader}
                    setHeader={setTableHeader}
                  />
                )}
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <TableView
                header={tableHeader}
                data={data}
                startNumber={pageSize * (page - 1)}
                initOrdering={currentOrdering}
                handleOrdering={handleOrdering}
                terms={term}
                stickyHeader
                tableModelId={tableModelId}
                site={site}
                modelName={modelName}
              />
            )}
            <TablePaginate
              pageSize={pageSize}
              page={page}
              count={count}
              handlePageClick={handlePageClick}
            />
            <ReactTooltip />
          </div>
        </div>
      </div>
      {showFilterModal && (
        <TableFilterModal
          show={showFilterModal}
          setShowModal={setShowFilterModal}
          filters={filters}
          setFilters={setFilters}
          filterModalFields={props.filterModalFields}
          handleApplyFilter={handleApply}
        />
      )}
      <ToastContainer theme="dark" transition={Flip} />
    </div>
  );
};

export default EditMainTable;

