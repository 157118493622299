import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { withRouter } from 'react-router-dom';
import { fetchMainModel } from "../../../store/actions/mainModelActions";
import Loader from "../../Loader/Loader";
import { ToastContainer, toast, Flip } from "react-toastify";
import SearchComponent from "../Components/SearchComponent";
import TableView from "../Components/TableView";
import TablePaginate from "../Components/TablePaginate";
import PageSizeSelection from "../Components/PageSizeSelection";
import "../styles.css";
import ReactTooltip from "react-tooltip";
import "../../../assets/css/styles.css";
import { Icon } from "@iconify/react";
import ProjectFilter from "../Components/ProjectFilter";
import { convertNick2Name } from "../../../utils/routingHelper";
import AddServerInviteModal from "../Components/EditServerInvite/AddServerInviteModal";
import { getDefaultOrderingField } from "../../../utils/tableDataHelper";

// TODO in the future will move this component to global.
const EditDiscordServerInviteTable = (props) => {
  const dispatch = useDispatch();
  const { tableHeader, URL, withoutSearch, withoutAddButton } = props;
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  const { data, count, isLoading, error } = useSelector(
    (state) => state.mainModel
  );
  const [keyword, setKeyword] = useState(
    convertNick2Name(projectNames, props.projectNickName)
  );
  const [keywordNick, setKeywordNick] = useState(
    props.projectNickName ? props.projectNickName : "all"
  );

  // Pagination states.
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [showAddModal, setShowAddModal] = useState(false);
  // Search state.
  // const [search, setSearch] = useState("");
  const [search, setSearch] = useState(
    useSelector((state) => state.uiSettings.searchText) || ""
  );

  const getCurrentOrdering = () => {
    const val =
      window.currentOrdering ||
      getDefaultOrderingField("edit-discord-serverinvite");
    // delete window.currentOrdering;
    return val;
  };
  const [currentOrdering, setCurrentOrdering] = useState(getCurrentOrdering());
  // Call Api function.
  const callApi = (params) => {
    const newPage = params?.newPage ? params.newPage : page;
    const newPageSize = params?.newPageSize ? params.newPageSize : pageSize;
    const newKeyword = params?.newKeyword ? params.newKeyword : keyword;
    const ordering = getCurrentOrdering();
    if (newKeyword === "All")
      dispatch(
        fetchMainModel({
          URL: URL,
          page: newPage,
          page_size: newPageSize,
          search: search,
          ordering,
        })
      );
    else
      dispatch(
        fetchMainModel({
          URL: URL,
          project_name: newKeyword,
          page: newPage,
          page_size: newPageSize,
          search: search,
          ordering,
        })
      );
  };

  const handleOrdering = (direction, sortField, sortType) => {
    setCurrentOrdering(
      (window.currentOrdering =
        (direction === "-" ? direction : "") + sortField)
    );
    callApi({ newPage: page });
  };

  // First Loading.
  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Error toast message.
  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [error]);

  // handles
  const handleApply = () => {
    setPage(1);
    callApi({ newPage: 1 });
  };

  const handlePageClick = (changePage) => {
    setPage(changePage);
    callApi({ newPage: changePage });
  };
  const changePageSize = (pageSize) => {
    setPageSize(pageSize);
    setPage(1);
    callApi({ newPage: 1, newPageSize: pageSize });
  };
  const setKeywordObject = (keywordObject) => {
    setKeyword(keywordObject.label);
    setKeywordNick(keywordObject.value);
  };
  const udpateKeyword = (keywordObject) => {
    setKeywordObject(keywordObject);
    callApi({ newPage: 1, newKeyword: keywordObject?.label });
  };

  return (
    <div className="row">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex flex-row align-items-center">
                {false && (
                  <ProjectFilter
                    keyword={{ label: keyword, value: keywordNick }}
                    setKeyword={udpateKeyword}
                  />
                )}
              </div>

              <div className="d-flex flex-row align-items-center">
                {withoutSearch ? (
                  <></>
                ) : (
                  <SearchComponent
                    search={search}
                    setSearch={setSearch}
                    handleSearch={handleApply}
                  />
                )}
                <PageSizeSelection
                  pageSize={pageSize}
                  changePageSize={changePageSize}
                />
                {withoutAddButton ? (
                  <></>
                ) : (
                  <a
                    href="#/"
                    className="btn btn-default table-header-filter d-flex align-items-center mx-1"
                    style={{ color: "#16C583" }}
                    onClick={() => setShowAddModal(true)}
                  >
                    <Icon
                      icon="material-symbols:add"
                      height="1.5rem"
                      color="#16C583"
                    />{" "}
                    Add Server Invite
                  </a>
                )}
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <TableView
                header={tableHeader}
                data={data}
                startNumber={pageSize * (page - 1)}
                initOrdering={currentOrdering}
                handleOrdering={handleOrdering}
                stickyHeader
                modelName="edit-discord-serverinvite"
              />
            )}
            <TablePaginate
              pageSize={pageSize}
              page={page}
              count={count}
              handlePageClick={handlePageClick}
            />
            <ReactTooltip />
          </div>
        </div>
      </div>
      {showAddModal && (
        <AddServerInviteModal
          show={showAddModal}
          setShowModal={setShowAddModal}
        />
      )}
      <ToastContainer theme="dark" transition={Flip} />
    </div>
  );
};

export default EditDiscordServerInviteTable;

