import {
  GET_PREFILTERS_LOADING,
  GET_PREFILTERS_SUCCESS,
  GET_PREFILTERS_FAIL,
} from '../types';

const initialState = {
  prefilters: [],
  prefiltersCount: 0,
  prefiltersLoading: false,
  prefilterserror: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_PREFILTERS_LOADING:
      return {
        ...state,
        prefiltersLoading: true,
        prefilterserror: null,
      };
    case GET_PREFILTERS_SUCCESS:
      return {
        ...state,
        prefilters: payload.data,
        prefiltersCount: payload.count,
        prefiltersLoading: false,
        prefilterserror: null,
      };
    case GET_PREFILTERS_FAIL:
      return {
        ...state,
        prefilters: [],
        prefiltersCount: 0,
        prefiltersLoading: false,
        prefilterserror: payload?.error
      };
    default:
      return state;
  }
}
