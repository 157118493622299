import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertNick2Name } from "../../../../../utils/routingHelper";
// import TopChannelCard from "./TopChannelCard";
// import TopUserCard from "./TopUserCard";
// import './styles.scss';
import { Reload } from "@rsuite/icons";
import { getYoutubeMostVideosApi } from "./../../../../../store/actions/getYoutubeMostVideosApiAction";
import { getYoutubeRecentVideosApi } from "./../../../../../store/actions/getYoutubeRecentVideosApiAction";
import VideoCard from "./VideoCard";
import VideoModal from "../../../../../components/Tables/Components/videoModal";

function TopInfluencersCard(props) {
  const [type, setType] = useState("top");

  const dispatch = useDispatch();
  const projectNames = useSelector(
    (state) => state.projectNames.projectNames || []
  );
  const { mostVideosData, mostVideosApiLoading } = useSelector(
    (state) => state.mostVideos || []
  );
  const { recentVideosData, recentVideosApiLoading } = useSelector(
    (state) => state.recentVideos || []
  );

  const projectName = useMemo(
    () => convertNick2Name(projectNames, props.nickName),
    [projectNames, props.nickName]
  );

  useEffect(() => {
    dispatch(getYoutubeMostVideosApi({ name: projectName }));
    dispatch(getYoutubeRecentVideosApi({ name: projectName }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName]);

  useEffect(() => {
    dispatch(getYoutubeMostVideosApi({ name: projectName }));
    dispatch(getYoutubeRecentVideosApi({ name: projectName }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    if (type === "top") {
      dispatch(getYoutubeMostVideosApi({ name: projectName }));
    } else {
      dispatch(getYoutubeRecentVideosApi({ name: projectName }));
    }
  };

  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleVideoModalClose = () => setShowVideoModal(false);
  const handleVideoModalShow = () => setShowVideoModal(true);
  const [openVideoRowIndex, setOpenVideoRowIndex] = useState(0);
  const handleShowVideoModal = (index) => {
    setOpenVideoRowIndex(index);
    handleVideoModalShow();
  };

  return (
    <>
      <div className="top-infulencer-con">
        <div className="top-infulencer-top-con">
          <p className="title-text">Youtube Videos</p>
          <div className="infulencer-type-switch-con mt-3 mt-md-0">
            <div
              onClick={() => {
                setType("top");
              }}
              className={
                type === "top"
                  ? "infulencer-type-switch-btn selected"
                  : "infulencer-type-switch-btn"
              }
            >
              Top
            </div>
            <div
              onClick={() => {
                setType("latest");
              }}
              className={
                type === "latest"
                  ? "infulencer-type-switch-btn selected"
                  : "infulencer-type-switch-btn"
              }
            >
              Latest
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end">
            <div
              onClick={() => {
                refresh();
              }}
              className={"project-tab-btn"}
            >
              <span>
                <Reload
                  spin={mostVideosApiLoading || recentVideosApiLoading}
                  style={{
                    fontSize: "1.2rem",
                    innerWidth: "bold",
                    marginRight: 3,
                  }}
                  color="green"
                />
              </span>{" "}
              Refresh
            </div>
          </div>
        </div>
        <div className="top-infulencer-bottom-con hide-scrollbar">
          {type === "top" &&
            mostVideosData &&
            mostVideosData.map((video, idx) => (
              <VideoCard
                video={video}
                index={idx}
                showModal={handleShowVideoModal}
              />
            ))}
          {type === "latest" &&
            recentVideosData &&
            recentVideosData.map((video, idx) => (
              <VideoCard
                video={video}
                index={idx}
                showModal={handleShowVideoModal}
              />
            ))}
        </div>
      </div>
      <VideoModal
        openVideoRowIndex={openVideoRowIndex}
        show={showVideoModal}
        onHide={handleVideoModalClose}
        data={type === "top" ? mostVideosData : recentVideosData}
      />
    </>
  );
}

export default TopInfluencersCard;

