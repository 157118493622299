import { transformNumber } from "../../utils/tableDataHelper";

// export const API_HOST = "http://127.0.0.1:8000";
export const API_HOST = "https://api.metalyzer.ai";
// export const WS_HOST = "ws://127.0.0.1:8001/ws/notifications/";
export const WS_HOST = "wss://api.metalyzer.ai/ws/notifications/";
export const TWITTER_LINK = "https://twitter.com/Metalyzerai";
export const DISCORD_INVITE_LINK = "https://discord.gg/cTu7VeQgYV";

export const MODEL_IDS = {
  PROJECT: 100,
  CHANNEL: 1,
  USER: 3,
  SERVER: 5,
  WEBSITE: 8,
  GROUP: 10,
  BLOG: 12,
};

export const SITE_IDS = {
  PROJECT: 10,
  CHANNEL: 1,
  USER: 2,
  SERVER: 3,
  WEBSITE: 4,
  GROUP: 5,
  BLOG: 6,
};

export const subscriptionList = [
  {
    title: "Standard",
    price: 100,
    value: "standard",
    description:
      "Access dashboards with the best on-chain data and find out what is happening on the blockchain real-time.",
    content: [
      "Token God Mode",
      "NFT Dashboards",
      "Wallet Profiler",
      "Smart Money",
      "Stablecoin Master",
      "DEX Trades",
      "Supported Chains",
    ],
  },
  {
    title: "Premium",
    price: 200,
    value: "premium",
    description:
      "Unlock the full power of Nansen dashboards with added customizable features. ",
    content: [
      "Easy Access to New Features",
      "Hot Contracts",
      "Downloadable CSV Data",
      "100 Custom Smart Alerts",
    ],
  },
  {
    title: "VIP",
    price: 400,
    value: "vip",
    description:
      "Follow the smart money and be a part of our private community with the smartest and most prominent investors in crypto. ",
    content: [
      "Private Discord Community",
      "War Room Calls",
      "Exclusive insights and Reports",
      "AMAs and Introductions to Early-Stage Projects",
      "Workshops on Special Topics of Interest",
    ],
  },
];
export const productIds = ["TC-PR_AnBlkLA", "TC-PR_RX53zGA"];
export const subscriptionProductList = [
  {
    id: "TC-PR_AnBlkLA",
    name: "Standard",
    description: "This is basic subscription.",
    logoUrl: "https://metalyzer.ai/Metalyzer.png",
    recurring: false,
    type: "PRODUCT_TYPE_RECURRING",
    chargeFrequency: "MONTHLY",
    price: {
      currency: "USD",
      price: 19.98,
      priceNet: 19.98,
      tax: 0,
      taxName: "Sales Tax",
      taxRate: 0,
    },
  },
  {
    id: "TC-PR_RX53zGA",
    name: "VIP",
    description: "This is premium subscription.",
    logoUrl: "https://metalyzer.ai/Metalyzer.png",
    recurring: false,
    type: "PRODUCT_TYPE_RECURRING",
    chargeFrequency: "MONTHLY",
    price: {
      currency: "USD",
      price: 49.99,
      priceNet: 49.99,
      tax: 0,
      taxName: "Sales Tax",
      taxRate: 0,
    },
  },
];
export const SubscriptionListHeader = [
  { title: "User Name", value: "username", width: "20%" },
  { title: "Email", value: "email", width: "15%" },
  { title: "Gift Code", value: "code", width: "15%" },
  { title: "Paid Amount (USD)", value: "amount", width: "15%" },
  { title: "Currency", value: "currency", width: "15%" },
  { title: "Currency Amount", value: "currencyAmount", width: "15%" },
  { title: "Created At", value: "createdAt", width: "15%" },
];

export const YOUTUBE_CATEGORY = [
  // { label: "Project", value: "/youtube/" },
  { label: "Channel", value: "/youtube-channels/" },
  { label: "Video", value: "/youtube-videos/" },
];

export const TWITTER_CATEGORY = [
  { label: "Project", value: "/twitter/" },
  { label: "User", value: "/twitter-users" },
  { label: "Tweet", value: "/twitter-tweets/" },
];

export const TELEGRAM_CATEGORY = [
  // { label: "Project", value: "/telegram/" },
  { label: "Group", value: "/telegram-groups" },
  // { label: "User", value: "/telegram-users/", },
];

export const DISCORD_CATEGORY = [
  // { label: "Project", value: "/discord/" },
  { label: "Server", value: "/discord-servers" },
  { label: "Channel", value: "/discord-channels" },
  // { label: "Message", value: "/discord-messages", },
];

export const DISCORD_SERVER_DETAILS_CATEGORY = [
  { label: "Overview", value: "/discord/server/" },
  { label: "Channel", value: "/discord/server/" },
  { label: "Website", value: "/discord/server/" },
];

export const NEWS_CATEGORY = [
  { label: "Project", value: "/news-project/" },
  { label: "News", value: "/news" },
];

export const WEBSITES_CATEGORY = [
  { label: "Websites", value: "/websites/" },
  { label: "News", value: "/news/" },
  { label: "Social", value: "/social/" },
];

export const EDIT_TOPIC_MODEL_LINKS = [
  { label: "Channels", value: "/edit/topic/channels" },
  { label: "Groups", value: "/edit/topic/groups" },
  { label: "Servers", value: "/edit/topic/servers" },
  { label: "Websites", value: "/edit/topic/websites" },
];

export const EDIT_PROJECT_MODEL_LINKS = [
  { label: "Project", value: "/edit/project" },
  { label: "Keyword", value: "/edit/project/keyword" },
  { label: "Category", value: "/edit/project/category" },
];

export const EDIT_DISCORD_MODEL_LINKS = [
  { label: "Server Invite", value: "/edit/discord/serverinvite" },
];

export const DASHBOARD_SUB_ROUTING_BUTTONS = {
  projects: [
    // { label: "Market", value: "market" },
    { label: "Social", value: "social" },
    { label: "Company", value: "company" },
  ],
  trending: [],
  youtube: [
    // { label: "Project", value: "youtube" },
    { label: "Channel", value: "youtube-channels" },
    { label: "Video", value: "youtube-videos" },
  ],
  telegram: [
    // { label: "Project", value: "telegram" },
    { label: "Group", value: "telegram-groups" },
    // { label: "User", value: "telegram-users" },
  ],
  twitter: [
    // { label: "Project", value: "twitter" },
    { label: "User", value: "twitter-users" },
    { label: "Tweet", value: "twitter-tweets" },
  ],
  discord: [
    // { label: "Project", value: "discord" },
    { label: "Server", value: "discord-servers" },
    { label: "Channel", value: "discord-channels" },
    // { label: "Message", value: "discord-messages" },
  ],
  websites: [],
  news: [],
  social: [],
};

export const TERMS = [
  { label: "24H", value: "1d" },
  { label: "7D", value: "7d" },
  { label: "30D", value: "30d" },
  { label: "3M", value: "3m" },
  { label: "6M", value: "6m" },
  { label: "1Y", value: "1y" },
  { label: "All", value: "all" },
];
export const DEFAULT_TERM = [
  {
    label: "24H",
    value: "1d",
  },
];
export const SEARCH_CATEGORIES = [
  { label: "All", value: "-1" },
  { label: "Project", value: "100" },
  { label: "Youtube", value: "1" },
  { label: "Discord", value: "5" },
  { label: "Website", value: "8" },
  { label: "Telegram", value: "10" },
];
export const DEFAULT_SEARCH_CATEGORY = [
  {
    label: "All",
    value: "-1",
  },
];

export const PROJECT_CATEGORY_SELECTION = [
  { label: "Cryptocurrency", value: "Cryptocurrency" },
  { label: "Crypto Topics", value: "Crypto Topics" },
  { label: "Web3 Games", value: "Web3 Games" },
  { label: "Other", value: "Other" },
];

export const CATEGORIES = [
  { label: "Category", value: "category" },
  { label: "Cryptocurrency", value: "cryptocurrency" },
  { label: "Crypto Topics", value: "crypto-topics" },
  { label: "Web3 Games", value: "web3-games" },
  { label: "Other", value: "other" },
];
export const DEFAULT_CATEGORIE = [
  {
    label: "Category",
    value: "category",
  },
];

export const WEBSITE_TOPIC_TYPE_SELECTION = [
  { label: "All Sources", value: "shared" },
  { label: "Youtube", value: "youtube" },
  { label: "Community", value: "community" },
];
export const DEFAULT_WEBSITE_TOPIC_TYPE = [
  {
    label: "All Sources",
    value: "shared",
  },
];

export const PAGE_SIZE_SELECTION = [
  { label: "100", value: "100" },
  { label: "50", value: "50" },
  { label: "20", value: "20" },
];
export const DEFAULT_PAGE_SIZE = [
  {
    label: "100",
    value: "100",
  },
];
export const VIDEO_LENGTH_SELECTION = [
  { label: "All", value: "0" },
  { label: "Normal", value: "2" },
  { label: "Shorts", value: "1" },
];
export const DEFAULT_VIDEO_LENGTH = [
  {
    label: "All",
    value: "0",
  },
];

export const KEYWORD_CATEGORY_SELECTION = [
  { label: "SEARCH", value: "SEARCH" },
  { label: "PRIMARY", value: "PRIMARY" },
  { label: "ASSIGN", value: "ASSIGN" },
  { label: "BLACKLIST", value: "BLACKLIST" },
];
export const DEFAULT_KEYWORD_CATEGORY = [
  {
    label: "SEARCH",
    value: "SEARCH",
  },
];

export const DEFAULT_PROJECT_CATEGORY = [
  {
    label: "Other",
    value: "Other",
  },
];

export const LANGUAGES = [
  { label: "English", value: "english" },
  { label: "Russian", value: "russian" },
];
export const DEFAULT_LANGUAGE = [
  {
    label: "English",
    value: "english",
  },
];

export const LINK_DOMAINS_TABLE_FILTERS = [{ label: "" }];

// Add Project Modal Field
export const ADD_PROJECT_MODAL_FIELDS = [
  { label: "Name", value: "name", type: false },
  { label: "Nick Name", value: "nickname", type: false },
  { label: "Icon", value: "icon", type: "file" },
  { label: "Description", value: "description", type: "textarea" },
  { label: "Youtube Link", value: "youtube_link", type: false },
  { label: "Twitter Link", value: "twitter_link", type: false },
  { label: "Discord Link", value: "discord_link", type: false },
  { label: "Site Link", value: "site_link", type: false },
];
// Filter Modal Structure
export const LINKS_FILTER_MODAL_FIELDS = [
  { label: "Domain", value: "domain", type: false },
  { label: "Min User Count", value: "min_user_count", type: false },
  { label: "Min Total Count", value: "min_total_count", type: false },
  { label: "Min youtube Count", value: "min_youtube_count", type: false },
  { label: "Min Twitter Count", value: "min_twitter_count", type: false },
  { label: "Min Discord Count", value: "min_discord_count", type: false },
];

export const WEBSITE_PARTNERS_FILTER_MODAL_FIELDS = [
  { label: "Min Videos (>=)", value: "min_videos", type: "number" },
  { label: "Max Videos (<=)", value: "max_videos", type: "number" },
  { label: "Min View Count (>=)", value: "min_viewCount", type: "number" },
  { label: "Max View Count (<=)", value: "max_viewCount", type: "number" },
  {
    label: "Min Subscribers (>=)",
    value: "min_subscriberCount",
    type: "number",
  },
  {
    label: "Max Subscribers (<=)",
    value: "max_subscriberCount",
    type: "number",
  },
  {
    label: "Min +Subscribers (>=)",
    value: "min_subscriberCount_diff",
    type: "number",
  },
  {
    label: "Max +Subscribers (<=)",
    value: "max_subscriberCount_diff",
    type: "number",
  },
];

export const WEBSITE_TOPIC_FILTER_MODAL_FIELDS = [
  {
    label: "Min Channel Count (>=)",
    value: "min_channelCount",
    type: "number",
  },
  {
    label: "Max Channel Count (<=)",
    value: "max_channelCount",
    type: "number",
  },
  { label: "Min View Count (>=)", value: "min_viewCount", type: "number" },
  { label: "Max View Count (<=)", value: "max_viewCount", type: "number" },
  {
    label: "Min Subscribers (>=)",
    value: "min_subscriberCount",
    type: "number",
  },
  {
    label: "Max Subscribers (<=)",
    value: "max_subscriberCount",
    type: "number",
  },
  { label: "Min Videos (>=)", value: "min_videos", type: "number" },
  { label: "Max Videos (<=)", value: "max_videos", type: "number" },
];

export const LINK_DOMAINS_FILTER_MODAL_FIELDS = [
  { label: "Min Total Count", value: "min_total_count", type: false },
  { label: "Min User Count", value: "min_user_count", type: false },
  { label: "Min youtube Count", value: "min_youtube_count", type: false },
  { label: "Min Twitter Count", value: "min_twitter_count", type: false },
  { label: "Min Discord Count", value: "min_discord_count", type: false },
];

export const LINK_DETAILS_FILTER_MODAL_FIELDS = [
  { label: "Domain", value: "domain", type: false },
  { label: "Social", value: "found_site", type: false },
  { label: "Model", value: "found_model", type: false },
];

export const DISCORD_MESSAGES_FILTER_MODAL_FIELDS = [
  { label: "Channel ID", value: "channel_id", type: false },
  { label: "Author ID", value: "author_id", type: false },
  { label: "Language", value: "lang", type: false },
];
export const DISCORD_CHANNELS_FILTER_MODAL_FIELDS = [
  { label: "Server ID", value: "server_id", type: false },
];
export const TWITTER_TWEETS_FILTER_MODAL_FIELDS = [
  { label: "User Handle", value: "handle", type: false },
];
export const TWITTER_USERS_FILTER_MODAL_FIELDS = [
  { label: "Min Follwer Count", value: "min_followers", type: false },
  { label: "Min Following Count", value: "min_following", type: false },
  { label: "Min Tweet Count", value: "min_tweets_count", type: false },
  { label: "Bot", value: "bot", type: "bool" },
];
export const TELEGRAM_DIALOGS_FILTER_MODAL_FIELDS = [
  { label: "Group ID", value: "dialog_id", type: false },
];
// export const YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS = [
//   { label: "Channel ID", value: "channelId", type: false },
//   { label: "Min Subscriber Count", value: "min_subscriberCount", type: false },
//   { label: "Min Vote Score", value: "min_sum_score", type: false },
// ];
export const YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS = [
  { label: "Min View Count (>=)", value: "min_viewCount", type: "number" },
  { label: "Max View Count (<=)", value: "max_viewCount", type: "number" },
  { label: "Min View Rate (>=)", value: "min_viewRate", type: "number" },
  { label: "Max View Rate (<=)", value: "max_viewRate", type: "number" },
  { label: "Min Length (>=)", value: "min_duration", type: "time" },
  { label: "Max Length (<=)", value: "max_duration", type: "time" },
  {
    label: "Min Subscribers (>=)",
    value: "min_subscriberCount",
    type: "number",
  },
  {
    label: "Max Subscribers (<=)",
    value: "max_subscriberCount",
    type: "number",
  },
  {
    label: "Min +Subscribers (>=)",
    value: "min_subscriberCount_diff",
    type: "number",
  },
  {
    label: "Max +Subscribers (<=)",
    value: "max_subscriberCount_diff",
    type: "number",
  },
];
export const YOUTUBE_CHANNELS_FILTER_MODAL_FIELDS = [
  { label: "Min Videos (>=)", value: "min_videos", type: "number" },
  { label: "Max Videos (<=)", value: "max_videos", type: "number" },
  { label: "Min View Count (>=)", value: "min_viewCount", type: "number" },
  { label: "Max View Count (<=)", value: "max_viewCount", type: "number" },
  {
    label: "Min Subscribers (>=)",
    value: "min_subscriberCount",
    type: "number",
  },
  {
    label: "Max Subscribers (<=)",
    value: "max_subscriberCount",
    type: "number",
  },
  {
    label: "Min +Subscribers (>=)",
    value: "min_subscriberCount_diff",
    type: "number",
  },
  {
    label: "Max +Subscribers (<=)",
    value: "max_subscriberCount_diff",
    type: "number",
  },
  {
    label: "Min +View Count (>=)",
    value: "min_viewCount_diff",
    type: "number",
  },
  {
    label: "Max +View Count (<=)",
    value: "max_viewCount_diff",
    type: "number",
  },
];

export const PROJECT_DETAILS_MAIN_CHART_SITES_GROUP_BUTTON = [
  { label: "Youtube", value: "youtube", type: false },
  // { label: 'Twitter', value: 'twitter', type:false},
  { label: "Discord", value: "discord", type: false },
  // { label: "Link", value: "link", type: false },
];

export const PROJECT_DETAILS_MAIN_CHART_TERMS_GROUP_BUTTON = [
  { label: "Day", value: "day", type: false },
  { label: "Week", value: "week", type: false },
  { label: "Month", value: "month", type: false },
  { label: "Year", value: "year", type: false },
];

export const PROJECT_DETAILS_SUB_ROUTING_GROUP_BUTTON = [
  { label: "Overview", value: "overview", type: false },
  { label: "Video", value: "videos", type: false },
  { label: "Channel", value: "channels", type: false },
  // { label: 'Tweet', value: 'tweets', type:false},
  // { label: 'User', value: 'users', type:false},
  { label: "Telegram", value: "groups", type: false },
  { label: "Discord", value: "servers", type: false },
  // { label: 'News', value: 'news', type:false},
  { label: "Website", value: "websites", type: false },
  { label: "News", value: "news", type: false },
  { label: "Social", value: "social", type: false },
];

export const DISCORD_SERVERS_DETAILS_SUB_ROUTING_GROUP_BUTTON = [
  { label: "Overview", value: "overview", type: false },
  { label: "Channel", value: "channels", type: false },
  { label: "Website", value: "websites", type: false },
  { label: "News", value: "news", type: false },
  { label: "Social", value: "social", type: false },
];
export const LINKS_DOMAINS_DETAILS_SUB_ROUTING_GROUP_BUTTON = [
  { label: "Overview", value: "overview", type: false },
  // { label: "FAQ", value: "faq", type: false },
  { label: "Videos", value: "videos", type: false },
  { label: "Partners", value: "partners", type: false },
];
export const YOUTUBE_CHANNELS_DETAILS_SUB_ROUTING_GROUP_BUTTON = [
  { label: "Overview", value: "overview", type: false },
  { label: "Video", value: "videos", type: false },
  { label: "Project", value: "projects", type: false },
  { label: "Website", value: "websites", type: false },
  { label: "News", value: "news", type: false },
  { label: "Social", value: "social", type: false },
];
export const TELEGRAM_GROUPS_DETAILS_SUB_ROUTING_GROUP_BUTTON = [
  { label: "Overview", value: "overview", type: false },
  { label: "Website", value: "websites", type: false },
  { label: "News", value: "news", type: false },
  { label: "Social", value: "social", type: false },
];

export const DASHBOARD_TABLE_GROUP_BUTTON = [
  { label: "Trending", value: "trending", type: false },
  // { label: "Market", value: "market", type: false },
  { label: "Youtube", value: "youtube", type: false },
  // { label: "Telegram", value: "telegram-groups", type: false },
  // { label: 'Twitter', value: 'twitter', type:false},
  // { label: "Discord", value: "discord-servers", type: false },
  { label: "Website", value: "websites", type: false },
  // { label: "News", value: "news", type: false },
  // { label: "Social", value: "social", type: false },
];

export const BOOKMARK_GROUP_BUTTON = [
  { label: "Project", value: MODEL_IDS.PROJECT },
  { label: "Youtube", value: MODEL_IDS.CHANNEL },
  // { label: "Telegram", value: MODEL_IDS.GROUP },
  // { label: "Discord", value: MODEL_IDS.SERVER },
  // { label: "Twitter", value: MODEL_IDS.USER },
  { label: "Website", value: MODEL_IDS.WEBSITE },
];

export const DASHBOARD_ROUTING_LIST = [
  { main: "youtube", sub: "youtube", modelName: "youtube-project--category" },
  {
    main: "youtube",
    sub: "youtube-channels",
    modelName: "youtube-channel--category",
  },
  {
    main: "youtube",
    sub: "youtube-videos",
    modelName: "youtube-video--category",
  },
  // {
  //   main: "telegram",
  //   sub: "telegram",
  //   modelName: "telegram-project--category",
  // },
  // {
  //   main: "telegram",
  //   sub: "telegram-groups",
  //   modelName: "telegram-group--category",
  // },
  // { main: "discord", sub: "discord", modelName: "discord-project--category" },
  // {
  //   main: "discord",
  //   sub: "discord-servers",
  //   modelName: "discord-server--category",
  // },
  // {
  //   main: "discord",
  //   sub: "discord-channels",
  //   modelName: "discord-channel--category",
  // },
  {
    main: "websites",
    sub: "websites",
    param: "topic",
    modelName: "website-topic--category",
  },
  // { main: "news", sub: "news", modelName: "news--category" },
  // {
  //   main: "social",
  //   sub: "social",
  //   param: "domain",
  //   modelName: "social-website--category",
  // },
];

export const PROJECT_DETAILS_RIGHT_LINKS_BUTTON = [
  { label: "Buy", value: "buy", type: false },
  { label: "Learn", value: "learn", type: false },
  { label: "Recommended", value: "recommended", type: false },
];

export const PROJECT_DETAILS_MAIN_CHART_FILTER_GROUP_BUTTON = {
  youtube: [
    { label: "Videos", value: "videos" },
    { label: "Channels", value: "channels" },
    { label: "Avg Subscribers", value: "avg_subscriberCount" },
  ],
  // 'twitter': [
  //   { label: 'Tweets', value: 'tweets' },
  //   { label: 'Sum Followers', value: 'sum_followers' },
  // ],
  discord: [
    { label: "Messages", value: "messages" },
    { label: "Writers", value: "writers" },
    { label: "Replies", value: "replies" },
    { label: "Avg Words", value: "avg_words" },
  ],
  link: [
    { label: "Links", value: "links" },
    // { label: 'Users', value: 'user_count'},
    { label: "Domains", value: "domain_count" },
    { label: "Youtube", value: "youtube_count" },
    // { label: 'Twitter', value: 'twitter_count'},
    { label: "Discord", value: "discord_count" },
  ],
};

export const PROJECT_DETAILS_CHART_OPTION = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      ticks: {
        callback: (value) => transformNumber(value, "shorterNumber"),
      },
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

export const SITE_NAME_LIST = ["youtube", "twitter", "discord", "website"];

export const MODEL_NAME_LIST = [
  "channel",
  "video",
  "user",
  "tweet",
  "server",
  "d_channel",
  "message",
  "website",
  "url",
];

export const TIME_1M = 1000 * 60;
export const TIME_5M = 1000 * 60 * 5;
export const TIME_15M = 1000 * 60 * 15;
export const TIME_30M = 1000 * 60 * 30;
export const TIME_1H = 1000 * 60 * 60;
export const TIME_10H = 1000 * 60 * 10;
export const TIME_24H = 1000 * 60 * 60 * 24;

export const SEO_TEXTS = {
  trending: {
    title: "Metalyzer: AI Powered All-In-One Social Media Agent",
    text: "Explore, analyze, and draw conclusions with Metalyzer. Your ultimate automated social media agent, eliminating All Tedious Tasks.",
  },
  market: {
    title: "Metalyzer: AI Powered All-In-One Social Media Agent",
    text: "Explore, analyze, and draw conclusions with Metalyzer. Your ultimate automated social media agent, eliminating All Tedious Tasks.",
  },
  youtube: {
    title: "Trending Category Youtube Niches Sorted By Social Growth",
    text: "Explore, analyze, and find trending Category Youtube Niches with Metalyzer. Data continuously updated in real-time to never miss an update.",
  },
  "youtube-channels": {
    title:
      "Trending Category Youtube Channels Sorted By Growth, Activity & Impact",
    text: "Explore, analyze, and find trending Category Youtube Channels with Metalyzer. Data continuously updated in real-time to never miss an update.",
  },
  channels: {
    title:
      "Trending Category Youtube Channels Sorted By Growth, Activity & Impact",
    text: "Explore, analyze, and find trending Category Youtube Channels with Metalyzer. Data continuously updated in real-time to never miss an update.",
  },
  "youtube-videos": {
    title: "Category Youtube Videos Sorted By Growth And Social Impact",
    text: "Discover Category YouTube videos. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a new video.",
  },
  videos: {
    title: "Category Youtube Videos Sorted By Growth And Social Impact",
    text: "Discover Category YouTube videos. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a new video.",
  },
  telegram: {
    title: "Trending Category Telegram Niches Sorted By Growth & Activity",
    text: "Explore, analyze, and find trending Category Telegram Niches with Metalyzer. Data continuously updated in real-time to never miss an update.",
  },
  "telegram-groups": {
    title: "Category Telegram Groups Sorted By Growth & Activity",
    text: "Discover Category Telegram groups. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss an active discussion.",
  },
  groups: {
    title: "Category Telegram Groups Sorted By Growth & Activity",
    text: "Discover Category Telegram groups. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss an active discussion.",
  },
  discord: {
    title: "Trending Category Discord Niches Sorted By Growth & Activity",
    text: "Explore, analyze, and find trending Category Discord Niches with Metalyzer. Data continuously updated in real-time to never miss an update.",
  },
  "discord-servers": {
    title: "Category Discord Servers Sorted By Growth And Activity",
    text: "Discover Category Discord servers. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss an active discussion.",
  },
  servers: {
    title: "Category Discord Servers Sorted By Growth And Activity",
    text: "Discover Category Discord servers. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss an active discussion.",
  },
  "discord-channels": {
    title: "Category Discord Channels Sorted By Activity",
    text: "Discover Category Discord channels. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss an active discussion.",
  },
  "d-channels": {
    title: "Category Discord Channels Sorted By Activity",
    text: "Discover Category Discord channels. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss an active discussion.",
  },
  websites: {
    title: "Trending Category Websites Sorted By Growth And Social Impact",
    text: "Discover Category Websites. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "websites-youtube": {
    title: "Trending Category Websites Sorted By Growth And Social Impact",
    text: "Discover Category Websites. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "websites-community": {
    title: "Trending Category Websites Sorted By Growth And Social Impact",
    text: "Discover Category Websites. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  news: {
    title: "Trending Category News Sorted By Growth And Social Impact",
    text: "Discover Category News. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  social: {
    title: "Trending Category Social Sorted By Growth And Social Impact",
    text: "Discover Category Social. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  links: {
    title: "Trending Category Links Sorted By Growth And Social Impact",
    text: "Discover Category Links. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  project: {
    title:
      "Explore Projectname Related Resources Easily Without Wasting Any Time",
    text: "Discover a diverse range of Projectname resources and access invaluable insights on growth and shared content. Stay informed and never miss a single piece of information with Metalyzer.",
  },
  "server-details-overview": {
    title: "Join serverName Discord Server And Gain Valueable Insights",
    text: "overview",
  },
  "server-details-channels": {
    title: "View serverName Discord Channel Acitvities And Insights",
    text: "Explore, analyze, and find trending serverName channels with Metalyzer. Data continuously updated in real-time to never miss an update.",
  },
  "server-details-websites": {
    title: "View serverName Discord Server Shared Websites",
    text: "Discover serverName shared websites. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "server-details-news": {
    title: "View serverName Discord Server Shared News",
    text: "Discover serverName shared News. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "server-details-social": {
    title: "View serverName Discord Server Shared Social Media",
    text: "Discover serverName shared Social Media. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "group-details-overview": {
    title: "Join groupName Telegram Group And Gain Valueable Insights",
    text: "overview",
  },
  "group-details-websites": {
    title: "View groupName Telegram Group Shared Websites",
    text: "Discover groupName shared websites. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "group-details-news": {
    title: "View groupName Telegram Group Shared News",
    text: "Discover groupName shared News. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "group-details-social": {
    title: "View groupName Telegram Group Shared Social Media",
    text: "Discover groupName shared Social Media. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "channel-details-overview": {
    title: "View channelName Youtube Channel Statistics And Insights",
    text: "overview",
  },
  "channel-details-videos": {
    title: "Explore channelName Youtube Trending Videos And Insights",
    text: "Discover channelName YouTube videos. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a new video.",
  },
  "channel-details-projects": {
    title: "Explore channelName Youtube Trending Projects And Insights",
    text: "Discover channelName YouTube projects. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a new video.",
  },
  "channel-details-websites": {
    title: "Explore channelName Shared And Promoted Resources",
    text: "Discover channelName shared and promoted websites. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "channel-details-news": {
    title: "Explore channelName Shared And Promoted Resources",
    text: "Discover channelName shared and promoted news. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "channel-details-social": {
    title: "Explore channelName Shared And Promoted Resources",
    text: "Discover channelName shared and promoted social media. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "website-details-overview": {
    title: "View websiteName Social Sentiment, Traffic Analytics And More",
    text: "overview",
  },
  "website-details-faq": {
    title: "View websiteName Website FAQ, Traffic Analytics And More",
    text: "FAQ",
  },
  "website-details-partners": {
    title: "Explore websiteName Partners of Youtube",
    text: "Discover social shares about websiteName. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "website-details-new-partners": {
    title: "Explore websiteName New Partners of Youtube",
    text: "Discover social shares about websiteName. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "website-details-videos": {
    title: "Explore websiteName Videos of Youtube",
    text: "Discover social shares about websiteName. Valuable insights, zero wasted time. Data continuously updated in real-time to never miss a trending project.",
  },
  "contact-us": {
    title: "Submit a resource, request or business inquiry",
    text: "Feel free to contact us for any inquiries related to our service. Whether you want to add a new resource, wish to explore partnership opportunities, or require assistance with your requests.",
  },
};

export const miniSidenavWidth = 60;
export const maxSidenavWidth = 170;
export const SEARCH_MODAL_CATEGORY_LIMIT = 3;

export const SOCIAL_LINK_ICONS = {
  youtube: "logos:youtube-icon",
  telegram: "logos:telegram",
  t: "logos:telegram",
  discord: "logos:discord-icon",
  twitter: "ri:twitter-x-fill",
  facebook: "logos:facebook",
  instagram: "skill-icons:instagram",
  tiktok: "logos:tiktok-icon",
  reddit: "logos:reddit-icon",
  linkedin: "logos:linkedin-icon",
  pinterest: "logos:pinterest",
  snapchat: "mdi:snapchat",
  github: "mingcute:github-fill",
  medium: "mingcute:medium-fill",
  twitch: "logos:twitch",
  vimeo: "fa:vimeo-square",
  whatsapp: "logos:whatsapp-icon",
};
