import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import EditProjectModal from "../EditProject/EditProjectModal";
import DeleteProjectModal from "../EditProject/DeleteProjectModal";
import EditKeywordModal from "../EditKeyword/EditKeywordModal";
import DeleteKeywordModal from "../EditKeyword/DeleteKeywordModal";
import EditCategoryModal from "../EditCategory/EditCategoryModal";
import DeleteCategoryModal from "../EditCategory/DeleteCategoryModal";
import EditServerInviteModal from "../EditServerInvite/EditServerInviteModal";
import DeleteServerInviteModal from "../EditServerInvite/DeleteServerInviteModal";
import DeleteBlogModal from "../DeleteBlogModal";

const ActionComponent = (props) => {
  const { type, item, deletable } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let history = useHistory();
  return (
    <div className="d-flex flex-row justify-content-end">
      <button
        className="btn btn-default mx-1"
        style={{ borderRadius: 4, color: "#007BFF" }}
        onClick={() => {
          if (type === "blog") {
            history.push(`/edit/blog/${item.id}/`);
          } else setShowEditModal(true);
        }}
      >
        <Icon
          icon="material-symbols:edit"
          height="1.5rem"
          color="#007BFF"
          style={{ marginRight: 3, marginTop: -3 }}
        />
        Edit
      </button>
      {deletable && (
        <button
          className="btn btn-default mx-1"
          style={{ borderRadius: 4, color: "red" }}
          onClick={() => setShowDeleteModal(true)}
        >
          <Icon
            icon="material-symbols:delete-outline"
            height="1.5rem"
            color="red"
            style={{ marginRight: 3, marginTop: -3 }}
          />
          Delete
        </button>
      )}
      {showEditModal &&
        (type === "project" ? (
          <EditProjectModal
            show={showEditModal}
            setShowModal={setShowEditModal}
            item={item}
          />
        ) : type === "keyword" ? (
          <EditKeywordModal
            show={showEditModal}
            setShowModal={setShowEditModal}
            item={item}
          />
        ) : type === "category" ? (
          <EditCategoryModal
            show={showEditModal}
            setShowModal={setShowEditModal}
            item={item}
          />
        ) : type === "serverinvite" ? (
          <EditServerInviteModal
            show={showEditModal}
            setShowModal={setShowEditModal}
            item={item}
          />
        ) : (
          <></>
        ))}
      {deletable &&
        showDeleteModal &&
        (type === "project" ? (
          <DeleteProjectModal
            show={showDeleteModal}
            setShowModal={setShowDeleteModal}
            item={item}
          />
        ) : type === "keyword" ? (
          <DeleteKeywordModal
            show={showDeleteModal}
            setShowModal={setShowDeleteModal}
            item={item}
          />
        ) : type === "category" ? (
          <DeleteCategoryModal
            show={showDeleteModal}
            setShowModal={setShowDeleteModal}
            item={item}
          />
        ) : type === "blog" ? (
          <DeleteBlogModal
            show={showDeleteModal}
            setShowModal={setShowDeleteModal}
            item={item}
          />
        ) : type === "serverinvite" ? (
          <DeleteServerInviteModal
            show={showDeleteModal}
            setShowModal={setShowDeleteModal}
            item={item}
          />
        ) : (
          <></>
        ))}
    </div>
  );
};

export default ActionComponent;
