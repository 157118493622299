import React from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const SparklineGraph = ({ data }) => {
  const sparkData = data.filter(ele => ele !== null && ele > 0);
  const lineColor = sparkData[0] < sparkData[sparkData.length - 1] ? "#16C784" : "#EA3943";

  return (
    <Sparklines data={sparkData} width={100} height={20}>
      <SparklinesLine  style={{ strokeWidth: 2, stroke: lineColor, fill: "none" }}/>
      {/* <SparklinesSpots /> */}
    </Sparklines>
  );
};

export default SparklineGraph;
