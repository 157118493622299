import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import { Icon } from "@iconify/react";
import "./styles.css";
import useMediaQuery from "../hooks/useMediaQuery";
import { maxSidenavWidth, miniSidenavWidth } from "../config/constants/data";
import { setMinisidenav } from "../store/actions/uiSettings";
import { leftMenu } from "../config/constants/menu";
import useUserRole from "../hooks/useUserRole";

const Sidebar = () => {
  const dispatch = useDispatch();
  const miniSideNav =
    useSelector((state) => state.uiSettings.miniSideNav) || false;
  const { isManager, isAuthenticated, isEditable } = useUserRole();

  let history = useHistory();

  // const [hoveredIndex, setHoveredIndex] = useState(null);

  // const handleItemMouseEnter = (index) => {
  //   setHoveredIndex(index);
  // };

  // const handleItemMouseLeave = () => {
  //   setHoveredIndex(null);
  // };

  const [state, setState] = useState({});
  const isMobile = useMediaQuery();
  const collapsed = miniSideNav || isMobile;

  useEffect(() => {
    onRouteChanged();
    // eslint-disable-next-line
  }, [history]);

  // useEffect(() => {
  //   dispatch(setMinisidenav(isMobile));
  //   // eslint-disable-next-line
  // }, [isMobile]);

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(state).forEach((i) => {
      setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/statistics", state: "statisticsMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setState({ [obj.state]: true });
      }
    });
  };
  const isVisible = (menu) => {
    return (
      menu.isAuthenticated === isAuthenticated ||
      menu.isManager === isManager ||
      menu.isEditable === isEditable
    );
  };
  const isPathActive = (path) => {
    if (!path) {
      return (
        !history.location.pathname.includes("/edit/") &&
        !history.location.pathname.includes("/view/") &&
        !history.location.pathname.includes("/profile") &&
        !history.location.pathname.includes("/users") &&
        !history.location.pathname.includes("/watchlist") &&
        !history.location.pathname.includes("/plans") &&
        !history.location.pathname.includes("/youtube-")
      );
    }
    if (typeof path === "string") {
      return history.location.pathname.includes(path);
    }
    try {
      return path(history.location.pathname);
    } catch {
      return false;
    }
  };

  const handleMouseEnter = () => {
    dispatch(setMinisidenav(false));
  };

  const handleMouseLeave = () => {
    dispatch(setMinisidenav(true));
  };

  return (
    <nav
      className="sidebar background-dark"
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={
        collapsed
          ? { width: miniSidenavWidth, transition: "0.2s all ease" }
          : { width: maxSidenavWidth, transition: "0.2s all ease" }
      }
    >
      <div
        className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top"
        style={
          collapsed
            ? { width: miniSidenavWidth, transition: "0.2s all ease" }
            : { width: maxSidenavWidth, transition: "0.2s all ease" }
        }
      >
        {!collapsed && (
          <div
            style={{ fontSize: 18, fontWeight: "bold", marginLeft: "-10px" }}
          >
            {/* {auth.me.role.toUpperCase()} */}
          </div>
        )}
        <div
          className="position-absolute w-100 pl-4"
          onClick={() => {
            dispatch(setMinisidenav(!miniSideNav));
          }}
        >
          {miniSideNav ? (
            <div className="d-flex align-items-center">
              <img
                src="/logo.png"
                width="25"
                height="25"
                className="logo-content align-left"
                alt=""
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <img
                src="/logo.png"
                width="25"
                height="25"
                className="logo-content align-left"
                alt=""
              />
              {/* <img
                src="/Metalyzer.png"
                className="brand-logo mr-3"
                style={{ width: "90px", height: "33px", marginLeft: "10px" }}
                alt="logo"
              /> */}
            </div>
          )}
        </div>
      </div>
      <ul
        className="nav"
        style={
          collapsed ? { width: miniSidenavWidth } : { width: maxSidenavWidth }
        }
      >
        {leftMenu.map(
          (menu, i) =>
            isVisible(menu) && (
              <li
                className={
                  isPathActive(menu.pathActive)
                    ? "nav-item menu-items active"
                    : "nav-item menu-items"
                }
                key={i}
              >
                <Link className="nav-link" to={menu.link}>
                  <span className="menu-icon mb-2">
                    <Icon
                      icon={menu.icon}
                      height="2rem"
                      width={miniSidenavWidth}
                      color={menu.color}
                    />
                  </span>
                  {collapsed ? null : (
                    <span className="menu-title">
                      <h3>
                        <Trans>{menu.title}</Trans>
                      </h3>
                    </span>
                  )}
                </Link>
              </li>
            )
        )}
      </ul>
    </nav>
  );
};

export default Sidebar;

