import React from "react";
import { Link, useLocation } from "react-router-dom";
import { sortString } from "../../../../../../utils/common";
// import ReactTooltip from 'react-tooltip';

import "../../styles.css";

const MainLink = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search).toString();

  const {
    path,
    state,
    text,
    image,
    alignment,
    width = 500,
    summary = null,
  } = props;
  const className = alignment
    ? `all-website-item-summary-a d-flex justify-content-${alignment} align-items-center link-item-${width}`
    : `all-website-item-summary-a d-flex justify-content-start align-items-center link-item-${
        summary ? "null" : width
      }`;
  return (
    <Link
      className={className}
      to={{
        pathname: path,
        state: state,
        search: searchParams,
      }}
      data-tip={text}
    >
      {alignment && alignment === "end" ? (
        <>
          <div>{sortString(text)}</div>
          {image && (
            <img
              src={image}
              alt=""
              width={30}
              height={30}
              loading="lazy"
              className="ml-3"
              style={
                text === "FIFA 23 Ultimate Team"
                  ? { borderRadius: "unset", height: "unset" }
                  : {}
              }
              // onError={(error) => { error.target.src = require('../../../../../../assets/icons/discord-logo.ico') }}
            />
          )}
        </>
      ) : (
        <>
          {image && (
            <img
              src={image}
              alt=""
              width={30}
              height={30}
              loading="lazy"
              className={summary ? `mr-3 all-website-logo` : `mr-3 `}
              style={
                text === "FIFA 23 Ultimate Team"
                  ? { borderRadius: "unset", height: "unset" }
                  : {}
              }
              // onError={(error) => { error.target.src = require('../../../../../../assets/icons/discord-logo.ico') }}
            />
          )}
          {summary ? (
            <div className="d-flex flex-column align-items-start">
              <p className="mb-0 all-website-item-title">{sortString(text)}</p>
              <p className="mb-0 all-website-item-summary">{summary}</p>
            </div>
          ) : (
            <div>{sortString(text)}</div>
          )}
        </>
      )}
    </Link>
  );
};

export default MainLink;

