import axios from "axios";
import { all, fork, takeLatest, call, put, select } from "redux-saga/effects";
import {
  FETCH_MAIN_MODEL_SUCCESS,
  MAIN_MODEL_FAILURE,
  FETCH_MAIN_MODEL_REQUEST,
} from "../types";

import { getHeadersWithToken } from "../actions/authActions";
import { params2URL } from "../../utils/common";

const getToken = (state) => state.auth.token;

const fetchMainModelPure = async (params, token) => {
  try {
    const response = await axios.get(params2URL(params), getHeadersWithToken(token));
    return response;
  } catch (err) {
    console.log("fetchMainModelPure Error.", err);
  }
};
function* fetchMainModel(action) {
  try {
    const token = yield select(getToken);
    const response = yield call(fetchMainModelPure, action.payload, token); // passing action.payload as params
    yield put({
      type: FETCH_MAIN_MODEL_SUCCESS,
      payload: {
        data:
          response.data?.results === undefined
            ? response.data
            : response.data?.results,
        count: response.data?.count,
      },
    });
  } catch (e) {
    console.error("error code: ", e);
    yield put({
      type: MAIN_MODEL_FAILURE,
      payload: { error: e.message },
    });
  }
}

function* watcherFetchMainModel() {
  yield takeLatest(FETCH_MAIN_MODEL_REQUEST, fetchMainModel);
}

export default function* fetchMainModelSaga() {
  yield all([fork(watcherFetchMainModel)]);
}
