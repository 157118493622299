import React from "react";
import { ToastContainer, Flip } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Layout from "../../layout/Layout";

import LinkButtons from "../../components/LinkButtons";
import MainTable from "../../components/Tables/MainTable";
import { useLocation, useParams } from "react-router-dom";

import {
  YOUTUBE_CATEGORY,
  YOUTUBE_CHANNELS_FILTER_MODAL_FIELDS,
} from "../../config/constants/data";
import {
  YOUTUBE_CHANNELS_TABLE_HEADER,
  YOUTUBE_CHANNELS_MAIN_TABLE_API,
} from "../../config/constants/tableHeaders";

const YoutubeChannels = () => {
  const { keyword } = useParams();
  const location = useLocation();
  // let filters = window.advancedFilters || {};
  delete window.advancedFilters;
  let filters = {};
  YOUTUBE_CHANNELS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });
  filters = { ...filters, ...location.state };

  return (
    <Layout>
      {/* <Breadcrumb
        parentLink="/youtube/"
        parentLabel="Youtube"
        currentLabel="Channels"
      />
      <PageTitle
        title="Youtube Channels Page."
        description=" metalyzer shows all youtube channels for all projects."
      /> */}
      <div className="tabs align-items-center mb-3">
        {/* <div className="category_divide" /> */}
        <LinkButtons linkItems={YOUTUBE_CATEGORY} activeItem={"channel"} />
      </div>
      <MainTable
        tableHeader={YOUTUBE_CHANNELS_TABLE_HEADER}
        URL={YOUTUBE_CHANNELS_MAIN_TABLE_API}
        withTermsFilter
        // withoutFilterModal
        projectNickName={keyword}
        filters={filters}
        filterModalFields={YOUTUBE_CHANNELS_FILTER_MODAL_FIELDS}
        model={"youtube-channels"}
        withoutTopicFilter
        withCategoryFilter
        modelName="youtube-channel"
      />
      <ToastContainer theme="dark" transition={Flip} />
    </Layout>
  );
};

export default YoutubeChannels;

