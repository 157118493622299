import * as Yup from 'yup';

export const profileSchema = Yup.object({
  first_name: Yup.string()
    .min(2, 'Must be 2 characters at minimum')
    .max(30, 'Must be 30 characters or less')
    // .matches(/^[a-zA-Z]+$/, 'Invalid characters in First name')
    .required(),
  // last_name: Yup.string()
  //   .min(2, 'Must be 2 characters at minimum')
  //   .max(30, 'Must be 30 characters or less')
  //   .matches(/^[a-zA-Z]+$/, 'Invalid characters in Last name')
  //   .required(),
  email: Yup.string().email('Invalid email address').required('Required')
});
