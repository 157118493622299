import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import useUserRole from "../../../hooks/useUserRole";

const SubGeneralView = ({ height, text, subLimitation=2}) => {
  const { subscription } = useUserRole();

  const extractLimitedContent = (content) => {
    const words = content.split(" ");
    const limit = Math.min(20, Math.floor(words.length * 0.1));
    const visibleWords = words.slice(0, limit);
    const asterisks = new Array(words.length - limit).fill("*");
    return [...visibleWords, ...asterisks].join(" ");
  };

  const redirectToPricing = () => {
    window.location.href = "/plans"; // Modify this to the correct route for your pricing page.
  };
  const renderContent = () => {
    if (typeof text === "string") {
      return subscription < subLimitation ? extractLimitedContent(text) : text;
    }
  };

  return (
    <Container>
      <Row>
        <Col
          style={{
            position: "relative",
            height: height,
            overflowY: subscription < subLimitation ? "hidden": "scroll",
            marginTop: "12px",
            fontSize: "14px",
            wordWrap: "break-word",
          }}
          className="pr-0 pl-0"
        >
          <div>{renderContent()}</div>
          {subscription < subLimitation && (
            <div
              style={{
                position: "absolute",
                top: "10%",
                left: 0,
                right: 0,
                bottom: 0,
                background: "rgba(23, 23, 26, 0.5)",
                backdropFilter: "blur(5px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ zIndex: 2, borderRadius: 6 }}
                size="lg"
                onClick={redirectToPricing}
              >
                Buy Premium
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SubGeneralView;
