import React from "react";
import { FaSearch } from "react-icons/fa";
import "./styles.scss";

const SearchComponent = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.handleSearch(props.search);
    }
  };
  return (
    <div className="form-control search-item mt-md-0 d-flex align-items-center search mx-2">
      <FaSearch size={14} />
      <input
        type="text"
        className="form-control search-input"
        placeholder="Search"
        onChange={(e) => props.setSearch(e.target.value)}
        value={props.search}
        onKeyDown={handleKeyDown}
      />
      {/* <button type="button" className="btn btn-default mx-1 search-item" onClick={props.handleSearch}>
        <FaSearch size={15}/>
      </button> */}
    </div>
  );
};

export default SearchComponent;
