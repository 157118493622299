import React, { useMemo } from "react";
import {
  secondsToTimeString,
  timeStringToSeconds,
} from "../../../../../utils/common";

const FilterModalField = ({ label, field, filters, setFilters, inputType }) => {
  const handleInputChange = (e) => {
    const value =
      inputType === "time"
        ? timeStringToSeconds(e.target.value)
        : e.target.value;
    // Additional validation can be added here if needed
    let fieldState = {};
    fieldState[field] = value;
    setFilters({ ...filters, ...fieldState });
  };

  const fieldValue = useMemo(() => {
    if (inputType !== "time") return filters[field] || "";
    return secondsToTimeString(filters[field] || 0);
  }, [filters]);

  const getInputType = () => {
    // Return 'number' for number type, else default to 'text'
    return inputType === "number" ? "number" : "text";
  };

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={field} className="mb-2" style={{ fontSize: 14 }}>
          {label}
        </label>
      )}
      <input
        id={field}
        type={getInputType()}
        className="form-control"
        style={{ textAlign: "center" }} // Adjusted fontSize and added height
        onChange={handleInputChange}
        {...(inputType === "time"
          ? { pattern: "^d+:(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])$" }
          : {})}
        value={fieldValue}
      />
    </div>
  );
};

export default FilterModalField;

