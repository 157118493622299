import {
  SET_MINISIDENAV,
  SET_TERMS,
  SET_PAGE_SIZE,
  SET_TOPIC_TYPE,
  SET_TABLE_TYPE,
  SET_SEARCH_TEXT,
  SET_SEARCH_TEXT_GLOBAL,
} from "../types";

export const setMinisidenav = (miniSideNav) => ({
  type: SET_MINISIDENAV,
  payload: miniSideNav,
});

export const setTerms = (terms) => ({
  type: SET_TERMS,
  payload: terms,
});

export const setPageSize = (pageSize) => ({
  type: SET_PAGE_SIZE,
  payload: pageSize,
});

export const setWebsiteTopicType = (topicType) => ({
  type: SET_TOPIC_TYPE,
  payload: topicType,
});

export const setTableType = (tableType) => ({
  type: SET_TABLE_TYPE,
  payload: tableType,
});

export const setSearchText = (searchText) => ({
  type: SET_SEARCH_TEXT,
  payload: searchText,
});

export const setSearchTextGlobal = (searchText) => ({
  type: SET_SEARCH_TEXT_GLOBAL,
  payload: searchText,
});

