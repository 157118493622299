import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";

import { Form, Row, Col } from "react-bootstrap";

import { addProjectSchema } from "./validation";
import { createMainModel } from "../../../../../store/actions/mainModelActions";

const AddProjectModal = (props) => {
  const { show, setShowModal } = props;
  const dispatch = useDispatch();
  const [iconImage, setIconImage] = useState(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      nickname: "",
      icon: null,
      description: "",
      youtubeLink: "",
      twitterLink: "",
      discordLink: "",
      siteLink: "",
    },
    validationSchema: addProjectSchema,
    onSubmit: async (values) => {
      values.icon = iconImage;
      values.official_links = {
        youtube: values.youtubeLink,
        twitter: values.twitterLink,
        discord: values.discordLink,
        official: values.siteLink,
      };
      let formDataValues = new FormData();
      formDataValues.append("name", values.name);
      formDataValues.append("nickname", values.nickname);
      if (values.icon !== null) formDataValues.append("icon", values.icon);
      formDataValues.append("description", values.description);
      formDataValues.append(
        "official_links",
        JSON.stringify(values.official_links)
      );
      dispatch(createMainModel('project/project/', formDataValues));
      setShowModal(false);
    },
  });

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // aria-labelledby="filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ fontSize: 18, textAlign: "center", width: "100%" }}
        >
          Add Project
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="pt-3" onSubmit={formik.handleSubmit}>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Name</div>
            <Form.Control
              type="text"
              size="lg"
              placeholder="Enter Project Name ..."
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="text-danger">{formik.errors.name}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Nick Name</div>
            <Form.Control
              type="text"
              size="lg"
              placeholder="Enter Project Nick Name ..."
              name="nickname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nickname}
            />
            {formik.touched.nickname && formik.errors.nickname ? (
              <p className="text-danger">{formik.errors.nickname}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Icon</div>
            {/* <Form.Control
              style={{ color: '#3fff3f' }}
              type='file'
              name='icon'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.icon}
            /> */}
            <input
              id="file"
              name="file"
              type="file"
              onChange={(event) => {
                setIconImage(event.currentTarget.files[0]);
              }}
            />
            {/* {formik.touched.icon && formik.errors.icon ? (
              <p className="text-danger">{formik.errors.icon}</p>
            ) : null} */}
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Description</div>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Enter Project Description ..."
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            {formik.touched.description && formik.errors.description ? (
              <p className="text-danger">{formik.errors.description}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Youtube Official Link</div>
            <Form.Control
              type="text"
              size="lg"
              placeholder="Enter Project Youtube Official Link ..."
              name="youtubeLink"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.youtubeLink}
            />
            {formik.touched.youtubeLink && formik.errors.youtubeLink ? (
              <p className="text-danger">{formik.errors.youtubeLink}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Twitter Official Link</div>
            <Form.Control
              type="text"
              size="lg"
              placeholder="Enter Project Twitter Official Link ..."
              name="twitterLink"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.twitterLink}
            />
            {formik.touched.twitterLink && formik.errors.twitterLink ? (
              <p className="text-danger">{formik.errors.twitterLink}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Discord Official Link</div>
            <Form.Control
              type="text"
              size="lg"
              placeholder="Enter Project Discord Official Link ..."
              name="discordLink"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.discordLink}
            />
            {formik.touched.discordLink && formik.errors.discordLink ? (
              <p className="text-danger">{formik.errors.discordLink}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Site Link</div>
            <Form.Control
              type="text"
              size="lg"
              placeholder="Enter Project Site Link ..."
              name="siteLink"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.siteLink}
            />
            {formik.touched.siteLink && formik.errors.siteLink ? (
              <p className="text-danger">{formik.errors.siteLink}</p>
            ) : null}
          </Form.Group>

          <Row className="mt-4">
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  disabled={!formik.isValid}
                  type="submit"
                >
                  Add
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {/* <Modal.Footer>
        
      </Modal.Footer> */}
    </Modal>
  );
};

export default AddProjectModal;
