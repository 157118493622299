import React from "react";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";

import { selectionStyle } from "../styles";

const compare = (a, b) => {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
};

function MainSelection({
  title,
  selectionItems,
  defaultItem,
  setItem,
  updateWithItem = false,
  paddingLeftZero = false,
  sorted = true,
  width = 75,
  height = 38,
  classNamePrefix = "main-select",
}) {
  let customStyle = selectionStyle(width, height);
  customStyle.menuList = (styles) => ({
    ...styles,
    backgroundColor: "#323546",
    textAlign: "center",
    width: "100%",
    minWidth: width + "px",
    left: 0,
  });

  return (
    <>
      {title && (
        <Row>
          <Col>
            <label htmlFor="#" className="mb-0 text-white-50 text-nowrap ml-1">
              {title}
            </label>
          </Col>
        </Row>
      )}
      <Row>
        <Col style={paddingLeftZero ? { paddingLeft: 0 } : {}}>
          {selectionItems && selectionItems.length && (
            // <>
            //   {
            //     selectionItems.map((v, i) => {
            //       return (
            //         <div>{JSON.stringify(v)}</div>
            //       )
            //     })
            //   }
            // </>
            <Select
              value={defaultItem}
              onChange={(value) => {
                setItem(updateWithItem ? value : value?.value);
              }}
              options={
                sorted ? [...selectionItems.sort(compare)] : [...selectionItems]
              }
              styles={customStyle}
              isSearchable={true}
              className="mx-1"
              classNamePrefix={classNamePrefix}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default MainSelection;
