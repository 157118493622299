import React from "react";
import MainSelection from "../../../Selections/MainSelection";
import { SEARCH_CATEGORIES, DEFAULT_SEARCH_CATEGORY } from "../../../../config/constants/data";

const SearchCategoryFilter = (props) => {
  const { searchCategory, setSearchCategory } = props;
  let defaultValue = SEARCH_CATEGORIES.filter((ele) => {
    return ele.value === searchCategory;
  });
  let items = SEARCH_CATEGORIES;
  
  const defaultItem = defaultValue.length > 0 ? defaultValue[0] : DEFAULT_SEARCH_CATEGORY;

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <MainSelection
        title=""
        selectionItems={items}
        defaultItem={defaultItem}
        setItem={setSearchCategory}
        sorted={false}
        width={80}
      />
    </div>
  );
};

export default SearchCategoryFilter;
