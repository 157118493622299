import { DISCORD_ASSETS_LINK } from "../config/constants";
import { API_HOST } from "../config/constants/data";

export const encodeRoute = (route) => {
  return route.toLowerCase().replace(' ', '-');
}

export const decodeRoute = (route) => {
  return route.replace('-', ' ');
}

export const convertNick2Name = (projectNames, nickName) => {
  if (nickName === '' || nickName === 'all' || nickName === undefined ) return 'All';
  const projectObj = projectNames?.length ? projectNames?.filter(element => element.nickname === nickName) : []
  if (projectObj?.length > 0 ) return projectObj[0].name;
  else return 'notfound';
};

export const convertID2Name = (projectNames, ID) => {
  if (ID === '' || ID === 'all' || ID === undefined ) return 'Other';
  const projectObj = projectNames?.length ? projectNames?.filter(element => element.project_id === ID.toString()) : []
  if (projectObj?.length > 0 ) return projectObj[0].name;
  else return 'Other';
};
export const convertID2Nick = (projectNames, ID) => {
  if (ID === '' || ID === 'all' || ID === undefined ) return 'other';
  const projectObj = projectNames?.length ? projectNames?.filter(element => element.project_id === ID.toString()) : []
  if (projectObj?.length > 0 ) return projectObj[0].nickname;
  else return 'other';
};

export const convertNick2ID = (projectNames, nickName) => {
  if (nickName === '' || nickName === 'all' || nickName === undefined ) return '0';
  const projectObj = projectNames?.filter(element => element.nickname === nickName);
  if (projectObj?.length > 0 ) return projectObj[0].project_id;
  else return '0';
};

export const convertName2Nick = (projectNames, name) => {
  if (name === '' || name === 'All' || name === undefined ) return 'all';
  const projectObj = projectNames?.filter(element => element.name === name);
  if (projectObj?.length > 0 ) return projectObj[0].nickname;
  else if (name === "CHATGPT") return 'chatgpt'
  else return 'notfound';
};

export const getTopicOptions = (topics, tableModelId=1, withNoTopic=false) => {
  const compare = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }
  let options = topics?.filter(
    topic => { return topic?.model === tableModelId; }
  ).map(topic => {
    return { label: topic.label, value: topic.id }
  });
  options = withNoTopic ? [{ label: "Topic", value: -1 }, { label: "No Topic", value: 0 }, ...options.sort(compare)]
    : [{ label: "Topic", value: -1 }, ...options.sort(compare)];
  if (tableModelId !== 5 && tableModelId !== 8 && withNoTopic)
    options.splice(2, 0, { label: "Blacklist", value: -2})
  if (tableModelId === 8 && !withNoTopic){
    options= options.filter(option => {
      return option.label !== 'News' && option.label !== 'Social Media' && (withNoTopic || option.value > 0);
    })
    options.splice(0, 0, { label: "All", value: "all"})
  }
  return options
}

export const getTopicOption = (topics, label, tableModelId=1) => {
  let options = topics?.length ? topics.filter(
    topic => { return topic?.model === tableModelId && topic?.label?.toLowerCase() === label?.toLowerCase(); }
  ) : []
  options = options.map(topic => {
    return { label: topic.label, value: topic.id }
  });
  if (options.length > 0)
    return options[0]
  else if (label === "all")
    return { label: "All", value: "all" }
  else
    return { label: "Topic", value: -1 }
}

export function newWebsitePathFromTopic(originalPath, topic) {
  // If the original path ends with '/', remove the trailing '/'
  if (originalPath.endsWith('/')) {
    originalPath = originalPath.slice(0, -1);
  }

  // Check if the path contains '/websites' or '/website'
  let basePart = originalPath.includes("/websites/") ? "/websites/" : (originalPath.includes("/website/") ? "/website/" : null);

  // If there's a match, split on that part, otherwise return the original path appended with the topic
  if (basePart) {
    let parts = originalPath.split(basePart);
    return `${parts[0]}${basePart}${topic==="Topic"?"":topic?.toLowerCase()}`;
  } else {
    return `${originalPath}/${topic==="Topic"?"":topic?.toLowerCase()}`;
  }
}
export function newSocialPathFromDomain(originalPath, domain) {
  // If the original path ends with '/', remove the trailing '/'
  if (originalPath.endsWith('/')) {
    originalPath = originalPath.slice(0, -1);
  }

  let basePart = (originalPath.includes("/social/") ? "/social/" : null);
  // If there's a match, split on that part, otherwise return the original path appended with the topic
  if (basePart) {
    let parts = originalPath.split(basePart);
    return `${parts[0]}${basePart}${domain==="website"?"":domain?.toLowerCase()}`;
  } else {
    return `${originalPath}/${domain==="website"?"":domain?.toLowerCase()}`;
  }
}

export const getIconFromSearchItem = (item) => {
  switch(item.model_id) {
    case "100": case "8":
      return `${API_HOST}/media/${item.icon}`;
    case "5": // discord server icon
      return `${DISCORD_ASSETS_LINK}icons/${item?.id?.toString()}/${item?.icon?.toString()}.png`;
    default: // include youtube channel
      return item.icon;
  }
}

export const getIconFromMedia = (item) => {
  if (item === null) return null;
  return `${API_HOST}/media/${item}`;
}

export const getProjectIcon = (projectItems, item) => {
  // Filter the array to find the element with id = 2
  const filteredArray = projectItems.filter(function(projectItem) {
    return projectItem.name === item.keyword;
  });

  // If the element is found, select it
  if (filteredArray.length > 0) {
    return getIconFromMedia(filteredArray[0]?.icon);
  } else {
    return null;
  }
}

export const getYoutubeChannelIcon = (channelItems, channelId) => {
  const filteredArray = channelItems.filter(function(channeltItem) {
    return channeltItem.id === channelId;
  });

  // If the element is found, select it
  if (filteredArray.length > 0) {
    return filteredArray[0]?.icon;
  } else {
    return null;
  }
}

export const getLinkFromSearchItem = (item) => {
  switch(item.model_id) {
    case "100":
      return `/project/${item.id}`;
    case "1":
      return `/youtube/channel/${item.id}`;
    case "5":
      return `/discord/server/${item.id}`;
    case "8":
      return `/website/${item.id}`;
    case "10":
      return `/telegram/group/${item.id}`;
    default:
      return `/project/${item.id}`;
  }
} 