import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
// import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import { Icon } from "@iconify/react";
import "./VideoModal.scss";
import SubGeneralView from "../../../Subscription/SubGeneralView";

const VideoModal = ({ openVideoRowIndex, data, show, onHide }) => {
  // const { data } = useSelector((state) => state.mainModel);
  const playerRef = useRef(null);
  const [videoWidth, setVideoWidth] = useState("100%");
  const [videoHeight, setVideoHeight] = useState("auto");
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [activeKey, setActiveKey] = useState("description");
  const [currentVideoIndex, setCurrentVideoIndex] = useState(openVideoRowIndex);
  const [openVideoRow, setOpenVideoRow] = useState({});
  useEffect(() => {
    setCurrentVideoIndex(openVideoRowIndex);
    setOpenVideoRow(data?.[openVideoRowIndex]);
  }, [openVideoRowIndex, data]);

  const handlePlayerReady = () => {
    setIsPlayerReady(true);
  };
  const handleEnd = () => {
    handleNextVideo();
  };
  const closeModal = () => {
    setIsPlayerReady(false);
    onHide();
  };
  const handlePreviousVideo = () => {
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex(currentVideoIndex - 1);
      setOpenVideoRow(data?.[currentVideoIndex - 1]);
    }
  };

  const handleNextVideo = () => {
    if (currentVideoIndex < data?.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
      setOpenVideoRow(data?.[currentVideoIndex + 1]);
    }
  };

  const isMobile = useMediaQuery();
  const updateVideoSize = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const modalPadding = 10 * 2;
    const modalWidthRatio = isMobile ? 0.9 : 0.5;
    // const aspectRatio = 16 / 9; // Default 16:9 aspect ratio

    // Fetch the video's natural dimensions
    const videoElement = playerRef.current.getInternalPlayer();
    const naturalWidth = videoElement?.videoWidth || 16;
    const naturalHeight = videoElement?.videoHeight || 9;

    // Calculate adjusted width and height based on aspect ratio and screen size
    let newWidth, newHeight;
    newWidth = screenWidth * modalWidthRatio - (isMobile ? 0 : modalPadding);
    newHeight = screenHeight - (isMobile ? 0 : modalPadding);
    // newWidth = newWidth;
    newHeight = newWidth / (naturalWidth / naturalHeight);

    setVideoWidth(newWidth);
    setVideoHeight(newHeight);
  };

  useEffect(() => {
    if (isPlayerReady) {
      updateVideoSize();
      window.addEventListener("resize", updateVideoSize);
      return () => {
        window.removeEventListener("resize", updateVideoSize);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [isPlayerReady]);

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      setDivHeight(divRef.current.offsetHeight);
    }
  }, [divRef, isPlayerReady, currentVideoIndex]);

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
      centered
      className="videoModal"
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>
          <span style={{ fontSize: "18px", color: "#3861FB" }}>
            {openVideoRow?.channelTitle}
          </span>
          <span
            style={{ fontSize: "12px", color: "#A9A9A9", marginLeft: "5px" }}
          >
            {"  published at "} {openVideoRow?.publishedAt}
          </span>
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <Row>
          <Col xl="9">
            <div className="player-container">
              <ReactPlayer
                ref={playerRef}
                url={`https://www.youtube.com/watch?v=${openVideoRow?.videoId}`}
                width={videoWidth}
                height={videoHeight}
                onReady={handlePlayerReady}
                playing
                controls
                onEnded={handleEnd}
              />
              {currentVideoIndex > 0 && (
                <div
                  className="prev-next-button left"
                  onClick={handlePreviousVideo}
                >
                  <Icon
                    icon="ic:sharp-skip-previous"
                    height="6rem"
                    color="white"
                  />
                </div>
              )}
              {currentVideoIndex < data?.length - 1 && (
                <div
                  className="prev-next-button right"
                  onClick={handleNextVideo}
                >
                  <Icon icon="ic:sharp-skip-next" height="6rem" color="white" />
                </div>
              )}
            </div>
          </Col>
          <Col xl="3">
            <div ref={divRef} className="mt-0">
              <span style={{ fontSize: "18px", color: "#3861FB" }}>
                {openVideoRow?.channelTitle}
              </span>
              <span
                style={{
                  fontSize: "12px",
                  color: "#A9A9A9",
                  marginLeft: "5px",
                }}
              >
                {openVideoRow?.publishedAt}
              </span>
              <div
                onClick={() => onHide()}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: isMobile ? 5 : -15,
                  right: isMobile ? 0 : -15,
                }}
              >
                <Icon
                  icon="mingcute:close-fill"
                  height="2rem"
                  className="mr-3 mb-1"
                />
              </div>
            </div>
            <Tabs
              activeKey={activeKey}
              id="uncontrolled-tab-example"
              onSelect={(key) => setActiveKey(key)}
              className="tabs-bottom mt-3"
            >
              <Tab eventKey="description" title="Description">
                <Container>
                  <Row>
                    <Col
                      style={{
                        height: videoHeight - divHeight - 97,
                        overflowY: "scroll",
                        marginTop: "12px",
                        fontSize: "14px",
                        wordWrap: "break-word",
                      }}
                      className="pr-0 pl-0"
                    >
                      <div>{openVideoRow?.description?.replace('"', "")}</div>
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="summary" title="Summary">
                <SubGeneralView 
                  height={videoHeight - divHeight - 97}
                  text={openVideoRow?.description?.replace('"', "")}
                  >
                </SubGeneralView>
              </Tab>
              <Tab eventKey="comments" title="Comments">
                <div>Comments</div>
              </Tab>
            </Tabs>
            <div className="d-flex flex-row justify-content-center align-items-center control-panel">
              <div
                className={`control-button left ${currentVideoIndex > 0 ? '' : 'disabled'}`}
                onClick={handlePreviousVideo}
              >
                <Icon
                  icon="ic:sharp-skip-previous"
                  height="2.5rem"
                  color="white"
                />
              </div>
              {currentVideoIndex + 1}
              <div className={`control-button right ${currentVideoIndex < data?.length - 1 ? '' : 'disabled'}`} onClick={handleNextVideo}>
                <Icon icon="ic:sharp-skip-next" height="2.5rem" color="white" />
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
