import React from 'react'
import { BsColumnsGap } from 'react-icons/bs';
import { CheckboxDropdown } from '../../../CheckboxDropdown/CheckboxDropdown';

const HeaderCheckDropdown = (props) => {
  const { header, setHeader } = props
  // Checkbox Dropdown -- start
  const handleChecked = (key, event) => {
    header.find((i) => i.title === key).checked = event.target.checked;
    setHeader([...header]);
  };

  const handleSelectAll = () => {
    header.forEach((i) => (i.checked = true));
    setHeader([...header]);
  };

  const handleSelectNone = () => {
    header.forEach((i) => (i.checked = false));
    setHeader([...header]);
  };
  return (
    <div className='mx-1'>
      <CheckboxDropdown
        items={header}
        title="Customize"
        icon={<BsColumnsGap />}
        handleChecked={handleChecked}
        handleSelectAll={handleSelectAll}
        handleSelectNone={handleSelectNone}
      />
    </div>
  )
}

export default HeaderCheckDropdown