import React from "react";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";

function TrendPercentage({ currentValue, pastValue, size = 14, style, mark="%" }) {
  const value =
    pastValue === 0 || pastValue === "0" || pastValue === undefined
      ? 0
      : (
          ((parseFloat(currentValue) - parseFloat(pastValue)) /
            parseFloat(pastValue)) *
          100
        ).toFixed(2);
  return (
    <span
      style={
        style
          ? { ...style, color: value >= 0 ? "#16C784" : "#EA3943" }
          : { color: value >= 0 ? "#16C784" : "#EA3943" }
      }
    >
      {value >= 0 ? (
        <BsFillCaretUpFill size={size} />
      ) : (
        <BsFillCaretDownFill size={size} />
      )}
      <span className="ml-1">{value}{mark}</span>
    </span>
  );
}

export default TrendPercentage;
