import {
  GET_TOPICS_LOADING,
  GET_TOPICS_SUCCESS,
  GET_TOPICS_FAIL,
} from '../types';

const initialState = {
  topics: [],
  topicsCount: 0,
  topicsLoading: false,
  topicserror: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_TOPICS_LOADING:
      return {
        ...state,
        topicsLoading: true,
        topicserror: null,
      };
    case GET_TOPICS_SUCCESS:
      return {
        ...state,
        topics: payload.data,
        topicsCount: payload.count,
        topicsLoading: false,
        topicserror: null,
      };
    case GET_TOPICS_FAIL:
      return {
        ...state,
        topics: [],
        topicsCount: 0,
        topicsLoading: false,
        topicserror: payload?.error
      };
    default:
      return state;
  }
}
