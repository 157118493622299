import React from "react";
import Layout from "../../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import TopSummary from "./Components/TopSummary";
import LinksDomainDetailsRouting from "./Components/LinksDomainDetailsRouting";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { getEveryMinuteApi } from "../../store/actions/getEveryMinuteApiAction";
import {
  WEBSITE_DOMAIN_DETAILS_API,
  WEBSITE_DOMAIN_HIGHLIGHT_API,
  WEBSITE_USERS_RANDOM_TABLE_API,
  WEBSITE_USERS_TABLE_HEADER,
} from "../../config/constants/tableHeaders";
import { getHighlightData } from "../../store/actions/getHighLightApiAction";
import SimpleTable from "../../components/Tables/SimpleTable";

export const RecommendItems = () => (
  <div className="mt-5 w-100 flex flex-column">
    <div className="text-center topnews-card-title mb-3">
      Recommend Websites
    </div>
    <SimpleTable
      header={WEBSITE_USERS_TABLE_HEADER.filter(({ value }) =>
        ["bookmark", "index", "domain", "micro_summary", "sum_score"].includes(
          value
        )
      )}
      URL={WEBSITE_USERS_RANDOM_TABLE_API}
      tableModelId="websites"
      modelName="project-website-link--nickName-topic"
    />
  </div>
);

const LinksDomainDetails = () => {
  const { domain } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedRoute, setSelectedRoute] = useState("overview");

  const { everyMinuteData, everyMinuteApiLoading } = useSelector(
    (state) => state.everyMinuteState
  );

  const [detailsData, setDetailsData] = useState({});
  const [hasApiBeenCalled, setHasApiBeenCalled] = useState(false);
  useEffect(() => {
    if (domain === undefined) {
      history.push(`/websites/`);
    }
    setHasApiBeenCalled(false);
    dispatch(
      getEveryMinuteApi({
        URL: WEBSITE_DOMAIN_DETAILS_API + `${domain}`,
      })
    );
    setHasApiBeenCalled(true);
    dispatch(
      getHighlightData({
        URL: WEBSITE_DOMAIN_HIGHLIGHT_API + `${domain}`,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  useEffect(() => {
    try {
      if (
        !everyMinuteApiLoading &&
        hasApiBeenCalled &&
        ((Array.isArray(everyMinuteData) && everyMinuteData.length === 0) ||
          (typeof everyMinuteData === "object" &&
            Object.keys(everyMinuteData).length === 0))
      ) {
        history.push("/notfound");
      }
      setDetailsData(everyMinuteData);
    } catch (e) {
      console.log("error: ", e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [everyMinuteData, everyMinuteApiLoading, hasApiBeenCalled]);

  return (
    <Layout>
      <TopSummary
        detailsData={detailsData}
        selectedRoute={selectedRoute}
        setSelectedRoute={setSelectedRoute}
      ></TopSummary>
      {/* <TopnewsCards detailsData={detailsData} /> */}
      <LinksDomainDetailsRouting
        detailsData={detailsData}
        setSelectedRoute={setSelectedRoute}
      />
    </Layout>
  );
};

export default LinksDomainDetails;
