import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import FaqItem from "./FaqItem";
import { faqData } from "./dummydata";
import "./styles.css";

const Faq = (props) => {
  // const { setSelectedRoute } = props;
  const { domain } = useParams();
  const [activeFaqIndex, setActiveFaqIndex] = useState(null);
  const onFaqClick = (index) => () => {
    if (activeFaqIndex === index) {
      setActiveFaqIndex(null);
    } else {
      setActiveFaqIndex(index);
    }
  };
  useEffect(() => {
    // setSelectedRoute("faq");
    // eslint-disable-next-line
  }, []);
  return (
    <Row className="justify-content-center">
      <Col xs={12} md={12} className="p-3">
        <h2 className="faq-title">{"FAQ for " + domain}</h2>
        {faqData.map((faq, index) => {
          return (
            <FaqItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              onFaqClick={onFaqClick(index)}
              isOpen={activeFaqIndex === index}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default Faq;
