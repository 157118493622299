import React, { useEffect } from "react";

import MainTable from "../../../../components/Tables/MainTable";
import { useLocation, useParams } from "react-router-dom";

import {
  YOUTUBE_CHANNELS_TABLE_HEADER,
  YOUTUBE_CHANNELS_MAIN_TABLE_API,
} from "../../../../config/constants/tableHeaders";
import { RecommendItems } from "../..";
import { YOUTUBE_CHANNELS_FILTER_MODAL_FIELDS } from "../../../../config/constants/data";

const YoutubeChannels = (props) => {
  const { setSelectedRoute } = props;
  const { nickName } = useParams();
  const location = useLocation();
  useEffect(() => {
    setSelectedRoute("channels");
  }, [setSelectedRoute]);

  let filters = window.advancedFilters || {};
  YOUTUBE_CHANNELS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });
  filters = { ...filters, ...location.state };

  return (
    <>
      <MainTable
        tableHeader={YOUTUBE_CHANNELS_TABLE_HEADER}
        URL={YOUTUBE_CHANNELS_MAIN_TABLE_API}
        filters={filters}
        filterModalFields={YOUTUBE_CHANNELS_FILTER_MODAL_FIELDS}
        withTermsFilter
        withoutProjectFilter
        projectNickName={nickName}
        defaultTerm={"7d"}
        model={"youtube-channels"}
        withoutTopicFilter
        modelName="project-youtube-channel--nickName"
      />
      <RecommendItems />
    </>
  );
};

export default YoutubeChannels;

