import React from "react";
import { useParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import TopnewsCardHeader from "./Header";
import { Img } from "react-image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import PatnerItem from "./PatnerItem";
import { responsiveCarousel } from "../../../../../utils/tableDataHelper";

const RecentAndTopPatners = ({ highlightData, vertical }) => {
  const { domain } = useParams();
  const recentPartners = highlightData?.["recent_partners"] ?? [];
  const mostPartners = highlightData?.["most_partners"] ?? [];
  if (recentPartners.length === 0 && mostPartners.length === 0) return <></>;
  return (
    <Col xs={12} md={vertical ? 12 : 4}>
      <div className="topnews-card">
        <Carousel
          responsive={responsiveCarousel}
          showDots={true}
          infinite={true}
          swipeable={false}
          draggable={false}
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={7000}
        >
          {recentPartners.length > 0 && (
            <div className="card-height-padding">
              <TopnewsCardHeader
                leftComponent={
                  <div className="d-flex flex-row align-items-center">
                    <Img width={20} height={20} src="/images/addIcon.png" />
                    <span className="topnews-card-title">Recent Partners</span>
                  </div>
                }
                website={domain}
                route="partners"
                order="firstPromote"
              />
              <div>
                {recentPartners.map((item, index) => {
                  return <PatnerItem key={index} data={item} />;
                })}
              </div>
            </div>
          )}
          {mostPartners.length > 0 && (
            <div className="card-height-padding">
              <TopnewsCardHeader
                leftComponent={
                  <div className="d-flex flex-row align-items-center">
                    <span className="card-title-icon">⭐️</span>
                    <span className="topnews-card-title">
                      Top Partners Last 30 Days
                    </span>
                  </div>
                }
                website={domain}
                route="partners"
                order="subs"
              />
              <div>
                {mostPartners.map((item, index) => {
                  return (
                    <PatnerItem key={index} data={item} datetime={false} />
                  );
                })}
              </div>
            </div>
          )}
        </Carousel>
      </div>
    </Col>
  );
};

export default RecentAndTopPatners;

