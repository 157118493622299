import React from 'react'
import ProjectSelectionWithoutAll from '../../../Selections/ProjectSelectionWithoutAll';

const ProjectFilterWithoutAll = (props) => {
  const { keyword, setKeyword } = props;
  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <ProjectSelectionWithoutAll
        title=""
        project={keyword ? { label: keyword.label, value: keyword.value } : null}
        setKeyword={setKeyword}
      />
    </div>
  )
}

export default ProjectFilterWithoutAll
