import React from 'react'
import useMediaQuery from '../../../../hooks/useMediaQuery';
// import { Button } from 'react-bootstrap';

function VoteComponent({item, vote, setVoteValue, primaryKey, showVoteButton}) {
  const isMobile = useMediaQuery();
  if (showVoteButton) 
    return (
      <div className="d-flex align-items-center justify-content-end">
        <span>{vote.data[item?.[primaryKey]] ? vote.data[item?.[primaryKey]].sum_score : 0}</span>
        <div className="d-flex align-items-center justify-content-center flex-column ml-2"
          style={{height: '40px'}}
        >
          <div
            className={`btn btn-${vote.data[item?.[primaryKey]]?.user_score === '1' ? '' : 'outline-'}default btn-sm`}
            onClick={() => !isMobile && setVoteValue(1, item?.[primaryKey])}
            onTouchStart={() => isMobile && setVoteValue(1, item?.[primaryKey])}
            // disabled={vote.data[item?.[primaryKey]]?.user_score === '-1'}
          >
            &#x25B2;
          </div>
          <div
            className={`btn btn-${vote.data[item?.[primaryKey]]?.user_score === '-1' ? '' : 'outline-'}default btn-sm`}
            onClick={() => !isMobile && setVoteValue(-1, item?.[primaryKey])}
            onTouchStart={() => isMobile && setVoteValue(-1, item?.[primaryKey])}
            // disabled={vote.data[item?.[primaryKey]]?.user_score === '1'}
          >
            &#x25BC;
          </div>
        </div>
      </div>
    )
  else
    return (
      <div className="d-flex align-items-center justify-content-end">
        <span>{vote.data[item?.[primaryKey]] ? vote.data[item?.[primaryKey]].sum_score : 0}</span>
      </div>
    )     
}

export default VoteComponent