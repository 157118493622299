import React from 'react'
import EditProjectTable from '../../../../../components/Tables/EditProjectTable'
import { PROJECT_EDIT_TABLE_HEADER, PROJECT_EDIT_MAIN_TABLE_API } from '../../../../../config/constants/tableHeaders'

const Project = () => {
  
  return (
    <EditProjectTable
      tableHeader={PROJECT_EDIT_TABLE_HEADER}
      URL={PROJECT_EDIT_MAIN_TABLE_API}
      withoutFilterModal
      model={"project"}
    />
  )
}

export default Project