import React from 'react'
import EditDiscordServerInviteTable from '../../../../../components/Tables/EditDiscordServerInviteTable'
import { DISCORD_SERVERINVITE_EDIT_TABLE_HEADER, DISCORD_SERVERINVITE_EDIT_MAIN_TABLE_API } from '../../../../../config/constants/tableHeaders'

const ServerInvite = () => {
  
  return (
    <EditDiscordServerInviteTable
      tableHeader={DISCORD_SERVERINVITE_EDIT_TABLE_HEADER}
      URL={DISCORD_SERVERINVITE_EDIT_MAIN_TABLE_API}
      withoutFilterModal
      withoutAddButton
      model={"project"}
    />
  )
}

export default ServerInvite