import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LINK_DOMAINS_FILTER_MODAL_FIELDS } from "../../../../config/constants/data";
import {
  WEBSITE_TOPIC_TABLE_HEADER,
  YOUTUBE_CHANNEL_DETAILS_WEBSITE_TOPIC_API,
} from "../../../../config/constants/tableHeaders";
import MainTable from "../../../../components/Tables/MainTable";
import { RecommendItems } from "../..";

const WebsitesTopic = (props) => {
  const { channelId } = useParams();
  useEffect(() => {
    props.setSelectedRoute("websites");
    // eslint-disable-next-line
  }, []);

  let filters = window.advancedFilters || {};
  LINK_DOMAINS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });

  return (
    <div>
      <MainTable
        tableHeader={WEBSITE_TOPIC_TABLE_HEADER}
        URL={YOUTUBE_CHANNEL_DETAILS_WEBSITE_TOPIC_API.replace(
          "{channelId}",
          channelId
        )}
        filters={filters}
        withoutFilterModal
        withoutProjectFilter
        withoutHeaderCheck
        // withoutTopicFilter
        // filterModalFields={LINK_DOMAINS_FILTER_MODAL_FIELDS}
        model={"websites"}
        modelName="youtube-channel-website-topic--channelId"
      />
      <RecommendItems />
    </div>
  );
};

export default WebsitesTopic;

