import {
  GET_SEARCH_ITEMS_LOADING,
  GET_SEARCH_ITEMS_SUCCESS,
  GET_SEARCH_ITEMS_FAIL,
} from '../types';

const initialState = {
  searchItems: [],
  searchItemsCount: 0,
  searchItemsLoading: false,
  searchItemserror: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_SEARCH_ITEMS_LOADING:
      return {
        ...state,
        searchItemsLoading: true,
        searchItemserror: null,
      };
    case GET_SEARCH_ITEMS_SUCCESS:
      return {
        ...state,
        searchItems: payload.data,
        searchItemsCount: payload.count,
        searchItemsLoading: false,
        searchItemserror: null,
      };
    case GET_SEARCH_ITEMS_FAIL:
      return {
        ...state,
        searchItems: [],
        searchItemsCount: 0,
        searchItemsLoading: false,
        searchItemserror: payload?.error
      };
    default:
      return state;
  }
}
