import React from "react";
import Layout from "../../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import TopSummary from "./Components/TopSummary";
import YoutubeChannelDetailsRouting from "./Components/YoutubeChannelDetailsRouting";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { getEveryMinuteApi } from "../../store/actions/getEveryMinuteApiAction";
import {
  YOUTUBE_CHANNELS_RANDOM_TABLE_API,
  YOUTUBE_CHANNELS_TABLE_HEADER,
  YOUTUBE_CHANNEL_DETAILS_API,
} from "../../config/constants/tableHeaders";
import SimpleTable from "../../components/Tables/SimpleTable";

export const RecommendItems = () => (
  <div className="mt-5 w-100 flex flex-column">
    <div className="text-center topnews-card-title mb-3">
      Recommend Channels
    </div>
    <SimpleTable
      header={YOUTUBE_CHANNELS_TABLE_HEADER}
      URL={YOUTUBE_CHANNELS_RANDOM_TABLE_API}
      tableModelId="youtube-channels"
      modelName="project-youtube-channel--nickName"
    />
  </div>
);

const YoutubeChannelDetails = () => {
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  const { everyMinuteData, everyMinuteApiLoading } = useSelector(
    (state) => state.everyMinuteState
  );
  const [detailsData, setDetailsData] = useState({});
  const [hasApiBeenCalled, setHasApiBeenCalled] = useState(false);

  useEffect(() => {
    if (channelId === undefined) {
      history.push(`/youtube/`);
    }
    setHasApiBeenCalled(false);
    dispatch(
      getEveryMinuteApi({
        URL: YOUTUBE_CHANNEL_DETAILS_API + `${channelId}`,
      })
    );
    setHasApiBeenCalled(true);
    // var getEveryMinuteApiInterval = setInterval(() => {
    //   getEveryMinuteApi({
    //     URL: YOUTUBE_CHANNEL_DETAILS_API + `${channelId}`
    //   });
    //   if (everyMinuteData.count === 0) {
    //     history.push(`/youtube/`)
    //   }
    // }, 1000 * 60);
    // return () => {
    //   clearInterval(getEveryMinuteApiInterval)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  useEffect(() => {
    try {
      if (
        !everyMinuteApiLoading &&
        hasApiBeenCalled &&
        ((Array.isArray(everyMinuteData) && everyMinuteData.length === 0) ||
          (typeof everyMinuteData === "object" &&
            Object.keys(everyMinuteData).length === 0))
      ) {
        history.push("/notfound");
      }
      const keywords = projectNames?.filter((project) => {
        return project.project_id === everyMinuteData?.project_id;
      });
      setDetailsData({ ...everyMinuteData, keyword: keywords?.[0]?.name });
    } catch (e) {
      console.log("error: ", e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [everyMinuteData, everyMinuteApiLoading, hasApiBeenCalled]);

  return (
    <Layout>
      <TopSummary detailsData={detailsData}></TopSummary>
      {/* <TopnewsCards /> */}
      <YoutubeChannelDetailsRouting detailsData={detailsData} />
    </Layout>
  );
};

export default YoutubeChannelDetails;

