import axios from 'axios';

import {
  GET_CHART_API_LOADING,
  GET_CHART_API_SUCCESS,
  GET_CHART_API_FAIL,
} from '../types';

import { attachTokenToHeaders } from './authActions';
import { params2URL } from '../../utils/common';

export const getChartApi = (params) => async (dispatch, getState) => {
  dispatch({ type: GET_CHART_API_LOADING });
  try {
    const response = await axios.get(params2URL(params), attachTokenToHeaders(getState));
    dispatch({
      type: GET_CHART_API_SUCCESS,
      payload: { chartData: response.data.results, chartDataCount: response.data.count },
    });

  } catch (err) {
    dispatch({
      type: GET_CHART_API_FAIL,
      payload: { chartError: err.response.data.message },
    });
  }
};
