import { MODEL_IDS } from "../config/constants/data";
import {
  DISCORD_SERVER_DETAILS_SOCIAL_LINKS_API,
  DISCORD_SERVER_DETAILS_WEBSITE_SOCIAL_API,
  DISCORD_SERVER_WEBSITE_SOCIAL_TABLE_HEADER,
  NEWS_PROJECTS_TABLE_HEADER,
  TELEGRAM_DIALOG_DETAILS_SOCIAL_LINKS_API,
  TELEGRAM_DIALOG_DETAILS_WEBSITE_SOCIAL_API,
  WEBSITE_SOCIAL_MEDIA_MAIN_TABLE_API,
  WEBSITE_SOCIAL_TABLE_HEADER,
  WEBSITE_TOPIC_TABLE_HEADER,
  WEBSITE_USERS_MAIN_TABLE_API,
  WEBSITE_USERS_TABLE_HEADER,
  YOUTUBE_CHANNEL_DETAILS_SOCIAL_LINKS_API,
  YOUTUBE_CHANNEL_DETAILS_WEBSITE_SOCIAL_API,
  YOUTUBE_CHANNEL_WEBSITE_SOCIAL_TABLE_HEADER,
} from "../config/constants/tableHeaders";
import {
  DISCORD_CHANNELS_MAIN_TABLE_API,
  NEWS_MAIN_TABLE_API,
  DISCORD_MESSAGES_MAIN_TABLE_API,
  DISCORD_SERVERS_MAIN_TABLE_API,
  LINKS_MAIN_TABLE_API,
  LINK_DETAILS_TABLE_API,
  PROJECTS_MAIN_TABLE_API,
  TWITTER_PROJECTS_MAIN_TABLE_API,
  TWITTER_TWEETS_MAIN_TABLE_API,
  TWITTER_USERS_MAIN_TABLE_API,
  YOUTUBE_CHANNELS_MAIN_TABLE_API,
  YOUTUBE_PROJECTS_MAIN_TABLE_API,
  YOUTUBE_VIDEOS_MAIN_TABLE_API,
  PROJECTS_MARKET_TABLE_API,
  DISCORD_PROJECTS_MAIN_TABLE_API,
  TELEGRAM_PROJECTS_MAIN_TABLE_API,
  TELEGRAM_DIALOGS_MAIN_TABLE_API,
  YOUTUBE_PROJECTS_TABLE_HEADER,
  DISCORD_CHANNELS_TABLE_HEADER,
  DISCORD_MESSAGES_TABLE_HEADER,
  DISCORD_SERVERS_TABLE_HEADER,
  LINKS_TABLE_HEADER,
  LINK_DETAILS_TABLE_HEADER,
  PROJECT_TABLE_HEADER,
  TWITTER_PROJECTS_TABLE_HEADER,
  TWITTER_TWEETS_TABLE_HEADER,
  TWITTER_USERS_TABLE_HEADER,
  YOUTUBE_CHANNELS_TABLE_HEADER,
  YOUTUBE_VIDEOS_TABLE_HEADER,
  PROJECTS_MARKET_TABLE_HEADER,
  DISCORD_PROJECTS_TABLE_HEADER,
  TELEGRAM_PROJECTS_TABLE_HEADER,
  TELEGRAM_DIALOGS_TABLE_HEADER,
  WEBSITE_TOPIC_MAIN_TABLE_API,
  WEBSITES_SOCIAL_TABLE_HEADER,
  WEBSITE_SOCIAL_LINKS_MAIN_TABLE_API,
} from "../config/constants/tableHeaders";

export const routing2TableHeaderURL = (model, topic = undefined, domain = undefined) => {
  switch (model) {
    case "trending":
      return [PROJECT_TABLE_HEADER, PROJECTS_MAIN_TABLE_API];
    case "market":
      return [PROJECTS_MARKET_TABLE_HEADER, PROJECTS_MARKET_TABLE_API];
    case "company":
      return [];
    case "youtube":
      return [YOUTUBE_PROJECTS_TABLE_HEADER, YOUTUBE_PROJECTS_MAIN_TABLE_API];
    case "youtube-videos":
      return [YOUTUBE_VIDEOS_TABLE_HEADER, YOUTUBE_VIDEOS_MAIN_TABLE_API];
    case "youtube-channels":
      return [YOUTUBE_CHANNELS_TABLE_HEADER, YOUTUBE_CHANNELS_MAIN_TABLE_API];
    case "twitter":
      return [TWITTER_PROJECTS_TABLE_HEADER, TWITTER_PROJECTS_MAIN_TABLE_API];
    case "twitter-tweets":
      return [TWITTER_TWEETS_TABLE_HEADER, TWITTER_TWEETS_MAIN_TABLE_API];
    case "twitter-users":
      return [TWITTER_USERS_TABLE_HEADER, TWITTER_USERS_MAIN_TABLE_API];
    case "telegram":
      return [TELEGRAM_PROJECTS_TABLE_HEADER, TELEGRAM_PROJECTS_MAIN_TABLE_API];
    case "telegram-groups":
      return [TELEGRAM_DIALOGS_TABLE_HEADER, TELEGRAM_DIALOGS_MAIN_TABLE_API];
    case "discord":
      return [DISCORD_PROJECTS_TABLE_HEADER, DISCORD_PROJECTS_MAIN_TABLE_API];
    case "discord-servers":
      return [DISCORD_SERVERS_TABLE_HEADER, DISCORD_SERVERS_MAIN_TABLE_API];
    case "discord-channels":
      return [DISCORD_CHANNELS_TABLE_HEADER, DISCORD_CHANNELS_MAIN_TABLE_API];
    case "discord-messages":
      return [DISCORD_MESSAGES_TABLE_HEADER, DISCORD_MESSAGES_MAIN_TABLE_API];
    case "links":
      return [LINKS_TABLE_HEADER, LINKS_MAIN_TABLE_API];
    case "news":
      return [NEWS_PROJECTS_TABLE_HEADER, NEWS_MAIN_TABLE_API];
    case "social":
      if (domain === undefined)
        return [WEBSITES_SOCIAL_TABLE_HEADER, WEBSITE_SOCIAL_MEDIA_MAIN_TABLE_API];
      else return [WEBSITE_SOCIAL_TABLE_HEADER, WEBSITE_SOCIAL_LINKS_MAIN_TABLE_API];
    case "websites":
      if (topic === undefined)
        return [WEBSITE_TOPIC_TABLE_HEADER, WEBSITE_TOPIC_MAIN_TABLE_API];
      else return [WEBSITE_USERS_TABLE_HEADER, WEBSITE_USERS_MAIN_TABLE_API];
    case "details":
      return [LINK_DETAILS_TABLE_HEADER, LINK_DETAILS_TABLE_API];
    // New Model Names.
    case "youtube-channel-social-website--channelId":
      return [WEBSITES_SOCIAL_TABLE_HEADER, YOUTUBE_CHANNEL_DETAILS_WEBSITE_SOCIAL_API];
    case "youtube-channel-social-link--channelId-domain":
      return [YOUTUBE_CHANNEL_WEBSITE_SOCIAL_TABLE_HEADER, YOUTUBE_CHANNEL_DETAILS_SOCIAL_LINKS_API];
    case "discord-server-social-website--server_id":
      return [WEBSITES_SOCIAL_TABLE_HEADER, DISCORD_SERVER_DETAILS_WEBSITE_SOCIAL_API];
    case "discord-server-social-link--server_id-domain":
      return [DISCORD_SERVER_WEBSITE_SOCIAL_TABLE_HEADER, DISCORD_SERVER_DETAILS_SOCIAL_LINKS_API];
    case "telegram-dialog-social-website--dialog_id":
      return [WEBSITES_SOCIAL_TABLE_HEADER, TELEGRAM_DIALOG_DETAILS_WEBSITE_SOCIAL_API];
    case "telegram-dialog-social-link--dialog_id-domain":
      return [DISCORD_SERVER_WEBSITE_SOCIAL_TABLE_HEADER, TELEGRAM_DIALOG_DETAILS_SOCIAL_LINKS_API];
    case "social-website":
      return [WEBSITES_SOCIAL_TABLE_HEADER, WEBSITE_SOCIAL_MEDIA_MAIN_TABLE_API];
    case "social-link--domain":
      return [WEBSITE_SOCIAL_TABLE_HEADER, WEBSITE_SOCIAL_LINKS_MAIN_TABLE_API];
    default:
      return [PROJECT_TABLE_HEADER, PROJECTS_MAIN_TABLE_API];
  }
};

export const modelId2TableHeaderURL = (model) => {
  switch (model) {
    case MODEL_IDS.PROJECT:
      return routing2TableHeaderURL("trending");
    case MODEL_IDS.CHANNEL:
      return routing2TableHeaderURL("youtube-channels");
    case MODEL_IDS.SERVER:
      return routing2TableHeaderURL("discord-servers");
    case MODEL_IDS.GROUP:
      return routing2TableHeaderURL("telegram-groups");
    case MODEL_IDS.USER:
      return routing2TableHeaderURL("twitter-users");
    case MODEL_IDS.WEBSITE:
      return [WEBSITE_USERS_TABLE_HEADER, WEBSITE_USERS_MAIN_TABLE_API];
    default:
      return routing2TableHeaderURL("trending");
  }
}


export const modelId2ModelName = (model) => {
  switch (model) {
    case MODEL_IDS.PROJECT:
      return ["trending", "trending"];
    case MODEL_IDS.CHANNEL:
      return ["youtube-channels", "youtube-channel"];
    case MODEL_IDS.SERVER:
      return ["discord-servers", "discord-server"];
    case MODEL_IDS.GROUP:
      return ["telegram-groups", "telegram-group"];
    case MODEL_IDS.USER:
      return ["twitter-users", "twitter-user"];
    case MODEL_IDS.WEBSITE:
      return ["websites", "website--topic"];
    default:
      return ["trending", "trending"];
  }
}

export const routing2TableModelId = (model) => {
  switch (model) {
    case "youtube-channels":
      return 1;
    case "youtube-videos":
      return 2;
    case "twitter-users":
      return 3;
    case "twitter-tweets":
      return 4;
    case "discord":
      return 5;
    case "discord-servers":
      return 5;
    case "discord-channels":
      return 6;
    case "discord-messages":
      return 7;
    case "websites":
      return 8;
    case "links":
      return 9;
    case "youtube":
      return 11;
    case "twitter":
      return 12;
    case "details":
      return 15;
    default: //main projects table
      return 10;
  }
};

export const routing2PrefilterTopicModelId = (model = "default") => {
  switch (model) {
    case "youtube-channels":
      return 1;
    case "youtube-videos":
      return 1;
    case "twitter-users":
      return 3;
    case "twitter-tweets":
      return 3;
    case "discord-servers":
      return 5;
    case "discord-channels":
      return 5;
    case "discord-messages":
      return 5;
    case "websites":
    case "website":
    case "analytics-websites":
      return 8;
    case "telegram-groups":
      return 10;
    case "links":
      return 13;
    case "youtube":
      return 17;
    case "trending":
      return 20;
    case "twitter":
      return 12;
    case "discord":
      return 14;
    case "details":
      return 15;
    case "telegram":
      return 16;
    case "news":
      return 21;
    case "social":
      return 22;
    default: //main projects table
      return 11;
  }
};

export const model2Site = (model) => {
  return model.split("-")[0];
};
