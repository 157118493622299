import { Icon } from "@iconify/react";
import React from "react";
import "./styles.scss";
import useMediaQuery from "../../../../hooks/useMediaQuery";

function DetailVoteComponent({
  id,
  vote,
  setVoteValue,
  showVoteButton = true,
}) {
  const userScore = vote.specialData[id]?.user_score;
  const sumScore = vote.specialData[id]?.sum_score;
  const isMobile = useMediaQuery();
  const onClickSetVote = (value) => {
    setVoteValue(value, id);
  };
  if (showVoteButton)
    if (!isMobile)
      return (
        <div className="d-flex align-items-center justify-content-end mr-0 pr-0">
          <span
            style={{
              color:
                userScore === "1"
                  ? "green"
                  : userScore === "-1"
                  ? "red"
                  : "white",
              fontSize: 18,
            }}
          >
            {vote.specialData[id] ? sumScore : 0}
          </span>
          <div className="d-flex align-items-center justify-content-center flex-column ml-2">
            <button
              className={`btn btn-sm vote-btn`}
              onClick={() => setVoteValue(1, id)}
              // disabled={userScore === "-1"}
              style={{ height: 19 }}
            >
              <Icon
                icon={`icon-park-${
                  userScore === "1" ? "solid" : "outline"
                }:up-two`}
                height="1.7rem"
                color={userScore === "1" ? "green" : "white"}
              />
            </button>
            <button
              className={`btn btn-sm vote-btn`}
              onClick={() => setVoteValue(-1, id)}
              // disabled={userScore === "1"}
            >
              <Icon
                icon={`icon-park-${
                  userScore === "-1" ? "solid" : "outline"
                }:down-two`}
                height="1.7rem"
                color={userScore === "-1" ? "red" : "white"}
              />
            </button>
          </div>
        </div>
      );
    else
      return (
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <div
              className={`btn btn-sm vote-btn`}
              onClick={() => !isMobile && onClickSetVote(1)}
              onTouchStart={() => isMobile && onClickSetVote(1)}
              disabled={userScore === "-1"}
            >
              <Icon
                icon={`icon-park-${
                  userScore === "1" ? "solid" : "outline"
                }:up-two`}
                height="2.5rem"
                color={userScore === "1" ? "green" : "white"}
              />
            </div>
          </div>
          <span
            style={{
              color:
                userScore === "1"
                  ? "green"
                  : userScore === "-1"
                  ? "red"
                  : "white",
              fontSize: 18,
            }}
          >
            {vote.specialData[id] ? sumScore : 0}
          </span>
          <div>
            <div
              className={`btn btn-sm vote-btn`}
              onClick={() => !isMobile && onClickSetVote(-1)}
              onTouchStart={() => isMobile && onClickSetVote(-1)}
              disabled={userScore === "1"}
            >
              <Icon
                icon={`icon-park-${
                  userScore === "-1" ? "solid" : "outline"
                }:down-two`}
                height="2.5rem"
                color={userScore === "-1" ? "red" : "white"}
              />
            </div>
          </div>
        </div>
      );
  else
    return (
      <div className="d-flex align-items-center justify-content-end">
        <span>{vote.specialData[id] ? vote.specialData[id].sum_score : 0}</span>
      </div>
    );
}

export default DetailVoteComponent;
