import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useCallback,
  createRef,
  useMemo,
} from "react";
import { RiSearchLine } from "react-icons/ri";
import { FaAngleLeft, FaSearch } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import Tippy from "@tippyjs/react/headless";
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/material.css";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import {
  getIconFromSearchItem,
  getLinkFromSearchItem,
} from "../../utils/routingHelper";
import {
  SEARCH_CATEGORIES,
  SEARCH_MODAL_CATEGORY_LIMIT,
} from "../../config/constants/data";
import { useDispatch, useSelector } from "react-redux";
import { setSearchTextGlobal } from "../../store/actions/uiSettings";

const SearchModal = forwardRef((props, ref) => {
  let history = useHistory();

  const initSearchItems = useSelector((state) => state.searchItems.searchItems);
  const searchItems = useMemo(() => initSearchItems || [], [initSearchItems]);

  const [searchResults, setSearchResults] = useState({
    activeIndex: 0,
    filteredItems: [],
    showProjects: true,
    // userInput: props.value || "",
  });
  const userInput = useMemo(() => props.value || "", [props.value]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const loadMoreRef = useRef(null);

  const MAX_ITEMS_PER_LOAD = 20; // Number of items to load per batch

  const loadMoreItems = useCallback(() => {
    setDisplayedItems((prevItems) => {
      const nextIndex = prevItems.length;
      const moreItems = searchResults.filteredItems?.slice(
        nextIndex,
        nextIndex + MAX_ITEMS_PER_LOAD
      );
      return [...prevItems, ...moreItems];
    });
  }, [searchResults.filteredItems]);

  useEffect(() => {
    const currentRef = loadMoreRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      },
      { threshold: 1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [loadMoreItems]);

  useEffect(() => {
    // applyFilters(userInput, searchCategory);
    setDisplayedItems(
      searchResults.filteredItems?.slice(0, MAX_ITEMS_PER_LOAD)
    );
  }, [searchResults.filteredItems]);

  useEffect(() => {
    if (props.selectedCategory !== undefined) {
      updateSearchCategory(props.selectedCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCategory]);

  const filterItems = (userInput, category) => {
    const searchFilteredItems = searchItems.filter(
      (item) =>
        item.name.toLowerCase().includes(userInput.toLowerCase()) &&
        (category === "-1" || item.model_id === category)
    );
    if (category !== "-1") return searchFilteredItems;
    else {
      let showIndex = 0;
      let showItems = [];
      SEARCH_CATEGORIES.filter((v) => v.value !== "-1").forEach((category) => {
        const showCategoryFilteredItems = searchFilteredItems
          .filter((item) => item.model_id === category.value)
          .slice(0, SEARCH_MODAL_CATEGORY_LIMIT);
        if (showCategoryFilteredItems.length > 0) {
          showCategoryFilteredItems.forEach((item) => {
            item["index"] = showIndex++;
            showItems.push(item);
          });
        }
      });
      return showItems;
    }
  };

  const applyFilters = (inputValue, category) => {
    console.log(inputValue, category);
    const filteredItems = filterItems(inputValue, category);
    setSearchResults({
      activeIndex: 0,
      filteredItems,
      showProjects: true,
      // userInput: inputValue,
    });
  };

  useEffect(() => {
    if (props.value) {
      applyFilters(props.value, searchCategory);
    }
  }, [props.value]);

  const onChange = (e) => {
    const userInput = e.target.value;
    if (props.onChange) {
      props.onChange(userInput);
    }
    // applyFilters(userInput, searchCategory);
  };

  const onClick = (item) => {
    history.push(getLinkFromSearchItem(item));
    props.hide();
  };

  const itemRefs = useRef([]);

  useEffect(() => {
    // Adjust the itemRefs array to match the number of displayed items
    itemRefs.current = displayedItems?.map(
      (_, i) => itemRefs.current[i] || createRef()
    );
  }, [displayedItems]);

  const onKeyDown = (e) => {
    const { activeIndex, filteredItems } = searchResults;
    let newIndex = activeIndex;

    switch (e.keyCode) {
      case 13: // Enter key
        history.push(getLinkFromSearchItem(filteredItems[activeIndex]));
        props.hide();
        break;
      case 27: // ESC key
        props.hide();
        break;
      case 38: // Up arrow key
        if (activeIndex > 0) {
          newIndex = activeIndex - 1;
        }
        break;
      case 40: // Down arrow key
        if (activeIndex < filteredItems.length - 1) {
          newIndex = activeIndex + 1;
        }
        break;
      default:
        return; // Ignore other keys
    }

    if (newIndex !== activeIndex) {
      setSearchResults({ ...searchResults, activeIndex: newIndex });
      scrollToActiveItem(newIndex);
    }
  };

  const scrollToActiveItem = (index) => {
    const activeItemRef = itemRefs?.current[index];
    if (activeItemRef && activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  /////////////////SEARCH CATEGORY FILTER/////////////////////
  const [searchCategory, setSearchCategory] = useState("-1");

  const updateSearchCategory = (newCategory) => {
    setSearchCategory(newCategory);
    applyFilters(userInput, newCategory);
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  const filterByCategoryAllPage = (category) => {
    return searchResults.filteredItems.filter(
      (item) => item.model_id === category
    );
  };

  const countItemsInCategory = (category) => {
    const lowerCaseInput = userInput.toLowerCase();
    return searchItems.filter(
      (item) =>
        item.model_id === category &&
        item.name.toLowerCase().includes(lowerCaseInput)
    ).length;
  };

  const CategoryWrapper = () => {
    return (
      <div className="category-wrapper">
        {SEARCH_CATEGORIES.map((category, index) => {
          return (
            <div
              key={category.value}
              className={`category-btn ${
                searchCategory === category.value ? "selected" : ""
              }`}
              onClick={() => updateSearchCategory(category.value)}
            >
              {category.label}
            </div>
          );
        })}
      </div>
    );
  };

  let searchItemsComponent;

  if (searchResults.showProjects) {
    if (searchResults.filteredItems && searchResults.filteredItems.length) {
      searchItemsComponent = (
        <ul
          className={
            searchCategory === "-1" ? "projects" : "projects more-padding-top"
          }
        >
          <CategoryWrapper />
          {searchCategory === "-1" ? (
            SEARCH_CATEGORIES.filter((v) => v.value !== "-1").map(
              (category) => {
                return (
                  filterByCategoryAllPage(category.value).length > 0 && (
                    <div>
                      <p className="category-title">{category.label}</p>
                      {filterByCategoryAllPage(category.value).map((item) => {
                        let className =
                          item.index === searchResults.activeIndex
                            ? "project-active"
                            : "";
                        return (
                          <li
                            className={"search-result-row " + className}
                            key={item.id}
                            ref={itemRefs?.current[item.index]}
                            onClick={() => onClick(item)}
                          >
                            <div>
                              <img
                                src={getIconFromSearchItem(item)}
                                className="mr-3"
                                style={{
                                  borderRadius: "100%",
                                  height: 25,
                                  width: 25,
                                }}
                                alt=""
                              />
                              {item.name}
                            </div>
                          </li>
                        );
                      })}
                      {countItemsInCategory(category.value) > 5 && (
                        <p
                          className="see-all-results"
                          onClick={() => updateSearchCategory(category.value)}
                        >{`See all results(${countItemsInCategory(
                          category.value
                        )})`}</p>
                      )}
                    </div>
                  )
                );
              }
            )
          ) : (
            <div>
              <div className="category-back-wrapper">
                <div
                  className="category-back"
                  onClick={() => {
                    updateSearchCategory("-1");
                  }}
                >
                  <FaAngleLeft size={14} />
                  <p className="mb-0">Back</p>
                </div>
              </div>
              {displayedItems?.map((item, index) => {
                let className =
                  index === searchResults.activeIndex ? "project-active" : "";
                return (
                  <li
                    ref={itemRefs?.current[index]}
                    className={"search-result-row " + className}
                    key={item.id}
                    onClick={() => onClick(item)}
                  >
                    <div>
                      <img
                        src={getIconFromSearchItem(item)}
                        className="mr-3"
                        style={{ borderRadius: "100%", height: 25, width: 25 }}
                        alt=""
                      />
                      {item.name}
                    </div>
                  </li>
                );
              })}
            </div>
          )}

          <div ref={loadMoreRef} />
        </ul>
      );
    } else {
      searchItemsComponent = (
        <div className="no-projects">
          <CategoryWrapper />
          No result for "{userInput}"
        </div>
      );
    }
  }

  return (
    <div className="search-modal-border">
      <div className="search-modal-wrapper">
        <div className="search-row">
          <RiSearchLine size={18} color={"#848BA1"} />
          <input
            ref={ref}
            type="text"
            className="project-search-input"
            placeholder="Search"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
          />
          <div className="hide-button" onClick={props.hide}>
            <AiFillCloseCircle size={20} color={"#323546"} />
          </div>
        </div>
        <div className="search-result-modal">{searchItemsComponent}</div>
      </div>
    </div>
  );
});

const SearchItemsModal = ({ searchItems, selectedCategory, buttonWidth }) => {
  const [visible, setVisible] = useState(false);
  const inputRef = useRef();
  const show = () => {
    setVisible(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };
  const hide = () => setVisible(false);
  // const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const search =
    useSelector((state) => state.uiSettings.searchTextGlobal) || "";

  const setSearch = (text) => {
    dispatch(setSearchTextGlobal(text));
  };
  return (
    <div className=" mt-md-0 d-flex align-items-center search mx-2">
      <Tippy
        visible={visible}
        interactive={true}
        onClickOutside={hide}
        theme={"material"}
        offset={[-0, -42]}
        placement={"bottom"}
        render={(attrs) => (
          <div {...attrs}>
            <SearchModal
              ref={inputRef}
              hide={hide}
              value={search}
              onChange={setSearch}
              searchItems={searchItems}
              selectedCategory={selectedCategory}
            />
          </div>
        )}
      >
        <button
          onClick={visible ? hide : show}
          className="search-button"
          style={{ width: buttonWidth || 240 }}
        >
          <div className="d-flex row justify-content-left align-items-center ml-1">
            <FaSearch />
            <div style={{ marginLeft: 3, color: search ? "#fff" : null }}>
              {search || "Search"}
            </div>
          </div>
          <div className="slashButton">/</div>
        </button>
        {/* <search-button onClick={search-buttonClick}/> */}
        {/* <button type="button" className="btn btn-default mx-1" style={{ padding: '5.3px 8px' }} onClick={props.handleSearch}> */}
        {/* <FaSearch /> */}
        {/* </button> */}
      </Tippy>
    </div>
  );
};

export default SearchItemsModal;

