// API URLs
// Websites.
export const WEBSITE_TOPIC_MAIN_TABLE_API = "/api/v1/website/topic/";
export const WEBSITE_USERS_MAIN_TABLE_API = "/api/v1/website/social/";
export const WEBSITE_USERS_RANDOM_TABLE_API = "/api/v1/website/social-rand/";
export const NEWS_MAIN_TABLE_API = "/api/v1/website/news/";
export const WEBSITE_SOCIAL_MEDIA_MAIN_TABLE_API =
  "/api/v1/analytics/website-social/";
export const WEBSITE_SOCIAL_LINKS_MAIN_TABLE_API =
  "/api/v1/analytics/social-links/";
export const LINKS_MAIN_TABLE_API = "/api/v1/analytics/links/";
export const BLOGS_MAIN_TABLE_API = "/api/v1/manager/blog";
export const LINK_DETAILS_TABLE_API = "/api/v1/analytics/link-details/";

export const DISCORD_PROJECTS_MAIN_TABLE_API = "/api/v1/discord/projects/";
export const DISCORD_SERVERS_MAIN_TABLE_API = "/api/v1/discord/servers/";
export const DISCORD_SERVERS_RANDOM_TABLE_API = "/api/v1/discord/servers-rand/";
export const DISCORD_MESSAGES_MAIN_TABLE_API = "/api/v1/discord/messages/";
export const DISCORD_CHANNELS_MAIN_TABLE_API = "/api/v1/discord/channels/";

export const NEWS_PROJECTS_MAIN_TABLE_API = "/api/v1/analytics/news-project/";

export const TWITTER_PROJECTS_MAIN_TABLE_API = "/api/v1/twitter/projects/";
export const TWITTER_USERS_MAIN_TABLE_API = "/api/v1/twitter/users/";
export const TWITTER_TWEETS_MAIN_TABLE_API = "/api/v1/twitter/tweets/";

export const TELEGRAM_PROJECTS_MAIN_TABLE_API = "/api/v1/telegram/projects/";
export const TELEGRAM_DIALOGS_MAIN_TABLE_API = "/api/v1/telegram/dialogs/";
export const TELEGRAM_DIALOGS_RANDOM_TABLE_API =
  "/api/v1/telegram/dialogs-rand/";

export const YOUTUBE_PROJECTS_MAIN_TABLE_API = "/api/v1/youtube/projects/";
export const YOUTUBE_CHANNELS_MAIN_TABLE_API = "/api/v1/youtube/channels/";
export const YOUTUBE_CHANNELS_RANDOM_TABLE_API = "/api/v1/youtube/channels/";
export const YOUTUBE_VIDEOS_MAIN_TABLE_API = "/api/v1/youtube/videos/";

export const PROJECTS_MAIN_TABLE_API = "/api/v1/project/dashboard/";
export const PROJECTS_RANDOM_TABLE_API = "/api/v1/project/dashboard-rand/";
export const PROJECTS_MARKET_TABLE_API = "/api/v1/market/price/";

export const PROJECT_DETAILS_GENERAL_API = "/api/v1/project/details-static/";
export const PROJECT_DETAILS_EVERY_MINUTE_API =
  "/api/v1/project/details-statistics/";

export const PROJECT_DETAILS_CHART_API = "/api/v1/project/details-chart/";
// Staff APIs

export const YOUTUBE_CHANNEL_TOPIC_MAIN_TABLE_API = "/api/v1/youtube/s-topic/";
export const TWITTER_USERS_STAFF_MAIN_TABLE_API = "/api/v1/twitter/s_users/";
export const TELEGRAM_DIALOGS_STAFF_MAIN_TABLE_API =
  "/api/v1/telegram/s-dialogs/";
export const DISCORD_SERVERS_STAFF_MAIN_TABLE_API =
  "/api/v1/discord/s-servers/";
export const LINK_WEBSITE_TOPIC_MAIN_TABLE_API = "/api/v1/website/s-topic/";
export const PROJECT_EDIT_MAIN_TABLE_API = "/api/v1/project/project/";
export const KEYWORD_EDIT_MAIN_TABLE_API = "/api/v1/project/keyword/";
export const PROJECT_CATEGORY_EDIT_MAIN_TABLE_API =
  "/api/v1/project/project_category/";
export const DISCORD_SERVERINVITE_EDIT_MAIN_TABLE_API =
  "/api/v1/discord/serverinvite/";
export const BLOG_EDIT_MAIN_TABLE_API = "/api/v1/manager/blog";
export const USERS_MAIN_TABLE_API = "/api/v1/user/users";

// Details APIs.
export const DISCORD_SERVER_DETAILS_API = "/api/v1/discord/server/";
export const DISCORD_SERVER_HIGHLIGHT_API = "/api/v1/discord/server-highlight/";
export const TELEGRAM_GROUP_DETAILS_API = "/api/v1/telegram/dialog/";
export const YOUTUBE_CHANNEL_DETAILS_API = "/api/v1/youtube/channel/";
export const WEBSITE_DOMAIN_DETAILS_API = "/api/v1/website/details/";
export const WEBSITE_DOMAIN_HIGHLIGHT_API = "/api/v1/website/highlight/";

export const WEBSITE_PARTNERS_MAIN_TABLE_API =
  "/api/v1/analytics/website-partners/";
export const NEW_PARTNERS_MAIN_TABLE_API = "/api/v1/analytics/new-partners/";
export const WEBSITE_VIDEOS_MAIN_TABLE_API =
  "/api/v1/analytics/website-videos/";
// Youtube Details Websites, News, Social APIs.
export const YOUTUBE_CHANNEL_DETAILS_PROJECTS_API =
  "/api/v1/youtube/channel/{channelId}/projects/";
export const YOUTUBE_CHANNEL_DETAILS_WEBSITE_TOPIC_API =
  "/api/v1/youtube/channel/{channelId}/website-topic/";
export const YOUTUBE_CHANNEL_DETAILS_WEBSITES_API =
  "/api/v1/youtube/channel/{channelId}/websites/";
export const YOUTUBE_CHANNEL_DETAILS_WEBSITE_NEWS_API =
  "/api/v1/youtube/channel/{channelId}/website-news/";
export const YOUTUBE_CHANNEL_DETAILS_WEBSITE_SOCIAL_API =
  "/api/v1/youtube/channel/{channelId}/website-social/";
export const YOUTUBE_CHANNEL_DETAILS_SOCIAL_LINKS_API =
  "/api/v1/youtube/channel/{channelId}/social-links/";
// Discord Server Details Websites, News, Social APIs.
export const DISCORD_SERVER_DETAILS_WEBSITE_TOPIC_API =
  "/api/v1/discord/server/{server_id}/website-topic/";
export const DISCORD_SERVER_DETAILS_WEBSITES_API =
  "/api/v1/discord/server/{server_id}/websites/";
export const DISCORD_SERVER_DETAILS_WEBSITE_NEWS_API =
  "/api/v1/discord/server/{server_id}/website-news/";
export const DISCORD_SERVER_DETAILS_WEBSITE_SOCIAL_API =
  "/api/v1/discord/server/{server_id}/website-social/";
export const DISCORD_SERVER_DETAILS_SOCIAL_LINKS_API =
  "/api/v1/discord/server/{server_id}/social-links/";
// Telegram Telegram Details Websites, News, Social APIs.
export const TELEGRAM_DIALOG_DETAILS_WEBSITE_TOPIC_API =
  "/api/v1/telegram/dialog/{dialog_id}/website-topic/";
export const TELEGRAM_DIALOG_DETAILS_WEBSITES_API =
  "/api/v1/telegram/dialog/{dialog_id}/websites/";
export const TELEGRAM_DIALOG_DETAILS_WEBSITE_NEWS_API =
  "/api/v1/telegram/dialog/{dialog_id}/website-news/";
export const TELEGRAM_DIALOG_DETAILS_WEBSITE_SOCIAL_API =
  "/api/v1/telegram/dialog/{dialog_id}/website-social/";
export const TELEGRAM_DIALOG_DETAILS_SOCIAL_LINKS_API =
  "/api/v1/telegram/dialog/{dialog_id}/social-links/";

const customHeader = ({
  title = "",
  value = "",
  transform = false,
  type = false,
  fixed = false,
  colored = false,
  width = "5%",
  sort = true,
  checked = true,
  align = "end",
} = {}) => {
  return {
    title,
    value,
    transform,
    type,
    fixed,
    colored,
    width,
    sort,
    checked,
    align,
  };
};

const bookmarkHeader = customHeader({
  title: "",
  value: "bookmark",
  transform: "bookmark",
});

const voteHeader = customHeader({
  title: "Vote",
  value: "sum_score",
  transform: "vote",
  type: "numeric",
});

const indexHeader = customHeader({
  title: "#",
  value: "index",
  transform: "index",
  type: "numeric",
  checked: false,
});

export const PROJECT_EDIT_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Name",
    value: "name",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Nick Name",
    value: "nickname",
  }),
  customHeader({
    title: "Description",
    value: "description",
    transform: "project_description",
  }),
  customHeader({
    title: "Official Links",
    value: "official_links",
    transform: "project_official_links",
  }),
  customHeader({
    title: "Action",
    value: "action",
    transform: "action_component",
    type: "project",
  }),
];
export const KEYWORD_EDIT_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Keyword",
    value: "keyword",
    transform: "textLeft",
    align: "start",
  }),
  customHeader({
    title: "Project",
    value: "project_name",
    transform: false,
  }),
  customHeader({
    title: "Category",
    value: "category",
    transform: false,
  }),
  customHeader({
    title: "Updated At",
    value: "updated_at",
    transform: "timestamp",
  }),
  customHeader({
    title: "Action",
    value: "action",
    transform: "action_component",
    type: "keyword",
  }),
];
export const PROJECT_CATEGORY_EDIT_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Project",
    value: "project_name",
    transform: "textLeft",
    align: "start",
  }),
  customHeader({
    title: "Category",
    value: "category",
    transform: false,
  }),
  customHeader({
    title: "Created At",
    value: "created_at",
    transform: "timestamp",
  }),
  customHeader({
    title: "Updated At",
    value: "updated_at",
    transform: "timestamp",
  }),
  customHeader({
    title: "Action",
    value: "action",
    transform: "action_component",
    type: "category",
  }),
];
export const DISCORD_SERVERINVITE_EDIT_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Server",
    value: "name",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Invite Link",
    value: "invite",
    transform: false,
  }),
  customHeader({
    title: "Scraper ID",
    value: "scraper",
    transform: false,
  }),
  customHeader({
    title: "Project",
    value: "project_id",
    transform: "project_id",
  }),
  customHeader({
    title: "Updated At",
    value: "updated_at",
    transform: "timestamp",
  }),
  customHeader({
    title: "Action",
    value: "action",
    transform: "action_component",
    type: "serverinvite",
  }),
];
export const BLOG_EDIT_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Title",
    value: "title",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "User",
    value: "user_name",
  }),
  customHeader({
    title: "Updated At",
    value: "posted_time",
    transform: "timestamp",
  }),
  customHeader({
    title: "Action",
    value: "action",
    transform: "action_component",
    type: "blog",
  }),
];
export const YOUTUBE_PROJECTS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Project",
    value: "keyword",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Videos",
    value: "videos",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Videos +%",
    value: "videos",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Channels",
    value: "influencers",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Channels +%",
    value: "influencers",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Avg Subs",
    value: "avg_subscribers",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Avg Subs +%",
    value: "avg_subscribers",
    transform: "prePercentage",
    type: "prePercentage",
  }),
];

export const YOUTUBE_VIDEOS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Video",
    value: "title",
    transform: "videoModal",
    align: "start",
  }),
  customHeader({
    title: "Channel",
    value: "channelTitle",
    transform: "link",
  }),
  customHeader({
    title: "Views",
    value: "viewCount",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "View Rate",
    value: "viewRate",
    transform: "percentage",
    type: "percentage",
  }),
  customHeader({
    title: "Length",
    value: "duration",
    transform: "duration",
    type: "numeric",
  }),
  customHeader({
    title: "Publish",
    value: "publishedAt",
    transform: "timestamp",
    type: "datetime",
  }),
];

export const YOUTUBE_CHANNELS_TABLE_HEADER = [
  bookmarkHeader,
  indexHeader,
  customHeader({
    title: "Channel",
    value: "title",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Videos",
    value: "videos",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Videos +%",
    value: "videos_percentage",
    transform: "prePercentage",
    type: "percentage",
    subscription: 2,
  }),
  customHeader({
    title: "Subs",
    value: "subscriberCount",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "+Subs",
    value: "subscriberCount_diff",
    transform: "shorterNumber",
    type: "numeric",
    subscription: 2,
  }),
  customHeader({
    title: "Subs +%",
    value: "subscriberCount_percentage",
    transform: "prePercentage",
    type: "percentage",
  }),
  customHeader({
    title: "Views",
    value: "viewCount",
    transform: "shorterNumber",
    type: "numeric",
    subscription: 2,
  }),
  customHeader({
    title: "+Views",
    value: "viewCount_diff",
    transform: "shorterNumber",
    type: "numeric",
    subscription: 2,
  }),
  customHeader({
    title: "Views +%",
    value: "viewCount_percentage",
    transform: "prePercentage",
    type: "percentage",
    subscription: 2,
  }),
  customHeader({
    title: "Topic",
    value: "topic",
    transform: "topic",
    type: "numeric",
    checked: false,
    subscription: 2,
  }),
  // voteHeader,
];

export const TWITTER_PROJECTS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Project",
    value: "keyword",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Tweets",
    value: "tweets",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Tweets +%",
    value: "tweets",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Users",
    value: "users",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Users +%",
    value: "users",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Avg Follower",
    value: "avg_followers",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Avg Follower +%",
    value: "avg_followers",
    transform: "prePercentage",
    type: "prePercentage",
  }),
];

export const TWITTER_TWEETS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Tweet",
    value: "text",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Language",
    value: "lang",
    transform: "lang",
  }),
  customHeader({
    title: "User",
    value: "username",
    transform: "link",
  }),
  customHeader({
    title: "Followers",
    value: "followers",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Time",
    value: "datetime",
    transform: "timestamp",
    type: "datetime",
  }),
];

export const TWITTER_USERS_TABLE_HEADER = [
  bookmarkHeader,
  indexHeader,
  customHeader({
    title: "User",
    value: "name",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Tweets",
    value: "tweets",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Tweets +%",
    value: "tweets",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Flw",
    value: "followers",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "+Flw",
    value: "followers",
    transform: "preCount",
    type: "preCount",
  }),
  customHeader({
    title: "Flw +%",
    value: "followers",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Topic",
    value: "topic",
    transform: "topic",
    type: "numeric",
    checked: false,
  }),
  // voteHeader,
];

export const TELEGRAM_PROJECTS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Project",
    value: "keyword",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Groups",
    value: "dialogs",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Users",
    value: "participants",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Users +%",
    value: "participants",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Messages",
    value: "message_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Messages +%",
    value: "message_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Writers",
    value: "user_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Writers +%",
    value: "user_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
];

export const DISCORD_MESSAGES_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Message",
    value: "content",
    transform: "text400",
    align: "start",
  }),
  customHeader({
    title: "Keyword",
    value: "keyword",
  }),
  customHeader({
    title: "Lang",
    value: "lang",
    transform: "lang",
  }),
  customHeader({
    title: "Channel",
    value: "channel_id",
  }),
  customHeader({
    title: "Author",
    value: "author_id",
    transform: "discord_avatar",
  }),
  customHeader({
    title: "Timestamp",
    value: "timestamp",
    transform: "timestamp",
    type: "datetime",
  }),
];

export const DISCORD_PROJECTS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Project",
    value: "keyword",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Servers",
    value: "servers",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Users",
    value: "member_count",
    transform: "commaNumber",
    type: "numeric",
    standard: true,
    premium: true,
    vip: true,
  }),
  customHeader({
    title: "Users +%",
    value: "member_count",
    transform: "prePercentage",
    type: "prePercentage",
    standard: true,
    premium: true,
    vip: true,
  }),
  customHeader({
    title: "Messages",
    value: "message_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Messages +%",
    value: "message_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Writers",
    value: "user_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Writers +%",
    value: "user_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  // customHeader({
  //   title: "New Writer",
  //   value: "local_new_writer",
  //   transform: "commaNumber",
  //   type: "numeric",
  // }),
  // customHeader({
  //   title: "Global New Writer",
  //   value: "global_new_writer",
  //   transform: "commaNumber",
  //   type: "numeric",
  // }),
];
export const DISCORD_SERVERS_TABLE_HEADER = [
  bookmarkHeader,
  indexHeader,
  customHeader({
    title: "Server",
    value: "name",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Users",
    value: "member_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "+Users",
    value: "member_count",
    transform: "preCount",
    type: "preCount",
  }),
  customHeader({
    title: "Users +%",
    value: "member_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Messages",
    value: "message_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Messages +%",
    value: "message_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Writers",
    value: "user_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Writers +%",
    value: "user_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Reply",
    value: "reply_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Reply +%",
    value: "reply_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Avg Word",
    value: "words_avg",
    transform: "float2",
    type: "numeric",
  }),
  // customHeader({
  //   title: "New Writer",
  //   value: "local_new_writer",
  //   transform: "commaNumber",
  //   type: "numeric",
  // }),
  // customHeader({
  //   title: "Global New Writer",
  //   value: "global_new_writer",
  //   transform: "commaNumber",
  //   type: "numeric",
  // }),
  customHeader({
    title: "Topic",
    value: "topic",
    transform: "topic",
    type: "numeric",
    checked: false,
  }),
  // voteHeader,
];

export const TELEGRAM_DIALOGS_TABLE_HEADER = [
  bookmarkHeader,
  indexHeader,
  customHeader({
    title: "Group",
    value: "title",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Users",
    value: "participants",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "+Users",
    value: "participants",
    transform: "preCount",
    type: "preCount",
  }),
  customHeader({
    title: "Users +%",
    value: "participants",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Messages",
    value: "message_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Messages +%",
    value: "message_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Writers",
    value: "user_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Writers +%",
    value: "user_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Reply",
    value: "reply_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Reply +%",
    value: "reply_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Avg Word",
    value: "words_avg",
    transform: "float2",
    type: "numeric",
  }),
  customHeader({
    title: "Topic",
    value: "topic",
    transform: "topic",
    type: "numeric",
    checked: false,
  }),
  // voteHeader,
];

export const DISCORD_CHANNELS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Name",
    value: "name",
    transform: "textLeft",
    align: "start",
  }),
  customHeader({
    title: "Messages",
    value: "message_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Messages +%",
    value: "message_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "Member",
    value: "user_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Member +%",
    value: "user_count",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  // { title: 'Quoted', value: 'quoted_msg_count', transform: "commaNumber", type: 'numeric', fixed: false, colored: false, width: '5%', sort: true, checked: true, standard: true, premium: true, vip: true, align: 'end' }),
  // customHeader({
  //   title: "Reply",
  //   value: "reply_count",
  //   transform: "commaNumber",
  //   type: "numeric",
  // }),
  // customHeader({
  //   title: "Reply +%",
  //   value: "reply_count",
  //   transform: "prePercentage",
  //   type: "prePercentage",
  // }),
  customHeader({
    title: "Avg Word",
    value: "words_avg",
    transform: "float2",
    type: "numeric",
  }),
  customHeader({
    title: "Server",
    value: "server_name",
    transform: "link",
  }),
];

export const NEWS_PROJECTS_TABLE_HEADER = [
  bookmarkHeader,
  indexHeader,
  customHeader({
    title: "Project",
    value: "keyword",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "News",
    value: "news",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Users +%",
    value: "news",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  // voteHeader,
];

export const BLOGS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Title",
    value: "title",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "User",
    value: "user_name",
  }),
  customHeader({
    title: "Shared",
    value: "posted_time",
    transform: "timestamp",
    type: "datetime",
  }),
  // voteHeader,
];

export const PROJECT_TABLE_HEADER = [
  bookmarkHeader,
  indexHeader,
  customHeader({
    title: "Project",
    value: "keyword",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Content",
    value: "content",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Content +%",
    value: "content_percentage",
    transform: "prePercentage",
    type: "percentage",
  }),
  customHeader({
    title: "Influencer",
    value: "influencer",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Influencer +%",
    value: "influencer_percentage",
    transform: "prePercentage",
    type: "percentage",
  }),
  // voteHeader,
];

export const PROJECTS_MARKET_TABLE_HEADER = [
  bookmarkHeader,
  indexHeader,
  customHeader({
    title: "Project",
    value: "keyword",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Price",
    value: "price",
    transform: "coinPrice",
    type: "numeric",
  }),
  customHeader({
    title: "1h %",
    value: "change1h",
    transform: "marketpercentage",
    type: "numeric",
  }),
  customHeader({
    title: "24h %",
    value: "change24h",
    transform: "marketpercentage",
    type: "numeric",
  }),
  customHeader({
    title: "7d %",
    value: "change7d",
    transform: "marketpercentage",
    type: "numeric",
  }),
  customHeader({
    title: "Market Cap",
    value: "marketCap",
    transform: "commaNumberDollar",
    type: "numeric",
  }),
  customHeader({
    title: "Volume(24h)",
    value: "volume24",
    transform: "commaNumberDollar",
    type: "numeric",
  }),
  customHeader({
    title: "Last 7 Days",
    value: "sparkline",
    transform: "sparkline",
    type: "sparkline",
  }),
  // voteHeader,
];

export const WEBSITE_TOPIC_HEADER = [
  bookmarkHeader,
  indexHeader,
  customHeader({
    title: "Website",
    value: "domain",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Summary",
    value: "micro_summary",
  }),
  customHeader({
    title: "Links",
    value: "links",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "First Shared",
    value: "first_datetime",
    transform: "timestamp",
    type: "datetime",
  }),
  customHeader({
    title: "Last Shared",
    value: "last_datetime",
    transform: "timestamp",
    type: "datetime",
  }),
  customHeader({
    title: "Topic",
    value: "topic",
    transform: "topic",
    type: "numeric",
    checked: false,
  }),
  // voteHeader,
];
export const USERS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "email",
    value: "email",
    transform: "user_avatar",
    align: "start",
  }),
  customHeader({
    title: "First Name",
    value: "first_name",
  }),
  customHeader({
    title: "Last Name",
    value: "last_name",
  }),
  customHeader({
    title: "Active",
    value: "is_active",
    transform: "checkBox",
    type: "action",
  }),
  customHeader({
    title: "Is Staff",
    value: "is_staff",
    transform: "checkBox",
  }),
  customHeader({
    title: "Joined",
    value: "date_joined",
    transform: "timestamp",
    type: "datetime",
  }),
  customHeader({
    title: "Role",
    value: "role_name",
  }),
  customHeader({
    title: "Subscription",
    value: "subscription_name",
  }),
];
export const WEBSITE_USERS_TABLE_HEADER = [
  bookmarkHeader,
  indexHeader,
  customHeader({
    title: "Website",
    value: "domain",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Description",
    value: "micro_summary",
    transform: "textLeft",
    align: "start",
  }),
  customHeader({
    title: "Channels",
    value: "youtube_channels",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Subscriber",
    value: "followers",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Videos",
    value: "youtube_videos",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Views",
    value: "youtube_views",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Links",
    value: "links",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Users",
    value: "community_users",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Community",
    value: "community_count",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Added",
    value: "first_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
  customHeader({
    title: "Added",
    value: "youtube_first_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
  customHeader({
    title: "Added",
    value: "community_first_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
  customHeader({
    title: "Last Shared",
    value: "last_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
  customHeader({
    title: "Last Shared",
    value: "youtube_last_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
  customHeader({
    title: "Last Shared",
    value: "community_last_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
  // customHeader({
  //   title: "Users",
  //   value: "total",
  //   transform: "commaNumber",
  //   type: "numeric",
  // }),

  customHeader({
    title: "Topic",
    value: "topic",
    transform: "topic",
    type: "numeric",
    checked: false,
  }),
  // voteHeader,
];
export const YOUTUBE_CHANNEL_WEBSITES_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Website",
    value: "domain",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Description",
    value: "micro_summary",
    transform: "textLeft",
    align: "start",
  }),
  customHeader({
    title: "Links",
    value: "links",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Videos",
    value: "videos",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Topic",
    value: "topic",
    transform: "topic",
    type: "numeric",
    checked: false,
  }),
  customHeader({
    title: "Shared",
    value: "final_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
  // voteHeader,
];
export const DISCORD_SERVER_WEBSITES_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Website",
    value: "domain",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Description",
    value: "micro_summary",
    transform: "textLeft",
    align: "start",
  }),
  customHeader({
    title: "Links",
    value: "links",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Users",
    value: "users",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Topic",
    value: "topic",
    transform: "topic",
    type: "numeric",
    checked: false,
  }),
  customHeader({
    title: "Shared",
    value: "final_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
  // voteHeader,
];
export const YOUTUBE_CHANNEL_WEBSITE_SOCIAL_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Link",
    value: "link",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Videos",
    value: "videos",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Shared",
    value: "final_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
];
export const DISCORD_SERVER_WEBSITE_SOCIAL_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Link",
    value: "link",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Users",
    value: "users",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Shared",
    value: "final_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
];
export const WEBSITE_SOCIAL_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Link",
    value: "link",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Channels",
    value: "channels",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Discords",
    value: "discords",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Telegrams",
    value: "telegrams",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Users",
    value: "total",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Shared",
    value: "final_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
];
export const WEBSITES_SOCIAL_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Website",
    value: "domain",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Links",
    value: "links",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Shared",
    value: "final_datetime",
    transform: "timestamp",
    type: "timestamp",
  }),
];
export const WEBSITE_TOPIC_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Name",
    value: "name",
    transform: "link",
    align: "start",
  }),
  // customHeader({
  //   title: "Summary",
  //   value: "summary",
  // }),
  customHeader({
    title: "Websites",
    value: "websites",
    transform: "numeric",
  }),
];
export const YOUTUBE_CHANNEL_PROJECTS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Project",
    value: "keyword",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Videos",
    value: "videos",
    transform: "numeric",
  }),
  customHeader({
    title: "Videos +%",
    value: "videos",
    transform: "prePercentage",
    type: "prePercentage",
  }),
  customHeader({
    title: "%",
    value: "videos_percent_without_others",
    transform: "percentage",
    type: "numeric",
  }),
  customHeader({
    title: "Views",
    value: "views",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Last Video",
    value: "final_publishedAt",
    transform: "timestamp",
    type: "datetime",
  }),
];
export const LINKS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Link",
    value: "link",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Project",
    value: "keyword",
  }),
  customHeader({
    title: "Website",
    value: "domain",
    transform: "link",
  }),
  customHeader({
    title: "Sum Followers",
    value: "sum_followers",
    transform: "shorterNumber",
    type: "numeric",
  }),
];
export const NEWS_TABLE_HEADER = [
  customHeader({
    title: "Link",
    value: "link",
    transform: "link",
    align: "start",
  }),
];
export const LINK_DETAILS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Link",
    value: "link",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Website",
    value: "domain",
    transform: "link",
  }),
  customHeader({
    title: "Project",
    value: "keyword",
  }),
  customHeader({
    title: "Social",
    value: "found_site",
  }),
  customHeader({
    title: "Model",
    value: "found_model",
  }),
  customHeader({
    title: "Content",
    value: "content",
    transform: "detailsItem",
  }),
  customHeader({
    title: "User",
    value: "user",
    transform: "detailsItem",
  }),
  customHeader({
    title: "Followers",
    value: "follwers",
    transform: "detailsItem",
    type: "numeric",
  }),
  customHeader({
    title: "Time",
    value: "published_timestamp",
    transform: "timestamp",
    type: "datetime",
  }),
];

export const PARTNERS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Channel",
    value: "title",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Subs",
    value: "subscriberCount",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Dedicated",
    value: "videos",
    transform: "Dedicated",
    // type: "suggestRate"
  }),
  customHeader({
    title: "Promoting",
    value: "first_datetime",
    transform: "Promoting",
    // type: "promoting"
  }),
  customHeader({
    title: "Links",
    value: "links",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Videos",
    value: "videos",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Suggest Rate",
    value: "videos",
    transform: "suggestRate",
    type: "suggestRate",
  }),
  // customHeader({
  //   title: "Avg Views",
  //   value: "viewCount",
  //   transform: "avgViews",
  //   type: "avgViews",
  // }),
  customHeader({
    title: "Views",
    value: "viewCount",
    transform: "shorterNumber",
    type: "numeric",
  }),
  // customHeader({
  //   title: "View Rate",
  //   value: "viewRate",
  //   transform: "percentage",
  //   type: "percentage",
  // }),
  customHeader({
    title: "Shared",
    value: "final_datetime",
    transform: "timestamp",
    type: "datetime",
  }),
];
export const NEW_PARTNERS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Channel",
    value: "title",
    transform: "link",
    align: "start",
  }),
  customHeader({
    title: "Subs",
    value: "subscriberCount",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Videos",
    value: "videos",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "Views",
    value: "viewCount",
    transform: "shorterNumber",
    type: "numeric",
  }),
  customHeader({
    title: "First Promo",
    value: "first_datetime",
    transform: "timestamp",
    type: "datetime",
  }),
];
export const WEBSITE_VIDEOS_TABLE_HEADER = [
  indexHeader,
  customHeader({
    title: "Video",
    value: "title",
    transform: "videoModal",
    align: "start",
  }),
  customHeader({
    title: "Channel",
    value: "channelTitle",
    transform: "link",
  }),
  customHeader({
    title: "Views",
    value: "viewCount",
    transform: "commaNumber",
    type: "numeric",
  }),
  customHeader({
    title: "View Rate",
    value: "viewRate",
    transform: "percentage",
    type: "percentage",
  }),
  customHeader({
    title: "Length",
    value: "duration",
    transform: "duration",
    type: "numeric",
  }),
  customHeader({
    title: "Shared",
    value: "publishedAt",
    transform: "timestamp",
    type: "datetime",
  }),
];

