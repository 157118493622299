import React, { useLayoutEffect } from "react";
import { Col, Row } from "react-bootstrap";
// import StatisticsInforRow from "../../../../components/Shared/StatitsicsInforRow";
import "./styles.css";
import { getIconFromMedia } from "../../../../utils/routingHelper";
import TOC from "../../../../components/Shared/TOC";
import Faq from "../Faq";
import TopnewsCards from "../TopnewsCards";
import { RecommendItems } from "../..";

const Overview = (props) => {
  const { detailsData } = props;
  // const avgViews =
  //   detailsData?.videoCount > 0
  //     ? detailsData?.viewCount / detailsData?.videoCount
  //     : 0;
  // const avgSub =
  //   detailsData?.channels > 0
  //     ? detailsData?.followers / detailsData?.channels
  //     : 0;
  // const viewRate =
  //   detailsData?.followers > 0 ? (avgViews / detailsData?.followers) * 100 : 0;

  const hashItems = [
    // {
    //   name: "title",
    //   hash: "#title",
    //   component: () => (
    //     <div style={{ fontSize: 24, fontWeight: "bold" }}>
    //       {detailsData?.micro_summary}
    //     </div>
    //   ),
    // },
    {
      name: "image",
      hash: "#image",
      component: () => (
        <div className="mt-0">
          <img
            src={getIconFromMedia(detailsData?.main_screenshot)}
            alt=""
            className="mr-3"
            style={{ width: "100%" }}
          />
        </div>
      ),
    },
    {
      name: "summary",
      hash: "#summary",
      component: () => (
        <div className="website-details-overview-summary-box">
          <div className="website-details-overview-summary-content">
            {detailsData?.long_summary}
          </div>
        </div>
      ),
    },
    {
      name: "blog1",
      hash: "#blog1",
      component: () => (
        <>
          {/* <div className="website-overview-blog">
            <h1>
              Mode: A Comprehensive Review of the Modular DeFi L2 Building the
              Superchain
            </h1>
            <section>
              <h2>Introduction to Mode</h2>
              <p>
                Mode is not just another DeFi platform; it's a comprehensive
                ecosystem designed to incentivize and reward developers and
                users alike, offering a wide range of features and incentives to
                foster growth and innovation. At its core, Mode operates as a
                Layer 2 solution built on top of Ethereum, leveraging the power
                of Optimism to enhance scalability and reduce transaction fees.
                With Mode, developers can quickly deploy their applications,
                access tools and resources, and tap into a vibrant community of
                like-minded individuals.
              </p>
            </section>
            <section>
              <h2>Features and Benefits</h2>
              <ul>
                <li>
                  <h3>
                    <strong>Developer-Friendly Environment</strong>{" "}
                  </h3>
                  <p>
                    One of Mode's standout features is its developer-friendly
                    environment. With support for Ethereum Virtual Machine (EVM)
                    compatibility, developers familiar with Ethereum can
                    seamlessly transition to Mode, bringing their projects to a
                    new audience without the steep learning curve. Additionally,
                    Mode offers robust documentation, tooling, and resources to
                    support developers at every stage of the development
                    process.
                  </p>
                </li>
                <li>
                  <h3>Onchain Economic Incentives</h3>
                  <p>
                    Mode introduces innovative onchain economic mechanisms to
                    incentivize participation and growth within the ecosystem.
                    Sequencer Fee Sharing rewards developers for their
                    contributions by sharing a portion of network sequencer
                    profits. This incentivizes developers to build high-quality
                    applications that drive network usage and adoption.
                    Furthermore, Developer Airdrops provide additional rewards
                    to developers, encouraging them to actively contribute to
                    the Mode ecosystem.
                  </p>
                </li>
                <li>
                  <h3>Low Fees and High Scalability</h3>
                  <p>
                    Scalability and affordability are critical considerations
                    for any blockchain platform. Mode addresses these challenges
                    by implementing Optimism's Bedrock upgrade, significantly
                    reducing transaction fees and increasing throughput. With
                    over 95% lower fees compared to Ethereum, Mode offers a
                    cost-effective solution for deploying and interacting with
                    decentralized applications (dApps), making it accessible to
                    a broader audience.
                  </p>
                </li>
                <li>
                  <h3>Community Engagement and Support</h3>
                  <p>
                    Mode places a strong emphasis on community engagement and
                    support, fostering collaboration and innovation within its
                    ecosystem. Through channels such as{" "}
                    <a href="https://discord.com/">Discord</a>,{" "}
                    <a href="https://twitter.com/">Twitter</a>, and{" "}
                    <a href="https://telegram.org/">Telegram</a>, users can
                    connect with like-minded individuals, seek assistance, and
                    stay updated on the latest developments. Additionally, Mode
                    provides extensive documentation, tutorials, and developer
                    resources to empower users and developers alike.
                  </p>
                </li>
              </ul>
            </section>
            <section>
              <h2>Critic Points</h2>
              <p>
                While Mode offers a compelling suite of features and benefits,
                it's essential to acknowledge potential areas for improvement.
                One possible concern is the level of competition within the DeFi
                space. With numerous platforms vying for market share, Mode must
                differentiate itself effectively and continuously innovate to
                maintain its competitive edge.
              </p>
              <p>
                Additionally, while Mode's low fees and scalability are
                significant advantages, scalability solutions such as Optimism
                are still relatively nascent and may face challenges as they
                scale to accommodate increasing demand. Ensuring robustness,
                security, and decentralization while scaling is paramount to
                maintaining user trust and confidence.
              </p>
              <p>
                Furthermore, while Mode's onchain economic incentives are
                innovative, there may be complexities and uncertainties
                regarding their implementation and effectiveness. Balancing the
                needs of developers, users, and the broader ecosystem requires
                careful planning and execution to achieve sustainable growth and
                prosperity.
              </p>
            </section>
            <section>
              <h2>Conclusion</h2>
              <p>
                In conclusion, Mode represents a promising evolution in the DeFi
                landscape, offering a comprehensive ecosystem tailored to the
                needs of developers and users. With its developer-friendly
                environment, innovative onchain economic incentives, low fees,
                and scalability, Mode is well-positioned to drive forward the
                next wave of DeFi innovation. However, it's essential to
                approach Mode with a critical eye, acknowledging potential
                challenges and uncertainties while also recognizing its
                potential to reshape the future of decentralized finance.
              </p>
              <p>
                As Mode continues to evolve and mature, it will be fascinating
                to see how it navigates the ever-changing DeFi landscape and
                fulfills its vision of becoming the leading platform for
                decentralized finance. Whether you're a developer looking to
                build the next groundbreaking dApp or a user seeking to explore
                new opportunities in DeFi, Mode offers a compelling platform
                worth exploring.
              </p>
              <p>
                So, if you're ready to embark on a journey of innovation and
                discovery in the world of decentralized finance, consider
                joining the Mode ecosystem today. With its promise of
                scalability, affordability, and community-driven growth, Mode is
                poised to make waves in the DeFi space and redefine the future
                of finance as we know it. Join the Mode revolution and be part
                of something extraordinary.
              </p>
            </section>
          </div> */}
        </>
      ),
    },
    {
      name: "blog2",
      hash: "#blog2",
      component: () => (
        <>
          {/* <article className="website-overview-blog">
            <header>
              <h2>
                Shaping the Future of Ethereum: The Arbitrum Foundation's Vision
              </h2>
            </header>
            <section>
              <p>
                The Arbitrum Foundation is at the forefront of a revolution in
                the Ethereum ecosystem, championing the cause of decentralized
                governance through innovative rollup technology. This blog post
                explores the foundation's vision for a collaborative and
                user-empowered future, highlighting the mechanisms of
                governance, token delegation, and the collective shaping of the
                ecosystem.
              </p>
            </section>
            <section>
              <h3>The Vision of Arbitrum Foundation:</h3>
              <p>
                At its core, the Arbitrum Foundation envisions a world where the
                users of a platform have a significant say in its operations,
                development, and future direction. Through the adoption of
                decentralized governance, Arbitrum is breaking down the barriers
                between developers and users, fostering a sense of ownership and
                responsibility across the community.
              </p>
            </section>
            <section>
              <h3>Decentralized Governance and User Empowerment:</h3>
              <p>
                Decentralized governance is more than just a buzzword for the
                Arbitrum Foundation; it's the principle that guides its approach
                to developing Ethereum rollup technology. By involving users in
                the decision-making process, Arbitrum ensures that its
                technology remains responsive to the needs of its community and
                adaptable to the fast-paced changes in the blockchain landscape.
              </p>
            </section>
            <section>
              <h3>Engagement Through Token Delegation:</h3>
              <p>
                Token delegation plays a vital role in the Arbitrum ecosystem,
                allowing users to entrust their voting rights to others who
                share their vision and values. This system not only amplifies
                the influence of active participants but also ensures that a
                diverse range of voices is heard in governance discussions.
              </p>
            </section>
            <section>
              <h3>Collaboration at the Heart of Innovation:</h3>
              <p>
                The Arbitrum Foundation believes that the key to sustainable
                innovation lies in collaboration. By creating a platform where
                users, developers, and stakeholders can come together to share
                ideas and resources, Arbitrum is nurturing a vibrant community
                dedicated to advancing the Ethereum ecosystem.
              </p>
            </section>
            <section>
              <h3>The Impact on Ethereum's Ecosystem:</h3>
              <p>
                The initiatives led by the Arbitrum Foundation have the
                potential to significantly impact the broader Ethereum
                ecosystem. By improving scalability through rollup technology
                and engaging a wider community in governance, Arbitrum is
                helping to solve some of Ethereum's most pressing challenges,
                setting the stage for a more efficient, inclusive, and
                decentralized future.
              </p>
            </section>
            <footer>
              <p>
                The Arbitrum Foundation's vision for decentralized governance
                and user empowerment represents a bold step forward in the
                evolution of the Ethereum ecosystem. Through innovative rollup
                technology, token delegation, and a commitment to collaboration,
                Arbitrum is not just developing solutions for today but is
                actively shaping the future of blockchain technology.
              </p>
            </footer>
          </article> */}
        </>
      ),
    },
    {
      name: "faq",
      hash: "#faq",
      component: () => <></>, //<Faq></Faq>,
    },
  ];

  useLayoutEffect(() => {
    const toc = document.querySelector(".toc-left-con");
    const topnews = document.querySelector(".topnewscards");
    toc.style.height = "unset";
    topnews.firstChild.insertBefore(toc, topnews.firstChild.firstChild);
  }, []);

  return (
    <>
      <Row>
        <Col xs={{ span: 12, order: 2 }} md={{ span: 9, order: 1 }}>
          <TOC hashItems={hashItems} />
          {/* <Row className="ml-0 mt-2" style={{ fontSize: 18 }}>
          {detailsData?.main_screenshot && (
            <div className="custom-margin-20">
              <img
                src={getIconFromMedia(detailsData?.main_screenshot)}
                alt=""
                className="mr-3"
                style={{ width: "100%" }}
              />
            </div>
          )}
        </Row>
        <Row className="ml-0 mt-3" style={{ fontSize: 18 }}>
          <div className="custom-margin-20">{detailsData?.long_summary}</div>
        </Row>
        <TOC />
        <Row className="ml-0 mt-3">
          <Faq></Faq>
        </Row> */}

          <RecommendItems />
        </Col>
        <Col
          className="topnewscards"
          xs={{ span: 12, order: 1 }}
          md={{ span: 3, order: 2 }}
        >
          <TopnewsCards detailsData={detailsData} vertical />
        </Col>
        {/* <Col xs={12} md={3}>
        <div className="mb-3" style={{ fontSize: 18, fontWeight: "bold" }}>
          {"Shared in the Last 30 Days"}
        </div>
        <StatisticsInforRow
          title="Youtube Channels"
          count={detailsData?.channels}
          trendable={false}
          format="commaNumber"
        />
        <StatisticsInforRow
          title="Avg Views"
          count={avgViews}
          trendable={false}
        />
        <StatisticsInforRow
          title="Avg View Rate"
          count={viewRate}
          trendable={false}
          format="float3"
          symbol="%"
        />
        <StatisticsInforRow
          title="Subscriber"
          count={detailsData?.followers}
          trendable={false}
        />
        <StatisticsInforRow
          title="Avg Subscriber"
          count={avgSub}
          trendable={false}
        />
        <StatisticsInforRow
          title="Discord Users"
          count={detailsData?.discords}
          trendable={false}
          format="commaNumber"
        />
        <StatisticsInforRow
          title="Telegram Users"
          count={detailsData?.telegrams}
          trendable={false}
          format="commaNumber"
        />
      </Col> */}
      </Row>
    </>
  );
};

export default Overview;

