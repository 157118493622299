import React, { useEffect } from "react";

import MainTable from "../../../../components/Tables/MainTable";
import { useParams } from "react-router-dom";

import {
  TELEGRAM_DIALOGS_TABLE_HEADER,
  TELEGRAM_DIALOGS_MAIN_TABLE_API,
} from "../../../../config/constants/tableHeaders";
import { RecommendItems } from "../..";

const TelegramGroups = (props) => {
  const UPDATE_TELEGRAM_GROUPS_TABLE_HEADER =
    TELEGRAM_DIALOGS_TABLE_HEADER.filter(
      (header) =>
        header.title !== "Owner" &&
        header.title !== "Region" &&
        header.title !== "Online Users" &&
        header.title !== "+ Online Users"
    );
  const { nickName } = useParams();
  useEffect(() => {
    props.setSelectedRoute("groups");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MainTable
        tableHeader={UPDATE_TELEGRAM_GROUPS_TABLE_HEADER}
        URL={TELEGRAM_DIALOGS_MAIN_TABLE_API}
        withTermsFilter
        projectNickName={nickName}
        withoutProjectFilter
        withoutFilterModal
        defaultTerm={"7d"}
        model={"telegram-groups"}
        modelName="project-telegram-group--nickName"
      />
      <RecommendItems />
    </>
  );
};

export default TelegramGroups;

