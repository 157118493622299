import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({
  parentLink,
  parentLabel,
  currentLabel,
  homeLabel = "Home",
}) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">{homeLabel}</Link>
        </li>
        {parentLabel !== "" && parentLabel !== undefined && (
          <li className="breadcrumb-item">
            <Link to={parentLink}>{parentLabel}</Link>
          </li>
        )}
        <li className="breadcrumb-item">{currentLabel}</li>
      </ol>
    </nav>
  );
};

export default Breadcrumb;

