import React from "react";
import { Button, Modal } from "react-bootstrap";
import BoolField from "./BoolField";
import FilterModalField from "./FilterModalField";
import FullModal from "../../../FullModal";

const TableAdvancedFilterModal = ({
  show,
  setShowModal,
  filterModalFields,
  filters,
  setFilters,
  handleApplyFilter,
  isMobile,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleApplyFilter();
    }
  };

  const renderFields = () =>
    filterModalFields?.map((modalField) => (
      <div key={modalField.value} className="col-6">
        {modalField.type === "bool" ? (
          <BoolField
            label={modalField.label}
            field={modalField.value}
            filters={filters}
            setFilters={setFilters}
          />
        ) : (
          <FilterModalField
            label={modalField.label}
            field={modalField.value}
            filters={filters}
            setFilters={setFilters}
            inputType={modalField.type}
          />
        )}
      </div>
    ));

  const clearFilters = () => {
    const resetFilters = {};
    filterModalFields.forEach((field) => {
      resetFilters[field.value] = "";
    });
    setFilters(resetFilters);
  };

  const modalFooter = (
    <>
      <Button variant="outline-danger" size="lg" onClick={clearFilters}>
        Remove All
      </Button>
      <Button variant="outline-primary" size="lg" onClick={handleApplyFilter}>
        Apply Filter
      </Button>
    </>
  );

  if (isMobile) {
    return (
      <FullModal
        isShowModal={show}
        setShowModal={setShowModal}
        onHide={() => setShowModal(false)}
        showHeader={true}
        title="Advanced Filter"
      >
        <Modal.Body>
          <div className="row">{renderFields()}</div>
        </Modal.Body>
        <Modal.Footer>{modalFooter}</Modal.Footer>
      </FullModal>
    );
  } else {
    return (
      <Modal
        show={show}
        onHide={() => setShowModal(false)}
        size="md"
        aria-labelledby="filter-modal"
        onKeyDown={handleKeyDown}
      >
        <Modal.Header closeButton>
          <Modal.Title id="filter-modal">Advanced Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">{renderFields()}</div>
        </Modal.Body>
        <Modal.Footer>{modalFooter}</Modal.Footer>
      </Modal>
    );
  }
};

export default TableAdvancedFilterModal;

