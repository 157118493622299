export const selectionStyle = (minWidth = 75, height = 38) => {
  return (
    {
      control: styles => ({ ...styles, minHeight: height, height: height, backgroundColor: '#323546', borderColor: 'transparent !important', boxShadow: "0", borderRadius: '8px', margin: '0', minWidth: minWidth }),
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
              ? "#3861fb1a"
              : isFocused
                ? "#171924"
                : null,
          color: isDisabled
            ? '#FFD166'
            : isSelected
              ? '#6188ff'
              : isFocused
                ? "white"
                : null,
          margin: "0",
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          fontSize: '12px',
          borderRadius: '0',
        };
      },
      input: (styles) => ({ ...styles, color: "white", width: "100%", position: 'relative' }),
      singleValue: styles => ({ ...styles, color: "white" }),
      menu: styles => ({
        ...styles, backgroundColor: "#323546", 
      }),
      menuList: styles => ({ ...styles, backgroundColor: "#323546", width: '200px', left: 0 }),
      indicatorSeparator: styles => ({ ...styles, display: "none" }),
      dropdownIndicator: styles => ({ ...styles, padding: "0px" }),
      indicatorsContainer: styles => ({ ...styles, margin: "0px", padding: "0px" }),
      valueContainer: styles => ({ ...styles, margin: "0px 0px 0px 8px", padding: "0px" }),
    }
  )
}
