import React from "react";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import { Img } from "react-image";
import {
  diffTimeStamp,
  seconds2HMS,
  transformNumber,
} from "../../../../../../utils/tableDataHelper";
import { getYoutubeChannelIcon } from "../../../../../../utils/routingHelper";

const VideoCard = (props) => {
  const { video, index, showModal} = props;
  const history = useHistory();
  // const thumbnailHeight = 120;
  // const thumbnailWidth = parseInt((thumbnailHeight * 4) / 3);
  // const goLink = () => {
  //   const path = `https://youtube.com/watch?v=${video?.videoId}`;
  //   window.open(path, "_blank", "noopener,noreferrer");
  // };
  const navigateTo = () => {
    // showModal(index);
    history.push(`/youtube/channel/${video?.channelId}`); // Use history to navigate
  };
  const searchItems =
    useSelector((state) => state.searchItems.searchItems) || [];
  const channelItems = searchItems.filter((item) => item.model_id === "1");

  return (
    <div
      className="comment-con"
      style={{ color: "lightgray" }}
      // onClick={goLink}
    >
      <div className="d-flex flex-column">
        <div>
          <div
            className="d-flex flex-row align-items-center project-channel-title mb-1"
            onClick={navigateTo}
          >
            <img
              src={getYoutubeChannelIcon(channelItems, video?.channelId)}
              alt="pfp"
              width={20}
              height={20}
              className="rounded-circle"
              style={{
                boxShadow:
                  "inset 0 3px 6px rgba(0,0,0,0.16), 0 4px 6px rgba(0,0,0,0.45)",
              }}
            />

            <p
              className="ml-2 mb-0 h6 gray-text system-font"
              style={{
                maxWidth: 180,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {video?.channelTitle}
            </p>
            <span className="gray-dot "></span>
            <span className="gray-text">
              {diffTimeStamp(video?.publishedAt)}
            </span>
          </div>
        </div>
        <div onClick={()=> showModal(index)} style={{marginTop: 4, marginBottom: 4, fontSize: 14}}>
          {video?.title}
        </div>
        <div onClick={()=> showModal(index)}
        >
          <Img
            // width={thumbnailWidth}
            // height={thumbnailHeight}
            width={"100%"}
            style={{ borderRadius: 10 }}
            src={`https://i.ytimg.com/vi/${video?.videoId}/maxresdefault.jpg`}
          />
        </div>
        <div className="d-flex flex-column">
          <div
            className="d-flex flex-row align-items-center mt-2 justify-content-between"
            style={{ fontSize: 12 }}
          >
            <div className="d-flex flex-row align-items-center">
              <Icon
                icon={"ph:eye-bold"}
                height="16px"
                className="gray-text"
                color={"#858ca2"}
              />
              <span className="gray-text ml-2">
                {transformNumber(video?.viewCount, "shorterNumber")}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <Icon
                icon={"bx:like"}
                height="16px"
                className="gray-text"
                color={"#858ca2"}
              />
              <span className="gray-text ml-2">
                {transformNumber(video?.likeCount, "shorterNumber")}
              </span>
            </div>
            <div className="d-flex flex-row align-items-center">
              <Icon
                icon={"pajamas:comment-dots"}
                height="16px"
                className="gray-text"
                color={"#858ca2"}
              />
              <span className="gray-text ml-2">
                {transformNumber(video?.commentCount, "shorterNumber")}
              </span>
            </div>
            {/* <span className="gray-dot "></span> */}
            <div className="d-flex flex-row align-items-center">
              <Icon
                icon={"tdesign:time-filled"}
                height="16px"
                className="gray-text"
                color={"#858ca2"}
              />
              <span className="gray-text ml-2">
                {seconds2HMS(parseInt(video?.duration))}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
