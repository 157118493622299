import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, Flip } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Layout from "../../layout/Layout";
import { BOOKMARK_GROUP_BUTTON, MODEL_IDS } from "../../config/constants/data";
import {
  PROJECTS_MAIN_TABLE_API,
  PROJECT_TABLE_HEADER,
} from "../../config/constants/tableHeaders";

import Breadcrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import MainTable from "../../components/Tables/MainTable";

import {
  getUserBookmarkValue,
  reloadBookmarkValue,
} from "../../store/actions/userBookmarkActions";
import GroupButton from "../../components/GroupButton";
import {
  modelId2ModelName,
  modelId2TableHeaderURL,
} from "../../utils/routing2TableHelper";

const BookmarkPage = () => {
  const { data: bookmarks } = useSelector((state) => state.userBookmark) || {};
  const [activeGroupButtons, setActiveGroupButtons] = useState(
    BOOKMARK_GROUP_BUTTON
  );
  const bookmarkFilter = { bookmark: "1" };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserBookmarkValue({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const bookmarkModels = bookmarks.map((bookmark) => bookmark.model_id);
    const FILTERED_BOOKMARK_GROUP_BUTTON = BOOKMARK_GROUP_BUTTON.filter((row) =>
      bookmarkModels.includes(row.value)
    );
    setActiveGroupButtons(FILTERED_BOOKMARK_GROUP_BUTTON);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmarks]);

  const [selectedRoute, setSelectedRoute] = useState(
    activeGroupButtons?.[0]?.value
  );
  const [tableHeader, setTableHeader] = useState(PROJECT_TABLE_HEADER);
  const [currentAPIUrl, setCurrentAPIUrl] = useState(PROJECTS_MAIN_TABLE_API);
  const [currentModelName, setCurrentModelName] = useState("trending");
  const [currentModel, setCurrentModel] = useState("trending");
  const navTo = (model) => {
    const [newHeader, newURL] = modelId2TableHeaderURL(model);
    const [newModel, newModelName] = modelId2ModelName(model);
    setTableHeader(newHeader);
    setCurrentAPIUrl(newURL);
    setSelectedRoute(model);
    setCurrentModelName(newModelName);
    setCurrentModel(newModel);
    dispatch(reloadBookmarkValue());
  };

  return (
    <Layout>
      <Breadcrumb currentLabel="Watchlist" />
      <PageTitle
        title="Watchlist Page."
        description=" metalyzer shows watchlist projects, youtubers, servers, groups, websites."
      />
      <div className="tabs align-items-center mb-3 mx-1">
        {/* <div className="category_divide" /> */}
        <GroupButton
          items={activeGroupButtons}
          selected={selectedRoute}
          onClick={navTo}
          groupClass="d-flex flex-row align-items-center x-scroll-wrapper"
          buttonClass="project-tab-btn mr-2"
        />
      </div>
      <MainTable
        tableHeader={tableHeader}
        URL={`${currentAPIUrl.slice(0, -1)}-bookmark/`}
        withTermsFilter={selectedRoute !== MODEL_IDS.WEBSITE}
        withoutSearch
        withoutProjectFilter
        withoutFilterModal
        withoutTopicFilter
        filters={bookmarkFilter}
        withWebsiteTopicTypeSelection={currentModel === "websites"}
        model={currentModel}
        modelName={currentModelName}
      />
      <ToastContainer theme="dark" transition={Flip} />
    </Layout>
  );
};

export default BookmarkPage;

