import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";
// import StatisticsBar from "./StatisticsBar";
import ToTopButton from "../components/ToTopButton";
import useUserRole from "../hooks/useUserRole";
import { miniSidenavWidth } from "../config/constants/data";

const Layout = ({ children, fullWidth }) => {
  const { isAuthenticated } = useUserRole();
  const miniSideNav =
    useSelector((state) => state.uiSettings.miniSideNav) || false;

  return (
    <div className="container-fluid p-0">
      {isAuthenticated && <Sidebar />}
      <div
        className="container-fluid pr-0"
        style={
          isAuthenticated
            ? miniSideNav
              ? {
                  paddingLeft: miniSidenavWidth,
                  transition: "0.5s all ease",
                }
              : {
                  paddingLeft: miniSidenavWidth,
                  transition: "0.5s all ease",
                }
            : {}
        }
      >
        <Navbar fluid={true} />

        <div className="container-fluid page-body-wrapper">
          <div
            className={
              fullWidth
                ? "content-wrapper container full-width-max pt-0"
                : "content-wrapper container"
            }
          >
            {children}
          </div>
        </div>
        <Footer />
        <ToTopButton />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

