import React from "react";
import { Route, Redirect } from "react-router-dom";
import NotFound from "../../pages/NotFound/NotFound";

const PrivateRoute = ({
  component: Component,
  authed,
  show404 = false,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <Component {...props} />
        ) : show404 ? (
          <NotFound />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;

