import {
  SUBSCRIPTION_PAYMENT_LOADING,
  SUBSCRIPTION_PAYMENT_SUCCESS,
  SUBSCRIPTION_PAYMENT_FAIL,
} from '../types';

const initialState = {
  subscription: [],
  subscriptionLoading: false,
  subscriptionerror: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SUBSCRIPTION_PAYMENT_LOADING:
      return {
        ...state,
        subscriptionLoading: true,
        subscriptionerror: null,
      };
    case SUBSCRIPTION_PAYMENT_SUCCESS:
      return {
        ...state,
        subscription: payload.data,
        subscriptionLoading: false,
        subscriptionerror: null,
      };
    case SUBSCRIPTION_PAYMENT_FAIL:
      return {
        ...state,
        subscription: [],
        subscriptionLoading: false,
        subscriptionerror: payload?.error
      };
    default:
      return state;
  }
}
