import React from "react";
import { Route, Switch, } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import "../../../../assets/css/styles.css";

import Overview from "../Overview";
import Partners from "../Partners";
import WebsiteVideos from "../WebsiteVideos";
import Faq from "../Faq";
import { Container, } from "react-bootstrap";

const LinksDomainDetailsRouting = ({ detailsData, setSelectedRoute }) => {
  return (
    <div>
      <Container className="px-0">
        <Switch>
          <Route
            exact
            key={0}
            path="/website/:domain"
            component={() => <Overview detailsData={detailsData} />}
          />
          <Route
            key={1}
            path="/website/:domain/faq"
            component={() => (
              <Faq
                detailsData={detailsData}
                setSelectedRoute={setSelectedRoute}
              />
            )}
          />
          <Route
            key={2}
            path="/website/:domain/videos"
            component={() => (
              <WebsiteVideos setSelectedRoute={setSelectedRoute} />
            )}
          />
          <Route
            key={4}
            path="/website/:domain/partners"
            component={() => <Partners setSelectedRoute={setSelectedRoute} />}
          />
        </Switch>
      </Container>
    </div>
  );
};

export default LinksDomainDetailsRouting;
