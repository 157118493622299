import React from 'react'
import { DEFAULT_VIDEO_LENGTH, VIDEO_LENGTH_SELECTION } from '../../../../config/constants/data'
import MainSelection from '../../../Selections/MainSelection'

const VideoLengthSelection = (props) => {
  const {videoLength, changeVideoLength} = props;
  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <MainSelection
        title=""
        selectionItems={VIDEO_LENGTH_SELECTION}
        defaultItem={videoLength===undefined ? DEFAULT_VIDEO_LENGTH : videoLength}
        setItem={changeVideoLength}
      />
    </div>
  )
}

export default VideoLengthSelection