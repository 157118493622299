import axios from 'axios';
import { API_HOST } from '../../config/constants/data';

import {
  GET_PROJECT_NAMES_LOADING,
  GET_PROJECT_NAMES_SUCCESS,
  GET_PROJECT_NAMES_FAIL
} from '../types'

import { attachTokenToHeaders } from './authActions';

export const getProjectNames = () => async (dispatch, getState) => {
  dispatch({ type: GET_PROJECT_NAMES_LOADING });
  try {
      const response = await axios.get(`${API_HOST}/api/v1/project/names/`, attachTokenToHeaders(getState));

      dispatch({
          type: GET_PROJECT_NAMES_SUCCESS,
          payload: { data: response.data.results, count: response.data.count },
      });

  } catch (err) {
    console.log("getProjectNames error: ", err)
      dispatch({
          type: GET_PROJECT_NAMES_FAIL,
          payload: { error: err.response.data.message },
      });
  }
};

export const setProjectNames = (payload) => (dispatch) => {
  dispatch({
    type: GET_PROJECT_NAMES_SUCCESS,
    payload: { data: payload.projectNames, count: payload.projectNamesCount },
  });
};
