import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  NEWS_MAIN_TABLE_API,
  NEWS_PROJECTS_TABLE_HEADER,
} from "../../../../config/constants/tableHeaders";
import MainTable from "../../../../components/Tables/MainTable";
import { RecommendItems } from "../..";

const WebsiteNews = (props) => {
  const { nickName } = useParams();
  useEffect(() => {
    props.setSelectedRoute("news");
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <MainTable
        tableHeader={NEWS_PROJECTS_TABLE_HEADER}
        URL={NEWS_MAIN_TABLE_API}
        withTermsFilter
        withoutSearch
        // withoutProjectFilter
        withoutFilterModal
        withoutTopicFilter
        projectNickName={nickName}
        defaultTerm={"7d"}
        model={"news"}
        modelName="project-news--nickName"
      />
      <RecommendItems />
    </>
  );
};

export default WebsiteNews;

