import React, { useEffect } from "react";
import { diffTimeStamp } from "../../../../utils/tableDataHelper";
import "./styles.css";

const NewsView = (props) => {
  const { data } = props;

  useEffect(() => {
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <div className="statistics-table table-responsive mt-2">
        {data && data?.length ? (
          data.map((item, index) => {
            return (
              <div className="row d-flex mx-1 my-5" key={index}>
                <div className="col col-12 col-md-3 news-thumbnail-con">
                  <a href={item.link} rel="noopener noreferrer" target="_blank">
                    <img
                      src={item.thumbnail}
                      alt="pfp"
                      className="news-thumbnail"
                    />
                  </a>
                </div>
                <div className="col col-12 col-md-9 ">
                  <div className="ml-lg-4 mt-3 mt-lg-0">
                    <div className="news-title">
                      <a
                        href={item?.link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {item?.title}
                      </a>
                    </div>
                    <div className="news-description">{item.description}</div>
                    <div className="news-project">{item.domain}</div>
                    <div className="news-datetime">
                      {diffTimeStamp(item?.last_datetime)}
                      {/* {parseInt(item?.total) > 1 ? ` by ${item?.total.toString()} users : ` : " "} */}
                      {" "}
                      {parseInt(item?.youtube_channels) > 0 ? ` ${item?.youtube_channels} channels `: ""}
                      {parseInt(item?.discord_users) > 0 ? ` ${item?.discord_users} discord users ` : ""}
                      {parseInt(item?.telegram_users) > 0 ? ` ${item?.telegram_users} telegram users ` : ""}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default NewsView;
