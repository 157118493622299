import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { DISCORD_ASSETS_LINK } from "../../../../config/constants/index";
import {
  convertName2Nick,
  getIconFromMedia,
  getProjectIcon,
} from "../../../../utils/routingHelper";
import "./styles.css";
import MainLink from "./Components/MainLink";
import ExternalLink from "./Components/ExternalLink";
import { API_HOST } from "../../../../config/constants/data";

const ItemLinkComponent = (props) => {
  const { item, modelName } = props;
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  const searchItems =
    useSelector((state) => state.searchItems.searchItems) || [];
  const projectItems = searchItems.filter(item => item.model_id === "100");
  const location = useLocation();
  let { nickName, category } = useParams();
  if (nickName === undefined)
    nickName = convertName2Nick(
      projectNames,
      item?.keyword ? item.keyword : item.name
    );
  // console.log("modelName: ", modelName);
  switch (modelName) {
    case "trending":
    case "market":
    case "project--category":
    case "youtube-project":
      return (
        <MainLink
          path={`/project/${nickName}/`}
          image={`${API_HOST}/media/${item.icon}`}
          text={item?.keyword}
        />
      );
    case "youtube-project--category":
      return (
        <MainLink
          path={`/project/${nickName}/videos`}
          image={`${API_HOST}/media/${item.icon}`}
          text={item?.keyword}
        />
      );
    case "youtube-channel":
    case "youtube-channel--category":
    case "website-partner--domain":
    case "website-newpartner--domain":
    case "project-youtube-channel--nickName":
      return (
        <MainLink
          path={`/youtube/channel/${item.channelId}`}
          state={{ channelId: item.channelId }}
          image={item?.thumbnail}
          text={item.title}
        />
      );
    case "youtube-video":
    case "website-video--domain":
    case "youtube-video--category":
    case "project-youtube-video--nickName":
      return (
        <MainLink
          path={`/youtube/channel/${item.channelId}`}
          alignment="end"
          text={item?.channelTitle}
        />
      );
    case "telegram-project":
    case "telegram-project--category":
      return (
        <MainLink
          path={`/project/${nickName}/groups`}
          image={getProjectIcon(projectItems, item)}
          text={item?.keyword}
        />
      );
    case "telegram-group":
    case "edit-topic-group":
    case "telegram-group--category":
    case "project-telegram-group--nickName":
      return (
        <MainLink
          path={`/telegram/group/${item.dialog_id}/`}
          // width="300"
          image={`${API_HOST}/media/${item.icon}`}
          text={item.title}
        />
      );
    case "discord-project":
    case "discord-project--category":
      return (
        <MainLink
          path={`/project/${nickName}/servers`}
          image={getProjectIcon(projectItems, item)}
          text={item?.keyword}
        />
      );
    case "discord-server":
    case "edit-topic-server":
    case "edit-discord-serverinvite":
    case "discord-server--category":
    case "project-discord-server--nickName":
      return (
        <MainLink
          path={`/discord/server/${item.id}/`}
          state={{ server_id: item?.id?.toString() }}
          image={`${DISCORD_ASSETS_LINK}icons/${item?.id?.toString()}/${item?.icon?.toString()}.png`}
          text={item.name}
        />
      );
    case "discord-channel--category":
      return (
        <MainLink
          path={`/discord/server/${item.server_id}/`}
          state={{ server_id: item.server_id }}
          image={`${DISCORD_ASSETS_LINK}icons/${item.server_id}/${item.server_icon}.png`}
          text={item?.server_name?.substring(0, 50)}
          alignment={"end"}
        />
      );
    case "website-topic--category":
      return (
        <MainLink
          image={getIconFromMedia(item.icon)}
          path={`/view/${category}/websites/${item.name?.toLowerCase()}`}
          text={item.name}
          summary={item.summary}
        />
      );
    case "website--topic":
    case "website-link--category-topic":
    case "project-website-link--nickName-topic":
    case "discord-server-website-link--server_id-topic":
    case "telegram-group-website--dialog_id":
    case "youtube-channel-website-link--channelId-topic":
      return (
        <MainLink
          image={getIconFromMedia(item.icon)}
          path={`/website/${item.domain?.toLowerCase()}/`}
          text={item.domain}
        />
      );
    case "social-website--category":
      return (
        <MainLink
          path={`/view/${category}/social/${item.domain}`}
          image={getIconFromMedia(item.icon)}
          state={{ domain: item.domain }}
          text={item.domain}
        />
      );
    case "social-link--domain":
    case "social-link--category-domain":
    case "project-social-link--nickName-website":
    case "discord-server-social-link--server_id-domain":
    case "youtube-channel-social-link--channelId-domain":
    case "telegram-dialog-social-link--dialog_id-domain":
      return (
        <ExternalLink path={`${item.link}`} text={item.link} width={600} />
      );
    case "project-website-topic--nickName":
      return (
        <MainLink
          image={getIconFromMedia(item.icon)}
          path={`/project/${nickName}/websites/${item.name?.toLowerCase()}`}
          text={item.name}
          summary={item.summary}
        />
      );
    case "project-social-website--nickName":
      return (
        <MainLink
          path={`/project/${nickName}/social/${item.domain}`}
          state={{ domain: item.domain }}
          text={item.domain}
        />
      );
    case "youtube-channel-project--channelId":
      return (
        <MainLink
          path={`/youtube/channel/${location.pathname.split("/")[3]}/videos`}
          image={`${API_HOST}/media/${item.icon}`}
          text={item?.keyword}
          state={{
            projectNickName: nickName,
          }}
        />
      );
    case "youtube-channel-website-topic--channelId":
      return (
        <MainLink
          image={getIconFromMedia(item.icon)}
          path={`/youtube/channel/${
            location.pathname.split("/")[3]
          }/websites/${item.name?.toLowerCase()}`}
          text={item.name}
          summary={item.summary}
        />
      );
    case "youtube-channel-social-website--channelId":
      return (
        <MainLink
          path={`/youtube/channel/${location.pathname.split("/")[3]}/social/${
            item.domain
          }`}
          image={getIconFromMedia(item.icon)}
          state={{ domain: item.domain }}
          text={item.domain}
        />
      );
    case "telegram-group-website--group_id":
      // TODO develop this later
      break;
    case "discord-channel":
      return (
        <MainLink
          path={`/discord/server/${item.server_id}/channels`}
          state={{ server_id: item.server_id }}
          image={`${DISCORD_ASSETS_LINK}icons/${item.server_id}/${item.server_icon}.png`}
          text={item?.server_name?.substring(0, 50)}
          alignment={"end"}
        />
      );
    case "discord-server-website-topic--server_id":
      return (
        <MainLink
          image={getIconFromMedia(item.icon)}
          path={`/discord/server/${
            location.pathname.split("/")[3]
          }/websites/${item.name?.toLowerCase()}`}
          text={item.name}
          summary={item.summary}
        />
      );
    case "telegram-group-website-topic--dialog_id":
      return (
        <MainLink
          image={getIconFromMedia(item.icon)}
          path={`/telegram/group/${
            location.pathname.split("/")[3]
          }/websites/${item.name?.toLowerCase()}`}
          text={item.name}
          summary={item.summary}
        />
      );
    case "discord-server-social-website--server_id":
      return (
        <MainLink
          path={`/discord/server/${location.pathname.split("/")[3]}/social/${
            item.domain
          }`}
          image={getIconFromMedia(item.icon)}
          state={{ domain: item.domain }}
          text={item.domain}
        />
      );
    case "telegram-dialog-social-website--dialog_id":
      return (
        <MainLink
          path={`/telegram/group/${location.pathname.split("/")[3]}/social/${
            item.domain
          }`}
          image={getIconFromMedia(item.icon)}
          state={{ domain: item.domain }}
          text={item.domain}
        />
      );
    case "website-topic":
      return (
        <MainLink
          image={getIconFromMedia(item.icon)}
          path={`/websites/${item.name?.toLowerCase()}`}
          text={item.name}
          summary={item.summary}
        />
      );
    case "social-website":
      return (
        <MainLink
          path={`/social/${item.domain?.toLowerCase()}`}
          image={getIconFromMedia(item.icon)}
          text={item.domain}
        />
      );
    case "blogs":
      return <MainLink path={`/blog/${item.id}/`} text={item?.title} />;
    case "edit-topic-channel":
      return (
        <ExternalLink
          path={`https://youtube.com/channel/${item.channelId}`}
          image={item?.thumbnail}
          text={item?.title}
        />
      );
    case "edit-topic-website":
      return (
        <ExternalLink path={`https://${item.domain}`} text={item?.domain} />
      );
    case "edit-project":
      return (
        <MainLink
          path={`/project/${nickName}/`}
          image={`${item.icon}`}
          text={item?.name}
        />
      );
    case "edit-blog":
      return <MainLink path={`/edit/blog/${item.id}`} text={item?.title} />;
    default:
      return (
        <a href={`${item.link}`} rel="noopener noreferrer" target="_blank">
          {" "}
          {item.link}{" "}
        </a>
      );
  }
};

export default ItemLinkComponent;
