import {
  SET_SUBSCRIBE_EMAIL_LOADING,
  SET_SUBSCRIBE_EMAIL_SUCCESS,
  SET_SUBSCRIBE_EMAIL_FAIL,
} from '../types';

const initialState = {
  isLoading: false,
  isSubscribed: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type }) {
  switch (type) {
    case SET_SUBSCRIBE_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSubscribed: true,
      };
    case SET_SUBSCRIBE_EMAIL_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_SUBSCRIBE_EMAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        isSubscribed: false,
      };
    default:
      return state;
  }
}