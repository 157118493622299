import React from "react";
import PageTitle from "../../../components/PageTitle";
import { BLOG_EDIT_TABLE_HEADER, BLOG_EDIT_MAIN_TABLE_API } from "../../../config/constants/tableHeaders";
import EditBlogTable from "../../../components/Tables/EditBlogTable";
import Layout from "../../../layout/Layout";

const EditBlog = () => {
  return (
    <Layout>
      <PageTitle
        title="Blog Edit Page."
        description="You can edit the Blogs here."
      />
      <EditBlogTable
        tableHeader={BLOG_EDIT_TABLE_HEADER}
        URL={BLOG_EDIT_MAIN_TABLE_API}
        withoutFilterModal
        model={"blog"}
        modelName="edit-blog"
      />
    </Layout>
  );
};

export default EditBlog;
