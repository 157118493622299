import React, { useEffect, useMemo } from "react";

import MainTable from "../../../../components/Tables/MainTable";
import { useParams } from "react-router-dom";

import {
  WEBSITE_TOPIC_TABLE_HEADER,
  WEBSITE_USERS_TABLE_HEADER,
  WEBSITE_USERS_MAIN_TABLE_API,
  WEBSITE_TOPIC_MAIN_TABLE_API,
} from "../../../../config/constants/tableHeaders";
import { RecommendItems } from "../..";

const Websites = (props) => {
  const { nickName, topic } = useParams();
  useEffect(() => {
    props.setSelectedRoute("websites");
    // eslint-disable-next-line
  }, []);
  const modelName = useMemo(
    () =>
      topic
        ? "project-website-link--nickName-topic"
        : "project-website-topic--nickName",
    [topic]
  );
  const tableHeader = useMemo(
    () => (topic ? WEBSITE_USERS_TABLE_HEADER : WEBSITE_TOPIC_TABLE_HEADER),
    [topic]
  );
  const tableAPI = useMemo(
    () => (topic ? WEBSITE_USERS_MAIN_TABLE_API : WEBSITE_TOPIC_MAIN_TABLE_API),
    [topic]
  );

  return (
    <>
      <MainTable
        currentTopic={topic}
        tableHeader={tableHeader}
        URL={tableAPI}
        withoutProjectFilter
        withoutTopicFilter={topic === undefined}
        withoutFilterModal
        projectNickName={nickName}
        model={"websites"}
        withWebsiteTopicTypeSelection
        modelName={modelName}
      />
      <RecommendItems />
    </>
  );
};

export default Websites;

