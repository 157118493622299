import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";

import Layout from "../../layout/Layout";
import { BLOGS_MAIN_TABLE_API } from "../../config/constants/tableHeaders";

import { useParams } from "react-router-dom";

import Breadcrumb from "../../components/Breadcrumb";

import "./BlogDetails.scss";
import { fetchMainModel } from "../../store/actions/mainModelActions";

const BlogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.mainModel);
  const callApi = () => {
    dispatch(
      fetchMainModel({
        URL: BLOGS_MAIN_TABLE_API + `/${id}/`,
      })
    );
  };
  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout>
      {/* <Helmet>
        <title>BlogDetails | metalyzer </title>
        <meta name="description" content={"BlogDetails."} />
        <meta
          name="keywords"
          content={
            "blogs,Project,metalyzer,Crypto,Social,Analytics,Metrics,Research"
          }
        />
      </Helmet> */}
      <Breadcrumb
        parentLink="/blogs/"
        parentLabel="Blog"
        currentLabel={data?.title}
      />
      {/* <PageTitle
        title="BlogDetails Page."
        description=" metalyzer shows all blogs by our service."
      /> */}
      <div className="blog-container">
        <div dangerouslySetInnerHTML={{ __html: data?.contents }} />
      </div>
    </Layout>
  );
};

export default BlogDetails;

