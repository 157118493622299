import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import "../styles.css";

import { Row, Col } from "react-bootstrap";
import { selectionStyle } from "../styles";

const compare = (a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

const ProjectSelection = (props) => {
  const { title, project, setKeyword, paddingLeftZero = false } = props;
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  let customStyle = selectionStyle(105, 38);
  customStyle.menuList = (styles) => ({
    ...styles,
    backgroundColor: "#323546",
    width: "100%",
    left: 0,
    minWidth: "200px"
  });
  const items = projectNames?.map((project) => {
    return { label: project.name, value: project.nickname };
  });

  return (
    <>
      {title && (
        <Row>
          <Col>
            <label htmlFor="#" className="mb-0 text-success text-nowrap">
              {title}
            </label>
          </Col>
        </Row>
      )}
      <Row>
        <Col style={paddingLeftZero ? { paddingLeft: 0 } : {}}>
          {items && items.length && (
            <Select
              value={project !== null ? project : items[0]}
              onChange={(value) => setKeyword(value)}
              options={[{ label: "All", value: "all" }, ...items.sort(compare)]}
              styles={customStyle}
              isSearchable={true}
              className="mx-1"
              classNamePrefix="project-select"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProjectSelection;
