import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";

import { Form, Row, Col } from "react-bootstrap";

import { editKeywordSchema } from "./validation";
import ProjectFilter from "../../ProjectFilter";
import KeywordCategorySelection from "../../KeywordCategorySelection";
import { putMainModel } from "../../../../../store/actions/mainModelActions";

const EditKeywordModal = (props) => {
  const { show, setShowModal, item } = props;
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState(item?.project_name);
  const [keywordNick, setKeywordNick] = useState("all");
  const [category, setCategory] = useState(item?.category);
  const keywordId = item?.id;

  const formik = useFormik({
    initialValues: {
      keyword: item?.keyword,
      project_name: item?.project_name,
      category: item?.category,
    },
    validationSchema: editKeywordSchema,
    onSubmit: async (values) => {
      values.project_name = keyword;
      values.category = category;
      await dispatch(putMainModel(`project/keyword/${keywordId}`, values));
    },
  });
  const udpateKeyword = (keywordObject) => {
    setKeyword(keywordObject.label);
    setKeywordNick(keywordObject.value);
  };
  const udpateCategory = (categoryObject) => {
    setCategory(categoryObject);
  };
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={"sm"}
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ fontSize: 18, textAlign: "center", width: "100%" }}
        >
          Edit Keyword
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="pt-3" onSubmit={formik.handleSubmit}>
          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className="mr-3">Project Name</div>
            <ProjectFilter
              keyword={{ label: keyword, value: keywordNick }}
              setKeyword={udpateKeyword}
            />
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Keyword</div>
            <Form.Control
              type="text"
              size="lg"
              placeholder="Enter Keyword ..."
              name="keyword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.keyword}
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="text-danger">{formik.errors.name}</p>
            ) : null}
          </Form.Group>

          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className="mr-3">Category</div>
            <KeywordCategorySelection
              category={category}
              changeCategory={udpateCategory}
            />
          </Form.Group>

          <Row className="mt-4">
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  disabled={!formik.isValid}
                  type="submit"
                >
                  Edit
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditKeywordModal;
