import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import GroupButton from "../../../../../components/GroupButton";
import {
  PROJECT_DETAILS_MAIN_CHART_SITES_GROUP_BUTTON,
  PROJECT_DETAILS_MAIN_CHART_TERMS_GROUP_BUTTON,
  PROJECT_DETAILS_MAIN_CHART_FILTER_GROUP_BUTTON,
  PROJECT_DETAILS_CHART_OPTION,
} from "../../../../../config/constants/data";
import { PROJECT_DETAILS_CHART_API } from "../../../../../config/constants/tableHeaders";
import { getChartApi } from "../../../../../store/actions/getChartApiAction";
import {
  convertNick2ID,
  convertNick2Name,
} from "../../../../../utils/routingHelper";

const MainChart = (props) => {
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  const { chartData } = useSelector((state) => state.chartApiState);
  const dispatch = useDispatch();
  const projectName = convertNick2Name(projectNames, props.nickName);
  const projectId = convertNick2ID(projectNames, props.nickName);
  const [siteType, setSiteType] = useState("youtube");
  const [terms, setTerms] = useState("day");
  const [filterField, setFilterField] = useState("videos");
  const [chartDataSet, setChartDataSet] = useState({});
  const updateSiteType = (site) => {
    setSiteType(site);
    setFilterField(
      PROJECT_DETAILS_MAIN_CHART_FILTER_GROUP_BUTTON?.[site]?.[0]?.value
    );
  };
  const callApi = (params) => {
    const newSite = params?.newSiteType ? params.newSiteType : siteType;
    const newTerms = params?.newTerms ? params.newTerms : terms;
    dispatch(
      getChartApi({
        URL: PROJECT_DETAILS_CHART_API,
        project_id: projectId,
        site: newSite,
        terms: newTerms,
      })
    );
  };
  // First Loading.
  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteType, terms]);
  useEffect(() => {
    const labels = chartData.map((data) =>
      terms === "day"
        ? data?.[terms].substring(5, 10)
        : terms === "week"
        ? data?.[terms].substring(0, 1) + "W ago"
        : data?.[terms]
    );
    const counts = chartData.map((data) => parseInt(data?.[filterField]));
    setChartDataSet({
      labels,
      datasets: [
        {
          label: projectName + " " + terms + " data",
          data: counts,
          borderColor: "rgb(22, 199, 132)",
          backgroundColor: "rgba(22, 199, 132, 0.1)",
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, filterField]);

  return (
    <div className="">
      <div className="d-md-flex justify-content-md-between">
        <p className="title-text">Chart</p>
      </div>
      <div className="d-md-flex justify-content-md-between">
        <GroupButton
          items={PROJECT_DETAILS_MAIN_CHART_SITES_GROUP_BUTTON}
          selected={siteType}
          onClick={updateSiteType}
          groupClass="project-chart-switch-con"
          buttonClass="chart-switch-btn"
        />
        <GroupButton
          items={PROJECT_DETAILS_MAIN_CHART_FILTER_GROUP_BUTTON?.[siteType]}
          selected={filterField}
          onClick={setFilterField}
          groupClass="project-chart-filter-switch-con mt-3 mt-md-0"
          buttonClass="chart-filter-switch-btn"
        />
        <GroupButton
          items={PROJECT_DETAILS_MAIN_CHART_TERMS_GROUP_BUTTON}
          selected={terms}
          onClick={setTerms}
          groupClass="project-chart-term-switch-con mt-3 mt-md-0"
          buttonClass="chart-term-switch-btn"
        />
      </div>
      <Line options={PROJECT_DETAILS_CHART_OPTION} data={chartDataSet} />
    </div>
  );
};

export default MainChart;
