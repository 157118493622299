import React from "react";
import { Icon } from "@iconify/react";

const TableHeader = (props) => {
  const {
    header,
    currentTableHeader,
    handleOrdering,
    currentOrdering,
    // sortType,
  } = props;
  return (
    <tr>
      {header &&
        currentTableHeader.map((h, i) => {
          return (
            h.checked &&
            (h.title === "#" ? (
              <th key={i}>
                <div className="text-center">#</div>
              </th>
            ) : h.title === "" ? (
              <th key={i}>
                <div className="text-center"></div>
              </th>
            ) : (
              <th
                key={i}
                onClick={() =>
                  h.sort
                    ? handleOrdering(
                        `${currentOrdering.indexOf("-") >= 0 ? "+" : "-"}`,
                        `${h.value}`,
                        `${h.type}`
                      )
                    : false
                }
              >
                <div
                  className={`d-flex align-items-center justify-content-${
                    h?.align ? h.align : "end"
                  }`}
                >
                  {h.title}
                  {h.sort && (
                    <>
                      {currentOrdering === h.value ||
                      currentOrdering === "-" + h.value ? (
                        <span className="sort-icons">
                          {currentOrdering.indexOf("-") >= 0 && (
                            <Icon
                              icon="icon-park-solid:up-one"
                              height="1.5rem"
                              color="white"
                              style={{ marginRight: 3, marginTop: -3 }}
                            />
                          )}
                          {currentOrdering.indexOf("-") < 0 && (
                            <Icon
                              icon="icon-park-solid:down-one"
                              height="1.5rem"
                              color="white"
                              style={{ marginRight: 3, marginTop: -3 }}
                            />
                          )}
                        </span>
                      ) : (
                        <span className="sort-icons">
                          <Icon
                            icon="icon-park-solid:up-one"
                            height="1.5rem"
                            color="white"
                            style={{ marginRight: 3, marginTop: -3 }}
                          />
                          <Icon
                            icon="icon-park-solid:down-one"
                            height="1.5rem"
                            color="white"
                            style={{ marginRight: 3, marginTop: -3 }}
                          />
                          {/* <i className="mdi mdi-chevron-up"></i> */}
                          {/* <i className="mdi mdi-chevron-down"></i> */}
                        </span>
                      )}
                    </>
                  )}
                </div>
              </th>
            ))
          );
        })}
    </tr>
  );
};

export default TableHeader;

