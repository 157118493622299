import React from "react";
// import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
import "./SubscriptionCard.scss";
import { Img } from "react-image";

function SubscriptionCard({ product, onBuyClick }) {
  return (
    // <Card className="subscriptionCard" style={{ width: "50%", height: "500px", fontSize: "18px" }}>
    //   <Card.Header>{product.name}</Card.Header>
    //   {/* <Card.Img variant="top" src={product.logoUrl} alt={product.name} /> */}
    //   <Card.Body>
    //     <Card.Text>{product.description}</Card.Text>
    //     <Card.Text>
    //       Price: ${product.price.price} {product.price.currency}
    //       <br />
    //       {!product.recurring
    //         ? `Billed ${product.chargeFrequency.toLowerCase()}`
    //         : "One-time purchase"}
    //       <br />
    //       This product would give you good experiences.
    //     </Card.Text>
    //   </Card.Body>
    //   <Card.Footer>
    //     <Button onClick={()=>onBuyClick(product['id'])} variant="primary">
    //       Purchase
    //     </Button>
    //   </Card.Footer>
    // </Card>
    <div className="sub-card active">
      <div className="sub-card-wrapper">
        <div className="card-top-wrapper">
          <div className="margin-bottom-2 margin-xxsmall">
            <div className="heading-style">{product.name}</div>
          </div>
          <p className="margin-0">{product.description}</p>
        </div>
        <p className="price">
          ${product.price.price}
          <span className="price-month"> {product.chargeFrequency}</span>
        </p>
        <div className="flex-vertical">
          <button
            className="sub-card-btn"
            onClick={() => onBuyClick(product["id"])}
          >
            Level Up Now
          </button>
        </div>
        <div className="card-desc">
          <p className="margin-0 margin-bottom">
            {product.name === "Standard"
              ? "Explore and Analyze with Ease: Metalyzer Standard."
              : "Exclusive Insights and Premium Support: Metalyzer VIP"}
          </p>
          <div className="sub-option-wrapper margin-bottom">
            <Img className="check-icon" src="/images/check.svg" />
            <p className="margin-0">
              {product.name === "Standard"
                ? "Automated Insights: Dive into the world of social media with automated analytics. Metalyzer Standard provides essential tools to explore trends, track engagement, and understand audience behavior."
                : "Advanced Analytical Capabilities: Elevate your social media strategy with advanced tools. Metalyzer VIP offers deeper insights, predictive analytics, and customized data solutions."}
            </p>
          </div>
          <div className="sub-option-wrapper margin-bottom">
            <Img className="check-icon" src="/images/check.svg" />
            <p className="margin-0">
              {product.name === "Standard"
                ? "Basic Analysis Tools: Access a suite of basic analytical tools tailored for effective social media analysis. Perfect for individuals or small businesses starting their journey in social media insights."
                : "Prioritized Data Processing: Get your data faster with prioritized processing. VIP members enjoy quicker turnaround times for their analytical requests."}
            </p>
          </div>
          <div className="sub-option-wrapper margin-bottom">
            <Img className="check-icon" src="/images/check.svg" />
            <p className="margin-0">
              {product.name === "Standard"
                ? "Standard Reporting: Receive regular reports highlighting key metrics and trends. Stay informed with data-driven insights to make informed decisions."
                : "Personalized Reports and Dashboards: Customize your data experience with tailored reports and interactive dashboards. Focus on metrics that matter most to you or your business."}
            </p>
          </div>
          <div className="sub-option-wrapper margin-bottom">
            <Img className="check-icon" src="/images/check.svg" />
            <p className="margin-0">
              {product.name === "Standard"
                ? "Community Support: Join our community of Metalyzer users. Share insights, learn from peers, and get community-driven support."
                : "Dedicated Support: Benefit from dedicated customer support. Our VIP support team is on hand to assist with your specific needs, ensuring a seamless Metalyzer experience."}
            </p>
          </div>
          {product.name !== "Standard" && (
            <div className="sub-option-wrapper margin-bottom">
              <Img className="check-icon" src="/images/check.svg" />
              <p className="margin-0">
                Exclusive Access to New Features: Be the first to access new
                tools and features. VIP members receive early access to the
                latest innovations in social media analytics.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubscriptionCard;
