import React from 'react'
import { useParams } from 'react-router-dom'
import { Flip, ToastContainer } from 'react-toastify';
import LinkButtons from '../../../components/LinkButtons';
import PageTitle from '../../../components/PageTitle';
import { EDIT_TOPIC_MODEL_LINKS } from '../../../config/constants/data';
import Channel from './Components/Channel';
import Server from './Components/Server';
import Website from './Components/Website';
import Dialog from './Components/Dialog';
import Layout from '../../../layout/Layout';

const EditTopic = () => {
  const {model} = useParams();
  const modelName = model?model:"channels";

  return (
    <Layout>
      <PageTitle
        title='Topic Edit Page.'
        description='You can edit the Topics here.'
      />
      <div className="tabs align-items-center" style={{marginLeft: -2}}>
        {/* <div className="category_divide" /> */}
        <LinkButtons
          linkItems={EDIT_TOPIC_MODEL_LINKS}
          activeItem={model?model:"channels"}
        />
      </div>
      <div>
        {modelName==="channels" && <Channel/>}
        {modelName==="groups" && <Dialog/>}
        {modelName==="servers" && <Server/>}
        {modelName==="websites" && <Website/>}
      </div>
      <ToastContainer theme="dark" transition={Flip} />
    </Layout>
  )
}

export default EditTopic