import React from "react";
import { Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import {
  diffTimeStamp,
  responsiveCarousel,
  transformNumber,
} from "../../../../../utils/tableDataHelper";
import TopnewsCardHeader from "./Header";
import { Img } from "react-image";

const Statistics = ({ vertical }) => {
  const { everyMinuteData: detailsData } = useSelector(
    (state) => state.everyMinuteState
  );
  const { data: highlightData } = useSelector((state) => state.highlight);
  return (
    <Col xs={12} md={vertical ? 12 : 4}>
      <div className="topnews-card">
        <Carousel
          responsive={responsiveCarousel}
          showDots={true}
          infinite={true}
          swipeable={false}
          draggable={false}
          arrows={false}
          autoPlay={
            detailsData?.youtube_channels > 0 &&
            detailsData?.community_count > 0
          }
          autoPlaySpeed={7000}
        >
          {detailsData?.youtube_channels > 0 && (
            <div className="card-height-padding">
              <TopnewsCardHeader
                leftComponent={
                  <div className="d-flex flex-row align-items-center">
                    <Img width={23} height={23} src="/images/barchart.png" />
                    <span className="topnews-card-title">
                      Youtube Statistics
                    </span>
                  </div>
                }
                more={false}
              />
              <div className="ml-2">
                <div className="mb-3">
                  <p className="sub-title-statistics system-font mb-1">
                    Trusted By
                  </p>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="mb-0 video-detail-text system-font">
                      {detailsData?.youtube_channels} Youtube Channels
                    </p>
                    <div className="d-flex flex-row align-items-center">
                      <p className="mb-0 video-detail-text system-font right">
                        {transformNumber(
                          detailsData?.followers,
                          "shorterNumber"
                        )}
                      </p>
                      <span className="gray-dot"></span>
                      <Icon
                        icon={"material-symbols:thumb-up-sharp"}
                        height="16px"
                        className="gray-text"
                        color={"#858ca2"}
                      />
                      {/* <span className="gray-dot"></span>
                  <p className="mb-0 video-detail-text system-font right">
                    Subscriber
                  </p> */}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <p className="sub-title-statistics system-font mb-1">
                    Shared In
                  </p>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="mb-0 video-detail-text system-font">
                      {detailsData?.youtube_videos} Videos
                    </p>
                    <div className="d-flex flex-row align-items-center">
                      <p className="mb-0 video-detail-text system-font right">
                        {transformNumber(
                          detailsData?.viewCounts,
                          "shorterNumber"
                        )}
                      </p>
                      <span className="gray-dot"></span>
                      <Icon
                        icon={"ph:eye-bold"}
                        height="16px"
                        className="gray-text"
                        color={"#858ca2"}
                      />
                      {/* <span className="gray-dot"></span>
                  <p className="mb-0 video-detail-text system-font right">
                    Views
                  </p> */}
                    </div>
                  </div>
                </div>
                <div>
                  <p className="sub-title-statistics system-font mb-1">
                    Last Mention
                  </p>
                  <p className="video-detail-text system-font">
                    {diffTimeStamp(
                      highlightData?.recent_videos?.[0]["publishedAt"]
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
          {detailsData?.community_count > 0 && (
            <div className="card-height-padding">
              <TopnewsCardHeader
                leftComponent={
                  <div className="d-flex flex-row align-items-center">
                    <Img width={23} height={23} src="/images/barchart.png" />
                    <span className="topnews-card-title">
                      Community Statistics
                    </span>
                  </div>
                }
                more={false}
              />
              <div className="ml-2">
                <div className="mb-3">
                  <p className="sub-title-statistics system-font mb-1">
                    Shared In
                  </p>
                  {detailsData?.discord_servers > 0 && (
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <p className="mb-0 video-detail-text system-font">
                        {detailsData?.discord_servers} Discord Servers
                      </p>
                      <div className="d-flex flex-row align-items-center">
                        <p className="mb-0 video-detail-text system-font right">
                          {transformNumber(
                            detailsData?.discord_users,
                            "shorterNumber"
                          )}{" "}
                        </p>
                        <span className="gray-dot"></span>
                        <Icon
                          icon={"mdi:users-outline"}
                          height="16px"
                          className="gray-text"
                          color={"#858ca2"}
                        />
                      </div>
                    </div>
                  )}
                  {detailsData?.telegram_groups > 0 && (
                    <div className="d-flex flex-row justify-content-between align-items-center">
                      <p className="mb-0 video-detail-text system-font">
                        {detailsData?.telegram_groups} Telegram groups
                      </p>
                      <div className="d-flex flex-row align-items-center">
                        <p className="mb-0 video-detail-text system-font right">
                          {transformNumber(
                            detailsData?.telegram_users,
                            "shorterNumber"
                          )}{" "}
                        </p>
                        <span className="gray-dot"></span>
                        <Icon
                          icon={"mdi:users-outline"}
                          height="16px"
                          className="gray-text"
                          color={"#858ca2"}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* <div className="mb-3">
                <p className="sub-title-statistics system-font mb-1">
                  Last Mention
                </p>
                
              </div> */}
                <div>
                  <p className="sub-title-statistics system-font mb-1">
                    Last Mention
                  </p>
                  {detailsData?.discord_last_datetime && (
                    <p className="video-detail-text system-font mb-0 ">
                      Discord{" "}
                      {diffTimeStamp(detailsData?.discord_last_datetime)}
                    </p>
                  )}
                  {detailsData?.telegram_last_datetime && (
                    <p className="video-detail-text system-font mb-0 ">
                      Telegram{" "}
                      {diffTimeStamp(detailsData?.telegram_last_datetime)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </Carousel>
      </div>
    </Col>
  );
};

export default Statistics;
