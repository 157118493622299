import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../store/actions/authActions";

const Logout = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    dispatch(logOutUser());
    history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default Logout;