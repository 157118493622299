import React, { useState, useEffect } from "react";

import { useFormik } from "formik";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Form, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import {
  loginUser,
  registerUserWithEmail,
  googleLogin,
} from "../../store/actions/authActions";
import { loginSchema, registerSchema } from "./validation";

import "./styles.scss";
import useMediaQuery from "../../hooks/useMediaQuery";

import { useGoogleLogin } from "react-google-login";
import { Icon } from "@iconify/react";
import useUserRole from "../../hooks/useUserRole";

const clientId =
  "57175740677-9uqqht9i3a7g14qmih3rjefutb27jte9.apps.googleusercontent.com";

const LoginSignupModal = (props) => {
  const isMobile = useMediaQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useUserRole();
  const { authCodeId, isLoading, error } = auth;
  const isLoginModal = props.isLoginModal;
  const [authCodeVisible, setAuthCodeVisible] = useState(false);
  const CustomHeader = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="login-signup-tab px-3 py-2"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        // onClick(e);
      }}
    >
      {children}
    </div>
  ));
  useEffect(() => {
    if (error) props.notifyError(error);
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (authCodeId === null) return;
    setAuthCodeVisible(true);
    // dispatch(registerUserWithEmail(signUpValues, history));
    // eslint-disable-next-line
  }, [authCodeId]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(loginUser(values, history));
      props.onHide();
    },
  });

  const formikR = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
      authCode: "",
    },
    validationSchema: registerSchema,
    onSubmit: async (values) => {
      if (authCodeId !== null) values["auth_id"] = authCodeId;
      dispatch(registerUserWithEmail(values, history));
      // if (authCodeId !== null) props.onHide();
    },
  });

  const onGoogleLoginSuccess = async (res) => {
    dispatch(
      googleLogin({ token: res?.tokenId }, res.profileObj?.imageUrl, history)
    );
    props.onHide();
  };

  const onGoogleLoginFailure = (res) => {
    console.log("LOGIN FAILED! res: ", res);
  };

  const { signIn } = useGoogleLogin({
    onSuccess: onGoogleLoginSuccess,
    onFailure: onGoogleLoginFailure,
    clientId,
    // isSignedIn: true,
    accessType: "offline",
    cookiePolicy: "single_host_origin",
    // responseType: 'code',
    // prompt: 'consent',
  });

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={isMobile ? "modal-100w" : "login-signup-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title as={CustomHeader}>
            {isLoginModal ? (
              <>
                <div
                  className="header-button active"
                  onClick={() => props.setIsLoginModal(true)}
                >
                  Log In
                  <div className="header-button-underline" />
                </div>
                <div
                  className="header-button"
                  onClick={() => props.setIsLoginModal(false)}
                >
                  Sign Up
                  <div className="header-button-underline hidden" />
                </div>
              </>
            ) : (
              <>
                <div
                  className="header-button"
                  onClick={() => props.setIsLoginModal(true)}
                >
                  Log In
                  <div className="header-button-underline hidden" />
                </div>
                <div
                  className="header-button active"
                  onClick={() => props.setIsLoginModal(false)}
                >
                  Sign Up
                  <div className="header-button-underline" />
                </div>
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        {isLoginModal ? (
          <Modal.Body bsPrefix="login-signup-modal-body">
            <Form className="pt-3" onSubmit={formik.handleSubmit}>
              <Form.Group className="d-flex flex-column pt-3">
                <div className="modal-label-name">Email Address</div>
                <Form.Control
                  type="text"
                  size="lg"
                  placeholder="Enter your email address..."
                  name="email"
                  className="login-signup-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-danger">{formik.errors.email}</p>
                ) : null}
              </Form.Group>
              <Form.Group className="d-flex flex-column">
                <div className="modal-label-name">Password</div>
                <Form.Control
                  type="password"
                  size="lg"
                  name="password"
                  placeholder="Password"
                  className="login-signup-input"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />

                {formik.touched.password && formik.errors.password ? (
                  <p className="text-danger">{formik.errors.password}</p>
                ) : null}
              </Form.Group>

              {error && <p className="error">{error}</p>}
              <div className="mt-5">
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  disabled={isLoading || !formik.isValid}
                  type="submit"
                >
                  Log In
                </button>
              </div>
            </Form>
            <div>
              <div className="mt-3">
                <button
                  onClick={() => signIn()}
                  className="btn d-flex flex-row btn-block btn-light btn-lg submit login-button justify-content-center"
                >
                  <Row className="justify-content-center align-items-center">
                    <Col>
                      <Icon
                        icon="flat-color-icons:google"
                        height="2rem"
                        className="mr-3 mb-1"
                      />
                      <span className="buttonText">Sign in with Google</span>
                    </Col>
                  </Row>
                </button>
              </div>
              <div className="my-2 d-flex justify-content-between align-items-center">
                <div className="form-check">
                  <label className="form-check-label text-muted mt-1">
                    <input type="checkbox" className="form-check-input" />
                    <i className="input-helper"></i>
                    Keep me signed in
                  </label>
                </div>
                <a
                  href="#/"
                  onClick={() => props.onShowForgetPassword()}
                  className="auth-link text-muted"
                >
                  Forgot password?
                </a>
              </div>
              <div className="d-flex justify-content-center text-center mt-4 font-weight-light">
                <div>Don't have an account?</div>
                <div
                  className="ml-2 text-primary cursor-pointer"
                  onClick={() => props.setIsLoginModal(false)}
                >
                  Create
                </div>
              </div>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body bsPrefix="login-signup-modal-body">
            <Form className="pt-3" onSubmit={formikR.handleSubmit}>
              <Row>
                <Col md="6">
                  <div className="modal-label-name">First Name</div>
                  <Form.Group className="d-flex flex-column pb-3">
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      className="login-signup-input"
                      size="lg"
                      onChange={formikR.handleChange}
                      onBlur={formikR.handleBlur}
                      value={formikR.values.first_name}
                    />

                    {formikR.touched.first_name && formikR.errors.first_name ? (
                      <p className="text-danger">{formikR.errors.first_name}</p>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md="6">
                  <div className="modal-label-name">Last Name</div>
                  <Form.Group className="d-flex flex-column pb-3">
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      className="login-signup-input"
                      size="lg"
                      onChange={formikR.handleChange}
                      onBlur={formikR.handleBlur}
                      value={formikR.values.last_name}
                    />

                    {formikR.touched.last_name && formikR.errors.last_name ? (
                      <p className="text-danger">{formikR.errors.last_name}</p>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="d-flex flex-column pb-3">
                <div className="modal-label-name">Email Address</div>
                <Form.Control
                  placeholder="Email Address"
                  name="email"
                  className="login-signup-input"
                  type="email"
                  size="lg"
                  disabled={authCodeVisible}
                  onChange={formikR.handleChange}
                  onBlur={formikR.handleBlur}
                  value={formikR.values.email}
                />

                {formikR.touched.email && formikR.errors.email ? (
                  <p className="text-danger">{formikR.errors.email}</p>
                ) : null}
              </Form.Group>
              <Form.Group className="d-flex flex-column pb-3">
                <div className="modal-label-name">Password</div>
                <Form.Control
                  placeholder="Password"
                  name="password"
                  className="login-signup-input"
                  type="password"
                  size="lg"
                  onChange={formikR.handleChange}
                  onBlur={formikR.handleBlur}
                  value={formikR.values.password}
                />

                {formikR.touched.password && formikR.errors.password ? (
                  <p className="text-danger">{formikR.errors.password}</p>
                ) : null}
              </Form.Group>
              <Form.Group className="d-flex flex-column pb-3">
                <div className="modal-label-name">Confirm Password</div>
                <Form.Control
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  className="login-signup-input"
                  type="password"
                  size="lg"
                  onChange={formikR.handleChange}
                  onBlur={formikR.handleBlur}
                  value={formikR.values.confirmPassword}
                />

                {formikR.touched.confirmPassword &&
                formikR.errors.confirmPassword ? (
                  <p className="text-danger">
                    {formikR.errors.confirmPassword}
                  </p>
                ) : null}
              </Form.Group>
              {authCodeVisible && (
                <Form.Group className="d-flex flex-column pb-3">
                  <div className="modal-label-name">Authentication Code</div>
                  <Form.Control
                    placeholder="Authentication Code"
                    name="authCode"
                    className="login-signup-input"
                    type="text"
                    size="lg"
                    onChange={formikR.handleChange}
                    onBlur={formikR.handleBlur}
                    value={formikR.values.authCode}
                  />

                  {formikR.touched.authCode && formikR.errors.authCode ? (
                    <p className="text-danger">{formikR.errors.authCode}</p>
                  ) : null}
                </Form.Group>
              )}

              {error && <p className="text-danger">{error}</p>}
              <div className="mt-5">
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  disabled={isLoading || !formikR.isValid}
                  type="submit"
                >
                  Create an account
                </button>
              </div>
            </Form>
            <div>
              <div className="mt-3">
                <button
                  onClick={() => signIn()}
                  className="btn btn-block btn-light btn-lg submit login-button justify-content-center"
                >
                  <Row className="justify-content-center align-items-center">
                    <Col>
                      <Icon
                        icon="flat-color-icons:google"
                        height="2rem"
                        className="mr-3 mb-1"
                      />
                      <span className="buttonText">Sign Up with Google</span>
                    </Col>
                  </Row>
                </button>
              </div>
              <div className="d-flex justify-content-center text-center mt-4 font-weight-light">
                <div>Already have an account?</div>
                <div
                  className="ml-2 text-primary cursor-pointer"
                  onClick={() => props.setIsLoginModal(true)}
                >
                  Login
                </div>
              </div>
            </div>
          </Modal.Body>
        )}

        <Modal.Footer>
          {/* footer */}
          {/* <Button variant="secondary">
            Close
          </Button>
          <Button variant="primary">
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LoginSignupModal;

