import React from "react";
import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS } from "../../../../config/constants/data";
import {
  YOUTUBE_VIDEOS_TABLE_HEADER,
  YOUTUBE_VIDEOS_MAIN_TABLE_API,
} from "../../../../config/constants/tableHeaders";
import MainTable from "../../../../components/Tables/MainTable";
import { RecommendItems } from "../..";

const Videos = (props) => {
  const { setSelectedRoute } = props;
  const { channelId } = useParams();
  const location = useLocation();
  const projectNickName = location?.state?.projectNickName;
  useEffect(() => {
    setSelectedRoute("videos");
  }, [setSelectedRoute]);

  let filters = window.advancedFilters || {};
  YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });
  if (channelId !== "" && channelId !== undefined) {
    filters["channelId"] = channelId;
  }

  const NEW_TABLE_HEADER = YOUTUBE_VIDEOS_TABLE_HEADER.filter(
    (field) => field.title !== "Channel"
  );

  return (
    <div>
      <MainTable
        tableHeader={NEW_TABLE_HEADER}
        URL={YOUTUBE_VIDEOS_MAIN_TABLE_API}
        projectNickName={projectNickName}
        withTermsFilter
        withVideoLengthFilter
        withoutTopicFilter
        filters={filters}
        defaultTerm={"7d"}
        filterModalFields={YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS}
        model={"youtube-videos"}
        modelName="youtube-channel-video--channelId"
      />
      <RecommendItems />
    </div>
  );
};

export default Videos;

