import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsStar, BsStarFill } from "react-icons/bs";
import { Reload } from "@rsuite/icons";
import {
  getUserBookmarkValue,
  setUserBookmarkValue,
} from "../../../store/actions/userBookmarkActions";

const BookMarkButton = (props) => {
  const {
    model_id = 100,
    main_id,
    style,
    className,
    borderButton = true,
    fetchable = false,
  } = props;
  const [bookMark, setBookMark] = useState(false);
  const {
    data: bookmarks,
    currentId,
    isLoading,
  } = useSelector((state) => state.userBookmark) || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchable)
      dispatch(getUserBookmarkValue({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkBookmark = () => {
    return bookmarks.some(
      ({ main_id: rowMainId, model_id: rowModelId }) =>
        rowMainId === main_id && rowModelId === model_id
    );
  };

  useEffect(() => {
    setBookMark(checkBookmark());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const clickBookmark = () => {
    dispatch(setUserBookmarkValue({ model_id: model_id, main_id: main_id }));
  };

  return (
    <div
      className={className ? `${className}` : "icon-btn"}
      onClick={clickBookmark}
      style={style}
    >
      {isLoading && main_id === currentId ? (
        <Reload
          spin={isLoading}
          style={{ fontSize: borderButton ? "1.4rem" : "1rem" }}
          color={bookMark ? "white" : "#F5B97F"}
        />
      ) : bookMark ? (
        <BsStarFill size={borderButton ? 22 : 14} color={"#F5B97F"} />
      ) : (
        <BsStar size={borderButton ? 22 : 14} color={"white"} />
      )}
    </div>
  );
};

export default BookMarkButton;
