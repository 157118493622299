import React from 'react'
import EditKeywordTable from '../../../../../components/Tables/EditKeywordTable'
import { KEYWORD_EDIT_TABLE_HEADER, KEYWORD_EDIT_MAIN_TABLE_API } from '../../../../../config/constants/tableHeaders'

const Keyword = () => {
  
  return (
    <EditKeywordTable
      tableHeader={KEYWORD_EDIT_TABLE_HEADER}
      URL={KEYWORD_EDIT_MAIN_TABLE_API}
      withoutFilterModal
      model={"project"}
    />
  )
}

export default Keyword