import { API_HOST } from "../config/constants/data";

export const shortenAddress = (address) => {
  return `${address.substring(0, 5)}...${address.substring(
    address.length - 3,
    address.length
  )}`;
};

export const getFixedLength = (x) => {
  var length = 0;
  if (x === 0) {
    length = -3;
  }
  if (x.toString().split(".").length > 1) {
    var temp = x.toString().split(".")[1];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i] === "0") {
        length++;
      } else {
        break;
      }
    }
  }
  return length;
};

export const sortString = (encodedStr) => {
  const decodedStr = encodedStr
    ? encodedStr.replace(/&amp;/g, "&").replace(/&#39;/g, "'")
    : encodedStr;
  return decodedStr;
};

export const params2URL = (params) => {
  let apiURL = `${API_HOST}`;
  if ("URL" in params) {
    if (typeof params["URL"] !== "undefined") {
      apiURL += params["URL"];
    }
  }
  let startParam = true;
  for (const key in params) {
    if (key === "keyword" && params[key]?.toLowerCase() === "all") continue;
    if (
      key !== "URL" &&
      typeof params[key] !== "undefined" &&
      params[key] !== ""
    ) {
      if (startParam) {
        apiURL += "?";
        startParam = false;
      } else {
        apiURL += "&";
      }
      apiURL += `${key}=${encodeURIComponent(params[key])}`;
    }
  }
  return apiURL;
};

export const capitalizeFirstLetter = (inputString) => {
  if (inputString === undefined) return "Website";
  else return inputString.charAt(0).toUpperCase() + inputString.slice(1);
};

export const mergeArrays = (array1, array2) => {
  let mergedArray = [];
  const maxLength = Math.max(array1.length, array2.length);

  for (let i = 0; i < maxLength; i++) {
    if (i < array1.length) {
      mergedArray.push({ ...array1[i], trend: true });
    }
    if (i < array2.length) {
      mergedArray.push({ ...array1[i], trend: false });
    }
  }

  return mergedArray;
};

export const timeStringToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const secondsToTimeString = (totalSeconds) => {
  totalSeconds = Number(totalSeconds || 0);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = (totalSeconds % 3600) % 60;

  return [
    String(hours).padStart(2, "0"),
    String(minutes).padStart(2, "0"),
    String(seconds).padStart(2, "0"),
  ].join(":");
};

