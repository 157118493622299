import {
  FETCH_MAIN_MODEL_REQUEST,
  MAIN_MODEL_REQUEST,
  FETCH_MAIN_MODEL_SUCCESS,
  UPDATE_MAIN_MODEL_SUCCESS,
  CREATE_MAIN_MODEL_SUCCESS,
  DELETE_MAIN_MODEL_SUCCESS,
  MAIN_MODEL_FAILURE,
  UPDATE_MAIN_MODEL,
  POST_MAIN_MODEL_SUCCESS,
} from "../types";

const initialState = {
  data: [],
  count: 0,
  isLoading: false,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_MAIN_MODEL_REQUEST:
      return {
        ...state,
        data: [],
        count: 0,
        isLoading: true,
        error: null,
      };
    case MAIN_MODEL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_MAIN_MODEL_SUCCESS:
      return {
        ...state,
        data: payload?.data,
        count: payload?.count,
        isLoading: false,
        error: null,
      };
    case UPDATE_MAIN_MODEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: Array.isArray(state.data)
          ? state.data.map((product) =>
              "id" in payload ? product.id === payload.id ? payload : product :
              ("name" in payload && "nickname" in payload) ? product.name === payload.name ? payload : product :
              product
            )
          : state.data,
      };
    case DELETE_MAIN_MODEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: Array.isArray(state.data)
          ? state.data.filter((product) => product.id !== payload.id)
          : state.data,
      };
    case POST_MAIN_MODEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [...state.data],
      };
    case CREATE_MAIN_MODEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: [...state.data, payload],
      };
    case UPDATE_MAIN_MODEL:
      return {
        ...state,
        data: payload?.data,
      };
    case MAIN_MODEL_FAILURE:
      return {
        ...state,
        data: [],
        count: 0,
        isLoading: false,
        error: payload?.error,
      };
    default:
      return state;
  }
}
