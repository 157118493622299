import React from 'react'
import EditMainTable from '../../../../../components/Tables/EditMainTable'
import { TELEGRAM_DIALOGS_STAFF_MAIN_TABLE_API, TELEGRAM_DIALOGS_TABLE_HEADER } from '../../../../../config/constants/tableHeaders'

const Dialog = () => {
  let updatedHeader = structuredClone(TELEGRAM_DIALOGS_TABLE_HEADER);
  updatedHeader = updatedHeader.filter(header => (header.title!=='Vote' && header.title!==''))
  updatedHeader[updatedHeader.length-1].type = "component";
  updatedHeader[updatedHeader.length-1].checked = true;
  
  return (
    <EditMainTable
      tableHeader={updatedHeader}
      URL={TELEGRAM_DIALOGS_STAFF_MAIN_TABLE_API}
      withTermsFilter
      withoutFilterModal
      model={"telegram-groups"}
      modelName="edit-topic-group"
    />
  )
}

export default Dialog