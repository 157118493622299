import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavbarComponent from "../../layout/Navbar";
import "./style.css";
import GradiantProgressBar from "../../components/GradiantProgressBar/GradiantProgressBar";
import { HomeButton } from "../../components/Shared/HomeButton";
import CountUp from "react-countup";
import Marquee from "react-fast-marquee";

const projectLogoGroup1 = [
  "/images/project-logos/group1_optimism.svg",
  "/images/project-logos/group1_bnb.svg",
  "/images/project-logos/group1_ethereum.svg",
  "/images/project-logos/group1_arbitrum.svg",
  "/images/project-logos/group1_uniswap.svg",
];
const projectLogoGroup2 = [
  "/images/project-logos/group2_synthetix.svg",
  "/images/project-logos/group2_aave.svg",
  "/images/project-logos/group2_tron.svg",
  "/images/project-logos/group2_avalanche.svg",
  "/images/project-logos/group2_polygon.svg",
];
const projectLogoGroup3 = [
  "/images/project-logos/group3_ronin.svg",
  "/images/project-logos/group3_solana.svg",
  "/images/project-logos/group3_fantom.svg",
  "/images/project-logos/group3_sushi_swap.svg",
  "/images/project-logos/group3_cronons.svg",
];

const LandingPage = () => {
  const [progress, setProgress] = useState(0);
  const [insightsProgress, setInsightsProgress] = useState(0);

  const contentArr = [
    {
      index: 0,
      title: "Analytics",
      description:
        "Discover the power of advanced, no-code social media analytics. Metalyzer transforms complex data into actionable insights. Set up Smart Alerts to stay ahead of significant social trends and engagement metrics.",
      imgUrl: "/landingPage/Screenshot_1.png",
    },
    {
      index: 1,
      title: "Query",
      description:
        "Gain rapid access to a wealth of social media data with Metalyzer Query. Programmatic retrieval lets you dive deep into specific areas of interest, delivering the information you need with unparalleled speed.",
      imgUrl: "/landingPage/Screenshot_2.png",
    },
    {
      index: 2,
      title: "Research",
      description:
        "Benefit from expertly crafted insights with Metalyzer Research Portal. Stay informed with comprehensive analyses, trend forecasts, and industry-leading social media studies.",
      imgUrl: "/landingPage/Screenshot_3.png",
    },
    {
      index: 3,
      title: "Portfolio",
      description:
        "Efficiently manage your or your organization's social media presence with Metalyzer Portfolio. Track performance, engagement, and growth across all platforms in one centralized dashboard.",
      imgUrl: "/landingPage/Screenshot_5.png",
    },
  ];
  const [insightsActiveIndex, setInsightsActiveIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  // This function calculates the increment factor based on a quadratic curve
  const calculateBezierFactor = (progress) => {
    // Adjust these constants to change the curve shape
    const peak = 0.03; // Peak at the middle
    const scale = 0.5; // Scaling factor for the increment
    return Math.max(peak, scale * 4 * progress * (1 - progress));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        // Assuming the progress goes from 0 to 100
        if (prevProgress >= 100) {
          return 100;
        }
        // Calculate the next progress increment
        const factor = calculateBezierFactor(prevProgress / 100);
        return Math.min(prevProgress + factor, 100);
      });
    }, 10);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setInsightsProgress((prevProgress) => {
        // Assuming the progress goes from 0 to 100
        if (prevProgress >= 100) {
          return 100;
        }
        // Calculate the next progress increment
        const factor = calculateBezierFactor(prevProgress / 100);
        return Math.min(prevProgress + factor, 100);
      });
    }, 10);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (insightsProgress >= 100) {
      setInsightsActiveIndex((prevIndex) =>
        prevIndex < 3 ? prevIndex + 1 : 0
      );
      setInsightsProgress(0);
    }
  }, [insightsProgress]);

  useEffect(() => {
    if (progress >= 100) {
      setActiveIndex((prevIndex) => (prevIndex < 3 ? prevIndex + 1 : 0));
      setProgress(0);
    }
  }, [progress]);

  return (
    <div>
      <NavbarComponent fluid={true} />
      <div className="homepage">
        {/* <div className="query-gradient-one"></div>
        <div className="query-gradient-two"></div>
        <div className="query-gradient-two-b"></div> */}
        {/* <div className="query-gradient-three"></div>
        <div className="query-gradient-three-b"></div>
        <div className="query-gradient-four"></div>
        <div className="query-gradient-four-b"></div>
        <div className="query-gradient-five"></div> */}
        <div className="section-v2 is--home-hero">
          <Container>
            <Row className="hero">
              <Col md={6} className="hero-left">
                <div>
                  <h1 className="h1-text">
                    Discover Social Media Insights Like Never Before
                  </h1>
                  <p className="text-size-medium my-4">
                    Explore the Depths of Social Media Engagement with Metalyzer
                    <br /> Unlock a world of social media intelligence with
                    Metalyzer. Your ultimate automated agent for navigating the
                    intricate landscape of social media data.
                  </p>
                  <div className="pt-5 d-flex">
                    <HomeButton title="Start your trial" className="mr-4" />
                    <HomeButton title="Contact sales" variant="outline" />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <img
                  src="/landingPage/Screenshot_6.png"
                  loading="lazy"
                  alt="data"
                  className="homepage-insights_img"
                  width="100%"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={6} md={12}>
                <Row>
                  <Col md={12}>
                    <h1 className="h1-text">
                      Amplify Your Insights, Simplify Your Efforts
                    </h1>
                  </Col>
                  <Col>
                    <p className="slide-in-2 py-5">
                      Metalyzer: Tailoring Social Media Intelligence for
                      Ambitious Marketers and Teams
                    </p>
                  </Col>
                </Row>
                {contentArr.map((item, index) => (
                  <Row key={index} className="mb-3">
                    <Col
                      style={{
                        display: "flex",
                        marginBottom: 5,
                        position: "relative",
                      }}
                    >
                      <GradiantProgressBar
                        progress={insightsProgress}
                        active={insightsActiveIndex === index}
                        vertical={true}
                      />
                      <div
                        className={`${
                          insightsActiveIndex === index
                            ? "active-content-item"
                            : ""
                        } content-item`}
                        onClick={() => {
                          setInsightsActiveIndex(index);
                          setInsightsProgress(0);
                        }}
                      >
                        <p
                          className={`content-title strong ${
                            insightsActiveIndex === index
                              ? "text-white"
                              : "text-content"
                          }`}
                        >
                          {item.title}
                        </p>
                        <p
                          className={`"content-description" ${
                            insightsActiveIndex === index
                              ? "text-white"
                              : "text-content"
                          }`}
                        >
                          {item.description}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col lg={6} md={12} className="content-img-item">
                <img
                  src={contentArr[insightsActiveIndex].imgUrl}
                  className="content-img "
                  alt="content"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ paddingTop: 150 }}>
          <Container>
            <Row className="justify-content-center">
              <Col md={8} xs={12}>
                <h1 className="h1-text text-center">
                  Amplify Your Insights, Simplify Your Efforts
                </h1>
              </Col>
            </Row>
            <Row className="justify-content-center py-5">
              <Col md={8} xs={12}>
                <p className="slide-in-2 text-center">
                  Metalyzer: Tailoring Social Media Intelligence for Ambitious
                  Marketers and Teams
                </p>
              </Col>
            </Row>
            <Row className="py-5">
              {contentArr.map((item, index) => (
                <Col xs={12} md={3} key={index}>
                  <Row className="mb-3">
                    <Col
                      style={{
                        display: "flex",
                        marginBottom: 5,
                        marginRight: 20,
                        position: "relative",
                      }}
                    >
                      <div
                        className={`${
                          activeIndex === index ? "active-content-item" : ""
                        } content-item`}
                        onClick={() => {
                          setActiveIndex(index);
                          setProgress(0);
                        }}
                      >
                        <p
                          className={`content-title strong ${
                            activeIndex === index
                              ? "text-white"
                              : "text-content"
                          }`}
                        >
                          {item.title}
                        </p>
                        <p
                          className={`"content-description" ${
                            activeIndex === index
                              ? "text-white"
                              : "text-content"
                          }`}
                        >
                          {item.description}
                        </p>
                      </div>
                      <GradiantProgressBar
                        progress={progress}
                        active={activeIndex === index}
                      />
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
            <Row className="justify-content-center">
              <Col lg={8} md={12} className="content-img-item">
                <img
                  src={contentArr[activeIndex].imgUrl}
                  className="content-img fixed-height"
                  alt="content1"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ paddingTop: 150 }}>
          <Container>
            <Row className="justify-content-center">
              <Col md={8} xs={12}>
                <h1 className="h1-text text-center">
                  The Foundation for <br />
                  Social Media Intelligence
                </h1>
              </Col>
            </Row>
            <Row className="justify-content-center py-5">
              <Col md={8} xs={12}>
                <p className="slide-in-2 text-center">
                  Discover Trends, Track Engagements, and Uncover Influential
                  Content with Precision.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center py-5">
              <Col md={4} xs={12} className="stats-col-after">
                <p className="stat-title larger text-center">
                  <CountUp end={600} />+
                </p>
                <p className="slide-in-2 text-center">Projects</p>
              </Col>
              <Col md={4} xs={12} className="stats-col-after">
                <p className="stat-title larger text-center">
                  <CountUp end={5000} />+
                </p>
                <p className="slide-in-2 text-center"> Websites</p>
              </Col>
              <Col md={4} xs={12} className="">
                <p className="stat-title larger text-center">
                  <CountUp end={10000} />+
                </p>
                <p className="slide-in-2 text-center">Influencers</p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section-v2 is--home-hero">
          <Container>
            <Row>
              <Col md={6}>
                <div>
                  <h1 className="h1-text">
                    Comprehensive Coverage for Social Media Platforms and
                    Analytics Tools
                  </h1>
                  <p className="text-size-medium my-4">
                    Experience unmatched support for a wide range of social
                    media networks and analytical methodologies, continuously
                    expanding to include the latest trends.
                  </p>
                  <div className="pt-5 px-3">
                    <Row>
                      <Col md={6} className="stats-col-before">
                        <p className="stat-title pl-4">
                          <CountUp end={600} />+
                        </p>
                        <p className="slide-in-2 pl-4">Projects supported</p>
                      </Col>
                      <Col md={6} className="stats-col-before">
                        <p className="stat-title pl-4">
                          <CountUp end={3000} />+
                        </p>
                        <p className="slide-in-2 pl-4">Discord Servers</p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="logo-wrapper">
                  <Marquee
                    className="mb-5"
                    autoFill
                    gradient
                    gradientColor="#17171a"
                    speed={45}
                    gradientWidth={300}
                  >
                    {projectLogoGroup1.map((logo, index) => {
                      return (
                        <img
                          key={index}
                          src={logo}
                          loading="lazy"
                          alt=""
                          className="coverage_logo-img"
                        />
                      );
                    })}
                  </Marquee>
                  <Marquee
                    className="mb-5"
                    autoFill
                    gradient
                    gradientColor="#17171a"
                    speed={35}
                    gradientWidth={300}
                    direction="right"
                  >
                    {projectLogoGroup2.map((logo, index) => {
                      return (
                        <img
                          key={index}
                          src={logo}
                          loading="lazy"
                          alt=""
                          className="coverage_logo-img"
                        />
                      );
                    })}
                  </Marquee>
                  <Marquee
                    autoFill
                    gradient
                    gradientColor="#17171a"
                    gradientWidth={300}
                    speed={30}
                  >
                    {projectLogoGroup3.map((logo, index) => {
                      return (
                        <img
                          key={index}
                          src={logo}
                          loading="lazy"
                          alt=""
                          className="coverage_logo-img"
                        />
                      );
                    })}
                  </Marquee>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;

