import React from 'react'
import Form from 'react-bootstrap/Form';

const BoolField = props => {
  const { label, field, filters, setFilters } = props;
  return (
    <div className='d-flex row justify-content-start align-items-center ml-3'>
      <Form.Check
        // className="h-auto w-100 form-control form-control-md mb-3"
        className="pb-1"
        type="checkbox"
        id="custom-switch"
        key={field}
        onChange={(e) => {
          let fieldState = {};
          fieldState[field] = e.target.checked.toString();
          setFilters(
            {
              ...filters,
              ...fieldState,
            })
        }}
      />
      {
        label &&
        <label className="pt-2 ml-3">
          <span>{label}</span>
        </label>
      }
    </div>
  )
}

export default BoolField