import { SET_WEBSITE_TOPICS } from "../types";

const initialState = {
  websiteTopics: [],
}

const websiteTopicReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_WEBSITE_TOPICS:
      return { ...state, websiteTopics: payload };
    default:
      return state;
  }
}

export default websiteTopicReducer;