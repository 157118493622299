import {
  GET_MOST_VIDEOS_LOADING,
  GET_MOST_VIDEOS_SUCCESS,
  GET_MOST_VIDEOS_FAIL,
} from '../types';

const initialState = {
  mostVideosData: [],
  mostVideosApiLoading: false,
  mostVideosError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_MOST_VIDEOS_LOADING:
      return {
        ...state,
        mostVideosApiLoading: true,
        mostVideosError: null,
      };
    case GET_MOST_VIDEOS_SUCCESS:
      return {
        ...state,
        mostVideosData: payload.mostVideosData,
        mostVideosApiLoading: false,
        mostVideosError: null,
      };
    case GET_MOST_VIDEOS_FAIL:
      return {
        ...state,
        mostVideosData: [],
        mostVideosApiLoading: false,
        mostVideosError: payload?.mostVideosError
      };
    default:
      return state;
  }
}
