import {
  GET_CHART_API_LOADING,
  GET_CHART_API_SUCCESS,
  GET_CHART_API_FAIL,
} from '../types';

const initialState = {
  chartData: [],
  chartdataCount: 0,
  chartApiLoading: false,
  chartError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_CHART_API_LOADING:
      return {
        ...state,
        chartApiLoading: true,
        chartError: null,
      };
    case GET_CHART_API_SUCCESS:
      return {
        ...state,
        chartData: payload.chartData,
        chartdataCount: payload.chartDataCount,
        chartApiLoading: false,
        chartError: null,
      };
    case GET_CHART_API_FAIL:
      return {
        ...state,
        chartData: [],
        chartApiLoading: false,
        chartError: payload?.chartError
      };
    default:
      return state;
  }
}
