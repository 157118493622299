import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LINK_DOMAINS_FILTER_MODAL_FIELDS } from "../../../../config/constants/data";
import {
  WEBSITE_TOPIC_TABLE_HEADER,
  YOUTUBE_CHANNEL_WEBSITES_TABLE_HEADER,
  YOUTUBE_CHANNEL_DETAILS_WEBSITE_TOPIC_API,
  YOUTUBE_CHANNEL_DETAILS_WEBSITES_API,
} from "../../../../config/constants/tableHeaders";
import MainTable from "../../../../components/Tables/MainTable";
import { RecommendItems } from "../..";

const Websites = (props) => {
  const { channelId, topic = undefined } = useParams();
  useEffect(() => {
    props.setSelectedRoute("websites");
    // eslint-disable-next-line
  }, []);
  const [tableHeader, setTableHeader] = useState(WEBSITE_TOPIC_TABLE_HEADER);
  const [tableAPI, setTableAPI] = useState(
    YOUTUBE_CHANNEL_DETAILS_WEBSITE_TOPIC_API
  );
  useEffect(() => {
    if (topic === undefined) {
      setTableHeader(WEBSITE_TOPIC_TABLE_HEADER);
      setTableAPI(YOUTUBE_CHANNEL_DETAILS_WEBSITE_TOPIC_API);
    } else {
      setTableHeader(YOUTUBE_CHANNEL_WEBSITES_TABLE_HEADER);
      setTableAPI(YOUTUBE_CHANNEL_DETAILS_WEBSITES_API);
    }
  }, [topic]);

  let filters = window.advancedFilters || {};
  LINK_DOMAINS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });

  return (
    <div>
      <MainTable
        currentTopic={topic}
        tableHeader={tableHeader}
        URL={tableAPI.replace("{channelId}", channelId)}
        filters={filters}
        withoutFilterModal
        withoutProjectFilter
        withoutTopicFilter={topic === undefined}
        // filterModalFields={LINK_DOMAINS_FILTER_MODAL_FIELDS}
        model={"websites"}
        modelName="youtube-channel-website-link--channelId-topic"
      />
      <RecommendItems />
    </div>
  );
};

export default Websites;

