import React from "react";
import { Spinner } from "react-bootstrap";
import "./LoadingOverlay.scss";

function LoadingOverlay() {
  return (
    <div className="loading-overlay">
      <Spinner animation="border" variant="primary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

export default LoadingOverlay;
