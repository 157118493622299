import axios from 'axios';

import {
  GET_EVERY_MINUTE_LOADING,
  GET_EVERY_MINUTE_SUCCESS,
  GET_EVERY_MINUTE_FAIL,
} from '../types';
import { attachTokenToHeaders } from './authActions';
import { params2URL } from '../../utils/common';

export const getEveryMinuteApi = (params) => async (dispatch, getState) => {
  dispatch({ type: GET_EVERY_MINUTE_LOADING });
  try {
    const response = await axios.get(params2URL(params), attachTokenToHeaders(getState));
    dispatch({
      type: GET_EVERY_MINUTE_SUCCESS,
      payload: { everyMinuteData: response.data },
    });

  } catch (err) {
    dispatch({
      type: GET_EVERY_MINUTE_FAIL,
      payload: { everyMinuteError: err.response.data.message },
    });
  }
};
