import * as Yup from 'yup';

export const passwordSchema = Yup.object({
    currentPwd: Yup.string()
        .required('Current password is required'),
    newPwd: Yup.string()
        .min(6, 'Must be 6 characters at minimum')
        .max(255, 'Must be 255 characters or less')
        .required('New password is required'),
    confirmPwd: Yup.string()
        .required('Confirm password is required')
        .min(6, 'Must be 6 characters at minimum')
        .max(255, 'Must be 255 characters or less')
        .oneOf([Yup.ref('newPwd'), null], 'Passwords must match')
});