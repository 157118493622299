import React from "react";
import { useParams } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import MainTable from "../../../../components/Tables/MainTable";

import { LINK_DOMAINS_FILTER_MODAL_FIELDS } from "../../../../config/constants/data";
import { routing2TableHeaderURL } from "../../../../utils/routing2TableHelper";
import { RecommendItems } from "../..";

const WebsiteSocial = () => {
  const { nickName, domain } = useParams();
  let modelName = "project-social-website--nickName";
  const [tableHeader, URL] = routing2TableHeaderURL(
    "social",
    undefined,
    domain
  );
  let filters = window.advancedFilters || {};
  LINK_DOMAINS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });
  if (domain !== undefined) {
    modelName = "project-social-link--nickName-website";
    filters["domain"] = domain;
  }
  return (
    <>
      <MainTable
        tableHeader={tableHeader}
        URL={URL}
        filters={filters}
        withTermsFilter
        withoutTopicFilter
        withoutFilterModal
        projectNickName={nickName}
        defaultTerm={"7d"}
        initOrdering={"-links"}
        model={"websites"}
        modelName={modelName}
      />
      <RecommendItems />
    </>
  );
};

export default WebsiteSocial;

