import {
  SET_TOPIC_VALUE_LOADING,
  SET_TOPIC_VALUE_SUCCESS,
  SET_TOPIC_VALUE_FAIL,
} from '../types';

const initialState = {
  data: "",
  isLoading: false,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_TOPIC_VALUE_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case SET_TOPIC_VALUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        error: null,
      };
    case SET_TOPIC_VALUE_FAIL:
      return {
        ...state,
        isLoading: false,
        data: "error",
        error: payload?.error
      };
    default:
      return state;
  }
}
