import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { ToastContainer, toast, Flip } from "react-toastify";
import SearchComponent from "../Components/SearchComponent";
import TableView from "../Components/TableView";
import TablePaginate from "../Components/TablePaginate";
import PageSizeSelection from "../Components/PageSizeSelection";
import "../styles.css";
import ReactTooltip from "react-tooltip";
import "../../../assets/css/styles.css";
import CategoryFilter from "../Components/CategoryFilter";
import { Icon } from "@iconify/react";
import AddProjectModal from "../Components/EditProject/AddProjectModal";
import { fetchMainModel } from "../../../store/actions/mainModelActions";
import { getDefaultOrderingField } from "../../../utils/tableDataHelper";

const EditProjectTable = (props) => {
  const {
    tableHeader,
    URL,
    withOutCategorySelection,
    withoutSearch,
    withoutAddProjectButton,
  } = props;
  const dispatch = useDispatch();
  const { data, count, isLoading, error } = useSelector(
    (state) => state.mainModel
  );
  // Pagination states.
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  // Terms states.
  const [category, setCategory] = useState("category");
  // Filters states.
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  // Search state.
  // const [search, setSearch] = useState("");
  const [search, setSearch] = useState(
    useSelector((state) => state.uiSettings.searchText) || ""
  );
  // Table Header state.
  const updateCategory = (newCategory) => {
    setCategory(newCategory);
    callApi({ newPage: 1, newCategory: newCategory, newKeyword: "All" });
  };

  const getCurrentOrdering = () => {
    const val =
      window.currentOrdering || getDefaultOrderingField("edit-project");
    // delete window.currentOrdering;
    return val;
  };
  const [currentOrdering, setCurrentOrdering] = useState(getCurrentOrdering());

  // Call Api function.
  const callApi = (params) => {
    const newPage = params?.newPage ? params.newPage : page;
    const newPageSize = params?.newPageSize ? params.newPageSize : pageSize;
    const newCategory = params?.newCategory ? params.newCategory : category;
    const ordering = getCurrentOrdering();
    dispatch(
      fetchMainModel({
        URL: URL,
        ...props?.filters,
        category: newCategory,
        page: newPage,
        page_size: newPageSize,
        search: search,
        ordering,
      })
    );
  };

  const handleOrdering = (direction, sortField, sortType) => {
    setCurrentOrdering(
      (window.currentOrdering =
        (direction === "-" ? direction : "") + sortField)
    );
    callApi({ newPage: page });
  };

  // First Loading.
  useEffect(() => {
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Error toast message.
  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [error]);

  // handles
  const handleApply = () => {
    setPage(1);
    callApi({ newPage: 1 });
  };

  const handlePageClick = (changePage) => {
    setPage(changePage);
    callApi({ newPage: changePage });
  };
  const changePageSize = (pageSize) => {
    setPageSize(pageSize);
    setPage(1);
    callApi({ newPage: 1, newPageSize: pageSize });
  };

  return (
    <div className="row">
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex flex-row align-items-center ml-2">
                {withOutCategorySelection ? (
                  <></>
                ) : (
                  <CategoryFilter
                    defaultCategory={category}
                    setCategory={updateCategory}
                  />
                )}
              </div>

              <div className="d-flex flex-row align-items-center">
                {withoutSearch ? (
                  <></>
                ) : (
                  <SearchComponent
                    search={search}
                    setSearch={setSearch}
                    handleSearch={handleApply}
                  />
                )}
                <PageSizeSelection
                  pageSize={pageSize}
                  changePageSize={changePageSize}
                />
                {withoutAddProjectButton ? (
                  <></>
                ) : (
                  <a
                    href="#/"
                    className="btn btn-default table-header-filter d-flex align-items-center mx-1"
                    style={{ color: "#16C583" }}
                    onClick={() => setShowAddProjectModal(true)}
                  >
                    <Icon
                      icon="material-symbols:add"
                      height="1.5rem"
                      color="#16C583"
                    />{" "}
                    Add Project
                  </a>
                )}
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <TableView
                header={tableHeader}
                data={data}
                startNumber={pageSize * (page - 1)}
                initOrdering={currentOrdering}
                handleOrdering={handleOrdering}
                stickyHeader
                modelName="edit-project"
              />
            )}
            <TablePaginate
              pageSize={pageSize}
              page={page}
              count={count}
              handlePageClick={handlePageClick}
            />
            <ReactTooltip />
          </div>
        </div>
      </div>
      {showAddProjectModal && (
        <AddProjectModal
          show={showAddProjectModal}
          setShowModal={setShowAddProjectModal}
        />
      )}
      <ToastContainer theme="dark" transition={Flip} />
    </div>
  );
};

export default EditProjectTable;

