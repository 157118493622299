import React from "react";
import "./styles.css";

const PageTitle = ({ title, description, style }) => {
  return (
    <div className="row mt-3 mx-1" style={style}>
      <div className="col-lg-6 col-sm-12 p-0">
        <p className="title-header">{title}</p>
        <p className="title-description">{description}</p>
      </div>
    </div>
  );
};

export default PageTitle;

