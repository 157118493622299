import * as Yup from 'yup';

export const forgetPasswordSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  authCode: Yup.string()
    .length(6, 'Validation code must be exactly 6 digits')
    .matches(/^[0-9]+$/, 'Verification code must contain only numbers')
    .required('Validation code is required'),
  password: Yup.string()
    .min(6, 'Must be 6 characters at minimum')
    .max(20, 'Must be 20 characters or less')
    .required('Password is Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Must match "password" field value')
    .required('Required'),
});
