import {
  GET_RECENT_VIDEOS_LOADING,
  GET_RECENT_VIDEOS_SUCCESS,
  GET_RECENT_VIDEOS_FAIL,
} from '../types';

const initialState = {
  recentVideosData: [],
  recentVideosApiLoading: false,
  recentVideosError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_RECENT_VIDEOS_LOADING:
      return {
        ...state,
        recentVideosApiLoading: true,
        recentVideosError: null,
      };
    case GET_RECENT_VIDEOS_SUCCESS:
      return {
        ...state,
        recentVideosData: payload.recentVideosData,
        recentVideosApiLoading: false,
        recentVideosError: null,
      };
    case GET_RECENT_VIDEOS_FAIL:
      return {
        ...state,
        recentVideosData: [],
        recentVideosApiLoading: false,
        recentVideosError: payload?.recentVideosError
      };
    default:
      return state;
  }
}
