import {
  GET_PROJECT_NAMES_LOADING,
  GET_PROJECT_NAMES_SUCCESS,
  GET_PROJECT_NAMES_FAIL,
} from '../types';

const initialState = {
  projectNames: [],
  projectNamesCount: 0,
  projectNamesLoading: false,
  projectNameserror: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROJECT_NAMES_LOADING:
      return {
        ...state,
        projectNamesLoading: true,
        projectNameserror: null,
      };
    case GET_PROJECT_NAMES_SUCCESS:
      return {
        ...state,
        projectNames: payload.data,
        projectNamesCount: payload.count,
        projectNamesLoading: false,
        projectNameserror: null,
      };
    case GET_PROJECT_NAMES_FAIL:
      return {
        ...state,
        projectNames: [],
        projectNamesCount: 0,
        projectNamesLoading: false,
        projectNameserror: payload?.error
      };
    default:
      return state;
  }
}
