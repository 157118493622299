import React from "react";
import MainSelection from "../../../Selections/MainSelection";
import { TERMS, DEFAULT_TERM } from "../../../../config/constants/data";

const TermsFilter = (props) => {
  const { term, setTerm, modelName } = props;
  let defaultValue = TERMS.filter((ele) => {
    return ele.value === term;
  });
  let items = TERMS;
  if (
    [
      "social-link--domain",
      "social-website",
      "social-website--category",
      "social-link--category-domain",
      "project-social-website--nickName",
      "project-social-link--nickName-website",
    ].includes(modelName)
  ) {
    items = items.slice(0, 3);
  }
  const defaultItem = defaultValue.length > 0 ? defaultValue[0] : DEFAULT_TERM;

  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <MainSelection
        title=""
        selectionItems={items}
        defaultItem={defaultItem}
        setItem={setTerm}
        sorted={false}
      />
    </div>
  );
};

export default TermsFilter;
