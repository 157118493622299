import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile/Profile";
import YoutubeProject from "./pages/YoutubeProject";
import YoutubeVideos from "./pages/YoutubeVideos";
import YoutubeChannels from "./pages/YoutubeChannels";
// import TwitterProject from './pages/TwitterProject';
// import TwitterTweets from './pages/TwitterTweets';
// import TwitterUsers from './pages/TwitterUsers';

import DiscordServers from "./pages/DiscordServers";
import DiscordChannels from "./pages/DiscordChannels";

import EditTopic from "./pages/Edit/EditTopic";
import EditProject from "./pages/Edit/EditProject";
import EditDiscord from "./pages/Edit/EditDiscord";

import ProjectDetail from "./pages/ProjectDetails";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import MetaTags from "./MetaTags";
import LoadSettings from "./LoadSettings";

import { loadMe } from "./store/actions/authActions";
// import DiscordProject from "./pages/DiscordProject";
import WebsiteNews from "./pages/WebsiteNews";
import ContactUs from "./pages/ContactUs";
// import TelegramProject from "./pages/TelegramProject";
import TelegramDialogs from "./pages/TelegramDialogs";
import TelegramGroupDetails from "./pages/TelegramGroupDetails";
import DiscordServerDetails from "./pages/DiscordServerDetails";
import LinkDomainDetails from "./pages/LinksDomainDetails";
import YoutubeChannelDetails from "./pages/YoutubeChannelDetails";
import ReactGA from "react-ga4";
import ScrollToTop from "./components/ScrollToTop";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import { gapi } from "gapi-script";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import EditBlog from "./pages/Edit/EditBlog";
import EditBlogDetails from "./pages/Edit/EditBlogDetails";
import Service from "./pages/Service";
import Payment from "./pages/Payment";
import WebsiteUser from "./pages/WebsiteUser";
import WebsiteSocial from "./pages/WebsiteSocial";
import useUserRole from "./hooks/useUserRole";
import Logout from "./pages/Logout";
import Users from "./pages/Users";
import LandingPage from "./pages/LandingPage/LandingPage";
import BookmarkPage from "./pages/BookmarkPage/BookmarkPage";
import Newsletter from "./pages/Newsletter/Newsletter";
// import Home from "./pages/Home";
// import WebSocketTest from "./components/WebSocketTest/WebsocketTest";

const clientId =
  "57175740677-9uqqht9i3a7g14qmih3rjefutb27jte9.apps.googleusercontent.com";

const App = () => {
  ReactGA.initialize("G-27DJWFJFJG");
  ReactGA.initialize("G-SN7XX5N50J");
  ReactGA.send({ hitType: "pageview", page: "/", title: "Dashboard" });

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  const dispatch = useDispatch();
  const { auth, isManager, isEditable, isAuthenticated } = useUserRole();
  // useEffect(() => {
  //   loadMe();
  // }, [loadMe]);
  // useEffect(() => {
  //   const tawkToScript = document.createElement("script");
  //   tawkToScript.async = true;
  //   tawkToScript.src = "https://embed.tawk.to/64872e5194cf5d49dc5d35eb/1h2o0ptri";
  //   tawkToScript.charset = "UTF-8";
  //   tawkToScript.setAttribute("crossorigin", "*");
  //   document.body.appendChild(tawkToScript);
  //   getuuId()
  // }, []);

  useEffect(() => {
    if (!auth.isLoading && auth.token) {
      dispatch(loadMe());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const NotFound = React.lazy(() => import("./pages/NotFound/NotFound"));
  // const Dashboard = React.lazy(() => import('./pages/Dashboard'))
  // const ProjectDetail = React.lazy(() => import('./pages/ProjectDetails'))
  // const YoutubeProject = React.lazy(() => import('./pages/YoutubeProject'))
  // const YoutubeChannels = React.lazy(() => import('./pages/YoutubeChannels'))
  // const YoutubeVideos = React.lazy(() => import('./pages/YoutubeVideos'))
  // const Profile = React.lazy(() => import('./pages/Profile/Profile'))
  // const TwitterProject = React.lazy(() => import('./pages/TwitterProject'))
  // const TwitterTweets = React.lazy(() => import('./pages/TwitterTweets'))
  // const TwitterUsers = React.lazy(() => import('./pages/TwitterUsers'))
  // const DiscordServers = React.lazy(() => import('./pages/DiscordServers'))
  // const DiscordChannels = React.lazy(() => import('./pages/DiscordChannels'))
  // const EditTopic = React.lazy(() => import('./pages/Edit/EditTopic'))
  // const EditProject = React.lazy(() => import('./pages/Edit/EditProject'))
  // const EditDiscord = React.lazy(() => import('./pages/Edit/EditDiscord'))

  // const PrivateRoute = React.lazy(() => import('./components/PrivateRoute/PrivateRoute'))
  // const Loader = React.lazy(() => import('./components/Loader/Loader'))
  // const MetaTags = React.lazy(() => import('./MetaTags'))
  // const LoadSettings = React.lazy(() => import('./LoadSettings'))

  return (
    <>
      <MetaTags />
      <ScrollToTop />
      <LoadSettings />
      {/* {auth.appLoaded ? ( */}
      <Suspense fallback={<></>}>
        <Switch>
          {/* Top Routes. */}
          <Route
            exact
            path="/"
            component={() => <Redirect to="/home" exact />}
          />
          <Route exact path="/home" component={LandingPage} />
          <Route key={1} path="/contact" component={ContactUs} />
          <Route key={2} path="/notfound" component={NotFound} />
          <Route key={3} path="/logout" component={Logout} />

          <PrivateRoute
            key={50}
            exact
            path="/newsletter"
            component={Newsletter}
            show404
            authed={isAuthenticated}
          />

          <Route key={4} path="/plans" component={Service} />
          <Route key={5} path="/payment" component={Payment} />

          <PrivateRoute
            key={6}
            path="/profile"
            component={Profile}
            show404
            authed={isAuthenticated}
          />
          <PrivateRoute
            key={7}
            path="/change-password"
            component={ChangePassword}
            show404
            authed={isAuthenticated}
          />

          <PrivateRoute
            key={8}
            exact
            path="/blogs"
            component={Blog}
            show404
            authed={isAuthenticated}
          />
          <PrivateRoute
            key={9}
            exact
            path="/blog/:id?"
            component={BlogDetails}
            show404
            authed={isAuthenticated}
          />

          {/* Project Relation. */}
          <PrivateRoute
            key={10}
            path="/project/:nickName?"
            component={ProjectDetail}
            show404
            authed={isAuthenticated}
          />

          {/* Category Relation. */}
          <PrivateRoute
            key={11}
            path="/view/:category?"
            component={Dashboard}
            show404
            authed={isAuthenticated}
          />
          {/* <PrivateRoute
            key={12}
            exact
            path="/market"
            component={Dashboard}
            show404
            authed={isAuthenticated}
          /> */}

          {/* Youtube Relation. */}
          {/* <PrivateRoute
            key={13}
            exact
            path="/youtube/"
            component={YoutubeProject}
            show404
            authed={isAuthenticated}
          /> */}
          <PrivateRoute
            key={14}
            path="/youtube-videos/:nickName?"
            component={YoutubeVideos}
            show404
            authed={isAuthenticated}
          />
          <PrivateRoute
            key={15}
            path="/youtube-channels/:nickName?"
            component={YoutubeChannels}
            show404
            authed={isAuthenticated}
          />
          <PrivateRoute
            key={16}
            path="/youtube/channel/:channelId?"
            component={YoutubeChannelDetails}
            show404
            authed={isAuthenticated}
          />
          {/* <PrivateRoute exact path="/twitter/" component={TwitterProject} 
            show404
            authed={isAuthenticated}/>
            <PrivateRoute path="/twitter-tweets/" component={TwitterTweets} 
            show404
            authed={isAuthenticated}/>
            <PrivateRoute path="/twitter-users/" component={TwitterUsers} 
            show404
            authed={isAuthenticated}/> */}
          {/* Telegram Relation. */}
          {/* <PrivateRoute key={17} exact path="/telegram/" component={TelegramProject} 
            show404
            authed={isAuthenticated}/> */}
          {/* <PrivateRoute
            key={18}
            exact
            path="/telegram-groups/"
            component={TelegramDialogs}
            show404
            authed={isAuthenticated}
          />
          <PrivateRoute
            key={19}
            path="/telegram/group/:dialog_id?"
            component={TelegramGroupDetails}
            show404
            authed={isAuthenticated}
          /> */}
          {/* Discord Relation. */}
          {/* <PrivateRoute key={20} exact path="/discord/" component={DiscordProject} 
            show404
            authed={isAuthenticated}/> */}
          {/* <PrivateRoute
            key={21}
            path="/discord-servers/:nickName?"
            component={DiscordServers}
            show404
            authed={isAuthenticated}
          />
          <PrivateRoute
            key={22}
            path="/discord-channels/:server_id?"
            component={DiscordChannels}
            show404
            authed={isAuthenticated}
          />
          <PrivateRoute
            key={23}
            path="/discord/server/:server_id?"
            component={DiscordServerDetails}
            show404
            authed={isAuthenticated}
          />
          */}
          {/* Website Relation */}
          {/* <PrivateRoute
            key={24}
            exact
            path="/websites/:topic?"
            component={WebsiteUser}
            show404
            authed={isAuthenticated}
          /> */}
          {/* <PrivateRoute
            key={33}
            exact
            path="/websocket"
            component={WebSocketTest}
          
            show404
            authed={isAuthenticated}/> */}
          <PrivateRoute
            key={26}
            path="/website/:domain?"
            component={LinkDomainDetails}
            show404
            authed={isAuthenticated}
          />
          <PrivateRoute
            key={27}
            path="/home"
            component={LandingPage}
            show404
            authed={isAuthenticated}
          />
          <PrivateRoute
            key={28}
            exact
            path="/news/"
            component={WebsiteNews}
            show404
            authed={isAuthenticated}
          />
          {/* <PrivateRoute
            key={29}
            exact
            path="/social/:domain?"
            component={WebsiteSocial}
            show404
            authed={isAuthenticated}
          /> */}
          <PrivateRoute
            key={30}
            path="/edit/topic/:model?"
            component={EditTopic}
            authed={isEditable}
          />
          <PrivateRoute
            key={31}
            path="/edit/project/:model?"
            component={EditProject}
            authed={isManager}
          />
          <PrivateRoute
            key={32}
            path="/edit/discord/:model?"
            component={EditDiscord}
            authed={isEditable}
          />
          <PrivateRoute
            key={33}
            exact
            path="/edit/blog"
            component={EditBlog}
            authed={isManager}
          />
          <PrivateRoute
            key={34}
            exact
            path="/edit/blog/:id?"
            component={EditBlogDetails}
            authed={isManager}
          />
          <PrivateRoute
            key={35}
            exact
            path="/users"
            component={Users}
            authed={isManager}
          />
          <PrivateRoute
            key={36}
            exact
            path="/watchlist"
            component={BookmarkPage}
            authed={true}
          />
          <Route key={100} component={NotFound} />
        </Switch>
      </Suspense>
      {/* ) : (
        <Loader />
      )} */}
    </>
  );
};

export default App;

