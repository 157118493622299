import { Icon } from "@iconify/react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import TrendPercentage from "../TrendPercentage";
import { transformNumber } from "../../../utils/tableDataHelper";

const StatisticsInforRow = (props) => {
  const { title, count, preCount, trendable = true, format = "shorterNumber", symbol = ""} = props;
  return (
    <Row className="d-flex flex-row justify-content-between align-items-baseline m-0">
      <Col xs={6} md={6}>
        <Row className="d-flex flex-row align-items-center">
          <div style={{ fontSize: 14 }}>{title}</div>
          <Icon
            icon="material-symbols:info-outline"
            height="1.5rem"
            style={{ marginLeft: 3 }}
          />
        </Row>
      </Col>
      {/* <Col xs={6} md={6}> */}
      <Row className="d-flex flex-row align-items-baseline my-2 m-0">
        {trendable && (
          <TrendPercentage
            currentValue={count}
            pastValue={preCount}
            mark="% (7d)"
          />
        )}
        <div className="ml-2" style={{ fontSize: 16, fontWeight: "bold", color: !trendable ? "#16C784" : "#fff"}}>
          {transformNumber(count, format)}{symbol}
        </div>
      </Row>
      {/* </Col> */}
    </Row>
  );
};

export default StatisticsInforRow;
