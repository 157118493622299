import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import { Form, Card, Button } from "react-bootstrap";

import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { passwordSchema } from "./validation";
import Layout from "../../layout/Layout";

import { changePassword } from "../../store/actions/authActions";
import useUserRole from "../../hooks/useUserRole";

const ChangePassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useUserRole();
  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);

  useEffect(() => {
    if (auth.error) {
      notifyError("Old password is wrong.");
    }
    if (auth.status === "ok") {
      notifySuccess("Password is changed successfully.");
    }
    if (auth.googleLoggedIn) {
      notifyError(
        "If you logged in with Google, you can manage your account and change your password through your Google account settings."
      );
    }
  }, [auth]);

  const initialValues = {
    currentPwd: "",
    newPwd: "",
    confirmPwd: "",
  };

  const handleSubmitFunc = async (values) => {
    await dispatch(changePassword(values, history));
  };
  return (
    <Layout>
      <div className="h-100">
        <div className="d-flex auth px-0 h-100">
          <div className="row w-100 mx-0">
            <div className="col-lg-6 mx-auto">
              <div className="card text-left py-5 px-4 px-sm-5"></div>
              <h1>Change password</h1>
              <Formik
                onSubmit={handleSubmitFunc}
                initialValues={initialValues}
                validationSchema={passwordSchema}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card className="mb-3">
                      <Card.Body>
                        <Form.Group className="d-flex flex-column pt-3">
                          <Form.Label>Current password</Form.Label>
                          <Form.Control
                            type="password"
                            name="currentPwd"
                            className="h-auto"
                            size="lg"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.currentPwd}
                            disabled={auth.googleLoggedIn}
                          />

                          {touched.currentPwd && errors.currentPwd ? (
                            <p className="text-danger">{errors.currentPwd}</p>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="d-flex flex-column pt-3">
                          <Form.Label>New password</Form.Label>
                          <Form.Control
                            type="password"
                            name="newPwd"
                            className="h-auto"
                            size="lg"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.newPwd}
                            disabled={auth.googleLoggedIn}
                          />

                          {touched.newPwd && errors.newPwd ? (
                            <p className="text-danger">{errors.newPwd}</p>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="d-flex flex-column pt-3">
                          <Form.Label>Confirm password</Form.Label>
                          <Form.Control
                            type="password"
                            name="confirmPwd"
                            className="h-auto"
                            size="lg"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPwd}
                            disabled={auth.googleLoggedIn}
                          />

                          {touched.confirmPwd && errors.confirmPwd ? (
                            <p className="text-danger">{errors.confirmPwd}</p>
                          ) : null}
                        </Form.Group>
                      </Card.Body>
                      <Card.Footer>
                        <Button
                          type="submit"
                          block
                          size="lg"
                          disabled={auth.googleLoggedIn}
                        >
                          Save{" "}
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        transition={Flip}
        theme="colored"
        pauseOnFocusLoss={false}
      />
    </Layout>
  );
};

export default ChangePassword;
