import React from 'react'
import { DEFAULT_KEYWORD_CATEGORY, KEYWORD_CATEGORY_SELECTION } from '../../../../config/constants/data'
import MainSelection from '../../../Selections/MainSelection'

const KeywordCategorySelection = (props) => {
  const {category, changeCategory} = props;
  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <MainSelection
        title=""
        selectionItems={KEYWORD_CATEGORY_SELECTION}
        defaultItem={category===undefined ? DEFAULT_KEYWORD_CATEGORY : {label: category, value: category}}
        setItem={changeCategory}
        width={120}
        sorted={false}
      />
    </div>
  )
}

export default KeywordCategorySelection