import React from "react";
import EditCategoryTable from "../../../../../components/Tables/EditCategoryTable";
import {
  PROJECT_CATEGORY_EDIT_TABLE_HEADER,
  PROJECT_CATEGORY_EDIT_MAIN_TABLE_API,
} from "../../../../../config/constants/tableHeaders";

const Category = () => {
  return (
    <EditCategoryTable
      tableHeader={PROJECT_CATEGORY_EDIT_TABLE_HEADER}
      URL={PROJECT_CATEGORY_EDIT_MAIN_TABLE_API}
      withoutFilterModal
      model={"project"}
    />
  );
};

export default Category;
