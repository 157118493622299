import axios from "axios";

import {
  SET_USER_BOOKMARK_LOADING,
  SET_USER_BOOKMARK_SUCCESS,
  SET_USER_BOOKMARK_FAIL,
  GET_USER_BOOKMARK_LOADING,
  GET_USER_BOOKMARK_SUCCESS,
  GET_USER_BOOKMARK_FAIL,
  RELOAD_USER_BOOKMARK_SUCCESS,
  RELOAD_USER_BOOKMARK_START,
} from "../types";

import { API_HOST } from "../../config/constants/data";
import { attachTokenToHeaders } from "./authActions";
import { params2URL } from "../../utils/common";

export const setUserBookmarkValue = (params) => async (dispatch, getState) => {
  dispatch({
    type: SET_USER_BOOKMARK_LOADING,
    payload: { currentId: params?.main_id },
  });
  try {
    const paramURI = `${API_HOST}/api/v1/user/bookmark/`;
    const response = await axios.post(
      paramURI,
      params,
      attachTokenToHeaders(getState)
    );

    dispatch({
      type: SET_USER_BOOKMARK_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    console.log("setUserBookmarkValue error : ", err);
    dispatch({
      type: SET_USER_BOOKMARK_FAIL,
      payload: { error: err.response.data },
    });
  }
};

export const getUserBookmarkValue = (params) => async (dispatch, getState) => {
  dispatch({ type: GET_USER_BOOKMARK_LOADING });
  try {
    params["URL"] = "/api/v1/user/bookmark";
    const response = await axios.get(
      params2URL(params),
      attachTokenToHeaders(getState)
    );

    dispatch({
      type: GET_USER_BOOKMARK_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_USER_BOOKMARK_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};

export const reloadBookmarkValue = () => async (dispatch) => {
  dispatch({ type: RELOAD_USER_BOOKMARK_START });
  setTimeout(() => {
    dispatch({ type: RELOAD_USER_BOOKMARK_SUCCESS });
  }, 50);
};
