import {
  SET_USER_BOOKMARK_LOADING,
  SET_USER_BOOKMARK_SUCCESS,
  SET_USER_BOOKMARK_FAIL,
  GET_USER_BOOKMARK_LOADING,
  GET_USER_BOOKMARK_SUCCESS,
  GET_USER_BOOKMARK_FAIL,
  RELOAD_USER_BOOKMARK_SUCCESS,
  RELOAD_USER_BOOKMARK_START,
} from "../types";

const initialState = {
  data: [],
  isLoading: false,
  currentId: "",
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER_BOOKMARK_LOADING:
    case GET_USER_BOOKMARK_LOADING:
      return {
        ...state,
        isLoading: true,
        currentId: payload?.currentId,
        error: null,
      };
    case SET_USER_BOOKMARK_SUCCESS:
      return {
        ...state,
        data:
          payload.data.status === "deleted"
            ? Array.isArray(state.data)
              ? state.data.filter(
                  (product) => product.id !== payload?.data?.results?.id
                )
              : state.data
            : [...state.data, payload?.data?.results],
        currentId: "",
        isLoading: false,
        error: null,
      };
    case GET_USER_BOOKMARK_SUCCESS:
      return {
        ...state,
        data: payload.data.results,
        currentId: "",
        isLoading: false,
        error: null,
      };
    case SET_USER_BOOKMARK_FAIL:
    case GET_USER_BOOKMARK_FAIL:
      return {
        ...state,
        data: [],
        isLoading: false,
        currentId: "",
        error: payload?.error,
      };
    case RELOAD_USER_BOOKMARK_START:
      return {
        ...state,
        isLoading: true,
      };
    case RELOAD_USER_BOOKMARK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
