import React from "react";
import { useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";

import { Form, Row, Col } from "react-bootstrap";

import { deleteMainModel } from "../../../../../store/actions/mainModelActions";

const DeleteCategoryModal = (props) => {
  const { show, setShowModal, item } = props;
  const dispatch = useDispatch();
  const categoryId = item?.id;
  const onDeleteCategory = async () => {
    await dispatch(deleteMainModel(`project/project_category/${categoryId}`, categoryId));
  };

  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      size={"sm"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          style={{ fontSize: 18, textAlign: "center", width: "100%" }}
        >
          Delete Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          className="pt-3"
          onSubmit={() => {
            onDeleteCategory();
          }}
        >
          <h4>
            {" "}
            Really, Are you sure you want to permanently remove this Project,
            Category "{item?.project_name}, {item?.category}"?
          </h4>

          <Row className="mt-4">
            <Col>
              <div>
                <button
                  className="btn btn-block btn-danger btn-lg submit login-button"
                  type="submit"
                >
                  Delete
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteCategoryModal;
