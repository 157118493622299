import React from 'react'
import { Button, ButtonGroup, Form } from 'react-bootstrap';

const HeaderCheckDropdownModal = (props) => {
  const {header, setHeader} = props;
  // Checkbox Dropdown -- start
  const handleChecked = (key, event) => {
    header.find((i) => i.title === key).checked = event.target.checked;
    setHeader([...header]);
  };

  const handleSelectAll = () => {
    header.forEach((i) => (i.checked = true));
    setHeader([...header]);
  };

  const handleSelectNone = () => {
    header.forEach((i) => (i.checked = false));
    setHeader([...header]);
  };
  
  return (
    <div className='mx-1'>
      <Form className="form-lg">
        {
          header.map((i) => (
            <Form.Group className="d-flex flex-row align-items-center ml-4" controlId={i.title}>
              <Form.Check.Input 
                type="checkbox"
                size="lg"
                checked={i.checked}
                onChange={handleChecked.bind(handleChecked, i.title)}
                style={{marginBottom:3}}
              />
              <Form.Check.Label style={{fontSize:20, fontWeight:"bold", margin:"5px 10px"}}>{i.title}</Form.Check.Label>
            </Form.Group>
          ))                    
        }
      </Form>
      <div className="pt-2 pb-0">
        <ButtonGroup size="lg">
          <Button variant="primary m-2" style={{borderRadius:8}} onClick={handleSelectAll}>
            Select All
          </Button>
          <Button variant="primary m-2" style={{borderRadius:8}} onClick={handleSelectNone}>
            Select None
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default HeaderCheckDropdownModal