import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsUpload } from "react-icons/bs";
import { useParams } from "react-router-dom";
import IconButton from "../../../../../components/Shared/IconButton";
import {
  setSpecialVoteValue,
  getSpecialVoteValue,
} from "../../../../../store/actions/userVoteActions";
import SocialShareModal from "../../../../../components/SocialShareModal";
import { API_HOST, MODEL_IDS } from "../../../../../config/constants/data";
import { getVotingParams } from "../../../../../utils/tableDataHelper";
import DetailVoteComponent from "../../../../../components/Tables/Components/DetailVoteComponent";
import { Col, Row } from "react-bootstrap";
import BookMarkButton from "../../../../../components/Shared/BookMarkButton";

const ProjectTitle = ({ keyword }) => {
  // const [watched, setWatched] = useState(true)
  const [modalShow, setModalShow] = useState(false);

  const { nickName } = useParams();
  const vote = useSelector((state) => state.userVote) || {};
  const votingParams = getVotingParams("keyword");
  const modelIds = [keyword];
  const params = {
    site_id: votingParams.siteId,
    model_type_id: votingParams.modelId,
    model_ids: modelIds,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSpecialVoteValue(params));
    // eslint-disable-next-line
  }, [keyword]);
  const setVoteValue = async (v, modelId) => {
    if (votingParams.modelId === "") return;
    const params = {
      vote_score: v,
      site_id: votingParams.siteId,
      model_type_id: votingParams.modelId,
      model_id: modelId,
    };
    await dispatch(setSpecialVoteValue(params));
    const getParams = {
      site_id: votingParams.siteId,
      model_type_id: votingParams.modelId,
      model_ids: modelIds,
    };
    dispatch(getSpecialVoteValue(getParams));
  };
  return (
    <div>
      <Row className="d-flex flex-row justify-centent-between align-items-center m-0">
        <Col xs={12} md={12}>
          <Row>
            <h1 className="mr-2 font-weight-bold">
              {" "}
              <img
                src={`${API_HOST}/api/v1/project/icon/${keyword}`}
                style={{
                  borderRadius: "100%",
                  height: 36,
                  width: 36,
                  marginRight: 10,
                }}
                alt=""
              />
              {keyword}
            </h1>
          </Row>
        </Col>
        {/* <CustomBadge style={{ marginRight: 10 }} >{statisticsScore?.symbol}</CustomBadge> */}
        {/* <IconButton style={{ marginRight: 10 }} icon={watched ? <BsFillStarFill size={16} color={"#F6B87E"} /> : <BsStar size={16} color={"#858ca2"} />} /> */}
        <Col xs={12} md={12} className="px-0">
          <Row className="d-flex flex-row justify-content-between align-items-center flex-nowrap m-0">
            <div className="d-flex flex-row align-items-center">
              <BookMarkButton
                model_id={MODEL_IDS.PROJECT}
                main_id={keyword}
                className="ml-mobile-auto icon-btn"
                style={{ height: "40px", width: "40px" }}
                fetchable={true}
              />
              {/* <IconButton
                className="ml-mobile-auto"
                style={{ height: "40px", width: "40px", marginLeft: "10px" }}
                onClick={() => setModalShow(true)}
                icon={<BsUpload size={22} color={"white"} />}
                title={""}
              /> */}
            </div>
            {/* <DetailVoteComponent
              id={keyword}
              vote={vote}
              setVoteValue={setVoteValue}
            /> */}
          </Row>
        </Col>
        {/* <CustomBadge style={{ marginLeft: 10, marginRight: 10 }}>
          On{" "}
          {vote?.data?.[keyword]?.sum_score
            ? vote?.data?.[keyword]?.sum_score
            : "0"}{" "}
          vote{vote?.data?.[keyword]?.sum_score > 1 ? "s" : ""}
        </CustomBadge> */}
      </Row>
      {/* <div className='d-flex align-items-center mt-4'>
        <CustomBadge style={{ marginRight: 10 }} color={"#858CA2"} >Rank #{statisticsScore?.ranking}</CustomBadge>
      </div> */}
      <SocialShareModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        image={`${API_HOST}/api/v1/project/icon/${keyword}`}
        url={`https://metalyzer.ai/projects/${nickName}/`}
      />
    </div>
  );
};

export default ProjectTitle;

