import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import "../styles.css";

import { Row, Col } from "react-bootstrap";
import { selectionStyle } from "../styles";
import "../styles.css";
import { getTopicOptions } from "../../../utils/routingHelper";

const TopicSelection = (props) => {
  const topics = useSelector((state) => state.topics.topics) || [];
  const websiteTopics = useSelector((state) => state.websiteTopic.websiteTopics) || [];
  const { tableModelId = 1, withNoTopic, title, topic, setTopic } = props;
  let customStyle = selectionStyle();
  customStyle.menuList = (styles) => ({
    ...styles,
    backgroundColor: "#323546",
    minWidth: "150px",
    left: 0,
    textAlign: "left",
  });
  let options = getTopicOptions(topics, tableModelId, withNoTopic);
  if (tableModelId === 8 && websiteTopics.length > 0){
    options = options.filter((option) => {
      return websiteTopics.includes(option.value.toString());
    })
  }

  const defaultFilter = options.filter((option) => {
    return option.value === props?.default;
  });
  const defaultValue =
    defaultFilter.length > 0
      ? defaultFilter?.[0]
      : { label: "Topic", value: -1 };

  return (
    <>
      {title && (
        <Row>
          <Col>
            <label htmlFor="#" className="mb-0 text-success text-nowrap">
              {title}
            </label>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {options && options.length && (
            <Select
              value={topic ? topic : defaultValue}
              onChange={(topic) => setTopic(topic)}
              options={options}
              styles={customStyle}
              isSearchable={true}
              className="mx-1"
              classNamePrefix="topic-select"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default TopicSelection;
