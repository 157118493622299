import React from 'react'
import ProjectSelection from '../../../Selections/ProjectSelection'

const ProjectFilter = (props) => {
  const { keyword, setKeyword, paddingLeftZero=false } = props;
  return (
    <div className="d-flex flex-row justify-content-center align-items-center">
      <ProjectSelection
        title=""
        project={keyword ? { label: keyword.label, value: keyword.value } : null}
        setKeyword={setKeyword}
        paddingLeftZero={paddingLeftZero}
      />
    </div>
  )
}

export default ProjectFilter
