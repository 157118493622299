import axios from "axios";
import { API_HOST } from "../../config/constants/data";

import {
  SUBSCRIPTION_PAYMENT_LOADING,
  SUBSCRIPTION_PAYMENT_SUCCESS,
  SUBSCRIPTION_PAYMENT_FAIL,
} from "../types";
import { attachTokenToHeaders } from "./authActions";

export const getSubscriptionPayment =
  (formData, history) => async (dispatch, getState) => {
    dispatch({ type: SUBSCRIPTION_PAYMENT_LOADING });
    try {
      const response = await axios.post(
        `${API_HOST}/api/v1/user/payment/`,
        formData,
        attachTokenToHeaders(getState)
      );
      console.log("response success : ", response)
      dispatch({
        type: SUBSCRIPTION_PAYMENT_SUCCESS,
        payload: { data: response.data.results },
      });
      history.push('/')
    } catch (err) {
      console.log("error")

      dispatch({
        type: SUBSCRIPTION_PAYMENT_FAIL,
        payload: { error: err.response.data.message },
      });
      history.push('/plans')
    }
  };
