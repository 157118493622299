import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { WEBSITE_PARTNERS_FILTER_MODAL_FIELDS } from "../../../../config/constants/data";
import {
  WEBSITE_PARTNERS_MAIN_TABLE_API,
  PARTNERS_TABLE_HEADER,
  NEW_PARTNERS_MAIN_TABLE_API,
  NEW_PARTNERS_TABLE_HEADER,
} from "../../../../config/constants/tableHeaders";
import MainTable from "../../../../components/Tables/MainTable";
import { setTableType } from "../../../../store/actions/uiSettings";
import { RecommendItems } from "../..";

const Partners = (props) => {
  const { domain } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { order } = location.state || {};
  const tableTypes = [
    { label: "Subs", value: "partner" },
    { label: "First Promo", value: "new-partner" },
  ];

  const tableType =
    useSelector((state) => state.uiSettings.tableType) || tableTypes[0];

  const [tableHeader, setTableHeader] = useState(PARTNERS_TABLE_HEADER);
  const [tableAPI, setTableAPI] = useState(WEBSITE_PARTNERS_MAIN_TABLE_API);

  useEffect(() => {
    if (order === "firstPromote") {
      dispatch(setTableType({ label: "First Promo", value: "new-partner" }));
    } else if (order === "subs") {
      dispatch(setTableType({ label: "Subs", value: "partner" }));
    }
    // This will set the route once when the component mounts
    props.setSelectedRoute("partners");
  }, [order, dispatch, props]);

  const updateTableType = useCallback(
    (newType) => {
      dispatch(setTableType(newType));
      switch (newType.value) {
        case "partner":
          setTableHeader(PARTNERS_TABLE_HEADER);
          setTableAPI(WEBSITE_PARTNERS_MAIN_TABLE_API);
          break;
        case "new-partner":
          setTableHeader(NEW_PARTNERS_TABLE_HEADER);
          setTableAPI(NEW_PARTNERS_MAIN_TABLE_API);
          break;
        default:
          setTableHeader(PARTNERS_TABLE_HEADER);
          setTableAPI(WEBSITE_PARTNERS_MAIN_TABLE_API);
          break;
      }
    },
    [dispatch]
  );

  useEffect(() => {
    // Update table type based on the selected type from redux store
    updateTableType(tableType);
  }, [tableType, updateTableType]);

  let filters = window.advancedFilters || {};
  WEBSITE_PARTNERS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });
  if (domain !== "" && domain !== undefined) {
    filters["website"] = domain;
  }

  return (
    <div>
      <MainTable
        tableHeader={tableHeader}
        URL={tableAPI}
        withTermsFilter
        withoutProjectFilter
        withoutTopicFilter
        withTableTypeSelection
        tableTypes={tableTypes}
        tableType={tableType}
        updateTableType={updateTableType}
        filters={filters}
        filterModalFields={WEBSITE_PARTNERS_FILTER_MODAL_FIELDS}
        defaultTerm={"all"}
        model={"partners"}
        modelName="website-partner--domain"
      />
      <RecommendItems />
    </div>
  );
};

export default Partners;

