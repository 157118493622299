import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import GroupButton from "../../../../components/GroupButton";
import { YOUTUBE_CHANNELS_DETAILS_SUB_ROUTING_GROUP_BUTTON } from "../../../../config/constants/data";
import "../../../../assets/css/styles.css";

import Overview from "../Overview";
import Websites from "../Websites";
import { useParams } from "react-router-dom";
import Videos from "../Videos";
import WebsiteNews from "../WebsiteNews";
import WebsiteSocial from "../WebsiteSocial";
import WebsitesTopic from "../WebsitesTopic";
import Projects from "../Projects";

const YoutubeChannelDetailsRouting = (props) => {
  const { detailsData } = props;
  const { channelId } = useParams();
  const [selectedRoute, setSelectedRoute] = useState("overview");
  let history = useHistory();
  const navTo = (val, new_channelId = channelId) => {
    if (val === "overview") {
      history.push(`/youtube/channel/${new_channelId}`);
    } else {
      history.push(`/youtube/channel/${new_channelId}/${val}`);
    }
    setSelectedRoute(val);
  };

  // useEffect(() => {
  //   const finalPath = location.pathname.split("/")[4];
  //   navTo(finalPath)
  // }, [])
  const location = useLocation();
  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const route = pathParts[4] || "overview";
    setSelectedRoute(route);
  }, [location]);

  return (
    <div>
      <div
        className="container-fluid border-top border-bottom py-3 mb-3"
        style={{ paddingLeft: 0 }}
      >
        <div className="d-flex flex-row">
          <GroupButton
            items={YOUTUBE_CHANNELS_DETAILS_SUB_ROUTING_GROUP_BUTTON}
            selected={selectedRoute}
            onClick={navTo}
            groupClass="d-flex flex-row align-items-center x-scroll-wrapper"
            buttonClass="project-tab-btn mr-2"
          />
        </div>
      </div>
      <Switch>
        <Route
          exact
          key={0}
          path="/youtube/channel/:channelId"
          component={() => <Overview detailsData={detailsData} />}
        />
        <Route
          key={1}
          path="/youtube/channel/:channelId/videos"
          component={() => <Videos setSelectedRoute={setSelectedRoute} />}
        />
        <Route
          key={2}
          path="/youtube/channel/:channelId/projects"
          component={() => <Projects setSelectedRoute={setSelectedRoute} />}
        />
        <Route
          exact
          key={3}
          path="/youtube/channel/:channelId/websites"
          component={() => (
            <WebsitesTopic setSelectedRoute={setSelectedRoute} />
          )}
        />
        <Route
          exact
          key={4}
          path="/youtube/channel/:channelId/websites/:topic?"
          component={() => <Websites setSelectedRoute={setSelectedRoute} />}
        />
        <Route
          key={5}
          path="/youtube/channel/:channelId/news"
          component={() => <WebsiteNews setSelectedRoute={setSelectedRoute} />}
        />
        <Route
          key={6}
          path="/youtube/channel/:channelId/social/:domain?"
          component={() => (
            <WebsiteSocial setSelectedRoute={setSelectedRoute} />
          )}
        />
      </Switch>
    </div>
  );
};

export default YoutubeChannelDetailsRouting;

