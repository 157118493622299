import { SET_SOCIAL_DOMAINS } from "../types";

const initialState = {
  socialDomains: [],
}

const socialDomainReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SOCIAL_DOMAINS:
      return { ...state, socialDomains: payload };
    default:
      return state;
  }
}

export default socialDomainReducer;