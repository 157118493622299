import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import CategoryFilter from "../../../../components/Tables/Components/CategoryFilter";
import { DASHBOARD_ROUTING_LIST } from "../../../../config/constants/data";
import DashboardMainView from "./Components/DashboardMainView";
import "./styles.css";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import FullModal from "../../../../components/FullModal";

import { setSearchText } from "../../../../store/actions/uiSettings";

const DashboardRouting = () => {
  const { category } = useParams();
  const location = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();
  // const [currentTopic, setCurrentTopic] = useState({
  //   label: "Topic",
  //   value: -1,
  // });
  // const initTopic = () => {
  //   setCurrentTopic({ label: "Topic", value: -1 });
  // };
  let firstModel = "trending";
  if (category === "all") {
    firstModel = location.pathname.split("/")[3];
  }
  const [selectedRoute, setSelectedRoute] = useState(firstModel);
  const updateCategory = (newCategory) => {
    if (newCategory === "category" && selectedRoute === "trending")
      history.push(`/`);
    else navTo(selectedRoute, newCategory);
  };
  const navTo = (model, newCategory = category) => {
    // const searchParams = new URLSearchParams(location.search).toString();
    // init topic when main site change.
    // initTopic();
    dispatch(setSearchText(""));
    setSelectedRoute(model);
    if (
      (newCategory === undefined ||
        newCategory === "category" ||
        newCategory === "all") &&
      model === "trending"
    ) {
      history.push(`/`);
    } else if (model === "market") {
      history.push(`/market`);
    } else if (
      newCategory === undefined ||
      newCategory === "category" ||
      newCategory === "all"
    ) {
      history.push(`/view/all/${model}`);
    } else if (model === "trending") {
      history.push(`/view/${newCategory}`);
    } else {
      history.push(`/view/${newCategory}/${model}`);
    }
  };
  const isMobile = useMediaQuery();
  const [isShowModal, setIsShowModal] = useState(false);

  const CategorySelector = () => (
    <div className="d-flex flex-row">
      {isMobile ? (
        <a
          style={{ marginLeft: 3 }}
          href="#/"
          className="btn btn-default table-header-filter d-flex align-items-center"
          onClick={() => setIsShowModal(true)}
        >
          <div className="pl-1">
            {category === "" || category === "all" || category === undefined
              ? "Category"
              : category}
          </div>
        </a>
      ) : (
        // <Button style={{ borderRadius: 8 }} onClick={() => setIsShowModal(true)}>
        //   {(category==="" || category==="all" || category===undefined) ? "Category": category}
        // </Button>
        <div style={{ marginLeft: 7 }}>
          <CategoryFilter
            defaultCategory={category}
            setCategory={updateCategory}
          />
        </div>
      )}
      {/* <div className="project_tabs_divide" />
      <GroupButton
        items={DASHBOARD_TABLE_GROUP_BUTTON}
        selected={selectedRoute}
        onClick={navTo}
        groupClass="d-flex flex-row align-items-center x-scroll-wrapper"
        buttonClass="project-tab-btn mr-2"
      /> */}
    </div>
  );

  return (
    <div>
      <Switch>
        <Route
          exact
          path="/"
          key={0}
          component={() => (
            <DashboardMainView
              categorySelector={<CategorySelector />}
              setSelectedRoute={setSelectedRoute}
              mainRoute={"trending"}
              modelName="trending"
            />
          )}
        />
        {/* <Route
            exact
            path="/market"
            key={15}
            component={() => (
              <DashboardMainView
                categorySelector={<CategorySelector />}
                setSelectedRoute={setSelectedRoute}
                mainRoute={"market"}
                modelName="market"
              />
            )}
          /> */}
        <Route
          exact
          path="/view/:category"
          key={1}
          component={() => (
            <DashboardMainView
              categorySelector={<CategorySelector />}
              setSelectedRoute={setSelectedRoute}
              mainRoute={"trending"}
              modelName="project--category"
            />
          )}
        />
        {DASHBOARD_ROUTING_LIST.map((routing, i) => {
          return (
            <Route
              path={
                "/view/:category/" +
                routing.sub +
                ("param" in routing ? `/:${routing.param}?` : "")
              }
              key={i + 2}
              component={() => (
                <DashboardMainView
                  categorySelector={<CategorySelector />}
                  setSelectedRoute={setSelectedRoute}
                  mainRoute={routing.main}
                  modelName={routing.modelName}
                />
              )}
            />
          );
        })}
      </Switch>
      <FullModal
        isShowModal={isShowModal}
        setShowModal={setIsShowModal}
        title={"Select Category"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="mr-4">Category :</div>
          <CategoryFilter
            defaultCategory={category}
            setCategory={updateCategory}
          />
        </div>
      </FullModal>
    </div>
  );
};

export default DashboardRouting;

