import axios from "axios";

import {
  SET_USER_VOTE_VALUE_LOADING,
  SET_USER_VOTE_VALUE_SUCCESS,
  SET_USER_VOTE_VALUE_FAIL,
  GET_USER_VOTE_VALUE_LOADING,
  GET_USER_VOTE_VALUE_SUCCESS,
  GET_USER_VOTE_VALUE_FAIL,
  SET_SPECIAL_VOTE_VALUE_LOADING,
  SET_SPECIAL_VOTE_VALUE_SUCCESS,
  SET_SPECIAL_VOTE_VALUE_FAIL,
  GET_SPECIAL_VOTE_VALUE_FAIL,
  GET_SPECIAL_VOTE_VALUE_SUCCESS,
  GET_SPECIAL_VOTE_VALUE_LOADING,
} from "../types";

import { API_HOST } from "../../config/constants/data";
import { attachTokenToHeaders, getuuId } from "./authActions";

export const setUserVoteValue = (params) => async (dispatch, getState) => {
  dispatch({ type: SET_USER_VOTE_VALUE_LOADING });
  const uuid = await getuuId();
  try {
    const paramURI = `${API_HOST}/api/v1/user/voting`;
    params["user_id"] = uuid;
    await axios.post(paramURI, params, attachTokenToHeaders(getState));

    dispatch({
      type: SET_USER_VOTE_VALUE_SUCCESS,
      // payload: { data: response.data.results, count: response.data.count },
    });
  } catch (err) {
    dispatch({
      type: SET_USER_VOTE_VALUE_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};

export const getUserVoteValue = (params) => async (dispatch, getState) => {
  dispatch({ type: GET_USER_VOTE_VALUE_LOADING });
  const uuid = await getuuId();
  try {
    const paramURI = `${API_HOST}/api/v1/user/votings`;
    params["user_id"] = uuid;

    const response = await axios.post(
      paramURI,
      params,
      attachTokenToHeaders(getState)
    );

    dispatch({
      type: GET_USER_VOTE_VALUE_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_USER_VOTE_VALUE_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};
export const setSpecialVoteValue = (params) => async (dispatch, getState) => {
  dispatch({ type: SET_SPECIAL_VOTE_VALUE_LOADING });
  const uuid = await getuuId();
  try {
    const paramURI = `${API_HOST}/api/v1/user/voting`;
    params["user_id"] = uuid;
    await axios.post(paramURI, params, attachTokenToHeaders(getState));

    dispatch({
      type: SET_SPECIAL_VOTE_VALUE_SUCCESS,
      // payload: { data: response.data.results, count: response.data.count },
    });
  } catch (err) {
    dispatch({
      type: SET_SPECIAL_VOTE_VALUE_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};

export const getSpecialVoteValue = (params) => async (dispatch, getState) => {
  if (
    !(
      params?.model_ids &&
      Array.isArray(params.model_ids) &&
      !params.model_ids.every((value) => value === null || value === undefined)
    )
  ) {
    return false;
  }
  dispatch({ type: GET_SPECIAL_VOTE_VALUE_LOADING });
  const uuid = await getuuId();
  try {
    const paramURI = `${API_HOST}/api/v1/user/votings`;
    params["user_id"] = uuid;

    const response = await axios.post(
      paramURI,
      params,
      attachTokenToHeaders(getState)
    );

    dispatch({
      type: GET_SPECIAL_VOTE_VALUE_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    dispatch({
      type: GET_SPECIAL_VOTE_VALUE_FAIL,
      payload: { error: err.response.data.message },
    });
  }
};
