import React from 'react'
import { sortString } from '../../../../../../utils/common';
// import { Img } from 'react-image';
import '../../styles.css'

const ExternalLink = (props) => {
  const { path, text, image, alignment, width=500 } = props
  const className = alignment? `d-flex justify-content-${alignment} align-items-center link-item-${width}`:`d-flex justify-content-start align-items-center link-item-${width}`;
  return (
    <div className={className}>
      {image &&
        <img
          src={image}
          alt=""
          width={30}
          height={30}
          loading="lazy"
          className="mr-3"
          // onError={(error) => { error.target.src = require('../../../../../../assets/icons/discord-logo.ico') }}
        />
      }
      <a
        data-tip={text?.substring(0, 500)}
        href={path}
        rel="noopener noreferrer"
        target="_blank"
      >
        {sortString(text)}
      </a>
    </div>
  )
}

export default ExternalLink