import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import ProjectOverview from "../ProjectOverview";
import GroupButton from "../../../../components/GroupButton";
import { PROJECT_DETAILS_SUB_ROUTING_GROUP_BUTTON } from "../../../../config/constants/data";
import YoutubeVideos from "../YoutubeVideos";
import Websites from "../Websites";
import YoutubeChannels from "../YoutubeChannels";
import LinkDetails from "../LinksDetails";
import DiscordServers from "../DiscordServers";
import DiscordChannels from "../DiscordChannels";
import { useSelector } from "react-redux";
import ProjectFilter from "../../../../components/Tables/Components/ProjectFilter";
import { convertNick2Name } from "../../../../utils/routingHelper";
import "../../../../assets/css/styles.css";

import useMediaQuery from "../../../../hooks/useMediaQuery";
import FullModal from "../../../../components/FullModal";
import TelegramGroups from "../TelegramGroups";
import WebsiteNews from "../WebsiteNews";
import WebsiteSocial from "../WebsiteSocial";
import "./styles.css";

const ProjectDetailsRouting = (props) => {
  // Is Mobile
  const isMobile = useMediaQuery();
  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const { nickName } = props;
  const [selectedRoute, setSelectedRoute] = useState("overview");
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];
  const projectNamesLoading = useSelector(
    (state) => state.projectNames.projectNamesLoading
  );
  const [projectName, setProjectName] = useState(
    convertNick2Name(projectNames, nickName)
  );
  let history = useHistory();

  const udpateKeyword = (keywordObject) => {
    if (keywordObject.value === "all") {
      history.push(`/`);
    } else navTo(selectedRoute, keywordObject.value);
  };
  const navTo = (val, n_nickName = nickName) => {
    if (val === "overview") {
      history.push(`/project/${n_nickName}`);
    } else {
      history.push(`/project/${n_nickName}/${val}`);
    }
    setSelectedRoute(val);
  };
  useEffect(() => {
    if (!projectNamesLoading && projectNames.length > 0) {
      // Make sure this runs only when not loading
      const convertedName = convertNick2Name(projectNames, nickName);
      setProjectName(convertedName);
      if (convertedName === "notfound") {
        history.push("/notfound");
      }
    }
    // eslint-disable-next-line
  }, [projectNames, projectNamesLoading]);
  return (
    <div className="project-center-sticky-con">
      <div
        className="container-fluid border-top border-bottom py-3 mb-3 project-detail-sticky-header"
        style={{ paddingLeft: isMobile ? 0 : 8 }}
      >
        <div className="d-flex flex-row">
          {isMobile ? (
            <a
              href="#/"
              className="btn btn-default table-header-filter d-flex align-items-center ml-0"
              onClick={() => setShowKeywordModal(true)}
            >
              <div className="pl-1">{projectName}</div>
            </a>
          ) : (
            <ProjectFilter
              keyword={{
                label: convertNick2Name(projectNames, nickName),
                value: nickName,
              }}
              setKeyword={udpateKeyword}
              paddingLeftZero={true}
            />
          )}
          <div className="project_tabs_divide" />
          <GroupButton
            items={PROJECT_DETAILS_SUB_ROUTING_GROUP_BUTTON}
            selected={selectedRoute}
            onClick={navTo}
            groupClass="d-flex flex-row align-items-center x-scroll-wrapper"
            buttonClass="project-tab-btn mr-2"
          />
        </div>
      </div>
      <div className="project-center-sticky-body hide-scrollbar">
        <Switch>
          <Route
            exact
            key={0}
            path="/project/:nickName"
            component={() => <ProjectOverview nickName={nickName} />}
          />
          <Route
            key={1}
            path="/project/:nickName/videos"
            component={() => (
              <YoutubeVideos setSelectedRoute={setSelectedRoute} />
            )}
          />
          <Route
            key={2}
            path="/project/:nickName/channels"
            component={() => (
              <YoutubeChannels setSelectedRoute={setSelectedRoute} />
            )}
          />
          <Route
            key={3}
            path="/project/:nickName/groups"
            component={() => (
              <TelegramGroups setSelectedRoute={setSelectedRoute} />
            )}
          />
          {/* <Route path="/projects/:nickName/tweets" component={() => <TwitterTweets setSelectedRoute={setSelectedRoute} />} />
            <Route path="/projects/:nickName/users" component={() => <TwitterUsers setSelectedRoute={setSelectedRoute} />} /> */}
          <Route
            key={4}
            path="/project/:nickName/servers"
            component={() => (
              <DiscordServers setSelectedRoute={setSelectedRoute} />
            )}
          />
          <Route
            key={5}
            path="/project/:nickName/d-channels"
            component={() => <DiscordChannels />}
          />
          {/* <Route path="/projects/:nickName/news" component={() => <News setSelectedRoute={setSelectedRoute} />} /> */}
          <Route
            key={6}
            path="/project/:nickName/websites/:topic?"
            component={() => <Websites setSelectedRoute={setSelectedRoute} />}
          />
          <Route
            key={7}
            path="/project/:nickName/news"
            component={() => (
              <WebsiteNews setSelectedRoute={setSelectedRoute} />
            )}
          />
          <Route
            key={8}
            path="/project/:nickName/social/:domain?"
            component={() => (
              <WebsiteSocial setSelectedRoute={setSelectedRoute} />
            )}
          />
          <Route
            key={9}
            path="/project/:nickName/details"
            component={() => <LinkDetails />}
          />
        </Switch>
      </div>
      <FullModal
        isShowModal={showKeywordModal}
        setShowModal={setShowKeywordModal}
        title={"Select Keyword"}
        showHeader={true}
      >
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div>Keyword : </div>
          <ProjectFilter
            keyword={{ label: projectName, value: nickName }}
            setKeyword={udpateKeyword}
          />
        </div>
      </FullModal>
    </div>
  );
};

export default ProjectDetailsRouting;

