import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast, Flip } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Breadcrumb from "../../../components/Breadcrumb";
import PageTitle from "../../../components/PageTitle";

import { useParams } from "react-router-dom";

import "./EditBlogDetails.scss";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Import the necessary Quill modules for media embedding
import Quill from "quill";
import ImageResize from "quill-image-resize-module-react";
import { BLOGS_MAIN_TABLE_API } from "../../../config/constants/tableHeaders";
import { Icon } from "@iconify/react";
import { Col, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { editBlogSchema } from "./validation";
import {
  createMainModel,
  fetchMainModel,
  putMainModel,
} from "../../../store/actions/mainModelActions";
import Layout from "../../../layout/Layout";

Quill.register("modules/imageResize", ImageResize);

const EditBlogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, isLoading } = useSelector((state) => state.mainModel);
  const callApi = (blogId) => {
    dispatch(
      fetchMainModel({
        URL: BLOGS_MAIN_TABLE_API + `/${blogId}/`,
      })
    );
  };

  useEffect(() => {
    if (id === "new") {
      setEditorHtml("");
      formik.setValues({
        title: "",
        user_name: "",
        contents: "",
      });
    } else {
      callApi(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const formik = useFormik({
    initialValues: {
      title: data?.title,
      user_name: data?.user_name,
      contents: data?.contents,
    },
    validationSchema: editBlogSchema,
    onSubmit: async (values) => {
      values.contents = editorHtml;
      if (id === "new") {
        await dispatch(createMainModel("manager/blog/", values));
        toast.success("Save Successfully.");
        history.push("/edit/blog");
      } else {
        await dispatch(putMainModel(`manager/blog/${data?.id}`, values));
        toast.success("Save Successfully.");
        history.push("/edit/blog");
      }
    },
  });

  useEffect(() => {
    if (!isLoading) {
      setEditorHtml(data?.contents);
      formik.setValues({
        title: data?.title,
        user_name: data?.user_name,
        contents: data?.contents,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const [editorHtml, setEditorHtml] = useState(data?.contents);

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      [{ direction: [] }],
      ["link", "image", "video"],

      ["clean"],
    ],
    imageResize: {
      modules: ["Resize", "DisplaySize", "Toolbar"],
    },
  };

  return (
    <Layout>
      {/* <Helmet>
        <title>Blog | metalyzer </title>
        <meta name="description" content={"Blog."} />
        <meta
          name="keywords"
          content={
            "blogs,Project,metalyzer,Crypto,Social,Analytics,Metrics,Research"
          }
        />
      </Helmet> */}
      <Breadcrumb
        parentLink="/edit/blog/"
        parentLabel="Edit Blog"
        currentLabel={data?.title}
      />
      <PageTitle
        title="Blog Page."
        description=" metalyzer shows all blogs by our service."
      />
      <div>
        {/* <button
          className="btn btn-default mb-2 mr-2"
          style={{ fontSize: 16, borderRadius: 4}}
          onClick={handleSave}
        >
          <div className="d-flex flex-row">
            <Icon
              icon="icon-park-outline:return"
              height="2rem"
              color="#C0D7DB"
              style={{ marginRight: 6 }}
            />
            <div style={{ fontSize: 16, color: "#C0D7DB" }}>Return</div>
          </div>
        </button> */}
        <Form className="pt-3" onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg="5" xs="12">
              <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
                <div className="mr-3">Title</div>
                <Form.Control
                  type="text"
                  size="lg"
                  placeholder="Enter Title ..."
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title ? (
                  <p className="text-danger">{formik.errors.title}</p>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg="5" xs="12">
              <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
                <div className="mr-3">User Name</div>
                <Form.Control
                  type="text"
                  size="lg"
                  placeholder="Enter User Name ..."
                  name="user_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.user_name}
                />
                {formik.touched.user_name && formik.errors.user_name ? (
                  <p className="text-danger">{formik.errors.user_name}</p>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg="2" xs="12">
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  disabled={!formik.isValid}
                  type="submit"
                >
                  <Icon
                    icon="ion:save-outline"
                    height="2rem"
                    color="#fff"
                    style={{ marginRight: 6 }}
                  />
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <ReactQuill
        theme="snow"
        value={editorHtml}
        onChange={handleChange}
        modules={modules} // Pass the modules configuration
      />
      <ToastContainer theme="dark" transition={Flip} />
    </Layout>
  );
};

export default EditBlogDetails;

