import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Container, Form } from "react-bootstrap";
import { subscribeSchema } from "./validation";
import { setSubscribeEmail } from "../../store/actions/subscribeActions";
import Layout from "../../layout/Layout";

function Newsletter() {
  const dispatch = useDispatch();
  const subscribe = useSelector((state) => state.subscribe);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: subscribeSchema,
    onSubmit: (values) => {
      dispatch(setSubscribeEmail(values));
    },
  });
  return (
    <Layout>
      {/* <Helmet>
        <title>Newsletter | metalyzer </title>
        <meta name="description" content={"Newsletter."} />
        <meta
          name="keywords"
          content={
            "newsletter,Project,metalyzer,Crypto,Social,Analytics,Metrics,Research"
          }
        />
      </Helmet> */}
      <div className="d-flex flex-row justify-content-center align-items-center">
        <section className="footer-subscription">
          <Container className="content-wrapper">
            {subscribe.isSubscribed ? (
              <div className="row align-items-center">
                <div className="col-lg-7 col-sm-12 mt-5">
                  <h2 className="">Awesome possum!</h2>
                  <p className="">
                    Look out for the latest newsletter in your inbox.
                  </p>
                </div>
                <div className="col-lg-5 col-sm-12 mt-5"></div>
              </div>
            ) : (
              <div className="d-flex flex-col justify-content-center align-items-center">
                <div className="col-lg-6 col-sm-12 mt-5">
                  <h2 className="">join the metalyzer newsletter!</h2>
                  <p className="">
                    Seek ye, valiant wanderer, whether thy path be the noble
                    main quest or a humble side venture. We, thy humble guides,
                    shall aid thee through the maze of distractions. Pledge thy
                    loyalty to our newsletter and unlock glorious achievements.
                  </p>
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group
                      className="mb-1 mt-5"
                      controlId="subscriber-form-group"
                    >
                      <Form.Label className="subscribe-input-label">
                        Subscribe now
                      </Form.Label>
                      <Form.Control
                        type="text"
                        size="lg"
                        placeholder="Enter your e-mail address"
                        name="email"
                        className="subscribe-input"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <p className="text-danger" style={{ fontSize: 12 }}>
                          {formik.errors.email}
                        </p>
                      ) : null}
                    </Form.Group>
                    <button
                      className="btn btn-primary btn-lg w-100 rounded rounded-lg mt-5"
                      disabled={subscribe.isSubscribed || !formik.isValid}
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </Form>
                </div>
                {/* <div className="col-lg-5 col-sm-12 mt-5"></div> */}
              </div>
            )}
          </Container>
        </section>
      </div>
    </Layout>
  );
}

export default Newsletter;

