import { combineReducers } from 'redux';

import authReducer from './authReducer';
import userVoteReducer from './userVoteReducer';

import projectDetailsReducer from './projectDetailsReducer';
import getGeneralApiReducer from './getGeneralApiReducer';
import getChartApiReducer from './getChartApiReducer';
import fetchProjectNamesReducer from './fetchProjectNamesReducer';
import getEveryMinuteApiReducer from './getEveryMinuteApiReducer';
import fetchPrefiltersReducer from './fetchPrefiltersReducer';
import fetchTopicsReducer from './fetchTopicsReducer';
import setTopicReducer from './setTopicReducer';
import getGlobalStatisticsApiReducer from './getGlobalStatisticsApiReducer';
import getTwitterTopUsersApiReducer from './getTwitterTopUsersApiReducer';
import getYoutubeTopChannelsApiReducer from './getYoutubeTopChannelsApiReducer';

import { all, fork } from 'redux-saga/effects';
import getSubscriptionPaymentReducer from './getSubscriptionPaymentReducer';
import uiSettingsReducer from './uiSettings';
import fetchMainModelSaga from '../sagas/fetchMainModelSaga';
import mainModelReducer from './mainModelReducer';
import socialDomainReducer from './socialDomainReducer';
import fetchSearchItemsReducer from './fetchSearchItemsReducer';
import subscribeReducer from './subscribeReducer';
import userBookmarkReducer from './userBookmarkReducer';
import getHighLightApiReducer from './getHighLightApiReducer';
import getYoutubeMostVideosApiReducer from './getYoutubeMostVideosApiReducer';
import getYoutubeRecentVideosApiReducer from './getYoutubeRecentVideosApiReducer';
import websiteTopicReducer from './websiteTopicReducer';



export function* rootSaga() {
  yield all([
    fork(fetchMainModelSaga),
  ])
}

export default combineReducers({
  // TODO updated Reducers.
  auth: authReducer,
  subscribe: subscribeReducer,
  uiSettings: uiSettingsReducer,
  
  projectNames: fetchProjectNamesReducer,
  prefilters: fetchPrefiltersReducer,
  topics: fetchTopicsReducer,

  searchItems: fetchSearchItemsReducer,

  mainModel: mainModelReducer,
  socialDomain: socialDomainReducer,
  websiteTopic: websiteTopicReducer,
  userVote: userVoteReducer,
  userBookmark: userBookmarkReducer,

  projectDetails: projectDetailsReducer,
  everyMinuteState: getEveryMinuteApiReducer,
  highlight: getHighLightApiReducer,

  // Update new Reducers.
  generalApiState: getGeneralApiReducer,
  chartApiState: getChartApiReducer,
  globalStatistics: getGlobalStatisticsApiReducer,
  subscriptionPayment: getSubscriptionPaymentReducer,
  topUsers: getTwitterTopUsersApiReducer,
  topChannels: getYoutubeTopChannelsApiReducer,

  // New project details page.
  mostVideos: getYoutubeMostVideosApiReducer,
  recentVideos: getYoutubeRecentVideosApiReducer,
  
  setTopic: setTopicReducer,
});
