import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

import Layout from '../../layout/Layout';

// import PageTitle from '../../components/PageTitle';
import DashboardRouting from './Components/DashboardRouting';


import "./styles.css";

const Dashboard = () => {

  return (
    <Layout>
      {/* <div className='d-flex row'>
        <PageTitle
          title='Dashboard'
          description='We are a pioneering AI-powered tech startup dedicated to simplifying your online experience. Our mission is to streamline your digital journey and save you precious time by cutting through the noise of the web.'
          style={{ flexGrow: 1 }}
        />
      </div> */}
      <DashboardRouting />
    </Layout>
  );
};

export default Dashboard
