/*global tc*/
import React, { useEffect } from "react";
import Layout from "../../layout/Layout";

import useScript from "../../hooks/useScript";
import SubscriptionCard from "./components/SubscriptionCard";
import { subscriptionProductList } from "../../config/constants/data";

const Service = () => {
  const scriptLoaded = useScript(
    "https://sandbox-cdn.transaction.cloud/latest/widget.sandbox.min.js"
  );

  const handleBuyClick = (productId) => {
    tc.buy(productId, {}).then(
      () => {},
      (error) => {
        alert(error);
      }
    );
  };

  useEffect(() => {
    if (scriptLoaded) {
      console.log("loaded tc.");
    }
  }, [scriptLoaded]);

  return (
    <Layout>
      {/* <Helmet>
        <title>Service | metalyzer </title>
        <meta
          name="description"
          content="
                metalyzer
                https://metalyzer.ai › service
                We offer a variety of agency related services in order to help you improve management, reduce cost, optimize time, and track your competition.
                "
        />
        <meta
          name="keywords"
          content="metalyzer,Contactus,Twitter,Youtube,Discord,Social,Analytics,Metrics,Research,Link,Details"
        />
      </Helmet> */}
      <div className="h-100">
        <div className="d-flex align-items-center auth px-0 h-100">
          <div className="row w-100 mx-0">
            {subscriptionProductList.map((product, index) => (
              <div
                className={
                  index % 2 === 0
                    ? "col-lg-4 col-md-6 col-12 ml-auto"
                    : "col-lg-4 col-md-6 col-12 mr-auto"
                }
                key={index}
              >
                <SubscriptionCard
                  product={product}
                  onBuyClick={handleBuyClick}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Service;

