export const socialMenu = [
  {
    title: "Youtube",
    link: "/youtube/",
    icon: "ri:youtube-line",
    color: "red",
    children: [
      {
        title: "Projects",
        link: "/youtube/",
        icon: "codicon:project",
        color: "#16C583",
      },
      {
        title: "Videos",
        link: "/youtube-videos/",
        icon: "ri:youtube-line",
        color: "red",
      },
      {
        title: "Channels",
        link: "/youtube-channels/",
        icon: "fluent:channel-16-regular",
        color: "#893FFB",
      },
    ],
  },
  // {
  //   title: "Telegram",
  //   link: "/telegram-groups/",
  //   icon: "logos:telegram",
  //   color: "",
  //   children: [
  //     // {
  //     //   title: "Projects",
  //     //   link: "/telegram/",
  //     //   icon: "codicon:project",
  //     //   color: "#16C583",
  //     // },
  //     {
  //       title: "Groups",
  //       link: "/telegram-groups/",
  //       icon: "clarity:group-line",
  //       color: "#893FFB",
  //     },
  //   ],
  // },
  // {
  //   title: "Discord",
  //   link: "/discord-servers/",
  //   icon: "mingcute:discord-line",
  //   color: "#5562EA",
  //   children: [
  //     // {
  //     //   title: "Projects",
  //     //   link: "/discord/",
  //     //   icon: "codicon:project",
  //     //   color: "#16C583",
  //     // },
  //     {
  //       title: "Servers",
  //       link: "/discord-servers/",
  //       icon: "mingcute:discord-line",
  //       color: "#5562EA",
  //     },
  //     {
  //       title: "Channels",
  //       link: "/discord-channels/",
  //       icon: "fluent:channel-16-regular",
  //       color: "#893FFB",
  //     },
  //   ],
  // },
  {
    title: "Website",
    link: "/websites/",
    icon: "gridicons:domains",
    color: "#893FFB",
    children: [
      {
        title: "Websites",
        link: "/websites/",
        icon: "gridicons:domains",
        color: "#3861FB",
      },
      // {
      //   title: "News",
      //   link: "/news/",
      //   icon: "ooui:logo-wikinews",
      //   color: "#6E29E5",
      // },
      // {
      //   title: "Social",
      //   link: "/social/",
      //   icon: "ion:share-social-outline",
      //   color: "#259A72",
      // },
    ],
  },
];

export const etcMenu = [
  // {
  //   title: "Blog",
  //   link: "/blogs",
  // },
  {
    title: "Pricing",
    link: "/plans",
  },
  {
    title: "Contact",
    link: "/contact",
  },
  {
    title: "Watchlist",
    link: "/watchlist",
  },
];

export const leftMenu = [
  {
    title: "Dashboard",
    link: "/",
    icon: "material-symbols:dashboard",
    color: "#16C784",
    pathActive: "",
    isAuthenticated: true,
  },
  {
    title: "Project",
    link: "/view/all",
    icon: "eos-icons:project-outlined",
    color: "#0df",
    pathActive: (path) => !!path.match(/\/view\/[^/]+$/i),
    isAuthenticated: true,
  },
  {
    title: "Youtube",
    link: "/youtube-channels",
    icon: "mdi:youtube",
    color: "#f00",
    pathActive: "/youtube-",
    isAuthenticated: true,
  },
  {
    title: "Website",
    link: "/view/all/websites",
    icon: "gg:website",
    color: "#C78416",
    pathActive: (path) => !!path.match(/\/view\/[^/]+\/websites/i),
    isAuthenticated: true,
  },
  {
    title: "Edit User",
    link: "/users",
    icon: "clarity:users-line",
    color: "#30AEE3",
    pathActive: "/users",
    isManager: true,
  },
  {
    title: "Edit Topics",
    link: "/edit/topic/channels",
    icon: "material-symbols:topic-outline",
    color: "#0CA896",
    pathActive: "/edit/topic",
    isEditable: true,
  },
  {
    title: "Edit Project",
    link: "/edit/project",
    icon: "codicon:project",
    color: "#86D8D1",
    pathActive: "/edit/project",
    isManager: true,
  },
  {
    title: "Edit Discord",
    link: "/edit/discord/serverinvite",
    icon: "mingcute:discord-line",
    color: "#5562EA",
    pathActive: "/edit/discord",
    isEditable: true,
  },
  {
    title: "Edit Blog",
    link: "/edit/blog",
    icon: "fa-solid:blog",
    color: "#FF6D09",
    pathActive: "/edit/blog",
    isManager: true,
  },
  {
    title: "Watchlist",
    link: "/watchlist",
    icon: "ic:round-star",
    color: "#F5B97F",
    pathActive: "/watchlist",
    isAuthenticated: true,
  },
  {
    title: "Pricing",
    link: "/plans",
    icon: "carbon:pricing-consumption",
    color: "#3C5AFA",
    pathActive: "/plans",
    isAuthenticated: true,
  },
  {
    title: "Profile",
    link: "/profile",
    icon: "icomoon-free:profile",
    color: "#3C5AFA",
    pathActive: "/profile",
    isAuthenticated: true,
  },
  {
    title: "Log out",
    link: "/logout",
    icon: "tabler:logout",
    color: "#a2b223",
    pathActive: "/logout",
    isAuthenticated: true,
    logout: true,
  },
];

