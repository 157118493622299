import React from "react";
import { useHistory } from "react-router-dom";
import { Img } from "react-image";
import {
  diffTimeStamp,
  transformNumber,
} from "../../../../../utils/tableDataHelper";

const PatnerItem = ({ data, datetime = true }) => {
  const history = useHistory();
  const navigateTo = () => {
    history.push(`/youtube/channel/${data?.channelId}`); // Use history to navigate
  };
  return (
    <div className="topnews-card-list-item" onClick={navigateTo}>
      <div className={`left-top-community`}>
        <div className={`d-flex flex-row align-items-center`}>
          <Img
            width={18}
            height={18}
            src={data.thumbnail}
            style={{ borderRadius: "100%" }}
          />
          <div className="pl-3 pr-3 partner-title">{data.title}</div>
          {datetime && (
            <div className=" pr-3 partner-subtitle gray-text">
              {diffTimeStamp(data.final_datetime)}
            </div>
          )}
        </div>
      </div>

      <div className="price-change d-flex flex-row align-items-center pr-2">
        <span>{`${transformNumber(
          data.subscriberCount,
          "shorterNumber"
        )}`}</span>
      </div>
    </div>
  );
};

export default PatnerItem;
