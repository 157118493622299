import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';

import {
  Form,
  Row,
  Col
} from 'react-bootstrap';

import ProjectFilterWithoutAll from '../../ProjectFilterWithoutAll/index';
import { addServerInviteSchema } from './validation';
import { createMainModel } from './../../../../../store/actions/mainModelActions';

const AddServerInviteModal = ( props ) => {
  const { show, setShowModal } = props;
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState(null);
  const [keywordNick, setKeywordNick] = useState(null)
  const formik = useFormik({
    initialValues: {
      keyword: '',
      id:'',
      server_id: null,
      scraper_name: null,
    },
    validationSchema: addServerInviteSchema,
    onSubmit: async (values) => {
      values.keyword = keyword
      await dispatch(createMainModel(`discord/serverinvite/`, values));
    },
  });

  const udpateKeyword = (keywordObject) => {
    setKeyword(keywordObject.label);
    setKeywordNick(keywordObject.value);
  }
  
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size={'sm'}
    // aria-labelledby="filter-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 18, textAlign: 'center', width: '100%' }}>
          Add Server Invite
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="pt-3" onSubmit={formik.handleSubmit}>
          <Form.Group className="d-flex flex-row align-items-center justify-content-between pt-2">
            <div className='mr-3'>Project Name</div>
            <ProjectFilterWithoutAll
              keyword={{ label: keyword, value: keywordNick }}
              setKeyword={udpateKeyword}
            />
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Invite ID</div>
            <Form.Control
              type='text'
              size='lg'
              placeholder='Enter Server Invite ID ...'
              name='id'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.id}
            />
            {formik.touched.id && formik.errors.id ? (
              <p className="text-danger">{formik.errors.id}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Server ID</div>
            <Form.Control
              type='text'
              size='lg'
              placeholder='Enter Server ID ...'
              name='server_id'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.server_id}
            />
            {formik.touched.server_id && formik.errors.server_id ? (
              <p className="text-danger">{formik.errors.server_id}</p>
            ) : null}
          </Form.Group>
          <Form.Group className="d-flex flex-column pt-2">
            <div>Scraper Name</div>
            <Form.Control
              type='text'
              size='lg'
              placeholder='Enter Scraper Name ...'
              name='scraper_name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.scraper_name}
            />
            {formik.touched.scraper_name && formik.errors.scraper_name ? (
              <p className="text-danger">{formik.errors.scraper_name}</p>
            ) : null}
          </Form.Group>
          <Row className="mt-4">
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  disabled={!formik.isValid}
                  type="submit"
                >
                  Add
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-block btn-primary btn-lg submit login-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddServerInviteModal;