import React from "react";
import { Icon } from "@iconify/react";
import "./styles.css";

const FaqItem = ({ question, answer, onFaqClick, isOpen }) => {
  const toggleOpen = () => {
    onFaqClick();
  };
  return (
    <div className="faq-item">
      <div className="faq-item-header" onClick={toggleOpen}>
        <h3 className="faq-item-header-title">{question}</h3>
        <div className="faq-item-header-icon">
          <Icon
            icon={isOpen ? "teenyicons:up-outline" : "teenyicons:down-outline"}
            height="1em"
            color="#858CA2"
          />
        </div>
      </div>
      <div className={`${isOpen ? "faq-item-body" : "faq-item-body hidden"}`}>
        <div className="d-inline">
          <div dangerouslySetInnerHTML={{ __html: answer }} />
        </div>
      </div>
    </div>
  );
};

export default FaqItem;
