import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Breadcrumb from "../../../../components/Breadcrumb";
import { getGeneralApi } from "../../../../store/actions/getGeneralApiAction";
import { getEveryMinuteApi } from "../../../../store/actions/getEveryMinuteApiAction";
import "../../styles.css";
import { useParams } from "react-router-dom";
import { convertNick2Name } from "../../../../utils/routingHelper";
import {
  PROJECT_DETAILS_GENERAL_API,
  PROJECT_DETAILS_EVERY_MINUTE_API,
} from "../../../../config/constants/tableHeaders";
import ProjectTitle from "./ProjectTitle";
import "./styles.css";
// import ProjectSummary from './ProjectSummary';
// import ProjectLinks from './ProjectLinks';
// import ProjectStatistics from './ProjectStatistics';
// import Categories from './Categories';

const TopSummary = () => {
  const { nickName } = useParams();
  const { generalData } = useSelector((state) => state.generalApiState) || {};
  const { everyMinuteData } =
    useSelector((state) => state.everyMinuteState) || [];
  const { projectNames } = useSelector((state) => state.projectNames) || [];
  const keyword = convertNick2Name(projectNames, nickName);
  const dispatch = useDispatch();
  // const [officialLinks, setOfficialLinks] = useState({})
  const [statisticsScore, setStatisticsScore] = useState({});
  // const [categories, setCategories] = useState([])
  useEffect(() => {
    dispatch(
      getGeneralApi({
        URL: PROJECT_DETAILS_GENERAL_API,
        name: keyword,
      })
    );
    dispatch(
      getEveryMinuteApi({
        URL: PROJECT_DETAILS_EVERY_MINUTE_API,
        name: keyword,
      })
    );
    // var getEveryMinuteApiInterval = setInterval(() => {
    //   getEveryMinuteApi({
    //     URL: PROJECT_DETAILS_EVERY_MINUTE_API,
    //     name: keyword,
    //   });
    // }, 1000 * 60);
    // return () => {
    //   clearInterval(getEveryMinuteApiInterval)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nickName]);

  useEffect(() => {
    try {
      // setOfficialLinks(JSON.parse(generalData?.["description"]?.["official_links"]));
      setStatisticsScore(everyMinuteData?.statistics_score);
      // setCategories(generalData?.category);
    } catch (e) {}
  }, [generalData, everyMinuteData]);

  return (
    <div className="top-sum-con">
      {/* <Breadcrumb
        parentLink="/"
        parentLabel=""
        currentLabel={keyword}
        homeLabel="Project"
      /> */}
      <div className="row h-100">
        <div className="col-lg-12 col-sm-12 project-detail-sticky-header py-3">
          <ProjectTitle keyword={keyword} statisticsScore={statisticsScore} />
        </div>
        <div className="col-lg-12 col-sm-12 overflow-auto hide-scrollbar project-detail-body-con">
          <div style={{ height: 1000 }}></div>
        </div>
        {/* <div className="col-lg-6 col-sm-12">
          <ProjectSummary keyword={keyword} statisticsScore={statisticsScore} />
        </div> */}
      </div>
      {/* <div className="row my-2">
        <div className="col-lg-6 col-sm-12">
          <ProjectLinks categories={categories} officialLinks={officialLinks} />
          <Categories categories={categories} officialLinks={officialLinks} />
        </div>
        <div className="col-lg-6 col-sm-12">
          <ProjectStatistics keyword={keyword} statisticsScore={statisticsScore} />
        </div>
      </div> */}
    </div>
  );
};

export default TopSummary;

