import React, { useState } from "react";
import { Row } from "react-bootstrap";
import "./styles.css";
import { NavHashLink } from "react-router-hash-link";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../utils/common";

const TOC = ({ hashItems }) => {
  // get current path
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(null);

  // State to track if we're currently scrolling to a target
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleIntersection = (entries) => {
      // Assuming you want the first (top-most) section to be the active one when multiple sections are visible
      const visibleSections = entries.filter((entry) => entry.isIntersecting);
      if (entries.length && visibleSections.length) {
        const topMostSection = visibleSections.reduce((prev, curr) => {
          return prev.target.offsetTop < curr.target.offsetTop ? prev : curr;
        });
        if (!isScrolling) {
          setActiveSection(topMostSection.target.id);
        }
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // viewport
      rootMargin: "0px 0px -600px 0px",
      threshold: 0.2, // trigger callback whenever the target enters the viewport
    });

    const elements = document.querySelectorAll(".toc-element");
    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, [isScrolling]); // Depend on isScrolling to re-initialize when scrolling stops

  // Adjust handleNavClick as follows
  const handleNavClick = (name) => {
    setIsScrolling(true);
    setActiveSection(name); // Forcefully set active section based on clicked hash
    setTimeout(() => {
      setIsScrolling(false);
    }, 100); // Adjust this timeout to match your scroll duration
  };

  // Function to determine active class
  const getActiveClass = (sectionId) => {
    return activeSection === sectionId ? "active" : "";
  };

  return (
    <Row className="ml-0 mt-1">
      <div className="">
        <div className="d-flex justify-content-center w-100">
          <div className="toc-left-con d-none d-lg-block">
            <div className="d-flex flex-column w-100">
              <div>
                <div className="toc-left-con-title d-none">
                  Table of Contents
                </div>
                <div className="toc-left-con-list">
                  <div className="toc-left-con-list-outline"></div>
                  <div className="toc-left-con-list-container">
                    {hashItems.map((item) => (
                      <div
                        key={item.name}
                        className={`toc-left-con-list-item ${getActiveClass(
                          item.name
                        )}`}
                      >
                        <NavHashLink
                          smooth
                          to={`${location.pathname}${item.hash}`}
                          onClick={() => handleNavClick(item.name)}
                        >
                          {capitalizeFirstLetter(item.name)}
                        </NavHashLink>
                        {activeSection === item.name ? (
                          <>
                            <div className="active-arrow"></div>
                            <div className="active-arrow-background"></div>
                          </>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="toc-right-con" style={{ fontSize: 18 }}>
            {/* very long lorem text */}

            {hashItems.map((item, index) => {
              return (
                <div className="toc-element" id={item.name} key={index}>
                  {item.component()}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Row>
  );
};

export default TOC;
