import React from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import "../styles.css";

import { Row, Col } from "react-bootstrap";
import { selectionStyle } from "../styles";

const compare = (a, b) => {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
};

const ProjectSelectionWithoutAll = (props) => {
  const { title, project, setKeyword } = props;
  const projectNames =
    useSelector((state) => state.projectNames.projectNames) || [];

  let customStyle = selectionStyle(105, 38);
  customStyle.menuList = (styles) => ({
    ...styles,
    backgroundColor: "#323546",
    width: "200px",
    left: 0,
  });
  const items = projectNames?.map((project) => {
    return { label: project.name, value: project.nickname };
  });

  return (
    <>
      {title && (
        <Row>
          <Col>
            <label htmlFor="#" className="mb-0 text-success text-nowrap">
              {title}
            </label>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          {items && items.length && (
            <Select
              value={project !== null ? project : items[0]}
              onChange={(value) => setKeyword(value)}
              options={items.sort(compare)}
              styles={customStyle}
              isSearchable={true}
              className="mx-1"
              classNamePrefix="project-select"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProjectSelectionWithoutAll;
