import React from 'react'
import { Link } from 'react-router-dom';

const LinkButtons = ({linkItems, activeItem}) => {
  return (
    <div className="d-flex row ml-0">
      {linkItems && linkItems.map((c, i) => {
        return (
          <li key={i} className="tab">
            <Link
              to={c.value}
              className={`tab-link ${activeItem === c.label.toLowerCase() && 'active'}`}
            >
              {c.label}
            </Link>
          </li>
        );
      })}</div>
  )
}

export default LinkButtons