import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { forgetPasswordSchema } from "./validation";

import "./styles.scss";
import useMediaQuery from "../../hooks/useMediaQuery";
import { resetPassword } from "../../store/actions/authActions";

const ForgetPassword = ( props ) => {
  const isMobile = useMediaQuery();
  const dispatch = useDispatch();
  const { isAuthenticated, authCodeId, isLoading, error } = useSelector((state) => state.auth)
  const CustomHeader = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="login-signup-tab px-3 py-2"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        // onClick(e);
      }}
    >
      {children}
    </div>
  ));
  useEffect(() => {
    if (isLoading && error) props.notifyError(error);
    // eslint-disable-next-line
  }, [isLoading, error]);

  const [currentState, setCurrentState] = useState("init");
  useEffect(() => {
    if (authCodeId === null) return;
    setCurrentState("sentCode")
    // await resetPasswordFunc(signUpValues, history);
    // eslint-disable-next-line
  }, [authCodeId]);
  // currentState could be "init", "sentCode", "end"

  useEffect(() => {
    formik.setValues((values) => ({
      ...values,
      password: currentState === "init" ? "password" : "",
      confirmPassword: currentState === "init" ? "password" : "",
      authCode: currentState === "init" ? "000000" : "",
    }));
    // eslint-disable-next-line
  }, [currentState]);

  const formik = useFormik({
    initialValues: {
      email: "",
      authCode: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: async (values) => {
      if (authCodeId !== null) values["auth_id"] = authCodeId;
      await dispatch(resetPassword(values));
      if (isAuthenticated && authCodeId !== null) {
        setCurrentState("init")
        props.notifySuccess("Reseted New Password Successfully.")
        props.onHide()
      }
    },
  });

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={isMobile ? "modal-100w" : "login-signup-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title as={CustomHeader}>
            <div className="header-button active">
              Forget Pasword
              <div className="header-button-underline" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix="login-signup-modal-body">
          <Form className="pt-3" onSubmit={formik.handleSubmit}>
            {currentState === "init" && (
              <Form.Group className="d-flex flex-column pb-3">
                <div className="modal-label-name">Email Address</div>
                <Form.Control
                  placeholder="Email Address"
                  name="email"
                  className="login-signup-input"
                  type="email"
                  size="lg"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />

                {formik.touched.email && formik.errors.email ? (
                  <p className="text-danger">{formik.errors.email}</p>
                ) : null}
              </Form.Group>
            )}
            {currentState === "sentCode" && (
              <Form.Group className="d-flex flex-column pb-3">
                <div className="modal-label-name">Validation Code</div>
                <Form.Control
                  placeholder="Validation Code"
                  name="authCode"
                  className="login-signup-input"
                  type="text"
                  size="lg"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.authCode}
                />

                {formik.touched.authCode && formik.errors.authCode ? (
                  <p className="text-danger">{formik.errors.authCode}</p>
                ) : null}
              </Form.Group>
            )}
            {currentState === "sentCode" && (
              <>
                <Form.Group className="d-flex flex-column pb-3">
                  <div className="modal-label-name">Password</div>
                  <Form.Control
                    placeholder="Password"
                    name="password"
                    className="login-signup-input"
                    type="password"
                    size="lg"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />

                  {formik.touched.password && formik.errors.password ? (
                    <p className="text-danger">{formik.errors.password}</p>
                  ) : null}
                </Form.Group>
                <Form.Group className="d-flex flex-column pb-3">
                  <div className="modal-label-name">Confirm Password</div>
                  <Form.Control
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    className="login-signup-input"
                    type="password"
                    size="lg"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                  />

                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <p className="text-danger">
                      {formik.errors.confirmPassword}
                    </p>
                  ) : null}
                </Form.Group>
              </>
            )}

            {error && <p className="text-danger">{error}</p>}
            <div className="mt-5">
              <button
                className="btn btn-block btn-primary btn-lg submit login-button"
                disabled={isLoading || !formik.isValid}
                type="submit"
              >
                {currentState === "init"
                  ? "Request Validation Code"
                  : "Reset Password"}
              </button>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          {/* footer */}
          {/* <Button variant="secondary">
            Close
          </Button>
          <Button variant="primary">
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};


export default ForgetPassword;