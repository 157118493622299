import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectNames, setProjectNames } from './store/actions/projectNamesActions';
import { getTopics, setTopics } from './store/actions/topicsActions';
import { getPrefilters, setPrefilters } from './store/actions/prefiltersActions';
import { TIME_24H } from './config/constants/data';
import { getSearchItems } from './store/actions/searchItemsActions';

const LoadSetting = () => {
  const dispatch = useDispatch();
  const projectNamesState = useSelector(state => state.projectNames);
  const prefiltersState = useSelector(state => state.prefilters);
  const topicsState = useSelector(state => state.topics);

  const { projectNames, projectNamesCount, projectNamesLoading } = projectNamesState;
  const { prefilters, prefiltersCount, prefiltersLoading } = prefiltersState;
  const { topics, topicsCount, topicsLoading } = topicsState;

  const getAllStaticStates = () => {
    dispatch(getProjectNames());
    dispatch(getPrefilters());
    dispatch(getTopics());
    dispatch(getSearchItems());
  }

  const setAllStaticStates = () => {
    dispatch(setProjectNames({
      projectNames: JSON.parse(localStorage.getItem("projectNames")), 
      projectNamesCount: localStorage.getItem("projectNamesCount")
    }));
    dispatch(setPrefilters({
      prefilters: JSON.parse(localStorage.getItem("prefilters")), 
      prefiltersCount: localStorage.getItem("prefiltersCount")
    }));
    dispatch(setTopics({
      topics: JSON.parse(localStorage.getItem("topics")), 
      topicsCount: localStorage.getItem("topicsCount")
    }));
  }

  useEffect(() => {
    getAllStaticStates();
    var getAllStaticStatesInterval = setInterval(() => {
      getAllStaticStates();
    }, TIME_24H);
    setTimeout(() => {
      setAllStaticStates();
    }, 3000);
    
    // The commented section for getGlobalStatisticsApi can be included as needed.

    return () => {
      clearInterval(getAllStaticStatesInterval);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (! projectNamesLoading && projectNames) {
      localStorage.setItem("projectNames", JSON.stringify(projectNames));
      localStorage.setItem("projectNamesCount", projectNamesCount);
    }
  }, [projectNames, projectNamesCount, projectNamesLoading]);

  useEffect(() => {
    if (! prefiltersLoading && prefilters) {
      localStorage.setItem("prefilters", JSON.stringify(prefilters));
      localStorage.setItem("prefiltersCount", prefiltersCount);
    }
  }, [prefilters, prefiltersCount, prefiltersLoading]);

  useEffect(() => {
    if (! topicsLoading && topics) {
      localStorage.setItem("topics", JSON.stringify(topics));
      localStorage.setItem("topicsCount", topicsCount);
    }
  }, [topics, topicsCount, topicsLoading]);

  return (
    <></>
  );
};

export default LoadSetting;