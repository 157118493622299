import React from "react";

function IconButton({ icon, title, style, onClick, className, titleStyle }) {
  return (
    <div
      className={className ? `${className} icon-btn` : "icon-btn"}
      onClick={onClick}
      style={style}
    >
      {icon}
      {title ? (
        <span className="lead pl-3 font-weight-bold" style={titleStyle}>
          {title}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default IconButton;

