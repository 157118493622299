import {
  GET_TOP_USERS_LOADING,
  GET_TOP_USERS_SUCCESS,
  GET_TOP_USERS_FAIL,
} from '../types';

const initialState = {
  topUsersData: [],
  topUsersApiLoading: false,
  topUsersError: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_TOP_USERS_LOADING:
      return {
        ...state,
        topUsersApiLoading: true,
        topUsersError: null,
      };
    case GET_TOP_USERS_SUCCESS:
      return {
        ...state,
        topUsersData: payload.topUsersData,
        topUsersApiLoading: false,
        topUsersError: null,
      };
    case GET_TOP_USERS_FAIL:
      return {
        ...state,
        topUsersData: [],
        topUsersApiLoading: false,
        topUsersError: payload?.topUsersError
      };
    default:
      return state;
  }
}
