export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const ME_LOADING = "ME_LOADING";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_FAIL = "ME_FAIL";

export const GET_USER_PROFILE_LOADING = "GET_USER_PROFILE_LOADING";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";

export const CONTACT_MESSAGE_SEND_LOADING = "CONTACT_MESSAGE_SEND_LOADING";
export const CONTACT_MESSAGE_SEND_SUCCESS = "CONTACT_MESSAGE_SEND_SUCCESS";
export const CONTACT_MESSAGE_SEND_FAIL = "CONTACT_MESSAGE_SEND_FAIL";

export const SET_USER_PROFILE_LOADING = "SET_USER_PROFILE_LOADING";
export const SET_USER_PROFILE_SUCCESS = "SET_USER_PROFILE_SUCCESS";
export const SET_USER_PROFILE_FAIL = "SET_USER_PROFILE_FAIL";

export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const REGISTER_WITH_EMAIL_LOADING = "REGISTER_WITH_EMAIL_LOADING";
export const REGISTER_WITH_EMAIL_SUCCESS = "REGISTER_WITH_EMAIL_SUCCESS";
export const REGISTER_WITH_EMAIL_GET_CODE = "REGISTER_WITH_EMAIL_GET_CODE";
export const REGISTER_WITH_EMAIL_FAIL = "REGISTER_WITH_EMAIL_FAIL";

// User Vote Types
export const GET_USER_VOTE_VALUE_LOADING = "GET_USER_VOTE_VALUE_LOADING";
export const GET_USER_VOTE_VALUE_SUCCESS = "GET_USER_VOTE_VALUE_SUCCESS";
export const GET_USER_VOTE_VALUE_FAIL = "GET_USER_VOTE_VALUE_FAIL";

export const GET_SPECIAL_VOTE_VALUE_LOADING = "GET_SPECIAL_VOTE_VALUE_LOADING";
export const GET_SPECIAL_VOTE_VALUE_SUCCESS = "GET_SPECIAL_VOTE_VALUE_SUCCESS";
export const GET_SPECIAL_VOTE_VALUE_FAIL = "GET_SPECIAL_VOTE_VALUE_FAIL";

export const SET_USER_VOTE_VALUE_LOADING = "SET_USER_VOTE_VALUE_LOADING";
export const SET_USER_VOTE_VALUE_SUCCESS = "SET_USER_VOTE_VALUE_SUCCESS";
export const SET_USER_VOTE_VALUE_FAIL = "SET_USER_VOTE_VALUE_FAIL";

export const SET_SPECIAL_VOTE_VALUE_LOADING = "SET_SPECIAL_VOTE_VALUE_LOADING";
export const SET_SPECIAL_VOTE_VALUE_SUCCESS = "SET_SPECIAL_VOTE_VALUE_SUCCESS";
export const SET_SPECIAL_VOTE_VALUE_FAIL = "SET_SPECIAL_VOTE_VALUE_FAIL";

export const GET_USER_BOOKMARK_LOADING = "GET_USER_BOOKMARK_LOADING";
export const GET_USER_BOOKMARK_SUCCESS = "GET_USER_BOOKMARK_SUCCESS";
export const GET_USER_BOOKMARK_FAIL = "GET_USER_BOOKMARK_FAIL";

export const RELOAD_USER_BOOKMARK_START = "RELOAD_USER_BOOKMARK_START";
export const RELOAD_USER_BOOKMARK_SUCCESS = "RELOAD_USER_BOOKMARK_SUCCESS";

export const SET_USER_BOOKMARK_LOADING = "SET_USER_BOOKMARK_LOADING";
export const SET_USER_BOOKMARK_SUCCESS = "SET_USER_BOOKMARK_SUCCESS";
export const SET_USER_BOOKMARK_FAIL = "SET_USER_BOOKMARK_FAIL";

export const SET_SUBSCRIBE_EMAIL_LOADING = "SET_SUBSCRIBE_EMAIL_LOADING";
export const SET_SUBSCRIBE_EMAIL_SUCCESS = "SET_SUBSCRIBE_EMAIL_SUCCESS";
export const SET_SUBSCRIBE_EMAIL_FAIL = "SET_SUBSCRIBE_EMAIL_FAIL";

// Project Details Types
export const GET_PROJECT_DETAILS_LOADING = "GET_PROJECT_DETAILS_LOADING";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";
export const GET_PROJECT_DETAILS_FAIL = "GET_PROJECT_DETAILS_FAIL";
// GET Main Api Types
export const GET_MAIN_API_LOADING = "GET_MAIN_API_LOADING";
export const GET_MAIN_API_SUCCESS = "GET_MAIN_API_SUCCESS";
export const GET_MAIN_API_FAIL = "GET_MAIN_API_FAIL";
export const UPDATE_MAIN_DATA = "UPDATE_MAIN_DATA";
// GET General Api Types
export const GET_GENERAL_API_LOADING = "GET_GENERAL_API_LOADING";
export const GET_GENERAL_API_SUCCESS = "GET_GENERAL_API_SUCCESS";
export const GET_GENERAL_API_FAIL = "GET_GENERAL_API_FAIL";
// GET Chart Api Types
export const GET_CHART_API_LOADING = "GET_CHART_API_LOADING";
export const GET_CHART_API_SUCCESS = "GET_CHART_API_SUCCESS";
export const GET_CHART_API_FAIL = "GET_CHART_API_FAIL";
// GET Search Items Types
export const GET_SEARCH_ITEMS_LOADING = "GET_SEARCH_ITEMS_LOADING";
export const GET_SEARCH_ITEMS_SUCCESS = "GET_SEARCH_ITEMS_SUCCESS";
export const GET_SEARCH_ITEMS_FAIL = "GET_SEARCH_ITEMS_FAIL";
// GET Project Names Types
export const GET_PROJECT_NAMES_LOADING = "GET_PROJECT_NAMES_LOADING";
export const GET_PROJECT_NAMES_SUCCESS = "GET_PROJECT_NAMES_SUCCESS";
export const GET_PROJECT_NAMES_FAIL = "GET_PROJECT_NAMES_FAIL";
// GET Prefilters Types
export const GET_PREFILTERS_LOADING = "GET_PREFILTERS_LOADING";
export const GET_PREFILTERS_SUCCESS = "GET_PREFILTERS_SUCCESS";
export const GET_PREFILTERS_FAIL = "GET_PREFILTERS_FAIL";
// GET Topics Types
export const GET_TOPICS_LOADING = "GET_TOPICS_LOADING";
export const GET_TOPICS_SUCCESS = "GET_TOPICS_SUCCESS";
export const GET_TOPICS_FAIL = "GET_TOPICS_FAIL";
// SET Topic Types
export const SET_TOPIC_VALUE_LOADING = "SET_TOPIC_VALUE_LOADING";
export const SET_TOPIC_VALUE_SUCCESS = "SET_TOPIC_VALUE_SUCCESS";
export const SET_TOPIC_VALUE_FAIL = "SET_TOPIC_VALUE_FAIL";

// GET Every Minute Types
export const GET_EVERY_MINUTE_LOADING = "GET_EVERY_MINUTE_LOADING";
export const GET_EVERY_MINUTE_SUCCESS = "GET_EVERY_MINUTE_SUCCESS";
export const GET_EVERY_MINUTE_FAIL = "GET_EVERY_MINUTE_FAIL";

// GET Highlight Data Types
export const GET_HIGHLIGHT_DATA_LOADING = "GET_HIGHLIGHT_DATA_LOADING";
export const GET_HIGHLIGHT_DATA_SUCCESS = "GET_HIGHLIGHT_DATA_SUCCESS";
export const GET_HIGHLIGHT_DATA_FAIL = "GET_HIGHLIGHT_DATA_FAIL";

// GET Global Statistics Types
export const GET_GLOBAL_STATISTICS_LOADING = "GET_GLOBAL_STATISTICS_LOADING";
export const GET_GLOBAL_STATISTICS_SUCCESS = "GET_GLOBAL_STATISTICS_SUCCESS";
export const GET_GLOBAL_STATISTICS_FAIL = "GET_GLOBAL_STATISTICS_FAIL";

// GET Twitter Top Users Types
export const GET_TOP_USERS_LOADING = "GET_TOP_USERS_LOADING";
export const GET_TOP_USERS_SUCCESS = "GET_TOP_USERS_SUCCESS";
export const GET_TOP_USERS_FAIL = "GET_TOP_USERS_FAIL";

// GET Youtube Top Channels Types
export const GET_TOP_CHANNELS_LOADING = "GET_TOP_CHANNELS_LOADING";
export const GET_TOP_CHANNELS_SUCCESS = "GET_TOP_CHANNELS_SUCCESS";
export const GET_TOP_CHANNELS_FAIL = "GET_TOP_CHANNELS_FAIL";

// GET Youtube MOST Videos Types
export const GET_MOST_VIDEOS_LOADING = "GET_MOST_VIDEOS_LOADING";
export const GET_MOST_VIDEOS_SUCCESS = "GET_MOST_VIDEOS_SUCCESS";
export const GET_MOST_VIDEOS_FAIL = "GET_MOST_VIDEOS_FAIL";

// GET Youtube RECENT Videos Types
export const GET_RECENT_VIDEOS_LOADING = "GET_RECENT_VIDEOS_LOADING";
export const GET_RECENT_VIDEOS_SUCCESS = "GET_RECENT_VIDEOS_SUCCESS";
export const GET_RECENT_VIDEOS_FAIL = "GET_RECENT_VIDEOS_FAIL";

// POST Create new Project Types
export const ADMIN_EDIT_PROJECT_LOADING = "ADMIN_EDIT_PROJECT_LOADING";
export const ADMIN_EDIT_PROJECT_SUCCESS = "ADMIN_EDIT_PROJECT_SUCCESS";
export const ADMIN_EDIT_PROJECT_FAIL = "ADMIN_EDIT_PROJECT_FAIL";
// POST Create new Keyword Types
export const ADMIN_EDIT_KEYWORD_LOADING = "ADMIN_EDIT_KEYWORD_LOADING";
export const ADMIN_EDIT_KEYWORD_SUCCESS = "ADMIN_EDIT_KEYWORD_SUCCESS";
export const ADMIN_EDIT_KEYWORD_FAIL = "ADMIN_EDIT_KEYWORD_FAIL";
// Edit  Category Types
export const ADMIN_EDIT_CATEGORY_LOADING = "ADMIN_EDIT_CATEGORY_LOADING";
export const ADMIN_EDIT_CATEGORY_SUCCESS = "ADMIN_EDIT_CATEGORY_SUCCESS";
export const ADMIN_EDIT_CATEGORY_FAIL = "ADMIN_EDIT_CATEGORY_FAIL";
// Edit Blog Types
export const ADMIN_EDIT_BLOG_LOADING = "ADMIN_EDIT_BLOG_LOADING";
export const ADMIN_EDIT_BLOG_SUCCESS = "ADMIN_EDIT_BLOG_SUCCESS";
export const ADMIN_EDIT_BLOG_FAIL = "ADMIN_EDIT_BLOG_FAIL";

// GET SUBSCRIPTION PAYMENT Types
export const SUBSCRIPTION_PAYMENT_LOADING = "SUBSCRIPTION_PAYMENT_LOADING";
export const SUBSCRIPTION_PAYMENT_SUCCESS = "SUBSCRIPTION_PAYMENT_SUCCESS";
export const SUBSCRIPTION_PAYMENT_FAIL = "SUBSCRIPTION_PAYMENT_FAIL";

// UI settings
export const SET_MINISIDENAV = "SET_MINISIDENAV";
export const SET_TERMS = "SET_TERMS";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_TOPIC_TYPE = "SET_TOPIC_TYPE";
export const SET_TABLE_TYPE = "SET_TABLE_TYPE";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_SEARCH_TEXT_GLOBAL = "SET_SEARCH_TEXT_GLOBAL";

// TODO new state management structure.
// mainModel
// Asynchronous types from calling apis.
export const FETCH_MAIN_MODEL_REQUEST = "FETCH_MAIN_MODEL_REQUEST";
export const MAIN_MODEL_REQUEST = "MAIN_MODEL_REQUEST";
export const FETCH_MAIN_MODEL_SUCCESS = "FETCH_MAIN_MODEL_SUCCESS";
export const UPDATE_MAIN_MODEL_SUCCESS = "UPDATE_MAIN_MODEL_SUCCESS";
export const CREATE_MAIN_MODEL_SUCCESS = "CREATE_MAIN_MODEL_SUCCESS";
export const POST_MAIN_MODEL_SUCCESS = "POST_MAIN_MODEL_SUCCESS";
export const DELETE_MAIN_MODEL_SUCCESS = "DELETE_MAIN_MODEL_SUCCESS";
export const MAIN_MODEL_FAILURE = "MAIN_MODEL_FAILURE";
// Synchronous types.
export const UPDATE_MAIN_MODEL = "UPDATE_MAIN_MODEL";

export const SET_SOCIAL_DOMAINS = "SET_SOCIAL_DOMAINS";
export const SET_WEBSITE_TOPICS = "SET_WEBSITE_TOPICS";

// subModel
export const SUB_MODEL_REQUEST = "SUB_MODEL_REQUEST";
export const SUB_MODEL_FAILURE = "SUB_MODEL_FAILURE";
export const FETCH_SUB_MODEL_SUCCESS = "FETCH_SUB_MODEL_SUCCESS";
export const UPDATE_SUB_MODEL_SUCCESS = "UPDATE_SUB_MODEL_SUCCESS";
export const CREATE_SUB_MODEL_SUCCESS = "CREATE_SUB_MODEL_SUCCESS";
export const DELETE_SUB_MODEL_SUCCESS = "DELETE_SUB_MODEL_SUCCESS";
// export const UPDATE_SUB_MODEL = 'UPDATE_SUB_MODEL';
