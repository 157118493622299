import {
    SET_USER_VOTE_VALUE_LOADING,
    SET_USER_VOTE_VALUE_SUCCESS,
    SET_USER_VOTE_VALUE_FAIL,
    GET_USER_VOTE_VALUE_LOADING,
    GET_USER_VOTE_VALUE_SUCCESS,
    GET_USER_VOTE_VALUE_FAIL,
    SET_SPECIAL_VOTE_VALUE_LOADING,
    SET_SPECIAL_VOTE_VALUE_SUCCESS,
    SET_SPECIAL_VOTE_VALUE_FAIL,
    GET_SPECIAL_VOTE_VALUE_FAIL,
    GET_SPECIAL_VOTE_VALUE_SUCCESS,
    GET_SPECIAL_VOTE_VALUE_LOADING
} from '../types';

const initialState = {
    data: [],
    specialData: [],
    isLoading: false,
    error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
    switch (type) {
        case SET_USER_VOTE_VALUE_LOADING:
        case GET_USER_VOTE_VALUE_LOADING:
        case SET_SPECIAL_VOTE_VALUE_LOADING:
        case GET_SPECIAL_VOTE_VALUE_LOADING:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case SET_USER_VOTE_VALUE_SUCCESS:
        case SET_SPECIAL_VOTE_VALUE_SUCCESS:
            return {
                ...state,
                // data: payload.data,
                isLoading: false,
                error: null,
            };
        case GET_USER_VOTE_VALUE_SUCCESS:
            return {
                ...state,
                data: payload.data,
                isLoading: false,
                error: null,
            };
        case GET_SPECIAL_VOTE_VALUE_SUCCESS:
            return {
                ...state,
                specialData: payload.data,
                isLoading: false,
                error: null,
            };
        case SET_USER_VOTE_VALUE_FAIL:
        case GET_USER_VOTE_VALUE_FAIL:
            return {
                ...state,
                data: [],
                isLoading: false,
                error: payload?.error
            };
        case GET_SPECIAL_VOTE_VALUE_FAIL:
        case SET_SPECIAL_VOTE_VALUE_FAIL:
            return {
                ...state,
                specialData: [],
                isLoading: false,
                error: payload?.error
            };
        default:
            return state;
    }
}
