import React, { useEffect } from "react";

import MainTable from "../../../../components/Tables/MainTable";
import { useLocation, useParams } from "react-router-dom";

import { YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS } from "../../../../config/constants/data";
import {
  YOUTUBE_VIDEOS_TABLE_HEADER,
  YOUTUBE_VIDEOS_MAIN_TABLE_API,
} from "../../../../config/constants/tableHeaders";
import { RecommendItems } from "../..";

const YoutubeVideos = (props) => {
  const { setSelectedRoute } = props;
  const UPDATE_YOUTUBE_VIDEOS_TABLE_HEADER = YOUTUBE_VIDEOS_TABLE_HEADER.filter(
    (header) => header.title !== "Project"
  );
  const { nickName } = useParams();
  const location = useLocation();
  let filters = window.advancedFilters || {};
  YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS.forEach((field) => {
    filters[field.value] = "";
  });
  filters = { ...filters, ...location.state };
  useEffect(() => {
    setSelectedRoute("videos");
  }, [setSelectedRoute]);

  return (
    <>
      <MainTable
        tableHeader={UPDATE_YOUTUBE_VIDEOS_TABLE_HEADER}
        URL={YOUTUBE_VIDEOS_MAIN_TABLE_API}
        filterModalFields={YOUTUBE_VIDEOS_FILTER_MODAL_FIELDS}
        withTermsFilter
        withoutProjectFilter
        withVideoLengthFilter
        projectNickName={nickName}
        filters={filters}
        defaultTerm={"7d"}
        model={"youtube-videos"}
        withoutTopicFilter
        modelName="project-youtube-video--nickName"
      />
      <RecommendItems />
    </>
  );
};

export default YoutubeVideos;

