import React from 'react';
import EditMainTable from '../../../../../components/Tables/EditMainTable'
import { LINK_WEBSITE_TOPIC_MAIN_TABLE_API, WEBSITE_TOPIC_HEADER } from '../../../../../config/constants/tableHeaders'

const Website = () => {
  let updatedHeader = structuredClone(WEBSITE_TOPIC_HEADER);
  updatedHeader = updatedHeader.filter(header => (header.title!=='Vote' && header.title!==''))
  updatedHeader[updatedHeader.length-1].type = "component";
  updatedHeader[updatedHeader.length-1].checked = true;
  
  return (
    <EditMainTable
      tableHeader={updatedHeader}
      URL={LINK_WEBSITE_TOPIC_MAIN_TABLE_API}
      withTermsFilter
      withoutProjectFilter
      withOutCategorySelection
      withoutFilterModal
      model={"website"}
      modelName="edit-topic-website"
    />
  )
}

export default Website