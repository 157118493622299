import {
  GET_HIGHLIGHT_DATA_LOADING,
  GET_HIGHLIGHT_DATA_SUCCESS,
  GET_HIGHLIGHT_DATA_FAIL,
} from '../types';

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_HIGHLIGHT_DATA_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_HIGHLIGHT_DATA_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
        error: null,
      };
    case GET_HIGHLIGHT_DATA_FAIL:
      return {
        ...state,
        data: [],
        isLoading: false,
        error: payload?.error
      };
    default:
      return state;
  }
}
