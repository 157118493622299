import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  NEWS_TABLE_HEADER,
  YOUTUBE_CHANNEL_DETAILS_WEBSITE_NEWS_API,
} from "../../../../config/constants/tableHeaders";
import MainTable from "../../../../components/Tables/MainTable";
import { RecommendItems } from "../..";

const WebsiteNews = (props) => {
  const { channelId } = useParams();
  useEffect(() => {
    props.setSelectedRoute("news");
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <MainTable
        tableHeader={NEWS_TABLE_HEADER}
        URL={YOUTUBE_CHANNEL_DETAILS_WEBSITE_NEWS_API.replace(
          "{channelId}",
          channelId
        )}
        withTermsFilter
        withoutProjectFilter
        withoutFilterModal
        withoutTopicFilter
        defaultTerm={"7d"}
        model={"news"}
        modelName="youtube-channel-news--channelId"
      />
      <RecommendItems />
    </div>
  );
};
export default WebsiteNews;

